import { Component, Input, OnInit } from "@angular/core";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";

@Component({
  selector: "app-voucher-list",
  templateUrl: "./voucher-list.component.html",
})
export class VoucherListComponent implements OnInit {
  @Input() travelDestinations!: TravelDestination[];
  @Input() editVoucherUrlTemplate!: (voucherId: number) => string;
  voucherTab!: number;
  selectedDestination?: TravelDestination;

  ngOnInit() {
    this.selectDestination(this.travelDestinations[0].id);
  }

  selectDestination(voucherId: number | undefined) {
    this.selectedDestination = this.travelDestinations.find((voucher) => voucher.id === voucherId);
    this.voucherTab = this.selectedDestination?.id || 0;
  }
}
