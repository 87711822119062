import { Observable } from "rxjs";
import { Ticket } from "../models/ticket";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Customer } from "../../customers/models/customer";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class TicketsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getPassangersData(id: number | undefined) {
    return this.http.get<Customer[]>(`${this.apiUrl}/api/Passangers/ticket/${id}`);
  }

  getTicketData(id: number | undefined) {
    return this.http.get<Ticket>(`${this.apiUrl}/api/Tickets/${id}`);
  }
  deleteTicket(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/Tickets/${id}`, { responseType: "text" });
  }
}
