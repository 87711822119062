<div *ngIf="selectedItems.length === maxSelection && select.focused" class="alert alert-warning mb-2">
  <small>{{ "merge_max_selection_reached" | translate }}</small>
</div>
<ng-select #select [items]="items" [multiple]="true" [maxSelectedItems]="maxSelection" [bindLabel]="bindLabel" [(ngModel)]="selectedItems"></ng-select>
<div class="mt-7">
  <button (click)="onMerge()" class="btn btn-primary w-full" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingTemplate">{{ "merge_selected" | translate }}</ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
    </ng-template>
  </button>
</div>
