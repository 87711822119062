<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <button type="button" (click)="isShowingSidebar = !isShowingSidebar" class="btn btn-primary gap-2">
    <icon-notes></icon-notes>
    {{ "show_file_history" | translate }}
  </button>
  <button type="button" class="btn btn-info gap-2" (click)="sendContract()">
    <icon-send />
    {{ "contract.send" | translate }}
  </button>
  <button type="button" class="btn btn-primary gap-2" (click)="printContract()">
    <icon-printer />
    {{ "contract.print" | translate }}
  </button>
  <button type="button" class="btn btn-warning gap-2" (click)="editContract()">
    <icon-edit />
    {{ "contract.edit" | translate }}
  </button>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id"></app-file-history>
</div>
<div class="panel" *ngIf="contract" id="contract">
  <div class="grid text-center text-xl font-bold">
    <p>{{ "contract.travel_contract" | translate }}</p>
    <p>br. {{ contract.uniqueId }} od {{ contract.createDate | date : "dd.MM.yyyy" }}</p>
  </div>
  <div class="mt-4 grid grid-cols-1 gap-1 sm:grid-cols-1">
    <div class="col-span-2 flex flex-col sm:col-span-1">
      <div class="">{{ "contract.traveller_information" | translate }}</div>
    </div>
    <table class="table-responsive mb-3 w-full border-gray-400">
      <thead class="border-top: 2px">
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
          <th class="border border-gray-300 sm:col-span-1">{{ "contract.name_and_surname" | translate }}</th>
          <th class="border border-gray-300 sm:col-span-1">{{ "contract.date_of_birth" | translate }}</th>
          <th class="border border-gray-300 sm:col-span-1">{{ "contract.address" | translate }}</th>
          <th class="border border-gray-300 sm:col-span-1">{{ "contract.contact" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let passanger of contract.passengers; index as i">
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">{{ passanger?.name }} {{ passanger?.surname }}</td>
            <td class="border border-gray-300 sm:col-span-1">{{ passanger?.dateOfBirth | date : "dd/MM/yyyy" }}</td>
            <td class="border border-gray-300 sm:col-span-1">{{ passanger?.address }}</td>
            <td class="border border-gray-300 sm:col-span-1">{{ passanger?.phoneNumber }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="col-span-2 flex flex-col sm:col-span-1">
      <div class="">{{ "contract.traveller_information" | translate }}</div>
    </div>
    <table class="table-responsive w-full border-gray-400">
      <thead class="border-top: 2px">
        <tr class="ng-star-inserted border border-gray-300 bg-gray-100">
          <th class="border border-gray-300">{{ "contract.hotel" | translate }}</th>
          <th class="border border-gray-300">{{ "contract.rooms" | translate }}</th>
          <th class="border border-gray-300">{{ "contract.check_in" | translate }}</th>
          <th class="border border-gray-300">{{ "contract.check_out" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
          <td class="border border-gray-300">{{ contract.hotel }}</td>
          <td class="border border-gray-300">{{ contract.rooms }}</td>
          <td class="border border-gray-300">{{ contract.checkIn | date : "dd/MM/yyyy" }}</td>
          <td class="border border-gray-300">{{ contract.checkOut | date : "dd/MM/yyyy" }}</td>
        </tr>
      </tbody>
      <thead class="border-top: 2px">
        <tr class="ng-star-inserted border border-gray-300 bg-gray-100">
          <th class="border border-gray-300">{{ "contract.destination" | translate }}</th>
          <th class="border border-gray-300">{{ "contract.start_date" | translate }}</th>
          <th class="border border-gray-300">{{ "contract.end_date" | translate }}</th>
          <th class="border border-gray-300">{{ "contract.transportation" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
          <td class="border border-gray-300">{{ contract.destination }}</td>
          <td class="border border-gray-300">{{ contract.startDate | date : "dd/MM/yyyy" }}</td>
          <td class="border border-gray-300">{{ contract.endDate | date : "dd/MM/yyyy" }}</td>
          <td class="border border-gray-300">{{ contract.transportation }}</td>
        </tr>
      </tbody>
    </table>

    <div class="col-span-2 flex flex-col sm:col-span-1">
      <div class="">{{ "contract.arrangement_information" | translate }}</div>
      <div class="text-start">Trip Organizer</div>
      <div class="flex text-start">
        <div class="font-bold">{{ contract.organizerInfo }}</div>
      </div>
      <div class="font-bold">{{ "contract.agent" | translate }}: PTTU ECCO D.O.O, 28. novembra 54, NP, Licenca OTP 243/2021</div>
      <div class="flex text-start">
        <div class="font-bold">{{ contract.organizerResponsiblePerson }}</div>
      </div>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="-dark text-white"></div>
      <div class="">{{ "contract.trip_invoice" | translate }}</div>
    </div>
    <table class="table-bordered mb-5 table">
      <thead class="bg-gray-100">
        <tr class="ng-star-inserted">
          <th class="border border-gray-300 sm:col-span-1">Id</th>
          <th class="border border-gray-300 sm:col-span-2" colspan="2">{{ "contract.description" | translate }}</th>
          <th class="border border-gray-300 sm:col-span-1">{{ "contract.sum" | translate }}</th>
          <th class="border border-gray-300 sm:col-span-1">{{ "contract.total" | translate }}</th>
        </tr>
      </thead>
      <tbody class="bg-gray-50">
        <tr class="ng-star-inserted">
          <td class="border border-gray-300 sm:col-span-1">1</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.adult_price" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.priceForAdult }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.numberOfAdoults }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.priceForAdult * contract.numberOfAdoults }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">2</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.adult_price" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.priceForChild }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.numberOfChildren }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.priceForChild * contract.numberOfChildren }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">3</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.suplement_for_trip_data" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.supplementForTravelPrice }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.supplementForTravelAmount }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.supplementForTravelPrice * contract.supplementForTravelAmount }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">4</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.suplement_for_ny_supper" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.supplementForNYPrice }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.supplementForNYAmount }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.supplementForNYPrice * contract.supplementForNYAmount }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">5</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.air_tax" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.airportTaxPrice }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.airportAmount }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.airportTaxPrice * contract.airportAmount }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">6</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.visum" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.visumPrice }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.visumAmount }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.visumPrice * contract.visumAmount }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">7</td>
          <td class="border border-gray-300 sm:col-span-1">{{ "contract.tax" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.taxPrice }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.taxAmount }}</td>
          <td class="border border-gray-300 sm:col-span-1">{{ contract.taxPrice * contract.taxAmount }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">8</td>
          <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.total_in_euro" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ contract.totalInEuroPrice }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1">9</td>
          <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.total_in_din" | translate }}</td>
          <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ contract.priceTotal }}</td>
        </tr>
        <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
          <td class="border border-gray-300 sm:col-span-1" colspan="3"></td>
          <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.fiscal_receipt_number" | translate }} : {{ contract.fiscalRecipetId }}</td>
        </tr>
      </tbody>
    </table>
    <div class="justify-content-between flex w-full">
      <div class="w-full">{{ "contract.payment_method" | translate }}:</div>
      <div class="w-full">{{ "contract.cash" | translate }}:X</div>
      <div class="w-full">{{ "contract.check" | translate }}:</div>
      <div class="w-full">{{ "contract.other" | translate }}: x</div>
    </div>
    <div class="w-full text-start">
      <p>{{ "contract.note" | translate }}:___________________________. {{ "contract.approved_deffered_payment_until" | translate }} _________________________________________.</p>
    </div>
    <div class="mb-4 w-full text-start">
      <p class="">
        <u>{{ "contract.guarantee" | translate }}</u>
      </p>
      <p>
        {{ "contract.section_1" | translate }}
      </p>
      <p>
        {{ "contract.complaint" | translate }}<br />
        <u>{{ "contract.statement" | translate }}</u>
      </p>
    </div>
    <div class="justify-content-end mt-5 flex w-full">
      <div class="w-full text-start">
        <p>{{ "contract.signature" | translate }}</p>
        <br />
        <p>_____________________________</p>
      </div>
      <div class="w-full text-end">
        <p>PTTO ECCO d.o.o.</p>
        <br />
        <p>_____________________________</p>
        <p>Direktor Esat Gicic</p>
      </div>
    </div>

    <div class="grid grid-cols-3 text-start sm:grid-cols-1">
      <p class="font-bold">{{ "contract.special_terms" | translate }}:</p>
      <br />
      <p>&nbsp; {{ "contract.section_2" | translate }}</p>
      <br />
      <p class="p-5">
        {{ "contract.5_%" | translate }}<br />
        {{ "contract.10_%" | translate }}<br />
        {{ "contract.20_%" | translate }}<br />
        {{ "contract.40_%" | translate }}<br />
        {{ "contract.80_%" | translate }}a<br />
        {{ "contract.90_%" | translate }}<br />
        {{ "contract.100_%" | translate }}
      </p>
    </div>

    <div class="grid grid-cols-3 text-start sm:grid-cols-1">
      <p>&nbsp; {{ "contract.section_3" | translate }}</p>
      <br />
      <p>&nbsp; {{ "contract.section_4" | translate }}</p>
      <br />
      <p>&nbsp; {{ "contract.section_5" | translate }}</p>
      <br />
      &nbsp; {{ "contract.section_6" | translate }}
    </div>
  </div>
</div>

<modal #modal class="modal-top custom-modal">
  <ng-template #modalBody>
    <ng-container *ngIf="contract">
      <app-file-send
        [title]="'contract_form_title_slip' | translate"
        fileType="contract"
        [passangers]="contract.passengers"
        [sendDocumentFn]="contractService.sendContract(this.contract.id)"
        [messageRs]="'sending_contract_RS_message' | translate"
        [messageEn]="'sending_contract_EN_message' | translate"
        (notifyWhenSend)="finishSending()"
      >
        <table class="mb-4 w-full table-auto">
          <thead>
            <tr>
              <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let passenger of contract.passengers">
              <td class="border px-4 py-2">{{ passenger.name }}</td>
              <td class="border px-4 py-2">{{ passenger.surname }}</td>
              <td class="border px-4 py-2">{{ passenger.email }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive mb-3 w-full border-gray-400">
          <thead class="border-top: 2px">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
              <th class="border border-gray-300 sm:col-span-1">{{ "contractsendemail.createdate" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "contractsendemail.pricetotal" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "contractsendemail.hotel" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "contractsendemail.rooms" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
              <td class="border border-gray-300 sm:col-span-1">{{ this.contract.createDate | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ this.contract.priceTotal | currency : "EUR" }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ this.contract.hotel }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ this.contract.rooms }}</td>
            </tr>
          </tbody>
          <thead class="border-top: 2px">
            <tr class="ng-star-inserted border border-gray-300 bg-gray-100">
              <th class="border border-gray-300">{{ "contractsendemail.checkin" | translate }}</th>
              <th class="border border-gray-300">{{ "contractsendemail.checkout" | translate }}</th>
              <th class="border border-gray-300">{{ "contractsendemail.startdate" | translate }}</th>
              <th class="border border-gray-300">{{ "contractsendemail.enddate" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
              <td class="border border-gray-300">{{ this.contract.checkIn | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300">{{ this.contract.checkOut | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300">{{ this.contract.startDate | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300">{{ this.contract.endDate | date : "dd.MM.yyyy" }}</td>
            </tr>
          </tbody>
        </table>
      </app-file-send>
    </ng-container>
  </ng-template>
</modal>
