<form class="space-y-5 py-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['payment'].touched ? (form.controls['payment'].errors ? 'has-error' : 'has-success') : ''">
      <label for="payment">{{ "payment_create.payment_amount" | translate }}</label>
      <div class="flex">
        <div
          class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
        >
          €
        </div>
        <input
          id="payment"
          type="number"
          pattern="[0-9]*"
          appOnlyNumber
          placeholder="{{ 'payment_create.please_fill_payment_in_instalments' | translate }}"
          formControlName="payment"
          class="form-input flex-1 ltr:rounded-l-none rtl:rounded-r-none"
        />
      </div>
      <ng-container *ngIf="form.controls['payment'].touched && !form.controls['payment'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "payment_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['payment'].touched && form.controls['payment'].errors">
        <p class="mt-1 text-danger">{{ "payment_create.please_provide_valid_amount" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['paymentDate'].touched ? (form.controls['paymentDate'].errors ? 'has-error' : 'has-success') : ''">
      <label for="paymentDate">{{ "payment_create.due_date" | translate }}</label>
      <input
        id="paymentDate"
        type="text"
        placeholder="{{ 'sale_create.enter_due_date' | translate }}"
        class="form-input"
        placeholder="__.__.____"
        [textMask]="{ mask: dateMask }"
        formControlName="paymentDate"
      />
      <ng-container *ngIf="form.controls['paymentDate'].touched && !form.controls['paymentDate'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "payment_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['paymentDate'].touched && form.controls['paymentDate'].errors">
        <p class="mt-1 text-danger">{{ "payment_create.please_provide_due_date" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['paymentType'].touched ? (form.controls['paymentType'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customType">{{ "sale_create.payment_type" | translate }}</label>
      <div>
        <app-select-remote id="customType" [type]="PicklistType.PaymentType" [selectControl]="form" labelForId="payment-type" formControlName="paymentType" [setDefaultFirstOption]="true"> </app-select-remote>
      </div>
      <ng-container *ngIf="form.controls['paymentType'].touched && !form.controls['paymentType'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "sale_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['paymentType'].touched && form.controls['paymentType'].errors">
        <p class="mt-1 text-danger">{{ "sale_create.please_select_payment_type" | translate }}</p>
      </ng-container>
    </div>
  </div>
</form>
