import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "app-horizontal-graph",
  templateUrl: "./horizontal-graph.html",
})
export class HorizontalGraphComponent implements OnChanges {
  @Input() transfer!: number;
  @Input() tickets!: number;
  @Input() travels!: number;
  @Input() grouptravel!: number;
  percentage1!: number;
  percentage2!: number;
  percentage3!: number;
  percentage4!: number;

  ngOnChanges(): void {
    const allValues = [this.transfer, this.tickets, this.travels, this.grouptravel];
    const total = allValues.reduce((a, b) => a + b, 0);

    if (total == 0) {
      this.percentage1 = 0;
      this.percentage2 = 0;
      this.percentage3 = 0;
      this.percentage4 = 0;
    } else {
      this.percentage1 = parseFloat(((this.tickets / total) * 100).toFixed(1));
      this.percentage2 = parseFloat(((this.travels / total) * 100).toFixed(1));
      this.percentage3 = parseFloat(((this.transfer / total) * 100).toFixed(1));
      this.percentage4 = parseFloat(((this.grouptravel / total) * 100).toFixed(1));
    }
  }
}
