import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-dashboard-dino",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M8.2,0.8c0.1,0,0.2,0.1,0.3,0.1C9,1.1,9.4,1.6,9.4,2.2c0,2.7,0,5.4,0,8.1c0,0.8-0.6,1.4-1.4,1.4
	 c-1.9,0-3.8,0-5.8,0c-0.8,0-1.4-0.6-1.4-1.4c0-2.7,0-5.4,0-8.1c0-0.7,0.5-1.2,1.1-1.4c0,0,0.1,0,0.1,0C4.1,0.8,6.2,0.8,8.2,0.8z
		M8.9,6.3c0-1.3,0-2.6,0-3.9c0-0.7-0.3-1-1-1c-1.8,0-3.7,0-5.5,0c-0.6,0-1,0.3-1,1c0,2.6,0,5.2,0,7.8c0,0.6,0.3,1,1,1
	 c1.8,0,3.7,0,5.5,0c0.6,0,1-0.3,1-1C8.9,8.9,8.9,7.6,8.9,6.3z"
        />
        <path
          class="st0"
          d="M19.2,18c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.4-0.6,0.6-1.1,0.6c-1.9,0-3.9,0-5.8,0c-0.8,0-1.4-0.6-1.4-1.4
	 c0-2.7,0-5.4,0-8.1c0-0.8,0.6-1.4,1.4-1.4c1.9,0,3.9,0,5.8,0c0.6,0,1.2,0.5,1.4,1.1c0,0,0,0.1,0,0.1C19.2,12.3,19.2,15.1,19.2,18z
		M18.6,13.7c0-1.3,0-2.6,0-3.9c0-0.6-0.3-1-1-1c-1.8,0-3.7,0-5.5,0c-0.6,0-1,0.3-1,1c0,2.6,0,5.2,0,7.8c0,0.6,0.3,1,1,1
	 c1.8,0,3.7,0,5.5,0c0.6,0,1-0.3,1-1C18.6,16.3,18.6,15,18.6,13.7z"
        />
        <path
          class="st0"
          d="M19.2,5.9c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.4-0.6,0.6-1.1,0.6c-1.9,0-3.9,0-5.8,0c-0.8,0-1.4-0.6-1.4-1.4
	 c0-1.2,0-2.3,0-3.5c0-0.6,0.5-1.2,1.1-1.4c0,0,0.1,0,0.1,0c2.1,0,4.1,0,6.2,0c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.4,1,0.8
	 c0.1,0.1,0.1,0.2,0.1,0.4C19.2,3.3,19.2,4.6,19.2,5.9z M14.9,6.6c0.9,0,1.9,0,2.8,0c0.6,0,0.9-0.4,0.9-0.9c0-1.1,0-2.2,0-3.3
	 c0-0.6-0.4-0.9-0.9-0.9c-1.9,0-3.7,0-5.6,0c-0.6,0-0.9,0.4-0.9,0.9c0,1.1,0,2.2,0,3.3c0,0.6,0.4,0.9,0.9,0.9
	 C13,6.6,13.9,6.6,14.9,6.6z"
        />
        <path
          class="st0"
          d="M5.1,12.9c0.9,0,1.9,0,2.8,0c0.9,0,1.5,0.6,1.5,1.5c0,1.1,0,2.2,0,3.3c0,0.9-0.6,1.5-1.5,1.5
	 c-1.9,0-3.7,0-5.6,0c-0.9,0-1.5-0.6-1.5-1.5c0-1.1,0-2.2,0-3.3c0-0.9,0.6-1.5,1.5-1.5C3.3,12.9,4.2,12.9,5.1,12.9z M5.1,18.6
	 c0.9,0,1.8,0,2.8,0c0.6,0,0.9-0.3,0.9-0.9c0-1.1,0-2.2,0-3.3c0-0.6-0.4-0.9-1-0.9c-1.8,0-3.7,0-5.5,0c-0.6,0-0.9,0.3-0.9,0.9
	 c0,1.1,0,2.2,0,3.3c0,0.6,0.4,0.9,1,0.9C3.3,18.6,4.2,18.6,5.1,18.6z"
        />
        <path
          class="st1"
          d="M8.9,6.3c0,1.3,0,2.6,0,3.9c0,0.6-0.3,1-1,1c-1.8,0-3.7,0-5.5,0c-0.6,0-1-0.3-1-1c0-2.6,0-5.2,0-7.8
	 c0-0.6,0.3-1,1-1c1.8,0,3.7,0,5.5,0c0.7,0,1,0.3,1,1C8.9,3.7,8.9,5,8.9,6.3z"
        />
        <path
          class="st1"
          d="M18.6,13.7c0,1.3,0,2.6,0,3.9c0,0.6-0.3,1-1,1c-1.8,0-3.7,0-5.5,0c-0.6,0-1-0.3-1-1c0-2.6,0-5.2,0-7.8
	 c0-0.6,0.3-1,1-1c1.8,0,3.7,0,5.5,0c0.6,0,1,0.3,1,1C18.6,11.1,18.6,12.4,18.6,13.7z"
        />
        <path
          class="st1"
          d="M14.9,6.6c-0.9,0-1.8,0-2.8,0c-0.6,0-0.9-0.4-0.9-0.9c0-1.1,0-2.2,0-3.3c0-0.6,0.4-0.9,0.9-0.9
	 c1.9,0,3.7,0,5.6,0c0.6,0,0.9,0.4,0.9,0.9c0,1.1,0,2.2,0,3.3c0,0.6-0.4,0.9-0.9,0.9C16.7,6.6,15.8,6.6,14.9,6.6z"
        />
        <path
          class="st1"
          d="M5.1,18.6c-0.9,0-1.8,0-2.8,0c-0.6,0-0.9-0.3-1-0.9c0-1.1,0-2.2,0-3.3c0-0.6,0.4-0.9,0.9-0.9c1.8,0,3.7,0,5.5,0
	 c0.6,0,0.9,0.3,1,0.9c0,1.1,0,2.2,0,3.3c0,0.6-0.4,0.9-0.9,0.9C7,18.6,6.1,18.6,5.1,18.6z"
        />
      </svg>
    </ng-template>
  `,
})
export class IconMenuDashboardDinoComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
