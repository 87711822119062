import { Store } from "@ngrx/store";
import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { TableService } from "src/app/shared/services/table.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { animate, style, transition, trigger } from "@angular/animations";
import { ETouristDestination, ETouristTravel } from "../models/e-tourist.travel";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  templateUrl: "./e-tourist.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class ETouristTableComponent extends BaseTableComponent<ETouristTravel> {
  @ViewChild("modal") modal!: ModalComponent;

  constructor(public override translate: TranslateService, public override store: Store<AppState>, public override tableService: TableService) {
    super(store, translate, selectFromTo, tableService, "etourist/travels/plans");
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override getColumns(): void {
    this.cols = [
      { field: "uniqueIdentifier", title: this.translate.instant("travel_package.uniqueIdentifier"), isUnique: true, hide: true } as DatatableColumn,
      { field: "travelAgencyName", title: this.translate.instant("travel_package.travelAgencyName"), hide: false } as DatatableColumn,
      { field: "travelAgencyIdentificationNumber", title: this.translate.instant("travel_package.travelAgencyIdentificationNumber"), hide: false } as DatatableColumn,
      { field: "name", title: this.translate.instant("travel_package.name"), hide: false } as DatatableColumn,
      { field: "destinationsCustom", title: this.translate.instant("travel_package.destinations"), hide: false } as DatatableColumn,
    ];
  }

  getDestinations(destinations: ETouristDestination[]) {
    return destinations.map((d) => `${d.countryName}, ${d.cityName}`).join(" | ");
  }
}
