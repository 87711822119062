<label [for]="'custom' + formControlName">{{ "destination_create." + formControlName | translate }}</label>
<app-autocomplete-remote
  [type]="EntityPicklistType.Destinations"
  labelName="description"
  placeholder="{{ 'destination_create.enter_' + formControlName | translate }}"
  [selectControl]="form"
  [labelForId]="'custom' + formControlName"
  [formControlName]="formControlName"
  [addCallback]="utilService.addDestinationWithCountry(formControlName, form, countryModal).bind(utilService)"
></app-autocomplete-remote>
<span *ngIf="!form.controls[formControlName].value" class="mt-1 inline-block text-[11px] text-white-dark"> {{ "hint.searchCity" | translate }} </span>
<ng-container *ngIf="form.controls[formControlName].touched && !form.controls[formControlName].errors">
  <p class="mt-1 text-[#1abc9c]">{{ "destination_create.looks_good" | translate }}</p>
</ng-container>
<ng-container *ngIf="form.controls[formControlName].touched && form.controls[formControlName].errors">
  <p class="mt-1 text-danger">{{ "destination_create.required" | translate }}</p>
</ng-container>
<modal #countryModal>
  <div>
    <ng-template #modalHeader>
      <div class="modal-header">{{ "country" | translate }}</div>
    </ng-template>
    <ng-template #modalBody>
      <div class="md:col-span-2" [ngClass]="form.controls['country'].touched ? (form.controls['country'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customCountry">{{ "country_create.country" | translate }}</label>
        <app-autocomplete-remote
          [type]="EntityPicklistType.Countries"
          labelName="name"
          placeholder="{{ 'country_create.enter_country' | translate }}"
          [selectControl]="form"
          labelForId="customCountry"
          formControlName="country"
          [addCallback]="utilService.countriesAdd('country', form).bind(utilService)"
        ></app-autocomplete-remote>
        <span *ngIf="!form.controls[formControlName].value" class="mt-1 inline-block text-[11px] text-white-dark"> {{ "hint.searchCountry" | translate }}</span>
        <ng-container *ngIf="form.controls['country'].touched && !form.controls['country'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "country_create.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['country'].touched && form.controls['country'].errors">
          <p class="mt-1 text-danger">{{ "country_create.required" | translate }}</p>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #modalFooter>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="save()">{{ "save" | translate }}</button>
      </div>
    </ng-template>
  </div>
</modal>
