import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Contract } from "../../documents/contract/models/contract";
import { ContractService } from "../../documents/contract/services/contract.service";


@Component({
	selector: "app-group-travel-contract",
	templateUrl: "./group-travel-contract.component.html",
})
export class GroupTravelContractComponent implements OnInit {
	groupTravelId!: number;
	contract!: Contract;
	editUrl!: string;

	constructor(public route: ActivatedRoute, public contractService: ContractService, public router: Router) { }

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.groupTravelId = Number(params.get("id"));
			this.fetchContract();
		});
	}
	fetchContract() {
		if (this.groupTravelId !== undefined) {
			this.contractService.getContractByGroupTravel(this.groupTravelId).subscribe(
				(response) => {
					this.contract = response;
					this.editUrl = `/dashboard/group-travels/${this.groupTravelId}/contract/${this.contract.id}/edit`;
				},
				(error) => {
					showMessage(PopupType.Danger, error.toString());
				}
			);
		}
	}
}
