<form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
  <!-- START eTurist Requirement -->
  <div class="flex items-center rounded bg-info-light p-3.5 text-info dark:bg-info-dark-light">
    <span class="ltr:pr-2 rtl:pl-2"><strong class="ltr:mr-1 rtl:ml-1">Info!</strong>Morate prvo odabrati opstinu da bi odabrali grad!</span>
  </div>

  <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3">
    <div [ngClass]="form.controls['municipality'].touched ? (form.controls['municipality'].errors ? 'has-error' : 'has-success') : ''">
      <label for="municipality">{{ "office-branch.municipality" | translate }}</label>
      <ng-select
        placeholder="{{ 'office-branch.enter_municipality' | translate }}"
        labelForId="municipality"
        [items]="optionsMunicipality$ | async"
        bindLabel="nameLatin"
        formControlName="municipality"
        [searchable]="true"
        [clearable]="false"
        class="custom-multiselect"
      >
      </ng-select>
      <ng-container *ngIf="form.controls['municipality'].touched && form.controls['municipality'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_municipality" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['city'].touched ? (form.controls['city'].errors ? 'has-error' : 'has-success') : ''">
      <label for="city">{{ "office-branch.city" | translate }}</label>
      <ng-select
        placeholder="{{ 'office-branch.enter_city' | translate }}"
        labelForId="city"
        [items]="optionsCities$ | async"
        bindLabel="nameLatin"
        formControlName="city"
        [searchable]="true"
        [clearable]="false"
        class="custom-multiselect"
        [disabled]="!form.controls['municipality'].value"
      >
      </ng-select>
      <!-- <input type="text" placeholder="{{ 'office-branch.enter_city' | translate }}" class="form-input" id="city" formControlName="city" /> -->
      <ng-container *ngIf="form.controls['city'].touched && form.controls['city'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_city" | translate }}</small>
        </p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['street'].touched ? (form.controls['street'].errors ? 'has-error' : 'has-success') : ''">
      <label for="street">{{ "office-branch.street" | translate }}</label>
      <ng-select
        placeholder="{{ 'office-branch.enter_street' | translate }}"
        labelForId="street"
        [items]="optionsStreets$ | async"
        bindLabel="nameLatin"
        formControlName="street"
        [searchable]="true"
        [clearable]="false"
        class="custom-multiselect"
        [disabled]="!form.controls['city'].value"
      >
      </ng-select>
      <!-- <input type="text" placeholder="{{ 'office-branch.enter_street' | translate }}" class="form-input" id="street" formControlName="street" /> -->
      <ng-container *ngIf="form.controls['street'].touched && form.controls['street'].errors">
        <p class="mt-1 text-danger">
          <small>{{ "office-branch.please_provide_street" | translate }}</small>
        </p>
      </ng-container>
    </div>
  </div>
  <!-- END eTurist Requirement -->
  <button type="submit" class="btn btn-primary mt-3 w-full">
    {{ "office-branch.update" | translate }}
  </button>
</form>
