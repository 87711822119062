<div class="grid grid-cols-1 gap-5 pt-3 md:grid-cols-2">
  <div class="col-md-6" *ngIf="popularDestinationsByTicket && popularDestinationsByTicket.length > 0">
    <h3>{{ "chart_statistics.popular_by_ticket" | translate }}</h3>
    <div *ngIf="columnCharts['popularDestinationsByTicket']">
      <apx-chart
        *ngIf="columnCharts['popularDestinationsByTicket'].series"
        [series]="columnCharts['popularDestinationsByTicket'].series"
        [chart]="columnCharts['popularDestinationsByTicket'].chart"
        [colors]="columnCharts['popularDestinationsByTicket'].colors"
        [dataLabels]="columnCharts['popularDestinationsByTicket'].dataLabels"
        [stroke]="columnCharts['popularDestinationsByTicket'].stroke"
        [plotOptions]="columnCharts['popularDestinationsByTicket'].plotOptions"
        [grid]="columnCharts['popularDestinationsByTicket'].grid"
        [xaxis]="columnCharts['popularDestinationsByTicket'].xaxis"
        [yaxis]="columnCharts['popularDestinationsByTicket'].yaxis"
        [tooltip]="columnCharts['popularDestinationsByTicket'].tooltip"
        class="mb-3 mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>
  <div class="col-md-6" *ngIf="popularDestinationsByTravel && popularDestinationsByTravel.length > 0">
    <h3>{{ "chart_statistics.popular_by_travel" | translate }}</h3>
    <div *ngIf="columnCharts['popularDestinationsByTravel']">
      <apx-chart
        *ngIf="columnCharts['popularDestinationsByTravel'].series"
        [series]="columnCharts['popularDestinationsByTravel'].series"
        [chart]="columnCharts['popularDestinationsByTravel'].chart"
        [colors]="columnCharts['popularDestinationsByTravel'].colors"
        [dataLabels]="columnCharts['popularDestinationsByTravel'].dataLabels"
        [stroke]="columnCharts['popularDestinationsByTravel'].stroke"
        [plotOptions]="columnCharts['popularDestinationsByTravel'].plotOptions"
        [grid]="columnCharts['popularDestinationsByTravel'].grid"
        [xaxis]="columnCharts['popularDestinationsByTravel'].xaxis"
        [yaxis]="columnCharts['popularDestinationsByTravel'].yaxis"
        [tooltip]="columnCharts['popularDestinationsByTravel'].tooltip"
        class="mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>
</div>
<div class="grid grid-cols-1 gap-5 pt-3 md:grid-cols-2">
  <div class="col-md-6" *ngIf="popularDestinationsByGroupTravel && popularDestinationsByGroupTravel.length > 0">
    <h3>{{ "chart_statistics.popular_by_grouptravel" | translate }}</h3>
    <div *ngIf="columnCharts['popularDestinationsByGroupTravel']">
      <apx-chart
        *ngIf="columnCharts['popularDestinationsByGroupTravel'].series"
        [series]="columnCharts['popularDestinationsByGroupTravel'].series"
        [chart]="columnCharts['popularDestinationsByGroupTravel'].chart"
        [colors]="columnCharts['popularDestinationsByGroupTravel'].colors"
        [dataLabels]="columnCharts['popularDestinationsByGroupTravel'].dataLabels"
        [stroke]="columnCharts['popularDestinationsByGroupTravel'].stroke"
        [plotOptions]="columnCharts['popularDestinationsByGroupTravel'].plotOptions"
        [grid]="columnCharts['popularDestinationsByGroupTravel'].grid"
        [xaxis]="columnCharts['popularDestinationsByGroupTravel'].xaxis"
        [yaxis]="columnCharts['popularDestinationsByGroupTravel'].yaxis"
        [tooltip]="columnCharts['popularDestinationsByGroupTravel'].tooltip"
        class="mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>

  <div class="col-md-6" *ngIf="popularDestinationsByTransfer && popularDestinationsByTransfer.length > 0">
    <h3>{{ "chart_statistics.popular_by_transfer" | translate }}</h3>
    <div *ngIf="columnCharts['popularDestinationsByTransfer']">
      <apx-chart
        *ngIf="columnCharts['popularDestinationsByTransfer'].series"
        [series]="columnCharts['popularDestinationsByTransfer'].series"
        [chart]="columnCharts['popularDestinationsByTransfer'].chart"
        [colors]="columnCharts['popularDestinationsByTransfer'].colors"
        [dataLabels]="columnCharts['popularDestinationsByTransfer'].dataLabels"
        [stroke]="columnCharts['popularDestinationsByTransfer'].stroke"
        [plotOptions]="columnCharts['popularDestinationsByTransfer'].plotOptions"
        [grid]="columnCharts['popularDestinationsByTransfer'].grid"
        [xaxis]="columnCharts['popularDestinationsByTransfer'].xaxis"
        [yaxis]="columnCharts['popularDestinationsByTransfer'].yaxis"
        [tooltip]="columnCharts['popularDestinationsByTransfer'].tooltip"
        class="mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>
</div>
