import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { PackageConfig, User } from "../model/user";
import { UserAuth } from "../model/user.auth";
import { BaseHttpService } from "src/app/service/base.http.service";
import { setUser } from "src/app/store/auth/auth.actions";

@Injectable()
export class AuthService extends BaseHttpService {
  private userId = 0;
  constructor(private http: HttpClient, public store: Store<any>) {
    super();
    this.loadUserFromLocalStorage();
  }

  authenticate(form: UserAuth): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/api/Users/login`, form).pipe(tap((response) => this.handleAuthentication(response)));
  }

  saveUser(user: User): void {
    const userInfo = {
      id: user.id,
      username: user.username,
      email: user.email,
      imageUrl: user.imageUrl,
      role: user.role || "Agent",
      settings: user.settings,
      token: user.token,
      isOfficeBranchManager: user.isOfficeBranchManager,
      updatedAt: new Date(user.updatedAt),
      createdAt: new Date(user.createdAt),
      eTouristAccessToken: user.eTouristAccessToken,
      eTouristRefreshToken: user.eTouristRefreshToken,
      officeBranch: user.officeBranch,
      name: user.name,
      surname: user.surname,
      uniqueMasterCitizenNumber: user.uniqueMasterCitizenNumber,
    };
    localStorage.setItem("user", JSON.stringify(userInfo));
    this.store.dispatch(setUser({ user: userInfo }));
  }

  handleAuthentication(response: User): void {
    if (response && response.token) {
      const user: User = {
        id: response.id,
        username: response.username,
        email: response.email,
        imageUrl: response.imageUrl,
        role: response.role || "Agent",
        settings: response.settings,
        isOfficeBranchManager: response.isOfficeBranchManager,
        token: response.token,
        updatedAt: new Date(response.updatedAt),
        createdAt: new Date(response.createdAt),
        eTouristAccessToken: response.eTouristAccessToken,
        eTouristRefreshToken: response.eTouristRefreshToken,
        officeBranch: response.officeBranch,
        name: response.name,
        surname: response.surname,
        uniqueMasterCitizenNumber: response.uniqueMasterCitizenNumber,
      };

      this.saveUser(user);
    }
  }

  loadUserFromLocalStorage(): void {
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      this.userId = parsedUser.id;
      if (this.isTokenExpired(parsedUser.token)) {
        localStorage.removeItem("user");
      } else {
        this.store.dispatch(setUser({ user: parsedUser }));
      }
    }
  }

  isTokenExpired(token: string): boolean {
    if (!token) {
      return false;
    }
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
  getUserRole(): Observable<string> {
    return this.store.select((state) => {
      const { role, isOfficeBranchManager } = state.auth.user;
      if (role.toLowerCase() === "Agent".toLowerCase()) {
        return isOfficeBranchManager ? "OfficeBranchManager" : "Agent";
      }
      return "Admin";
    });
  }

  getUserId(): number {
    return this.userId;
  }
  getPackageConfiguration(): Observable<PackageConfig> {
    return this.store.select((state) => {
      return state.auth.user.packageConfiguration;
    });
  }
}
