<div class="space-y-8">
  <div class="panel p-0 py-5">
    <div>
      <div class="relative z-[1]">
        <div
          class="absolute top-[30px] -z-[1] m-auto h-1 w-[15%] bg-primary transition-[width] ltr:left-0 rtl:right-0 print:hidden"
          [ngStyle]="{
            width: tabSizeHandler(activeTab, isExistsAnnouncement)
          }"
        ></div>
        <ul [ngClass]="isExistsAnnouncement ? 'grid-cols-4' : 'grid-cols-3'" class="mb-5 grid print:hidden">
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 1 }"
              (click)="proceedToNextStage(1)"
            >
              <icon-person></icon-person>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 1 }">{{ "travel_checkout.passangers" | translate }}</span>
          </li>
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 2 }"
              (click)="proceedToNextStage(2)"
            >
              <icon-home></icon-home>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 2 }">{{ "travel_checkout.travel" | translate }}</span>
          </li>

          <ng-container *ngIf="isExistsAnnouncement">
            <li class="mx-auto">
              <a
                href="javascript:;"
                class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
                [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 3 }"
                (click)="proceedToNextStage(3)"
              >
                <icon-mail></icon-mail>
              </a>
              <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 3 }">{{ "travel_checkout.prepare_announcement" | translate }}</span>
            </li>
          </ng-container>

          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 4 }"
              (click)="proceedToNextStage(4)"
            >
              <icon-thumbs-up></icon-thumbs-up>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 3 }">{{ "travel_checkout.reciept" | translate }}</span>
          </li>
        </ul>
      </div>
      <div>
        <div [ngStyle]="isVisibleTab(1)">
          <div class="mx-5">
            <h3 class="font-bold">{{ "travel_checkout.lead_pasanger" | translate }}</h3>
            <hr />
          </div>
          <app-passanger-list #passangerList></app-passanger-list>
        </div>
        <div [ngStyle]="isVisibleTab(2)">
          <div class="mx-5">
            <h3 class="font-bold">{{ "travel_checkout.travel" | translate }}</h3>
            <hr />
          </div>

          <app-travel-create #travelBase (notify)="onNotify($event)"></app-travel-create>

          <div class="relative px-5">
            <div class="mb-5 rounded-xl bg-gray-100 p-5">
              <app-travel-destination-create #mainDestination> </app-travel-destination-create>
            </div>
          </div>
          <div class="mx-5 grid grid-cols-1">
            <div class="flex gap-3">
              <h3 class="font-bold">
                {{ "group_travel_create.destination" | translate }}
              </h3>
              <button (click)="addDestination()" ngxTippy="Add more destination">
                <icon-plus></icon-plus>
              </button>
            </div>

            <div class="mt-3 grid">
              <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="travelDestinations.length > 0">
                <ng-container *ngFor="let item of travelDestinations; index as i" id="index">
                  <div
                    [ngClass]="{ 'bg-green-100': editDestination && editDestination.guid === item.guid }"
                    [id]="i"
                    class="flex min-w-[625px] items-center justify-between rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b]"
                  >
                    <div class="user-profile">
                      <icon-location></icon-location>
                    </div>
                    <div *ngIf="item.destination">{{ item.destination.description }}</div>
                    <div *ngIf="item.hotel">{{ "travel_create.hotel" | translate }}: {{ item.hotel.name }}</div>
                    <div *ngIf="item.service">{{ "service" | translate }}: {{ item.service.title }}</div>
                    <div *ngIf="!item.destination">{{ "group_travel_create.destination" | translate }} {{ i + 1 }}</div>
                    <div class="px-2">{{ item.start }} - {{ item.end }}</div>
                    <ng-template #backUp> </ng-template>
                    <div>
                      <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('sale_details.edit')" (click)="toEditDestination(item)">
                        <icon-pencil />
                      </a>
                    </div>
                    <div>
                      <a type="button" [ngxTippy]="translate.instant('sale_details.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removeDestination(item.guid)">
                        <icon-trash-lines />
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <hr />
              <div class="relative" *ngIf="editDestination">
                <hr />
                <app-travel-destination-create [defaultDestination]="editDestination" (submitDestination)="handleDestination($event)" [isMainDestination]="false"> </app-travel-destination-create>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isExistsAnnouncement">
          <div [ngStyle]="isVisibleTab(3)">
            <app-announcement #announcement [travelId]="id" [organizer]="travel.organizer" (notifyWhenEditAndSend)="handleVoucherSent()" *ngIf="travel && activeTab === 3"></app-announcement>
          </div>
        </ng-container>
        <div [ngStyle]="isVisibleTab(4)">
          <app-sale-create #sale></app-sale-create>
        </div>
        <div class="flex justify-between px-5 print:hidden">
          <button type="button" class="btn btn-primary" (click)="returnBack()">
            {{ "travel_checkout.back" | translate }}
          </button>

          <button type="button" class="btn btn-primary" *ngIf="!(activeTab === 4 || activeTab === 2)" (click)="proceedToNextStage(activeTab + 1)">
            {{ "travel_checkout.next" | translate }}
          </button>

          <button type="button" class="btn btn-primary" *ngIf="activeTab === 2" (click)="updateTravelDraft()">
            {{ "travel_checkout.next" | translate }}
          </button>

          <button type="button" class="btn btn-primary" *ngIf="activeTab === 4" (click)="updateTravelDraft()">
            {{ "travel_checkout.finish_sale" | translate }}
          </button>
        </div>
        <div class="p-5 print:hidden">
          <hr class="my-2" />
          <div>
            <label for="notes">{{ "ticket_checkout.remarks" | translate }}</label>
            <textarea id="notes" rows="3" class="w-100 form-textarea mt-2" [placeholder]="translate.instant('ticket_checkout.remarks')" [(ngModel)]="note"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
