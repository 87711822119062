import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { VoucherService } from "../services/voucher.service";
import { Voucher } from "src/app/domain/travels/models/voucher";
import { TravelsService } from "src/app/domain/travels/services/travels.service";
import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from "@angular/core";
@Component({
  selector: "app-voucher",
  templateUrl: "./voucher.component.html",
})
export class VoucherComponent implements OnInit {
  constructor(private route: ActivatedRoute, public travelService: TravelsService, public router: Router, public voucherService: VoucherService) {}
  @Input() voucher!: Voucher;
  @Input() editUrl!: string;
  @ViewChild("modal") modal!: ModalComponent;
  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();
	isShowingSidebar = false;
	public entityname = "vouchers";
	public id !: number;
  travelId!: number;
  today: string = new Date().toLocaleDateString("sr-RS");

  sendVoucher() {
    this.modal.open();
  }
	ngOnInit() {
		this.id = this.voucher.id;
	}

  navigateToEditVoucher() {
    this.router.navigate([this.editUrl]);
  }

  printVoucher() {
    window.print();
  }

  finishSending() {
    this.modal.close();
  }
}
