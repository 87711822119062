<div hlMenu class="!block">
  <a
    [routerLink]="'/dashboard/e-tourist'"
    type="button"
    hlMenuButton
    class="group relative block rounded-md bg-gray-200"
    [ngClass]="{
       'bg-gray-200 border border-gray-400': checked, 
       'bg-white border border-gray-200': !checked
     }"
    [ngxTippy]="message"
  >
    <img class="w-18 h-9" src="assets/images/eturista-logo.png" />
    <span class="absolute top-0 flex h-3 w-3 ltr:right-1 rtl:left-0">
      <span class="absolute -top-[3px] inline-flex h-full w-full animate-ping rounded-full bg-success/50 opacity-75 ltr:-left-[3px] rtl:-right-[3px]"></span>
      <span class="relative inline-flex h-[6px] w-[6px] rounded-full bg-success"></span>
    </span>
  </a>
</div>
