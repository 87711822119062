import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Contract } from "../../documents/contract/models/contract";
import { ContractService } from "../../documents/contract/services/contract.service";

@Component({
  selector: "app-travel-contract",
  templateUrl: "./travel-contract.component.html",
})
export class TravelContractComponent implements OnInit {
  travelId!: number;
  contract!: Contract;
  editUrl!: string;

  constructor(public route: ActivatedRoute, public contractService: ContractService, public router: Router, public translate: TranslateService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.travelId = parseInt(params["id"]);
      this.fetchContract();
    });
  }

  fetchContract() {
    this.contractService.getTravelContract(this.travelId).subscribe(
      (response) => {
        this.contract = response;
        this.editUrl = `/dashboard/travels/${this.travelId}/contract/${response.id}`;
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }
}
