import { Picklist } from "../models/picklist";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, Validators } from "@angular/forms";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { SettingsService } from "../services/settings.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-settings-create",
  templateUrl: "./settings.create.component.html",
})
export class SettingsCreateComponent implements OnInit {
  picklistType: Picklist[] = [];
  id: number | null = null;

  @Input() type = "room-type";
  @Input() title = "Room Type";
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("deleteConfirmationModal") deleteConfirmationModal!: ModalComponent;
  constructor(public settingsService: SettingsService, public fb: FormBuilder, public translate: TranslateService) {}
  modalTitle!: string;
  modalButton!: string;
  form = this.fb.group({
    title: ["", Validators.required],
    description: ["", Validators.required],
    delimeter: ["", Validators.required],
  });
  ngOnInit() {
    this.getPicklistType();
  }

  getPicklistType() {
    this.settingsService.getPicklistType(this.type).subscribe(
      (res) => {
        this.picklistType = res;
      },

      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }

  openModal() {
    this.modal.open();
    this.modalTitle = this.translate.instant("settings.add_new_item");
    this.modalButton = this.translate.instant("settings.add");
  }

  openModalForDelete(id: number) {
    this.id = id;
    this.deleteConfirmationModal.open();
  }

  openModalForEditing(id: number) {
    this.id = id;
    this.modalTitle = this.translate.instant("settings.edit_item");
    this.modalButton = this.translate.instant("settings.update");
    this.modal.open();
  }

 

  fetchItem() {
    const { title, description } = this.form.value;
    const value = this.type.split("-").join(" ");
    const data = { title, description, delimeter: this.type, value: value };
    return data;
  }
	delete() {
    if (this.id !== null) {
      this.settingsService.deletePicklistItem(this.id).subscribe(
        () => {
          this.getPicklistType();
          this.deleteConfirmationModal.close();
          showMessage(PopupType.Success, this.translate.instant("settings.deleted_successfully"));
        },
        (error) => {
          showMessage(PopupType.Danger, error.toString());
        }
      );
    }
	}

  

  addItem() {
    if (this.id === null) {
      this.settingsService.addPicklistItem(this.fetchItem()).subscribe(
        (res) => {
          this.getPicklistType();
          this.modal.close();
          showMessage(PopupType.Success, this.translate.instant("settings.added_successfully"));
        },
        (error) => {
          showMessage(PopupType.Danger, error.toString());
        }
      );
    }
    if (this.id !== null) {
      this.settingsService.updatePicklistItem(this.fetchItem(), this.id).subscribe(
        (res) => {
          this.getPicklistType();
          this.modal.close();
          this.id = null;
          showMessage(PopupType.Success, this.translate.instant("settings.updated_successfully"));
        },
        (error) => {
          showMessage(PopupType.Danger, error.toString());
        }
      );
    }
  }
	


}
