import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-logo-text",
  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 101.7 32"
        style="enable-background:new 0 0 101.7 32;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #d4483b;
          }
        </style>
        <g>
          <g>
            <path
              class="st0"
              d="M27.4,27.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7l0-2h-0.6v-0.3l0.6-0.1
					 l0.1-1h0.4v1H28V24h-1.1v2c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0.4,0v0.4H27.4z
					 "
            />
            <path
              class="st0"
              d="M30.2,27.3c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.6
					 c-0.1-0.2-0.1-0.6-0.1-0.9c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1
					 c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.6c0.1,0.2,0.1,0.6,0.1,0.9c0,0.4,0,0.7-0.1,0.9
					 c-0.1,0.2-0.2,0.4-0.3,0.6s-0.3,0.2-0.5,0.3C30.7,27.3,30.4,27.3,30.2,27.3z M30.2,26.8c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2
					 c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2,0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
					 c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.8
					 c0,0.3,0,0.6,0.1,0.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2C29.8,26.8,30,26.8,30.2,26.8z"
            />
            <path
              class="st0"
              d="M33.7,27.3c-0.4,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8v-2.6h0.5V26c0,0.3,0.1,0.5,0.2,0.6
					 c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.3v-2.9h0.5v3.7h-0.4l-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.3
					 C34.3,27.2,34,27.3,33.7,27.3z"
            />
            <path
              class="st0"
              d="M36.7,27.2v-3.7h0.4l0.1,0.6c0.2-0.2,0.3-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.1,0,0.2,0
					 c0.1,0,0.1,0,0.2,0v0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.2
					 c-0.1,0.1-0.2,0.2-0.4,0.3v2.6H36.7z"
            />
            <path
              class="st0"
              d="M39.6,22.9c-0.1,0-0.1,0-0.1-0.1v-0.5c0-0.1,0-0.1,0.1-0.1H40c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1v0.5
					 c0,0.1,0,0.1-0.1,0.1H39.6z M39.5,27.2v-3.7H40v3.7H39.5z"
            />
            <path
              class="st0"
              d="M42.3,27.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0v-0.3h1.5
					 c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3v-0.2c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1H42
					 c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5v-0.2c0-0.2,0-0.4,0.1-0.6c0.1-0.1,0.2-0.3,0.4-0.3
					 c0.2-0.1,0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1V24h-1.4c-0.2,0-0.4,0-0.5,0.1
					 c-0.1,0.1-0.2,0.2-0.2,0.5v0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0h0.5c0.3,0,0.6,0.1,0.8,0.2
					 c0.2,0.2,0.3,0.4,0.3,0.7v0.3c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.3C42.8,27.3,42.6,27.3,42.3,27.3z"
            />
            <path
              class="st0"
              d="M45.7,27.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7l0-2h-0.6v-0.3l0.6-0.1
					 l0.1-1h0.4v1h1.1V24h-1.1v2c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0.4,0v0.4H45.7
					 z"
            />
            <path
              class="st0"
              d="M50.1,27.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0v-0.3h1.5
					 c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3v-0.2c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.1-0.5-0.1h-0.5
					 c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5v-0.2c0-0.2,0-0.4,0.1-0.6c0.1-0.1,0.2-0.3,0.4-0.3
					 c0.2-0.1,0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1V24h-1.4c-0.2,0-0.4,0-0.5,0.1
					 c-0.1,0.1-0.2,0.2-0.2,0.5v0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0h0.5c0.3,0,0.6,0.1,0.8,0.2
					 c0.2,0.2,0.3,0.4,0.3,0.7v0.3c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.3C50.6,27.3,50.4,27.3,50.1,27.3z"
            />
            <path
              class="st0"
              d="M53.7,27.3c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.6
					 c-0.1-0.2-0.1-0.6-0.1-0.9c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1
					 s0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.6c0.1,0.2,0.1,0.6,0.1,0.9c0,0.4,0,0.7-0.1,0.9
					 c-0.1,0.2-0.2,0.4-0.3,0.6s-0.3,0.2-0.5,0.3C54.2,27.3,54,27.3,53.7,27.3z M53.7,26.8c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2
					 c0.1-0.1,0.2-0.2,0.2-0.4s0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
					 c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.8
					 c0,0.3,0,0.6,0.1,0.8c0,0.2,0.1,0.3,0.2,0.4s0.2,0.2,0.3,0.2C53.4,26.8,53.5,26.8,53.7,26.8z"
            />
            <path
              class="st0"
              d="M56.4,27.2V24h-0.6v-0.3l0.6-0.1v-0.4c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.3,0.3-0.4C57,22,57.2,22,57.5,22
					 c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0v0.4h-0.6c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.4v0.4h1.1V24h-1.1
					 v3.2H56.4z"
            />
            <path
              class="st0"
              d="M59.9,27.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7l0-2h-0.6v-0.3l0.6-0.1
					 l0.1-1h0.4v1h1.1V24h-1.1v2c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0.4,0v0.4H59.9
					 z"
            />
            <path class="st0" d="M62,27.2l-1.1-3.7h0.6l0.9,3.1l0.9-3.1h0.5l0.9,3.1l0.9-3.1H66l-1.1,3.7h-0.6l-0.9-2.9l-0.9,2.9H62z" />
            <path
              class="st0"
              d="M67.3,27.3c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.7v-0.3c0-0.3,0.1-0.5,0.3-0.7
					 c0.2-0.2,0.5-0.3,0.9-0.3h1.2v-0.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.3-0.2C68.2,24,68,24,67.8,24h-1.1v-0.3
					 c0.2,0,0.3-0.1,0.6-0.1c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v2.6h-0.4
					 l-0.1-0.4c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.4,0.2c-0.1,0.1-0.3,0.1-0.5,0.1C67.6,27.3,67.4,27.3,67.3,27.3z M67.5,26.9
					 c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0v-1.1l-1.1,0
					 c-0.3,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.2
					 C67.3,26.9,67.4,26.9,67.5,26.9z"
            />
            <path
              class="st0"
              d="M70.3,27.2v-3.7h0.4l0.1,0.6c0.2-0.2,0.3-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.1,0,0.2,0
					 c0.1,0,0.1,0,0.2,0v0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.2
					 c-0.1,0.1-0.2,0.2-0.4,0.3v2.6H70.3z"
            />
            <path
              class="st0"
              d="M74.5,27.3c-0.4,0-0.7-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6C73,26.3,73,25.9,73,25.4c0-0.5,0-0.9,0.1-1.1
					 c0.1-0.3,0.3-0.5,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.7
					 c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.1-0.6,0.1h-1.3c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.3,0.4
					 c0.2,0.1,0.4,0.1,0.7,0.1h1.2v0.3c-0.2,0-0.4,0.1-0.6,0.1C75,27.3,74.7,27.3,74.5,27.3z M73.5,25.3h1.3c0.2,0,0.4,0,0.5-0.1
					 c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.3,0-0.5,0-0.6,0.1
					 c-0.1,0.1-0.2,0.2-0.3,0.4C73.5,24.7,73.5,24.9,73.5,25.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M11.3,5.7c0.2,0,0.3,0.1,0.3,0.3v1.9c0,0.2-0.1,0.3-0.3,0.3H8v8.1c0,0.2-0.1,0.3-0.3,0.3H5.2
					 c-0.2,0-0.3-0.1-0.3-0.3V8.3H1.6c-0.2,0-0.3-0.1-0.3-0.3V6c0-0.2,0.1-0.3,0.3-0.3H11.3z"
            />
            <path
              class="st0"
              d="M11.2,13.6V8.7c0-0.2,0.1-0.3,0.3-0.3h2.3c0.2,0,0.3,0.1,0.3,0.3v4.2c0,1,0.5,1.6,1.3,1.6
					 c0.8,0,1.4-0.5,1.4-1.6V8.7c0-0.2,0.1-0.3,0.3-0.3h2.3c0.2,0,0.3,0.1,0.3,0.3v7.6c0,0.2-0.1,0.3-0.3,0.3h-2.2
					 c-0.2,0-0.3-0.1-0.3-0.3v-1c-0.6,1-1.4,1.6-2.8,1.6C12.1,16.9,11.2,15.6,11.2,13.6z"
            />
            <path
              class="st0"
              d="M26.8,8.5v2.8c0,0.2-0.1,0.3-0.3,0.3c-1-0.2-2.2,0.2-2.2,1.9v2.8c0,0.2-0.1,0.3-0.3,0.3h-2.3
					 c-0.2,0-0.3-0.1-0.3-0.3V8.7c0-0.2,0.1-0.3,0.3-0.3h2.2c0.2,0,0.3,0.1,0.3,0.3v1.9c0.6-1.8,1.6-2.4,2.3-2.4
					 C26.7,8.2,26.8,8.3,26.8,8.5z"
            />
            <path
              class="st0"
              d="M27.7,6.2c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C28.4,7.8,27.7,7.1,27.7,6.2z
						M27.8,8.7c0-0.2,0.1-0.3,0.3-0.3h2.3c0.2,0,0.3,0.1,0.3,0.3v7.6c0,0.2-0.1,0.3-0.3,0.3h-2.3c-0.2,0-0.3-0.1-0.3-0.3V8.7z"
            />
            <path
              class="st0"
              d="M39.5,14.1c0,1.8-1.8,2.7-4,2.7c-1.4,0-2.7-0.3-3.5-0.8c-0.2-0.1-0.3-0.3-0.2-0.5l0.6-1.2
					 c0.1-0.3,0.3-0.3,0.5-0.2c0.8,0.4,1.4,0.7,2.3,0.7c0.6,0,1.1-0.1,1.1-0.5c0-1.1-4.2-0.4-4.2-3.4c0-1.7,1.4-2.7,3.7-2.7
					 c1.3,0,2.5,0.3,3.2,0.8c0.2,0.1,0.3,0.3,0.2,0.5l-0.5,1.1c-0.1,0.3-0.3,0.3-0.5,0.2c-0.4-0.2-1.1-0.6-2-0.6
					 c-0.8,0-1.1,0.2-1.1,0.5C35.2,11.8,39.5,11.1,39.5,14.1z"
            />
            <path
              class="st0"
              d="M47.4,15.6c0.1,0.2,0,0.4-0.1,0.5c-0.8,0.5-1.6,0.8-2.7,0.8c-1.9,0-3.1-1.1-3.1-2.9v-3.2h-1
					 c-0.2,0-0.3-0.1-0.3-0.3V8.7c0-0.2,0.1-0.3,0.3-0.3h1V7.2c0-0.2,0.1-0.4,0.4-0.4L44,6.2c0.3-0.1,0.4,0,0.4,0.3v1.9h2.3
					 c0.2,0,0.3,0.1,0.3,0.3v1.7c0,0.2-0.1,0.3-0.3,0.3h-2.3v2.5c0,0.8,0.2,1.2,1,1.2c0.4,0,0.6-0.1,1-0.3c0.2-0.1,0.4-0.1,0.5,0.2
					 L47.4,15.6z"
            />
            <path
              class="st0"
              d="M62.5,16.2c0.1,0.3,0,0.4-0.3,0.4h-2.6c-0.2,0-0.3-0.1-0.4-0.3l-0.7-1.7h-4.3l-0.7,1.7
					 c-0.1,0.2-0.2,0.3-0.4,0.3h-2.4c-0.3,0-0.4-0.2-0.3-0.4L55,5.9c0.1-0.2,0.2-0.3,0.4-0.3h2.2c0.2,0,0.3,0.1,0.4,0.3L62.5,16.2z
						M55,12.7h2.7l-1.3-3.3L55,12.7z"
            />
            <path
              class="st0"
              d="M71.6,9.8c0.2,0.3,0.3,0.7,0.3,1.1c0,1.7-1.5,2.7-4.2,2.7h-0.9c-0.5,0-0.7,0.1-0.7,0.4c0,0.3,0.2,0.5,0.7,0.5
					 h2.6c2.2,0,3.4,0.8,3.4,2.4c0,1.7-1.5,2.8-5,2.8c-3.4,0-4.6-0.8-4.6-2.1c0-0.9,0.5-1.4,1.4-1.7c-0.6-0.3-0.9-0.8-0.9-1.5
					 c0-0.7,0.4-1.2,1.2-1.5c-0.9-0.5-1.4-1.2-1.4-2.1c0-1.7,1.5-2.7,4.2-2.7c1.1,0,2,0.2,2.7,0.5c0.4-1,1.1-1.6,2-1.6
					 c0.2,0,0.3,0.1,0.3,0.3V9c0,0.2-0.1,0.3-0.3,0.3C72,9.3,71.8,9.5,71.6,9.8z M68,16.4c-1.6,0-2.1,0.3-2.1,0.8S66.4,18,68,18
					 c1.6,0,2-0.3,2-0.8S69.6,16.4,68,16.4z M69.2,10.9c0-0.7-0.6-1-1.5-1c-0.9,0-1.5,0.3-1.5,1c0,0.7,0.6,1,1.5,1
					 C68.6,11.9,69.2,11.5,69.2,10.9z"
            />
            <path
              class="st0"
              d="M81.7,14.5c0.3,0.1,0.3,0.3,0.2,0.5c-0.6,1-2.1,1.8-4,1.8c-2.5,0-4.6-1.6-4.6-4.4c0-2.8,2.1-4.3,4.6-4.3
					 c2.3,0,4.4,1.3,4.4,4.7h-6.2c0.2,1.2,1,1.8,1.9,1.8c0.7,0,1.3-0.2,1.6-0.7c0.2-0.2,0.3-0.3,0.5-0.2L81.7,14.5z M76.2,11.4h3.1
					 c-0.1-0.8-0.6-1.4-1.5-1.4C77,10.1,76.4,10.5,76.2,11.4z"
            />
            <path
              class="st0"
              d="M92.2,11.4v4.9c0,0.2-0.1,0.3-0.3,0.3h-2.3c-0.2,0-0.3-0.1-0.3-0.3v-4.2c0-1-0.5-1.6-1.4-1.6
					 c-0.9,0-1.5,0.5-1.5,1.6v4.2c0,0.2-0.1,0.3-0.3,0.3h-2.3c-0.2,0-0.3-0.1-0.3-0.3V8.7c0-0.2,0.1-0.3,0.3-0.3H86
					 c0.2,0,0.3,0.1,0.3,0.3v1c0.6-1,1.5-1.6,2.9-1.6C91.3,8.2,92.2,9.4,92.2,11.4z"
            />
            <path
              class="st0"
              d="M100.4,15.6c0.1,0.2,0,0.4-0.1,0.5c-0.8,0.5-1.6,0.8-2.7,0.8c-1.9,0-3.1-1.1-3.1-2.9v-3.2h-1
					 c-0.2,0-0.3-0.1-0.3-0.3V8.7c0-0.2,0.1-0.3,0.3-0.3h1V7.2c0-0.2,0.1-0.4,0.4-0.4l2.2-0.6c0.3-0.1,0.4,0,0.4,0.3v1.9h2.3
					 c0.2,0,0.3,0.1,0.3,0.3v1.7c0,0.2-0.1,0.3-0.3,0.3h-2.3v2.5c0,0.8,0.2,1.2,1,1.2c0.4,0,0.6-0.1,1-0.3c0.2-0.1,0.4-0.1,0.5,0.2
					 L100.4,15.6z"
            />
          </g>
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuLogoTextComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
