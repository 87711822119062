import { Router } from '@angular/router';
import { ModalComponent } from "angular-custom-modal";
import { Component, Input, ViewChild } from "@angular/core";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { PaymentService } from './../../../domain/sales/services/payment.service';




@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
	styleUrls: ["./invoice.component.css"],
})
export class InvoiceComponent {
  @Input() plan!: SaleBase;
  @ViewChild("modal") modal!: ModalComponent;
	constructor(public paymentService: PaymentService, private router: Router) {}

  sendInvoice() {
    this.modal.open();
  }
  printInvoice() {
    window.print();
  }
  getCurrentDate(): Date {
    return new Date();
  }
	finishSending() {
    this.modal.close();
		this.router.navigate(["dashboard/sales"]);
  }
	closeModal() {
		this.modal.close();
	}
	
}
