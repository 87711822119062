import { Component, Input, OnChanges, SimpleChanges,Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-simple-column",
  templateUrl: "./simple-column.html",
})
export class SimpleColumnComponent implements OnChanges {
  @Input() bruto!: number;
  @Input() neto!: number;
  @Input() name!: string;
  @Input() data!: { year: number; month: number; numberOfPlans: number }[];
  @Output() yearSelected = new EventEmitter<number>(); // Dodajemo EventEmitter

  columnChart: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      const isDark = true;
      const isRtl = false;
      
      const dataByYear = this.data.reduce((acc, item) => {
        if (!acc[item.year]) {
          acc[item.year] = item.numberOfPlans;
        } else {
          acc[item.year] += item.numberOfPlans;
        }
        return acc;
      }, {} as { [year: number]: number });

      const categories = Object.keys(dataByYear);

      const series = [{
        name: 'Plans',
        data: categories.map(year => dataByYear[parseInt(year)])
      }];

      this.columnChart = {
        series: series,
        chart: {
          height: 300,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#378CE7", "#67C6E3"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        grid: {
          borderColor: isDark ? "#378CE7" : "#67C6E3",
        },
        xaxis: {
          type: "category", // Set x-axis type to category
          categories: categories,
          axisBorder: {
            color: isDark ? "#378CE7" : "#67C6E3",
          },
        },
        yaxis: {
          opposite: isRtl ? true : false,
          labels: {
            offsetX: isRtl ? -10 : 0,
          },
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: {
            formatter: function (val: any) {
              return val;
            },
          },
        },
      };

			
    }
		
  }
	
}
