import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Voucher } from "../../travels/models/voucher";
import { Customer } from "src/app/domain/customers/models/customer";
import { VoucherService } from "../../documents/voucher/services/voucher.service";

@Component({
  selector: "app-group-travel-voucher-edit",
  templateUrl: "./group-travel-voucher-edit.component.html",
})
export class GroupTravelVoucherEditComponent implements OnInit {
  voucher!: Voucher;
  passangers!: Customer[];
  groupTravelPlanId!: number;
  voucherId!: number;
  cancelVoucherUrlTemplate!: string;

  constructor(private route: ActivatedRoute, public voucherService: VoucherService) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.groupTravelPlanId = Number(params.get("id"));
      this.voucherId = Number(params.get("voucherId"));
      this.cancelVoucherUrlTemplate = `/dashboard/group-travels/${this.groupTravelPlanId}/voucher-list`;
      this.getVoucherData();
    });
  }

  getVoucherData() {
    this.voucherService.getVoucher(this.voucherId).subscribe((response) => {
      this.passangers = response.passengers;
      this.passangers = this.passangers.map((passanger: Customer) => {
        passanger.fullName = passanger.name + " " + passanger.surname;
        passanger.dateOfBirth = passanger.dateOfBirth ? moment(passanger.dateOfBirth).format("DD/MM/YYYY") : undefined;
        return passanger;
      });
      this.voucher = response;
    });
  }
}
