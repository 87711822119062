import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-confirmation-dialog",
  styleUrls: ["./confirmation-dialog.component.scss"],
  templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {
  @Input() title = "Confirmation";
  @Input() message = "Are you sure you want to proceed?";
  @Input() confirmButtonText = "Confirm";
  @Input() cancelButtonText = "Cancel";

  @Output() confirmed = new EventEmitter<boolean>();

  onCancel(): void {
    this.confirmed.emit(false);
  }

  onConfirm(): void {
    this.confirmed.emit(true);
  }
}
