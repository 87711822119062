import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-organizer",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M9.8,11.1c0-0.2,0-0.4,0-0.6c-0.1,0-0.1,0-0.2,0c-1,0-2,0-3,0c-0.7,0-1-0.5-0.8-1.1c0.2-0.6,0.6-1,1.1-1.2
				 c0.5-0.2,0.9-0.4,1.4-0.5C8.8,7.5,9,7.2,9.2,6.8C9,6.6,8.8,6.5,8.7,6.4C8.3,6.1,8.1,5.8,7.9,5.3c0-0.1-0.1-0.1-0.1-0.2
				 C7.4,5,7.2,4.6,7.4,4.1c0-0.1,0-0.2-0.1-0.3C6.9,3.5,6.6,3.1,6.5,2.6C6.2,1.6,7.1,0.8,8.1,1c0.1,0,0.1,0,0.2,0
				 C8.9,1.2,9.4,1.2,10,1c0.2-0.1,0.5-0.2,0.7-0.2c0.6-0.1,1.2,0.3,1.5,0.9c0,0.1,0.1,0.1,0.2,0.2c0.3,0.2,0.4,0.4,0.4,0.7
				 c0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.5,0,0.9-0.4,1.1c0,0-0.1,0.1-0.1,0.1c-0.4,0.8-0.5,1-1.2,1.5
				 c0.1,0.4,0.4,0.7,0.8,0.8c0.5,0.1,1,0.3,1.5,0.5c0.6,0.3,1,0.7,1.2,1.3c0.2,0.6-0.2,1.1-0.8,1.1c-1,0-2,0-3,0c-0.1,0-0.1,0-0.2,0
				 c0,0.2,0,0.4,0,0.6c0.1,0,0.1,0,0.2,0c1.7,0,3.4,0,5.1,0c0.6,0,0.9,0.3,0.9,0.9c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.2
				 c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-0.3-0.1-0.4-0.4-0.4c-0.4,0-0.8,0-1.1,0c-1.4,0-2.7,0-4.1,0c-0.1,0-0.1,0-0.2,0
				 c0,0.2,0,0.3,0,0.5c0,0.4,0,0.5-0.2,0.5c-0.2,0-0.2-0.1-0.2-0.5c0-0.1,0-0.3,0-0.5c-0.1,0-0.1,0-0.2,0c-1.7,0-3.4,0-5.1,0
				 c-0.4,0-0.5,0.1-0.5,0.5c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.4,0-0.6
				 c0.1-0.4,0.4-0.6,0.8-0.6c1.3,0,2.5,0,3.8,0C8.7,11.1,9.2,11.1,9.8,11.1z M10,10c1.1,0,2.3,0,3.4,0c0.4,0,0.5-0.2,0.4-0.6
				 c-0.2-0.4-0.4-0.8-0.9-1C12.5,8.3,12,8.1,11.5,8c-0.1,0-0.2,0-0.2,0.1c-0.3,0.2-0.6,0.5-0.8,0.8c-0.4,0.3-0.6,0.3-1,0
				 C9.2,8.6,8.9,8.3,8.7,8.1C8.6,8,8.5,8,8.5,8C8,8.1,7.5,8.3,7,8.5C6.6,8.6,6.3,9,6.2,9.4C6,9.8,6.2,10,6.6,10C7.7,10,8.9,10,10,10z
					M12.2,3.6c0.2-0.4,0.3-0.8,0.2-1.2c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3-0.1-0.3-0.3c-0.2-0.5-0.7-0.8-1.2-0.7
				 c-0.3,0.1-0.5,0.1-0.8,0.2c-0.5,0.1-0.9,0.1-1.4,0C8.3,1.4,8.1,1.4,7.8,1.4C7.2,1.3,6.8,1.9,6.9,2.5C7,2.9,7.3,3.3,7.6,3.6
				 c0.1,0.1,0.2,0.1,0.3-0.1C8,3.4,8.1,3.4,8.2,3.4c0.7,0.2,1.3,0,1.8-0.5c0.2-0.3,0.4-0.2,0.6,0.1c0.2,0.4,0.7,0.6,1.1,0.5
				 C11.9,3.4,12.1,3.4,12.2,3.6z M10.3,3.2C9.9,3.6,9.4,3.8,8.9,3.9C8.6,3.9,8.3,3.8,8,4C7.9,4.1,7.7,4.2,7.8,4.5
				 c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2,0.1,0.2,0.2c0.1,0.3,0.2,0.5,0.4,0.8c0.4,0.6,1.2,0.9,1.8,0.6c0.6-0.2,1-0.7,1.2-1.4
				 c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.2-0.1,0.2-0.2c0.1-0.3-0.1-0.6-0.5-0.6C11.1,4,10.6,3.7,10.3,3.2z M9,7.8C9.3,8,9.6,8.3,9.9,8.6
				 c0,0,0.2,0.1,0.2,0C10.4,8.3,10.7,8,11,7.8c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2,0-0.5,0-0.7,0C9.4,7.2,9.2,7.5,9,7.8z"
          />
          <path
            class="st0"
            d="M10,19.2c-0.7,0-1.3,0-2,0c-0.5,0-0.8-0.3-0.8-0.8c0-0.9,0.3-1.4,1.2-1.7c1.1-0.3,2.2-0.3,3.3,0
				 c0.5,0.2,1,0.5,1.1,1.1c0.1,0.2,0.1,0.5,0.1,0.8c0,0.4-0.3,0.6-0.7,0.6C11.4,19.2,10.7,19.2,10,19.2z M10,18.8c0.7,0,1.3,0,2,0
				 c0.2,0,0.3-0.1,0.3-0.3c0.1-0.5-0.1-1-0.6-1.3c-0.3-0.2-0.7-0.3-1-0.3c-0.6,0-1.3,0-1.9,0.1c-0.8,0.1-1.2,0.6-1.2,1.4
				 c0,0.3,0.1,0.4,0.4,0.4C8.7,18.8,9.3,18.8,10,18.8z"
          />
          <path
            class="st0"
            d="M3.6,19.2c-0.7,0-1.3,0-2,0c-0.5,0-0.8-0.3-0.8-0.8c0-0.9,0.3-1.4,1.1-1.7c1.1-0.4,2.3-0.4,3.4,0
				 c0.8,0.3,1.2,1,1.1,1.8c0,0.3-0.3,0.6-0.7,0.6c-0.4,0-0.8,0-1.2,0C4.2,19.2,3.9,19.2,3.6,19.2z M3.6,18.8c0.7,0,1.4,0,2,0
				 c0.2,0,0.3-0.1,0.3-0.2c0.1-0.5-0.1-1-0.5-1.2C5.1,17.2,4.8,17,4.5,17c-0.6-0.1-1.2-0.1-1.8,0c-0.9,0.1-1.5,0.6-1.4,1.4
				 c0,0.3,0.1,0.4,0.4,0.4C2.2,18.8,2.9,18.8,3.6,18.8z"
          />
          <path
            class="st0"
            d="M16.4,19.2c-0.7,0-1.3,0-2,0c-0.5,0-0.8-0.3-0.8-0.8c0-0.9,0.3-1.4,1.2-1.7c1.1-0.3,2.2-0.3,3.3,0
				 c0.5,0.2,0.9,0.6,1.1,1.2c0,0.2,0,0.4,0,0.6c0,0.5-0.3,0.7-0.7,0.7C17.8,19.2,17.1,19.2,16.4,19.2z M16.4,18.8c0.7,0,1.3,0,2,0
				 c0.2,0,0.3-0.1,0.3-0.2c0.1-0.5-0.1-1-0.6-1.3c-0.2-0.1-0.4-0.2-0.7-0.3c-0.7-0.1-1.5-0.2-2.2,0c-0.8,0.2-1.3,0.6-1.2,1.4
				 c0,0.3,0.1,0.4,0.4,0.4C15.1,18.8,15.8,18.8,16.4,18.8z"
          />
          <path
            class="st0"
            d="M2.1,14.4c0-0.9,0.7-1.5,1.6-1.4c0.8,0,1.4,0.7,1.3,1.6c0,0.4-0.2,0.8-0.5,1.1c-0.6,0.6-1.3,0.6-1.9,0
				 C2.3,15.3,2.1,14.8,2.1,14.4z M4.6,14.5c0-0.7-0.4-1.1-1-1.1c-0.5,0-1,0.3-1,0.8c-0.1,0.5,0.1,1,0.5,1.3c0.3,0.3,0.7,0.3,1,0
				 C4.4,15.2,4.6,14.8,4.6,14.5z"
          />
          <path
            class="st0"
            d="M8.5,14.5c0-1,0.7-1.6,1.6-1.6c0.9,0.1,1.4,0.8,1.3,1.7c-0.1,0.4-0.3,0.8-0.6,1.1c-0.5,0.5-1.2,0.5-1.7,0
				 C8.7,15.4,8.5,14.9,8.5,14.5z M10,13.4c-0.5,0-0.9,0.3-1,0.8c-0.1,0.5,0.2,1.1,0.6,1.3c0.3,0.2,0.6,0.2,0.9,0
				 c0.3-0.2,0.5-0.5,0.5-0.9C11.1,13.9,10.7,13.4,10,13.4z"
          />
          <path
            class="st0"
            d="M15,14.5c0-1,0.7-1.6,1.6-1.6c0.9,0.1,1.4,0.8,1.3,1.7c-0.1,0.4-0.3,0.8-0.6,1.1c-0.5,0.5-1.2,0.5-1.7,0
				 C15.2,15.4,15,14.9,15,14.5z M15.4,14.4c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.3,1.1,0c0.3-0.3,0.5-0.6,0.5-1c0.1-0.6-0.3-1-0.9-1.1
				 C15.9,13.4,15.4,13.8,15.4,14.4z"
          />
          <path
            class="st1"
            d="M10,10c-1.1,0-2.2,0-3.4,0C6.2,10,6,9.8,6.2,9.4C6.3,9,6.6,8.6,7,8.5C7.5,8.3,8,8.1,8.5,8c0.1,0,0.1,0,0.2,0.1
				 c0.3,0.2,0.6,0.5,0.8,0.8c0.4,0.3,0.6,0.3,1,0c0.3-0.3,0.6-0.5,0.8-0.8C11.4,8,11.5,8,11.5,8c0.5,0.1,1,0.3,1.4,0.5
				 c0.4,0.2,0.7,0.5,0.9,1c0.1,0.4,0,0.6-0.4,0.6C12.3,10,11.1,10,10,10z"
          />
          <path
            class="st1"
            d="M12.2,3.6c-0.1-0.2-0.2-0.2-0.4-0.2c-0.4,0.1-0.9-0.2-1.1-0.5c-0.2-0.3-0.4-0.3-0.6-0.1
				 C9.5,3.4,8.9,3.6,8.2,3.4c-0.2,0-0.3,0-0.3,0.1C7.8,3.7,7.7,3.7,7.6,3.6C7.3,3.3,7,2.9,6.9,2.5C6.8,1.9,7.2,1.3,7.8,1.4
				 c0.2,0,0.4,0.1,0.6,0.1c0.5,0.1,0.9,0.1,1.4,0c0.3-0.1,0.5-0.2,0.8-0.2c0.5-0.1,1.1,0.2,1.2,0.7c0.1,0.2,0.1,0.3,0.3,0.3
				 c0.1,0,0.2,0.1,0.2,0.2C12.5,2.9,12.4,3.2,12.2,3.6z"
          />
          <path
            class="st1"
            d="M10.3,3.2c0.4,0.5,0.8,0.7,1.4,0.7c0.4,0,0.6,0.3,0.5,0.6c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2,0.1-0.3,0.3
				 c-0.2,0.6-0.6,1.1-1.2,1.4c-0.6,0.2-1.4,0-1.8-0.6C8.5,5.6,8.4,5.3,8.3,5c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3-0.1-0.3-0.3
				 C7.7,4.2,7.9,4.1,8,4c0.3-0.2,0.6-0.1,0.9-0.1C9.4,3.8,9.9,3.6,10.3,3.2z"
          />
          <path
            class="st1"
            d="M9,7.8c0.2-0.3,0.4-0.6,0.6-0.9c0.2,0,0.5,0,0.7,0c0.2,0.3,0.4,0.6,0.6,0.9c-0.3,0.2-0.6,0.5-0.9,0.8
				 c0,0-0.2,0-0.2,0C9.6,8.3,9.3,8,9,7.8z"
          />
          <path
            class="st1"
            d="M10,18.8c-0.7,0-1.3,0-2,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.8,0.4-1.2,1.2-1.4c0.6-0.1,1.2-0.1,1.9-0.1
				 c0.4,0,0.7,0.1,1,0.3c0.5,0.3,0.7,0.7,0.6,1.3c0,0.2-0.2,0.3-0.3,0.3C11.3,18.8,10.7,18.8,10,18.8z"
          />
          <path
            class="st1"
            d="M3.6,18.8c-0.7,0-1.3,0-2,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.8,0.5-1.3,1.4-1.4c0.6-0.1,1.2-0.1,1.8,0
				 c0.3,0,0.7,0.2,0.9,0.3c0.4,0.2,0.6,0.8,0.5,1.2c0,0.2-0.2,0.2-0.3,0.2C4.9,18.8,4.2,18.8,3.6,18.8z"
          />
          <path
            class="st1"
            d="M16.4,18.8c-0.7,0-1.3,0-2,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.8,0.4-1.2,1.2-1.4c0.7-0.2,1.5-0.1,2.2,0
				 c0.2,0,0.5,0.1,0.7,0.3c0.4,0.2,0.7,0.8,0.6,1.3c0,0.2-0.2,0.2-0.3,0.2C17.8,18.8,17.1,18.8,16.4,18.8z"
          />
          <path
            class="st1"
            d="M4.6,14.5c0,0.3-0.2,0.7-0.5,1c-0.3,0.3-0.7,0.3-1,0c-0.4-0.3-0.6-0.8-0.5-1.3c0.1-0.5,0.5-0.8,1-0.8
				 C4.2,13.4,4.6,13.8,4.6,14.5z"
          />
          <path
            class="st1"
            d="M10,13.4c0.7,0,1.1,0.5,1,1.2c-0.1,0.4-0.2,0.7-0.5,0.9c-0.3,0.2-0.6,0.2-0.9,0c-0.4-0.3-0.7-0.9-0.6-1.3
				 C9.1,13.7,9.5,13.4,10,13.4z"
          />
          <path
            class="st1"
            d="M15.4,14.4c0-0.6,0.5-1,1.1-1c0.6,0,1,0.5,0.9,1.1c0,0.4-0.2,0.7-0.5,1c-0.3,0.3-0.7,0.3-1.1,0
				 C15.6,15.2,15.4,14.8,15.4,14.4z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuOrganizerComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
