<div class="datatable">
  <ng-datatable
    [rows]="allTasks"
    [columns]="cols"
    [sortable]="true"
    [loading]="loading"
    skin="whitespace-nowrap table-hover bg-info/10"
    [paginationInfo]="'paginationInfoLabel' | translate"
    [noDataContent]="'empty_tables' | translate"
  >
    <ng-template slot="start" let-value="data"> {{ value.start | date : "dd.MM.yyyy" }} </ng-template>
    <ng-template slot="customer" let-value="data">
      <span>{{ value.customer?.name }} {{value.customer?.surname}}</span>
    </ng-template>
    <ng-template slot="days_nights" let-value="data">
      <span>{{ value.days}} {{ "draft_table.days" | translate }} / {{value.nights}} {{ "draft_table.nights" | translate }}  </span>
    </ng-template>
    <ng-template slot="date" let-value="data">
      <span>{{ value.start | date : "dd.MM.yyyy" }} - {{ value.end | date : "dd.MM.yyyy" }}</span>
    </ng-template>
    <ng-template slot="hotels" let-value="data">
      <span>
        <ng-container *ngFor="let td of value.travelDestinations">
          {{ td.hotel.name }}
        </ng-container>
      </span>
    </ng-template>
    <ng-template slot="destinations" let-value="data">
      <span>
        <ng-container *ngFor="let td of value.travelDestinations">
          {{ td.destination?.description }}
        </ng-container>
      </span>
    </ng-template>
    <ng-template slot="status" let-value="data">
      <span class="badge bg-warning">{{ getStatusLabel(value.plan.status) }} - {{ "not-paid" | translate }}</span>
    </ng-template>
    <ng-template slot="actions" let-value="data">
      <div class="flex w-max items-center justify-between">
        <div hlMenu class="dropdown">
          <a hlMenuButton href="javascript:;">
            <icon-horizontal-dots class="rotate-90 opacity-70" />
          </a>
          <div #dropdownPlaceholder *hlMenuItems>
            <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
              <li>
                <a href="javascript:;" *hlMenuItem="let menuItem" (click)="editDraftAndPrepare(value.id)">
                  <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                  {{ "draft_table.edit_and_send_again" | translate }}
                </a>
              </li>
              <li>
                <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deleteDraftTravel(value.id)">
                  <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
                  {{ "draft_table.delete" | translate }}
                </a>
              </li>
              <li>
                <a href="javascript:;" *hlMenuItem="let menuItem" (click)="prepareAnouncement(value.id)">
                  <icon-checks></icon-checks>
                  {{ "draft_table.announcement_accepted" | translate }}
                </a>
              </li>
            </app-message-portal>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-datatable>
</div>
