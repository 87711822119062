
<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="grid grid-cols-1 gap-5 sm:grid-cols-1">
    <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="name">{{ "organizer_data.name" | translate }}</label>
      <input id="name" type="text" placeholder="{{ 'organizer_data.name' | translate }}" class="form-input" formControlName="name" />
      <small class="text-danger" *ngIf="form.controls['name'].touched && form.controls['name'].errors">{{ "organizer_data.please_provide_name" | translate }}</small>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
    <div [ngClass]="form.controls['firstName'].touched ? (form.controls['firstName'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="firstName">{{ "organizer_data.fullName" | translate }}</label>
      <input id="firstName" type="text" placeholder="{{ 'organizer_data.fullName' | translate }}" class="form-input" formControlName="firstName" />
      <small class="text-danger" *ngIf="form.controls['firstName'].touched && form.controls['firstName'].errors">{{ "organizer_data.please_provide_first_name" | translate }}</small>
    </div>
    <div [ngClass]="form.controls['lastName'].touched ? (form.controls['lastName'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="lastName">{{ "organizer_data.lastName" | translate }}</label>
      <input id="lastName" type="text" placeholder="{{ 'organizer_data.lastName' | translate }}" class="form-input" formControlName="lastName" />
      <small class="text-danger" *ngIf="form.controls['lastName'].touched && form.controls['lastName'].errors">{{ "organizer_data.please_provide_last_name" | translate }}</small>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
    <div [ngClass]="form.controls['phone'].touched ? (form.controls['phone'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="phone">{{ "organizer_data.phone" | translate }}</label>
      <input id="phone" type="text" placeholder="{{ 'organizer_data.phone' | translate }}" class="form-input" formControlName="phone" />
      <small class="text-danger" *ngIf="form.controls['phone'].touched && form.controls['phone'].errors">{{ "organizer_data.please_provide_phone" | translate }}</small>
    </div>
    <div [ngClass]="form.controls['licence'].touched ? (form.controls['licence'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="licence">{{ "organizer_data.licence" | translate }}</label>
      <input id="licence" type="text" placeholder="{{ 'organizer_data.licence' | translate }}" class="form-input" formControlName="licence" />
      <small class="text-danger" *ngIf="form.controls['licence'].touched && form.controls['licence'].errors">{{ "organizer_data.please_provide_licence" | translate }}</small>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
    <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="email">{{ "organizer_data.email" | translate }}</label>
      <input id="email" type="text" placeholder="{{ 'organizer_data.email' | translate }}" class="form-input" formControlName="email" />
      <small class="text-danger" *ngIf="form.controls['email'].touched && form.controls['email'].errors">{{ "organizer_data.please_provide_email" | translate }}</small>
    </div>
    <div [ngClass]="form.controls['address'].touched ? (form.controls['address'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="address">{{ "organizer_data.address" | translate }}</label>
      <input id="address" type="text" placeholder="{{ 'organizer_data.address' | translate }}" class="form-input" formControlName="address" />
      <small class="text-danger" *ngIf="form.controls['address'].touched && form.controls['address'].errors">{{ "organizer_data.please_provide_address" | translate }}</small>
    </div>
  </div>
  <button *ngIf="!editMode" type="submit" class="btn btn-primary w-full" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingTemplate">{{ "organizer_data.save" | translate }} </ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
    </ng-template>
  </button>
  <button *ngIf="editMode" type="submit" class="btn btn-primary w-full" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingTemplate">{{ "organizer_data.update" | translate }} </ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
    </ng-template>
  </button>
</form>