import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Voucher } from "src/app/domain/travels/models/voucher";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class VoucherService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getVoucherByTravelDestination(id: number): Observable<Voucher> {
    return this.http.get<Voucher>(`${this.apiUrl}/api/vouchers/TravelDestination/${id}`);
  }

  getVoucher(id: number): Observable<Voucher> {
    return this.http.get<Voucher>(`${this.apiUrl}/api/vouchers/${id}`);
  }

  updateTravelVoucher(id: number, data: any): Observable<Voucher> {
    return this.http.put<Voucher>(`${this.apiUrl}/api/vouchers/${id}`, data);
  }
  getVoucherByGroupTravel(groupTravelPlanId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/vouchers/group-travel/${groupTravelPlanId}`);
  }
  getGroupTravelVoucher(id: number): Observable<Voucher> {
    return this.http.get<Voucher>(`${this.apiUrl}/api/vouchers/TravelDestination/${id}/Voucher`);
  }
  sendVoucher(voucherId: number): (data: any) => Observable<any> {
    return (body: FormData): Observable<any> => {
      body.append("VoucherId", voucherId.toString());
      return this.http.post(`${this.apiUrl}/api/Vouchers/send`, body);
    };
  }
  stringToHtmlFile(htmlContent: string, fileName: string): File {
    const blob = new Blob([htmlContent], { type: "text/html" });
    return new File([blob], fileName, { type: "text/html" });
  }
  getTravelVoucherEmail(id: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/api/vouchers/${id}/emails`);
  }
}
