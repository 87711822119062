import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Hotel, HotelStatistics } from "../models/hotel";
import { map, Observable } from "rxjs";
import { GenericResponse } from "src/app/shared/models/generics";
import { Sale } from "../../sales/models/sale";

@Injectable({
  providedIn: "root",
})
export class HotelsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getHotelsRecords(id: number, pageNumber: number, pageSize: number, filterType: string) {
    const apiUrl = `${this.apiUrl}/api/hotels/plans/${id}`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("from", "2021-03-24").set("filterType", filterType);
    // .set('sortProperty', 'numberOfPassangers')
    // .set('sortType', 'Asc')

    return this.http.get<Sale[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Sale> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  getHotelStatistics(id: number) {
    return this.http.get<HotelStatistics>(`${this.apiUrl}/api/hotels/statistics/${id}`);
  }

  addHotel(data: any) {
    return this.http.post<Hotel>(`${this.apiUrl}/api/hotels`, data);
  }

  updateHotel(id: number, data: any) {
    return this.http.put<Hotel>(`${this.apiUrl}/api/hotels/${id}`, data);
  }
  searchHotelByIdWithTerm(hotelId: number, term: string) {
    return this.http.get<Hotel[]>(`${this.apiUrl}/api/Hotels/search/${hotelId}?term=${term}`);
  }
  mergeHotels(rootId: number, listOfIds: number[]): Observable<any> {
    const url = `${this.apiUrl}/api/Hotels/merge`;
    const body = { rootId, listOfIds };
    return this.http.post(url, body, { responseType: "text" });
  }
  deleteHotel(id: number) {
    return this.http.delete(`${this.apiUrl}/api/hotels/${id}`, { responseType: "text" });
  }
  getHotels() {
    return this.http.get<Hotel[]>(`${this.apiUrl}/api/hotels`);
  }
}
