<div class="mb-5" *ngIf="travelDestinations">
  <div class="mb-5 mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a] print:hidden">
    <a
      href="javascript:;"
      class="-mb-[1px] flex items-center border-transparent p-5 py-3 !outline-none transition duration-300 hover:border-b hover:!border-secondary hover:text-secondary"
      *ngFor="let travelDestination of travelDestinations; index as i"
      [ngClass]="{ 'border-b !border-primary text-primary': voucherTab === travelDestination.id }"
      (click)="selectDestination(travelDestination.id)"
    >
      {{ travelDestination?.destination?.description }}
    </a>
  </div>
  <div class="flex-1 text-sm">
    <div *ngIf="selectedDestination && voucherTab === selectedDestination.id">
      <app-voucher [voucher]="selectedDestination.voucher" [editUrl]="editVoucherUrlTemplate(selectedDestination.voucher.id)"></app-voucher>
    </div>
  </div>
</div>
