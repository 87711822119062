<div class="panel ng-tns-c48-7 h-full w-full" *ngIf="data">
  <div class="ng-tns-c48-7 mb-5 flex items-center justify-between">
    <h5 class="ng-tns-c48-7 text-lg font-semibold text-black dark:text-white-light">{{ "quickview.ticket_info" | translate }}</h5>
  </div>
  <div class="table-responsive ng-tns-c48-7 overflow-x-hidden">
    <table class="ng-tns-c48-7 w-full">
      <thead class="ng-tns-c48-7 bg-primary text-dark">
        <tr class="ng-tns-c48-7 border-b-0">
          <th class="ng-tns-c48-7 w-1/2 text-left ltr:rounded-l-md rtl:rounded-r-md">{{ "customer" | translate }}</th>
          <th class="ng-tns-c48-7 w-1/2 text-left">{{ "tickets_info.reservation_number" | translate }}:</th>
        </tr>
      </thead>
      <tbody class="ng-tns-c48-7">
        <tr class="ng-tns-c48-7 group text-white-dark hover:text-black dark:hover:text-white-light/90">
          <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
            <span class="mr-2">{{ data.customer.surname }} {{ data.customer.name }}</span>
          </td>
          <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
            <span class="mr-2">{{ getReservationNumber(data.passangers) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-responsive ng-tns-c48-7 overflow-x-hidden">
      <table class="ng-tns-c48-7 w-full">
        <thead class="ng-tns-c48-7 bg-primary text-dark">
          <tr class="ng-tns-c48-7 border-b-0">
            <th class="ng-tns-c48-7 w-1/2 text-left ltr:rounded-l-md rtl:rounded-r-md">{{ "ticket_details.departure_from" | translate }}</th>
            <th class="ng-tns-c48-7 w-1/2 text-left">{{ "ticket_details.destination" | translate }}</th>
          </tr>
        </thead>
        <tbody class="ng-tns-c48-7">
          <tr class="ng-tns-c48-7 group text-white-dark hover:text-black dark:hover:text-white-light/90">
            <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
              <span class="mr-2">{{ data.departure.description }}</span>
            </td>
            <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
              <span class="mr-2">{{ data.destination.description }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive ng-tns-c48-7 overflow-x-hidden">
      <table class="ng-tns-c48-7 w-full">
        <thead class="ng-tns-c48-7 bg-primary text-dark">
          <tr class="ng-tns-c48-7 border-b-0">
            <th class="ng-tns-c48-7 w-1/2 text-left ltr:rounded-l-md rtl:rounded-r-md">{{ "ticket_details.departure_date" | translate }}</th>
            <th class="ng-tns-c48-7 w-1/2 text-left">{{ "ticket_details.arrival_date" | translate }}</th>
          </tr>
        </thead>
        <tbody class="ng-tns-c48-7">
          <tr class="ng-tns-c48-7 group text-white-dark hover:text-black dark:hover:text-white-light/90">
            <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
              <span class="mr-2">{{ data.departureDate | date : "dd.MM.yyyy" }}</span>
            </td>
            <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
              <span class="mr-2">{{ data.arrivalDate ? (data.arrivalDate | date : "dd.MM.yyyy") : "-" }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive ng-tns-c48-7 overflow-x-hidden">
      <table class="ng-tns-c48-7 w-full">
        <thead class="ng-tns-c48-7 bg-primary text-dark">
          <tr class="ng-tns-c48-7 border-b-0">
            <th class="ng-tns-c48-7 w-1/2 text-left ltr:rounded-l-md rtl:rounded-r-md">{{ "sale_details.dept" | translate }}</th>
            <th class="ng-tns-c48-7 w-1/2 text-left">{{ "tickets_info.numberOfPassangers" | translate }}:</th>
          </tr>
        </thead>
        <tbody class="ng-tns-c48-7">
          <tr class="ng-tns-c48-7 group text-white-dark hover:text-black dark:hover:text-white-light/90">
            <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
              <span class="mr-2">{{ data.plan.bruto - (data.plan.avans ?? 0) | currency : "EUR" }}</span>
            </td>
            <td class="ng-tns-c48-7 w-1/2 min-w-[150px] text-left text-black dark:text-white">
              <span class="mr-2">{{ data.numberOfPassangers }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex items-center justify-center">
      <div class="mx-3 mb-1 w-1/2 text-center text-[10px] text-dark">
        {{ "tickets_info.created_date" | translate }}:
        <p class="mb-1 flex justify-center text-left text-[10px] text-dark">{{ data.createdAt | date : "dd.MM.yyyy" }}</p>
      </div>

      <div class="mx-3 mb-1 w-1/2 text-left text-center text-[10px] text-dark">
        {{ "tickets_info.created_by" | translate }}:
        <p class="mb-1 flex justify-center text-[10px] text-dark" *ngIf="data.plan.createdBy; else noCreator">{{ data.plan.createdBy.name }} {{ data.plan.createdBy.surname }}</p>
      </div>
    </div>

    <ng-template #noCreator>
      <p class="mb-1 flex w-1/2 justify-center text-left text-[10px] text-dark">-</p>
    </ng-template>
  </div>
</div>
