import { Contract } from "../models/contract";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "angular-custom-modal";
import { ActivatedRoute, Router } from "@angular/router";
import { ContractService } from "../services/contract.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
})
export class ContractComponent implements OnInit {
  isShowingSidebar = false;
  @Input() contract!: Contract;
  @Input() editUrlNavigation!: string;
  public entityname = "contract";
  public id!: number;
  @ViewChild("modal") modal!: ModalComponent;

  constructor(public route: ActivatedRoute, public contractService: ContractService, public router: Router, public translate: TranslateService) {}
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.id = Number(params.get("id"));
    });
  }
  editContract() {
    this.router.navigate([this.editUrlNavigation]);
  }

  sendContract() {
    this.modal.open();
  }

  printContract() {
    window.print();
  }

  finishSending() {
    this.modal.close();
  }
}
