<div class="space-y-8">
  <div *ngIf="destinationData" class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.basic_information" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex items-center pl-2 sm:col-span-2">
          <div class="flex flex-col">
            <div class="text-white-dark">{{ "destination_info.description" | translate }}</div>
            <div class="text-xl font-bold">{{ destinationData.description }}</div>
            <div class="text-[15px]" style="font-style: italic">
            </div>
          </div>
        </div>
        <div class="col-span-1 flex items-center pl-2 sm:col-span-2">
          <div class="flex flex-col"></div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div class="flex flex-col p-2">
            <div class="text-[15px]">{{ "destination_info.country" | translate }}:</div>
            <div class="pt-1 text-[18px]">{{ destinationData.country.name }}</div>
          </div>
        </div>
      </div>

      <div class="gric-cols-2 col-span-2 grid pl-2 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pl-2 sm:col-span-2">
          <div style="font-size: 0.875rem; color: darkslategray">{{ "customer_details.createdAt" | translate }} :</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div style="font-size: 0.875rem; color: darkslategray">{{ "customer_details.updatedAt" | translate }} :</div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pb-4 sm:col-span-2">
          <div class="text-xl font-bold">
            {{ "customer_stats.payments" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex flex-col pb-4 sm:col-span-2">
          <div class="text-xl font-bold">
            {{ "statistics" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div class="p-1">{{ "hotel_statistics.bruto" | translate }}:</div>
          <div class="p-1">{{ destinationData.destinationBrutoSum | currency : "EUR" }}</div>

          <div class="p-1">{{ "destinationStatistics.destinationNetoSum" | translate }}:</div>
          <div class="p-1">{{ destinationData.destinationNetoSum | currency : "EUR" }}</div>

          <div class="p-1">{{ "destinationStatistics.totalDept" | translate }}:</div>
          <div class="p-1">{{ destinationData.totalDept | currency : "EUR" }}</div>

          <div class="p-1">{{ "destination_info.profit_on_waiting" | translate }}:</div>
          <div class="p-1">{{ destinationData.profitOnWaiting | currency : "EUR" }}</div>
        </div>

        <div class="max-height sm:col-span-1">
          <div class="yearly-chart custom-chart chart-small h-100">
            <apx-chart
              *ngIf="chartData.length > 0"
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis"
              [yaxis]="chartOptions.yaxis"
              [legend]="chartOptions.legend"
              [dataLabels]="chartOptions.dataLabels"
            ></apx-chart>
          </div>
        </div>
        <div class="monthly-chart max-height sm:col-span-1" *ngIf="monthlyChartOptions && monthlyChartOptions.series && monthlyChartOptions.series.length > 0">
          <apx-chart
            [series]="monthlyChartOptions.series"
            [chart]="monthlyChartOptions.chart"
            [xaxis]="monthlyChartOptions.xaxis"
            [yaxis]="monthlyChartOptions.yaxis"
            [legend]="monthlyChartOptions.legend"
            [dataLabels]="monthlyChartOptions.dataLabels"
            class="h-100"
          ></apx-chart>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.services" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "destinationStatistics.passangersCount" | translate }}:</div>
          <div>{{ destinationData.passangersCount }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "destination_info.number_of_tickets" | translate }} :</div>
          <div>{{ destinationData.numberOfTickets }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "destination_info.number_of_travels" | translate }}:</div>
          <div>{{ destinationData.numberOfTravels }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "destinationStatistics.groupTravels" | translate }}:</div>
          <div>{{ destinationData.serviceStatisticPerCustomer.GroupTravels }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "destinationStatistics.transfers" | translate }}:</div>
          <div>{{ destinationData.serviceStatisticPerCustomer.Transfer }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <h5 class="text-lg font-semibold dark:text-white-light">{{ "show_hide_columns" | translate }}</h5>

      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div>
          <input [(ngModel)]="search" type="text" class="form-input" placeholder="{{ 'search_placeholder' | translate }}" />
        </div>
        <app-export-table
          [exportApi]="exportApiUrl"
          [filterType]="filterType"
          [sortColumn]="'none'"
          [sortDirection]="'asc'"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Destinations Table Export'"
        ></app-export-table>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [loading]="loading"
        [isServerMode]="true"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
      >
        <ng-template slot="createdAtFormated" let-value="data"> {{ value.createdAt | date : "dd.MM.yyyy" }} </ng-template>
        <ng-template slot="destinations" let-value="data">
          <span>{{ value.departure.description }}, {{ value.destination.description }}</span>
        </ng-template>
        <ng-template slot="getStatus" let-value="data">
          <span *ngIf="value.status; else elseBlock" class="badge bg-success">{{ "paid" | translate }}</span>
          <ng-template #elseBlock>
            <span class="badge bg-danger">{{ "not-paid" | translate }}</span>
          </ng-template>
        </ng-template>
        <ng-template slot="bruto" let-value="data">{{ value.bruto | currency : "EUR" }}</ng-template>
        <ng-template slot="depth" let-value="data"
          ><span class="text-danger">{{ value.depth | currency : "EUR" }}</span>
        </ng-template>
        <ng-template slot="event_starts" let-value="data"> {{ value.event_starts | date : "dd/MM/yyyy" }} </ng-template>
        <ng-template slot="event_ends" let-value="data"> {{ value.event_ends | date : "dd/MM/yyyy" }} </ng-template>

        <ng-template slot="actions" let-value="data">
          <div class="flex items-center">
            <div>
              <a [routerLink]="'/dashboard/destinations/' + value.id" type="button" ngxTippy="Delete" class="btn btn-dark h-10 w-10 rounded-full p-0">
                <icon-info-circle />
              </a>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
</div>
