import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-plane",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <polyline class="st0" points="5.5,15.7 5.5,15.7 7.2,19 7.8,18.4 7.4,14.3 	" />
          <path
            class="st1"
            d="M0.7,12.6c0.2-0.2,0.4-0.4,0.6-0.7c0.1-0.1,0.3-0.2,0.5-0.1C3,12,4.2,12.1,5.4,12.2c0.1,0,0.2,0,0.2-0.1
		 C6.7,10.8,8,9.7,9.2,8.5c0.1-0.1,0.1-0.1,0.2-0.2C9.1,8.2,8.8,8,8.6,7.9C6.5,6.7,4.5,5.6,2.4,4.4C1.9,4.1,1.8,3.6,2.2,3.2
		 c0.1-0.1,0.3-0.3,0.4-0.4C2.8,2.6,3,2.6,3.3,2.6C4,2.8,4.8,3,5.6,3.1c0.1,0,0.2,0,0.2-0.1c0.3-0.3,0.6-0.6,0.9-0.8
		 c0.4-0.4,1-0.4,1.5,0C8.4,2.4,8.6,2.7,8.6,3c0,0.2-0.1,0.5-0.1,0.7c0.2,0,0.5,0.1,0.8,0.2c0,0,0.1,0,0.2-0.1
		 c0.2-0.2,0.4-0.4,0.7-0.7c0.5-0.4,1-0.4,1.5,0c0.3,0.3,0.5,0.6,0.5,1c0,0.1,0,0.2-0.1,0.4c0.3,0.1,0.6,0.1,0.9,0.2
		 c0.1,0,0.1,0,0.2-0.1c0.8-0.7,1.5-1.5,2.3-2.2c0.6-0.6,1.3-1.1,2.1-1.4c0.2-0.1,0.5-0.2,0.8-0.3c0.2,0,0.3,0,0.5,0
		 c0.4,0.1,0.6,0.4,0.7,0.7c0,0.1,0,0.3,0,0.4c-0.4,1.4-1.3,2.4-2.2,3.4C16.5,5.9,16,6.4,15.4,7c-0.1,0.1-0.1,0.2-0.1,0.3
		 c0.1,0.3,0.1,0.5,0.2,0.8C16.2,7.9,17,8.3,17.1,9c0.1,0.4,0,0.7-0.3,0.9c-0.2,0.2-0.4,0.5-0.7,0.7c-0.1,0.1-0.1,0.2-0.1,0.3
		 c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.2,0.9,0c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.4,0.1,0.8-0.2,1.1
		 c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.8,0.3,1.5,0.5,2.3c0.1,0.2,0,0.5-0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.4
		 c-0.4,0.4-0.9,0.3-1.1-0.2c-0.9-1.7-1.9-3.4-2.8-5c-0.4-0.6-0.7-1.3-1.1-1.9c-0.1,0.1-0.1,0.1-0.2,0.1c-1.1,1.1-2.2,2.2-3.3,3.3
		 c-0.2,0.1-0.4,0.3-0.4,0.4c-0.1,0.2,0,0.4,0,0.6c0.1,1,0.2,2.1,0.4,3.1c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.3
		 c-0.3,0.3-0.8,0.2-1-0.2c-0.4-0.8-0.9-1.7-1.3-2.5c0-0.1-0.1-0.1-0.1-0.2c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-0.9-0.2
		 c-0.2-0.2-0.3-0.5-0.1-0.9c0.1-0.3,0.3-0.6,0.4-0.9c0,0-0.1-0.1-0.2-0.1c-0.8-0.4-1.6-0.8-2.5-1.2c-0.2-0.1-0.4-0.2-0.5-0.5
		 C0.7,12.8,0.7,12.7,0.7,12.6z M18.7,1.8c0-0.3-0.1-0.5-0.3-0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.9,0.4-1.6,1.1-2.3,1.8
		 c-2.5,2.5-4.9,4.9-7.4,7.4c-0.9,0.9-1.8,1.9-2.5,2.9C4.7,14.3,4.3,15,4,15.8c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0
		 c0.3-0.1,0.6-0.3,0.9-0.5C6.4,14.9,7.5,14,8.5,13c2.8-2.8,5.6-5.6,8.4-8.4c0.7-0.7,1.2-1.4,1.6-2.3C18.6,2.1,18.7,1.9,18.7,1.8z
			M16.2,17.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.2,0.1-0.3c-0.6-2.6-1.2-5.3-1.7-7.9c-0.1-0.4-0.2-0.7-0.2-1.1
		 c-0.1,0.1-0.1,0.1-0.2,0.2c-0.8,0.8-1.5,1.5-2.3,2.3c-0.1,0.1-0.1,0.2,0,0.3c1.3,2.3,2.6,4.6,3.9,6.8
		 C16.2,17.3,16.2,17.3,16.2,17.4z M12.4,5.2c-0.1,0-0.1,0-0.2,0C10.8,4.9,9.4,4.6,8,4.3c-1.6-0.3-3.1-0.7-4.7-1c-0.1,0-0.2,0-0.2,0
		 C2.9,3.4,2.7,3.6,2.6,3.8c0.1,0,0.1,0.1,0.2,0.1C5,5.2,7.3,6.5,9.6,7.8c0.1,0.1,0.2,0,0.3,0c0.8-0.8,1.5-1.5,2.3-2.3
		 C12.3,5.3,12.3,5.3,12.4,5.2z M5,12.8C5,12.8,5,12.8,5,12.8c-1.1-0.1-2.2-0.3-3.3-0.4c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.3
		 c0.9,0.5,1.9,0.9,2.8,1.4C4.4,13.7,4.7,13.3,5,12.8z M7.2,18.6c0.1,0,0.1-0.1,0.1-0.1c0.2-0.1,0.2-0.3,0.2-0.5
		 c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4-0.1-0.9-0.2-1.3c-0.5,0.3-0.9,0.6-1.3,0.9C6.3,16.8,6.8,17.7,7.2,18.6z M6.4,3.3
		 c0.4,0.1,0.7,0.1,1,0.2c0.2,0.1,0.3,0,0.5-0.2C8.1,3.2,8,3,7.9,2.8C7.6,2.5,7.4,2.4,7.2,2.6c0,0,0,0-0.1,0C6.9,2.8,6.7,3,6.4,3.3z
			M16.7,13.5c0.3-0.2,0.5-0.5,0.7-0.7c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.4,0
		 c-0.1,0.1-0.3,0.1-0.2,0.3C16.5,12.8,16.6,13.1,16.7,13.5z M15.9,10c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.1-0.3-0.1-0.5
		 c-0.2-0.2-0.3-0.4-0.6-0.3c0,0-0.1,0.1-0.1,0.2C15.7,9.2,15.8,9.5,15.9,10z M10.1,4.1c0.4,0.1,0.8,0.2,1.1,0.2c0,0,0.1-0.1,0.2-0.1
		 c0.1-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.3-0.2-0.5-0.1c0,0,0,0,0,0C10.4,3.7,10.3,3.9,10.1,4.1z"
          />
          <path
            class="st0"
            d="M18.7,1.8c0,0.1-0.1,0.3-0.2,0.5c-0.4,0.9-0.9,1.6-1.6,2.3c-2.8,2.8-5.6,5.6-8.4,8.4c-1,1-2.1,1.9-3.3,2.6
		 c-0.3,0.2-0.6,0.3-0.9,0.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0.3-0.8,0.7-1.5,1.2-2.1c0.8-1,1.6-2,2.5-2.9
		 c2.5-2.5,4.9-4.9,7.4-7.4c0.7-0.7,1.4-1.3,2.3-1.8c0.3-0.1,0.6-0.2,0.9-0.3C18.6,1.3,18.7,1.4,18.7,1.8z"
          />
          <path
            class="st0"
            d="M16.2,17.4c-0.1-0.1-0.1-0.1-0.1-0.2c-1.3-2.3-2.6-4.6-3.9-6.8c-0.1-0.1-0.1-0.2,0-0.3
		 c0.8-0.8,1.5-1.5,2.3-2.3c0,0,0.1-0.1,0.2-0.2C14.9,8,14.9,8.4,15,8.7c0.6,2.6,1.2,5.3,1.7,7.9c0,0.1,0,0.2-0.1,0.3
		 C16.5,17.1,16.4,17.3,16.2,17.4z"
          />
          <path
            class="st0"
            d="M12.4,5.2c-0.1,0.1-0.1,0.1-0.1,0.1c-0.8,0.8-1.6,1.5-2.3,2.3c-0.1,0.1-0.2,0.1-0.3,0C7.3,6.5,5,5.2,2.7,3.9
		 c0,0-0.1-0.1-0.2-0.1c0.2-0.2,0.4-0.4,0.5-0.5c0,0,0.1,0,0.2,0c1.6,0.3,3.1,0.7,4.7,1c1.4,0.3,2.8,0.6,4.3,0.9
		 C12.3,5.2,12.3,5.2,12.4,5.2z"
          />
          <path
            class="st0"
            d="M5,12.8c-0.3,0.4-0.6,0.9-0.9,1.3c-0.9-0.5-1.8-0.9-2.8-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1,0,0.1,0
		 C2.8,12.6,3.9,12.7,5,12.8C5,12.8,5,12.8,5,12.8z"
          />
          <path
            class="st0"
            d="M6.4,3.3C6.7,3,6.9,2.8,7.1,2.6c0,0,0,0,0.1,0c0.2-0.1,0.4-0.1,0.7,0.3C8,3,8.1,3.2,7.9,3.4
		 C7.8,3.5,7.7,3.6,7.5,3.5C7.1,3.4,6.8,3.4,6.4,3.3z"
          />
          <path
            class="st0"
            d="M16.7,13.5c-0.1-0.4-0.2-0.8-0.3-1.1c0-0.2,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.3,0.2,0.4,0.4
		 c0.1,0.1,0.1,0.3,0,0.4C17.2,13.1,17,13.3,16.7,13.5z"
          />
          <path
            class="st0"
            d="M15.9,10c-0.1-0.4-0.2-0.8-0.3-1.2c0,0,0.1-0.1,0.1-0.2c0.3-0.1,0.5,0.1,0.6,0.3c0.2,0.2,0.2,0.3,0.1,0.5
		 C16.3,9.6,16.1,9.8,15.9,10z"
          />
          <path
            class="st0"
            d="M10.1,4.1c0.2-0.2,0.4-0.4,0.5-0.6c0,0,0,0,0,0c0.2-0.1,0.3-0.1,0.5,0.1c0.2,0.2,0.4,0.3,0.3,0.6
		 c0,0-0.1,0.1-0.2,0.1C10.8,4.3,10.5,4.2,10.1,4.1z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuPlaneComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
