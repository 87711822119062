<div class="space-y-8">
  <div class="relative flex h-full gap-1 sm:h-[calc(100vh_-_150px)]">
    <!-- <div class="overlay absolute z-[5] hidden h-full w-full rounded-md bg-black/60" [ngClass]="{ '!block xl:!hidden': isShowTaskMenu }" (click)="isShowTaskMenu = !isShowTaskMenu"></div> -->
    <div class="panel h-full flex-1 overflow-auto p-0">
      <div class="flex h-full flex-col">
        <div class="flex w-full flex-col gap-4 p-4 sm:flex-row sm:items-center">
          <div class="flex items-center ltr:mr-3 rtl:ml-3">
            <!-- <button type="button" class="block hover:text-primary xl:hidden ltr:mr-3 rtl:ml-3" (click)="isShowTaskMenu = !isShowTaskMenu">
              <icon-menu />
            </button> -->
            <div class="group relative flex-1">
              <input type="text" class="peer form-input ltr:!pr-10 rtl:!pl-10" placeholder="{{ 'search_task' | translate }}..." name="searchTask" [(ngModel)]="searchTask" (ngModelChange)="searchTasks()" />
              <div class="absolute top-1/2 -translate-y-1/2 peer-focus:text-primary ltr:right-[11px] rtl:left-[11px]">
                <icon-search />
              </div>
            </div>
          </div>
          <div class="flex flex-1 items-center justify-center sm:flex-auto sm:justify-end">
            <p class="ltr:mr-3 rtl:ml-3">{{ pager.startIndex + 1 + "-" + (pager.endIndex + 1) + " of " + plans.length }}</p>
            <button
              type="button"
              [disabled]="pager.currentPage === 1"
              class="rounded-md bg-[#f4f4f4] p-1 enabled:hover:bg-primary-light disabled:cursor-not-allowed disabled:opacity-60 dark:bg-white-dark/20 enabled:dark:hover:bg-white-dark/30 ltr:mr-3 rtl:ml-3"
              (click)="pager.currentPage = pager.currentPage - 1"
            >
              <icon-caret-down class="h-5 w-5 rotate-90 rtl:-rotate-90" />
            </button>
            <button
              type="button"
              [disabled]="pager.currentPage === pager.totalPages"
              class="rounded-md bg-[#f4f4f4] p-1 enabled:hover:bg-primary-light disabled:cursor-not-allowed disabled:opacity-60 dark:bg-white-dark/20 enabled:dark:hover:bg-white-dark/30"
              (click)="pager.currentPage = pager.currentPage + 1"
            >
              <icon-caret-down class="h-5 w-5 -rotate-90 rtl:rotate-90" />
            </button>
          </div>
        </div>
        <div class="h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b]"></div>
        <ng-container>
          <div class="table-responsive min-h-[400px] grow overflow-y-auto sm:min-h-[300px]">
            <table class="table-hover">
              <thead>
                <tr>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.customer" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.destination" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.passengers_number" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.start_date" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.service" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.period" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.created_date" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.departure_place" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.profit" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.debt" | translate }}
                    </div>
                  </th>
                  <th class="w-1">
                    <div class="whitespace-nowrap text-base font-semibold">
                      {{ "evidence_book.additionals" | translate }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="plans.length">
                <ng-container *ngFor="let plan of plans">
                  <tr class="group cursor-pointer" [ngClass]="{ 'bg-white-light/30 dark:bg-[#1a2941]': plan.id === 1 }">
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">{{ plan?.customer?.name }} {{ plan?.customer?.surname }}</div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.travelDestinations[0]?.destination?.description }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.passangers?.length }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan.travelDestinations[0]?.start | date : "dd.MM.yyyy" }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.travelDestinations[0]?.end | date : "dd.MM.yyyy" }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.travelDestinations[0]?.days }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.createdAt | date : "dd.MM.yyyy" }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.departure?.description }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace -nowrap text-base group-hover:text-primary">
                        {{ plan?.accountingData?.sumInRSD }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.plan?.neto }}
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="whitespace-nowrap text-base group-hover:text-primary">
                        {{ plan?.plan?.notes }}
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-container *ngIf="!plans.length">
          <div class="flex h-full min-h-[400px] items-center justify-center text-lg font-semibold sm:min-h-[300px]">No data available</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
