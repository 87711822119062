<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <button (click)="editDocument()" class="btn btn-warning gap-2">
    <icon-change></icon-change>
    {{ "daily_sale_file.edit" | translate }}
  </button>
</div>
<div class="panel">
  <div class="flex flex-wrap justify-between gap-4 px-4">
    <div class="text-2xl font-semibold">{{ "daily_sale_file.name" | translate }}: {{ dailySaleDocument.createdAt | date : "dd.MM.yyyy" }}</div>
    <div class="shrink-0"><img src="/assets/images/logo.svg" alt="" class="w-14 ltr:ml-auto rtl:mr-auto" /></div>
  </div>
  <div class="px-4 ltr:text-right rtl:text-left">
    <div class="mt-6 space-y-1 text-white-dark">
      <div>28. Novembar No. 54 Novi Pazar, 36300</div>
      <div>officetouristagent@gmail.com</div>
      <div>+381 64 974 6676</div>
    </div>
  </div>
  <hr class="my-6 border-[#e0e6ed] dark:border-[#1b2e4b]" />

  <div class="grid grid-cols-1 sm:grid-cols-1">
    <table class="grid-cols table-responsive col-span-1 grid grid w-full border-2 border-gray-400 sm:col-span-12">
      <thead>
        <tr class="grid grid-cols-2 sm:grid-cols-12">
          <th class="w-1/12 sm:col-span-1">{{ "daily_sale_file.serial_number" | translate }}</th>
          <th class="w-3/12 sm:col-span-3">{{ "daily_sale_file.ledger_entry" | translate }}</th>
          <th class="w-3/12 sm:col-span-3">{{ "daily_sale_file.description" | translate }}</th>
          <th class="w-2/12 sm:col-span-2">{{ "daily_sale_file.entry" | translate }}</th>
          <th class="w-2/12 sm:col-span-2">{{ "daily_sale_file.exit" | translate }}</th>
          <th class="w-1/12 sm:col-span-1">{{ "daily_sale_file.account_number" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of dailySaleDocument.items; let i = index">
          <tr class="ng-star-inserted grid grid-cols-2 sm:grid-cols-12">
            <td class="sm:col-span-1">{{ i + 1 }}</td>
            <td class="sm:col-span-3">{{ item?.voucherName }}</td>
            <td class="sm:col-span-3">{{ item?.description }}</td>
            <td class="sm:col-span-2">{{ item?.price }} din</td>
            <td class="sm:col-span-2">-</td>
            <td class="sm:col-span-1">-</td>
          </tr>
        </ng-container>
        <tr class="ng-star-inserted grid grid-cols-2 sm:grid-cols-12">
          <td class="sm:col-span-1">{{ dailySaleDocument.items.length + 1 }}</td>
          <td class="sm:col-span-3">{{ "daily_sale_file.payment_slip" | translate }}</td>
          <td class="sm:col-span-3">{{ "daily_sale_file.daily_sale_col" | translate }}</td>
          <td class="sm:col-span-2">-</td>
          <td class="sm:col-span-2">{{ totalPrice }} din</td>
          <td class="sm:col-span-1">-</td>
        </tr>
      </tbody>
    </table>

    <div class="grid-cols-1 grid-cols-3 items-start sm:grid">
      <table class="table-responsive mt-3 w-full border-2 border-gray-400">
        <tbody class="border-top: 2px">
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
            <td class="sm:col-span-1">{{ "daily_sale_file.denomination" | translate }}</td>
            <td class="sm:col-span-1">{{ "daily_sale_file.command" | translate }}</td>
            <td class="sm:col-span-1">{{ "daily_sale_file.total" | translate }}</td>
          </tr>
          <ng-container *ngFor="let item of dailySaleDocument.denomination; let i = index">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
              <td class="border border-gray-300 sm:col-span-1">{{ denomination[i] }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ item }}</td>
              <td class="border border-gray-300 sm:col-span-1"></td>
            </tr>
          </ng-container>
          <tr class="ng-star-inserted bg-blue-100">
            <td colspan="2" class="border border-slate-400">{{ "daily_sale_file.all" | translate }}</td>
            <td class="border border-slate-400">{{ totalPrice }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table-responsive border-b-2 border-l-2 border-r-2 border-gray-400 sm:ml-1">
        <tbody>
          <tr class="ng-star-inserted col-span-1 sm:col-span-3">
            <td class="border-b border-l border-r border-slate-400 sm:col-span-1">{{ "daily_sale_file.cash_flow" | translate }}</td>
            <td class="border-b border-l border-r border-slate-400 text-center sm:col-span-1">1320</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.balance_due" | translate }} 03/09/2023</td>
            <td class="border border-slate-400 text-center sm:col-span-1"></td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.total_cash" | translate }}</td>
            <td class="border border-slate-400 text-center sm:col-span-1">1230</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.total_check" | translate }}</td>
            <td class="border border-slate-400 text-center sm:col-span-1">1230</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-slate-400 sm:col-span-1">{{ "daily_sale_file.balance_from" | translate }} 03/09/2023</td>
            <td class="border border-slate-400 text-center sm:col-span-1"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="ml-5 mt-3 flex justify-end gap-5">
    <div class="border-black-500 flex w-1/3 border-2 p-3">
      {{ "daily_sale_file.verified" | translate }}:
      <div class="flex-1 text-slate-800"></div>
    </div>
    <div class="border-black-500 flex w-1/3 border-2 p-3">
      {{ "daily_sale_file.cashier" | translate }}:
      <div class="flex-1 text-slate-800"></div>
    </div>
  </div>
</div>
