import { TranslateService } from "@ngx-translate/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
  selector: "app-global-table-filters",
  templateUrl: "./global-table-filters.component.html",
})
export class GlobalTableFiltersComponent {
  public filterType = "None";
  @Input() total!: number;
  @Input() withDept!: number;
  @Input() withoutDept!: number;
  @Input() draftFilterVisible = false;
  @Output() selectFilter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private translate: TranslateService) {}
  onSelectionChange(val: string): void {
    this.selectFilter.emit(val);
  }
}
