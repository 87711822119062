<div class="panel relative rounded-md border-white-dark/20 bg-slate-100 dark:bg-gray-900" *ngIf="overallStatistics">
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.purchasesMade" | translate }}:</div>
        <div>{{ overallStatistics.purchasesMade }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.travelsSold" | translate }}:</div>
        <div>{{ overallStatistics.travelsSold }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.ticketsSold" | translate }}:</div>
        <div>{{ overallStatistics.ticketsSold }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.transferPlansSold" | translate }}:</div>
        <div>{{ overallStatistics.transferPlansSold }}</div>
      </div>
    </div>
    <div class="col-span-2 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.groupTravelPlansSold" | translate }}:</div>
        <div>{{ overallStatistics.groupTravelPlansSold }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.otherServicesSold" | translate }}:</div>
        <div>{{ overallStatistics.otherServicesSold }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.profit" | translate }}:</div>
        <div>{{ overallStatistics.profit | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.profitOnWait" | translate }}:</div>
        <div>{{ overallStatistics.profitOnWait | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.brutoTotal" | translate }}:</div>
        <div>{{ overallStatistics.brutoTotal | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.netoTotal" | translate }}:</div>
        <div>{{ overallStatistics.netoTotal | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "overallstatistics.globalDepth" | translate }}:</div>
        <div>{{ overallStatistics.globalDepth | currency : "EUR" }}</div>
      </div>
    </div>
  </div>
</div>
