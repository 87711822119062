import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-statistics",
  styleUrls: ["../icons.css"],
  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M4.9,15.2c0,0.5,0,1,0,1.5V18c0,0.8-0.5,1.3-1.3,1.3c-0.3,0-0.5,0-0.8,0c-0.7,0-1.2-0.5-1.2-1.2c0-1,0-2,0-2.9
				 c0-0.6,0.5-1.1,1.1-1.2c0.3,0,0.7,0,1,0C4.4,14,4.9,14.5,4.9,15.2z"
          />
          <path
            class="st0"
            d="M9.4,11.6c0,1.1,0,2.1,0,3.2V18c0,0.8-0.5,1.3-1.3,1.3c-0.2,0-0.5,0-0.8,0c-0.7,0-1.2-0.5-1.2-1.2
				 c0-2.2,0-4.4,0-6.6c0-0.7,0.5-1.2,1.2-1.2c0.3,0,0.6,0,0.8,0C8.9,10.4,9.4,10.8,9.4,11.6z"
          />
          <path
            class="st0"
            d="M13.9,12.9c0,0.9,0,1.7,0,2.6c0,0.8,0,1.7,0,2.5c0,0.8-0.5,1.3-1.3,1.3c-0.3,0-0.6,0-0.8,0
				 c-0.7,0-1.2-0.5-1.2-1.2c0-1.7,0-3.5,0-5.2c0-0.6,0.5-1.1,1.1-1.2c0.4,0,0.7,0,1.1,0C13.4,11.8,13.9,12.3,13.9,12.9z"
          />
          <path
            class="st0"
            d="M2.4,10.2C2,9.8,2,9.2,2.3,8.8c0.3-0.5,0.9-0.6,1.4-0.4c0.1,0.1,0.2,0,0.3,0C4.9,7.5,5.7,6.8,6.6,6
				 c0.1-0.1,0.1-0.2,0.1-0.3C6.4,5,7,4.2,7.8,4.2c0.6,0,1.1,0.4,1.2,1C9,5.3,9,5.4,9.2,5.4c0.6,0.2,1.3,0.3,1.9,0.5
				 c0.1,0,0.2,0,0.3-0.1c0.3-0.4,0.8-0.6,1.3-0.4c0.1,0,0.2,0,0.3-0.1c0.9-0.9,1.8-1.9,2.7-2.8c0.1-0.1,0.1-0.1,0-0.2
				 c-0.2-0.5-0.1-0.9,0.2-1.3c0.3-0.4,0.8-0.5,1.2-0.3c0.5,0.2,0.8,0.5,0.8,1C18,2.6,17.1,3.3,16.3,3c-0.1,0-0.2,0-0.3,0.1
				 c-0.9,0.9-1.8,1.9-2.7,2.8c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.5,0.1,0.9-0.2,1.2c-0.3,0.4-0.7,0.5-1.2,0.4c-0.5-0.1-0.8-0.5-0.8-0.9
				 c0-0.2-0.1-0.2-0.2-0.3C10.2,6.4,9.6,6.2,8.9,6C8.8,6,8.8,6,8.7,6.1C8.3,6.5,7.8,6.7,7.3,6.4c-0.1,0-0.2,0-0.3,0
				 C6.2,7.2,5.3,8,4.5,8.8C4.4,8.9,4.4,8.9,4.4,9.1c0.2,0.5,0,1.1-0.5,1.4C3.4,10.7,2.8,10.7,2.4,10.2z"
          />
          <path
            class="st0"
            d="M18.3,8.2c0,1.6,0,3.3,0,4.9c0,1.6,0,3.2,0,4.9c0,0.8-0.5,1.3-1.3,1.3c-0.3,0-0.5,0-0.8,0
				 c-0.6,0-1.2-0.5-1.2-1.2c0-3.3,0-6.7,0-10c0-0.7,0.5-1.2,1.2-1.2c0.3,0,0.5,0,0.8,0C17.9,6.9,18.3,7.4,18.3,8.2z"
          />
          <g>
            <path
              class="st1"
              d="M17.1,0.8c-0.5-0.2-0.9,0-1.2,0.3c-0.3,0.4-0.4,0.8-0.2,1.3c0,0.1,0,0.2,0,0.2c-0.9,0.9-1.8,1.9-2.7,2.8
					 c-0.1,0.1-0.1,0.1-0.3,0.1c-0.5-0.2-1,0-1.3,0.4C11.2,6,11.2,6,11.1,5.9c-0.6-0.2-1.3-0.4-1.9-0.5C9,5.4,9,5.3,8.9,5.2
					 c-0.1-0.6-0.6-1-1.2-1C7,4.2,6.4,5,6.6,5.7c0,0.1,0,0.2-0.1,0.3C5.7,6.8,4.9,7.5,4.1,8.3C4,8.4,3.9,8.4,3.8,8.4
					 C3.3,8.1,2.7,8.3,2.3,8.8C2,9.2,2,9.8,2.4,10.2c0.4,0.4,1,0.5,1.5,0.2c0.5-0.3,0.7-0.9,0.5-1.4c0-0.1,0-0.2,0.1-0.3
					 C5.3,8,6.2,7.2,7,6.5c0.1-0.1,0.1-0.1,0.3,0c0.5,0.2,1.1,0.1,1.4-0.3C8.8,6,8.8,6,8.9,6c0.6,0.2,1.3,0.4,1.9,0.5
					 c0.1,0,0.2,0.1,0.2,0.3c0.1,0.5,0.4,0.8,0.8,0.9c0.5,0.1,0.9,0,1.2-0.4c0.3-0.4,0.4-0.8,0.2-1.2c-0.1-0.1,0-0.2,0-0.3
					 C14.3,4.9,15.1,4,16,3.1C16.1,3,16.2,3,16.3,3c0.8,0.3,1.7-0.4,1.6-1.2C17.8,1.3,17.5,0.9,17.1,0.8z M3.3,10C3,10,2.8,9.7,2.8,9.4
					 c0-0.3,0.3-0.5,0.6-0.5s0.5,0.2,0.5,0.5C3.9,9.7,3.6,10,3.3,10z M7.8,5.9c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.5-0.6
					 c0.3,0,0.6,0.2,0.6,0.5C8.3,5.6,8.1,5.9,7.8,5.9z M12.2,7.2c-0.3,0-0.5-0.2-0.6-0.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.5,0.3,0.5,0.6
					 C12.8,6.9,12.5,7.2,12.2,7.2z M16.7,2.4c-0.3,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.5,0.5-0.6s0.6,0.2,0.6,0.5C17.3,2.2,17,2.4,16.7,2.4z
					 "
            />
            <path
              class="st1"
              d="M17,6.9c-0.3,0-0.5,0-0.8,0c-0.7,0-1.2,0.5-1.2,1.2c0,3.3,0,6.7,0,10c0,0.6,0.5,1.1,1.2,1.2
					 c0.3,0,0.5,0,0.8,0c0.8,0,1.3-0.5,1.3-1.3c0-1.6,0-3.2,0-4.9c0-1.6,0-3.3,0-4.9C18.3,7.4,17.9,6.9,17,6.9z M17.7,18.1
					 c0,0.4-0.2,0.6-0.6,0.6c-0.3,0-0.5,0-0.8,0c-0.4,0-0.6-0.2-0.6-0.6V8.3c0-0.5,0.2-0.7,0.7-0.7c0.2,0,0.5,0,0.7,0
					 c0.4,0,0.6,0.2,0.6,0.6C17.7,11.5,17.7,14.8,17.7,18.1z"
            />
            <path
              class="st1"
              d="M8.2,10.4c-0.3,0-0.6,0-0.8,0c-0.7,0-1.2,0.5-1.2,1.2c0,2.2,0,4.4,0,6.6c0,0.7,0.5,1.2,1.2,1.2
					 c0.3,0,0.5,0,0.8,0c0.9,0,1.3-0.5,1.3-1.3v-3.2c0-1.1,0-2.1,0-3.2C9.4,10.8,8.9,10.4,8.2,10.4z M8.8,14.8c0,1,0,2.1,0,3.1
					 c0,0.5-0.2,0.7-0.7,0.7c-0.2,0-0.5,0-0.7,0c-0.3,0-0.6-0.2-0.6-0.6v-6.5C6.8,11.2,7,11,7.3,11c0.3,0,0.6,0,0.8,0
					 c0.4,0,0.6,0.2,0.6,0.6C8.8,12.7,8.8,13.7,8.8,14.8z"
            />
            <path
              class="st1"
              d="M12.8,11.7c-0.4,0-0.7,0-1.1,0c-0.6,0-1.1,0.5-1.1,1.2c0,1.7,0,3.5,0,5.2c0,0.7,0.5,1.2,1.2,1.2
					 c0.3,0,0.6,0,0.8,0c0.8,0,1.3-0.5,1.3-1.3c0-0.8,0-1.7,0-2.5c0-0.9,0-1.7,0-2.6C13.9,12.3,13.4,11.8,12.8,11.7z M13.2,18
					 c0,0.4-0.2,0.6-0.6,0.6c-0.3,0-0.5,0-0.8,0c-0.4,0-0.6-0.2-0.6-0.6V13c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0,0.9,0
					 c0.3,0,0.5,0.2,0.5,0.6c0,0.9,0,1.7,0,2.6C13.2,16.4,13.2,17.2,13.2,18z"
            />
            <path
              class="st1"
              d="M3.8,14c-0.3,0-0.7,0-1,0c-0.6,0-1.1,0.5-1.1,1.2c0,1,0,2,0,2.9c0,0.7,0.5,1.2,1.2,1.2c0.3,0,0.5,0,0.8,0
					 c0.8,0,1.3-0.5,1.3-1.3v-1.4c0-0.5,0-1,0-1.5C4.9,14.5,4.4,14,3.8,14z M4.3,16.7c0,0.5,0,0.9,0,1.4c0,0.4-0.2,0.6-0.6,0.6
					 c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6-0.2-0.6-0.6c0-0.9,0-1.9,0-2.8c0-0.4,0.2-0.6,0.6-0.6c0.3,0,0.6,0,0.9,0c0.3,0,0.5,0.2,0.6,0.6
					 C4.3,15.7,4.3,16.2,4.3,16.7z"
            />
          </g>
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuStatisticsComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
