import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { slideDownUp } from "../shared/animations";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Type } from "@angular/core";
import { IconMenuBusComponent } from "../shared/icon/menu/icon-menu-bus";
import { animate, style, transition, trigger } from "@angular/animations";
import { IconMenuHotelComponent } from "../shared/icon/menu/icon-menu-hotel";
import { IconMenuPlaneComponent } from "../shared/icon/menu/icon-menu-plane";
import { IconMenuTravelComponent } from "../shared/icon/menu/icon-menu-travel";
import { IconMenuCompanyComponent } from "../shared/icon/menu/icon-menu-company";
import { IconMenuWorkersComponent } from "../shared/icon/menu/icon-menu-workers";
import { IconMenuTransferComponent } from "../shared/icon/menu/icon-menu-transfer";
import { IconMenuCustomerComponent } from "../shared/icon/menu/icon-menu-customer";
import { IconMenuOrganizerComponent } from "../shared/icon/menu/icon-menu-organizer";
import { IconMenuStatisticsComponent } from "../shared/icon/menu/icon-menu-statistics";
import { IconMenuDailySalesComponent } from "../shared/icon/menu/icon-menu-daily-sales";
import { IconMenuDestinationComponent } from "../shared/icon/menu/icon-menu-destination";
import { IconMenuGroupTravelComponent } from "../shared/icon/menu/icon-menu-group-travel";
import { IconMenuDashboardDinoComponent } from "../shared/icon/menu/icon-menu-dashboard-dino";
import { IconMenuOtherServicesComponent } from "../shared/icon/menu/icon-menu-other-services";
import { IconMenuDailyWorkerSalesComponent } from "../shared/icon/menu/icon-menu-dailyworker-sales";

@Component({
  moduleId: module.id,
  selector: "app-sidebar",
  templateUrl: "./sidebar.html",
  animations: [
    slideDownUp,
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  active = false;
  store: any;
  activeDropdown: string[] = [];
  parentDropdown = "";

  constructor(public translate: TranslateService, public storeData: Store<any>, public router: Router) {
    this.initStore();
  }
  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  public sidebarSections: SidebarSection[] = [
    {
      titleTranslationKey: "",
      items: [
        { path: "/dashboard/sales", translationKey: "dashboard", iconComponent: IconMenuDashboardDinoComponent },
        { path: "/dashboard/statistics", translationKey: "stats", iconComponent: IconMenuStatisticsComponent, packageRequirment: "statisticsAccounting" },
      ],
    },
    {
      titleTranslationKey: "entities",
      items: [
        { path: "/dashboard/tickets/bus", translationKey: "bus_tickets", iconComponent: IconMenuBusComponent },
        { path: "/dashboard/tickets/flight", translationKey: "plane_tickets", iconComponent: IconMenuPlaneComponent },
        { path: "/dashboard/travels", translationKey: "travel", iconComponent: IconMenuTravelComponent },
        { path: "/dashboard/group-travels", translationKey: "group_travel", iconComponent: IconMenuGroupTravelComponent },
        { path: "/dashboard/transfers", translationKey: "sidebar.transfer", iconComponent: IconMenuTransferComponent },
        { path: "/dashboard/otherservices", translationKey: "sidebar.other_services", iconComponent: IconMenuOtherServicesComponent },
      ],
    },
    {
      titleTranslationKey: "clients_and_colaborators",
      items: [
        { path: "/dashboard/customers", translationKey: "customers", iconComponent: IconMenuCustomerComponent },
        { path: "/dashboard/hotels", translationKey: "hotels", iconComponent: IconMenuHotelComponent },
        { path: "/dashboard/companies", translationKey: "companies", iconComponent: IconMenuCompanyComponent },
        { path: "/dashboard/organizers", translationKey: "organizers", iconComponent: IconMenuOrganizerComponent },
        { path: "/dashboard/destinations", translationKey: "destinations", iconComponent: IconMenuDestinationComponent },
        { path: "/dashboard/workers", translationKey: "workers", iconComponent: IconMenuWorkersComponent, roles: ["Admin", "OfficeBranchManager"] },
      ],
    },
    {
      titleTranslationKey: "book_keeping",
      items: [
        { path: "/dashboard/daily-sale", translationKey: "daily_sales", iconComponent: IconMenuDailySalesComponent, packageRequirment: "creationOfDailySalesOrders" },
        { path: "/dashboard/daily_actions", translationKey: "daily_actions", iconComponent: IconMenuDailySalesComponent, packageRequirment: "statisticsAccounting", roles: ["Agent"] },
        { path: "/dashboard/daily_actions_worker", translationKey: "daily_actions_worker", iconComponent: IconMenuDailyWorkerSalesComponent, roles: ["Admin", "OfficeBranchManager"] },
      ],
    },
  ];

  ngOnInit() {
    this.setActiveDropdown();
  }

  setActiveDropdown() {
    const selector = document.querySelector('.sidebar ul a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add("active");
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }

  toggleMobileMenu() {
    if (window.innerWidth < 1024) {
      this.storeData.dispatch({ type: "toggleSidebar" });
    }
  }
}

interface SidebarItem {
  path: string;
  translationKey: string;
  iconComponent: Type<any>;
  roles?: string[];
  packageRequirment?: string | undefined;
}

interface SidebarSection {
  titleTranslationKey: string;
  items: SidebarItem[];
}
