import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-dailyworker-sales",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M8,9.4C8.1,9.2,8.2,9,8.2,8.9c0-0.2,0-0.3-0.1-0.4C8,8.4,7.8,8.3,7.7,8.3C7.3,8.3,7.1,8.6,7,9.2L5.7,9
		 c0.1-0.6,0.3-1.1,0.7-1.4c0.4-0.3,0.8-0.4,1.3-0.4c0.6,0,1,0.2,1.4,0.5c0.4,0.3,0.5,0.7,0.5,1.2c0,0.3-0.1,0.6-0.3,0.9
		 c-0.2,0.3-0.8,0.8-1.7,1.4h2l-0.1,1.3H5.7v-1.2l0.9-0.6C6.7,10.5,7,10.3,7.3,10C7.7,9.7,7.9,9.5,8,9.4z"
          />
          <path
            class="st0"
            d="M18.9,10.3c-0.1,2.4-1,4.5-2.7,6.1C14.5,18.1,12.3,19,10,19c-1.7,0-3.3-0.5-4.8-1.4c-3.4-2.1-5-6.4-3.7-10.3
		 c1.3-3.9,5-6.4,9.2-6.1c1.5,0.1,2.8,0.5,4.1,1.3c0.1,0.1,0.2,0.1,0.3,0.2c0-0.2,0-0.4,0-0.6c0-0.3,0.2-0.6,0.4-0.7
		 C15.6,1.1,15.8,1,16,1.1c0.3,0.1,0.8,0.5,0.8,0.8c0.1,0.9,0,1.8,0,2.6c0,0.6-0.4,0.9-1,0.9c-0.6,0-1.2,0-1.8,0h-0.5
		 c-0.6,0-1-0.3-1-0.9c0-0.5,0.3-0.8,0.8-0.9c-2.7-1.4-6-0.9-8.2,1.3c-2.2,2.2-2.8,5.5-1.4,8.3C5,15.9,8,17.5,11,17
		 c3.1-0.5,5.5-2.8,6-5.8c0-0.3,0.1-0.6,0.1-0.9l0-0.1c0-0.4,0.2-0.7,0.4-0.9c0.2-0.1,0.4-0.2,0.6-0.2C18.7,9.2,19,9.6,18.9,10.3z"
          />
          <g>
            <path
              class="st1"
              d="M18.1,9.1c-0.2,0-0.4,0-0.6,0.2c-0.2,0.2-0.4,0.5-0.4,0.9l0,0.1c0,0.3-0.1,0.6-0.1,0.9c-0.5,3-2.9,5.4-6,5.8
			 c-3,0.4-6-1.1-7.3-3.8C2.3,10.4,2.8,7.1,5,4.9c2.2-2.1,5.5-2.6,8.2-1.3c-0.5,0.1-0.8,0.4-0.8,0.9c0,0.6,0.4,0.9,1,0.9H14
			 c0.6,0,1.2,0,1.8,0c0.6,0,1-0.3,1-0.9c0-0.9,0.1-1.8,0-2.6c0-0.3-0.5-0.7-0.8-0.8c-0.2-0.1-0.4,0-0.6,0.1C15.2,1.3,15,1.6,15,1.9
			 c0,0.2,0,0.4,0,0.6c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.8-2.6-1.2-4.1-1.3C6.5,0.8,2.7,3.3,1.5,7.2c-1.3,3.9,0.3,8.2,3.7,10.3
			 c1.5,1,3.2,1.4,4.8,1.4c2.3,0,4.5-0.9,6.2-2.6c1.7-1.6,2.6-3.7,2.7-6.1C19,9.6,18.7,9.2,18.1,9.1z M18.5,10.3
			 c-0.1,2.3-1,4.2-2.6,5.8c-2.8,2.8-7,3.2-10.5,1.1c-3.3-2-4.7-6.1-3.5-9.8C3.1,3.8,6.3,1.5,10,1.5c0.2,0,0.4,0,0.6,0
			 c1.4,0.1,2.7,0.5,3.8,1.2c0.1,0.1,0.2,0.1,0.4,0.2l0.6,0.4V3c0-0.2,0-0.3,0-0.4c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.3,0.2-0.4
			 c0.1,0,0.1-0.1,0.2,0c0.2,0,0.4,0.3,0.4,0.4c0.1,0.9,0,1.7,0,2.6c0,0.2-0.1,0.5-0.5,0.5c-0.6,0-1.2,0-1.8,0h-0.5
			 c-0.5,0-0.6-0.2-0.6-0.4c0-0.2,0-0.4,0.5-0.5c0.1,0,0.2,0,0.3,0l0.7-0.1L14,3.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
			 c-2.9-1.5-6.5-1-8.8,1.3c-2.4,2.3-2.9,5.9-1.5,8.8c1.4,2.9,4.6,4.6,7.8,4.1c3.3-0.5,5.9-3,6.4-6.2c0.1-0.3,0.1-0.6,0.1-0.9l0-0.1
			 c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.1-0.1,0.2-0.1C18.3,9.6,18.5,9.7,18.5,10.3z"
            />
          </g>
          <g>
            <g>
              <path
                class="st1"
                d="M7.7,11.1c0.9-0.6,1.5-1.1,1.7-1.4c0.2-0.3,0.3-0.6,0.3-0.9c0-0.5-0.2-0.9-0.5-1.2C8.8,7.3,8.4,7.2,7.8,7.2
				 c-0.5,0-1,0.1-1.3,0.4C6.1,7.9,5.8,8.3,5.7,9L7,9.2c0-0.6,0.3-0.9,0.6-0.9C7.8,8.3,8,8.4,8,8.5c0.1,0.1,0.1,0.2,0.1,0.4
				 C8.2,9,8.1,9.2,8,9.4C7.9,9.5,7.7,9.7,7.3,10c-0.4,0.3-0.6,0.5-0.7,0.6l-0.9,0.6v1.2h3.9l0.1-1.3H7.7z"
              />
            </g>
            <g>
              <path class="st1" d="M13.7,10.1v-3h-1.6l-1.9,3v1.1h2.1v1.1h1.4v-1.1h0.7v-1.1H13.7z M12.3,10.1h-1l1-1.5V10.1z" />
            </g>
          </g>
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuDailyWorkerSalesComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
