<div *ngIf="ticket">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div>
        <div class="col-span-2 flex flex-col sm:col-span-1">
          <div class="text-white-dark">
            {{ "ticket_details.lead_passanger" | translate }}
          </div>
          <div class="text-xl">{{ ticket.customer.name }} {{ ticket.customer.surname }}</div>
          <div class="text-[15px] italic text-white-dark" style="font-style: italic">
            {{ ticket.customer.phoneNumber }}
          </div>
        </div>
      </div>

      <div class="col-span-2 flex sm:col-span-1">
        <div class="flex place-content-center items-center self-center">
          <div>{{ "ticket_details.status" | translate }}:</div>
          <ng-template #notPaid>
            <div class="badge bg-slate-200 text-white">
              {{ "ticket_details.not_paid" | translate }}
            </div>
          </ng-template>

          <ng-container *ngIf="ticket.plan.status; else notPaid">
            <div class="badge bg-success">
              {{ "ticket_details.paid" | translate }}
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-span-2 flex sm:col-span-2">
        <div class="flex place-content-center items-center self-center">
          <div class="ltr:mr-2 rtl:ml-2">{{ "ticket_details.active" | translate }}:</div>
          <div class="badge bg-success" *ngIf="ticket.plan.active; else notActive">
            {{ "ticket_details.active" | translate }}
          </div>
          <ng-template #notActive>
            <div class="badge bg-slate-200 text-white">
              {{ "ticket_details.deleted" | translate }}
            </div>
          </ng-template>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "ticket_details.departure_from" | translate }} :</div>
          <div>{{ ticket.departure.description }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-3">
        <div class="flex-col">
          <div>{{ "ticket_details.destination" | translate }} :</div>
          <div>{{ ticket.destination.description }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "ticket_details.departure_date" | translate }}:</div>
          <div>{{ ticket.departureDate | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "ticket_details.arrival_date" | translate }}:</div>
          <div>{{ ticket.arrivalDate | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-2">
        <div class="flex-col">
          <div>{{ "ticket_details.company" | translate }}:</div>
          <div>{{ ticket.company.name }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "ticket_details.ticket_type" | translate }} :</div>
          <div *ngIf="ticket.oneWay">
            {{ "ticket_details.one_way" | translate }}
          </div>
          <div *ngIf="!ticket.oneWay">
            {{ "ticket_details.round_trip" | translate }}
          </div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "ticket_details.ticket_category" | translate }} :</div>
          <div *ngIf="ticket.type === 'flight'">
            {{ "ticket_details.airplane" | translate }}
          </div>
          <div *ngIf="ticket.type !== 'flight'">
            {{ "ticket_details.bus" | translate }}
          </div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col"></div>
      </div>

      <div class="col-span-2 sm:col-span-4">
        <div class="flex-col">
          <div class="flex-none ltr:mr-2 rtl:ml-2">{{ "ticket_details.number_of_passengers" | translate }} :</div>
          <div class="text-dark">{{ ticket.numberOfPassangers }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel relative mt-5 rounded-md bg-white shadow-md dark:bg-gray-900" *ngIf="passangers.length > 0">
    <div class="p-5 text-[20px] text-dark">{{ "ticket_details.all_passengers" | translate }}:</div>
    <div class="grid grid-cols-1 justify-center p-5 sm:grid-cols-1">
      <ng-container *ngFor="let item of passangers; index as i">
        <div
          class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
        >
          <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
            <img [src]="item.imageUrl ? item.imageUrl : 'https://i.imgur.com/BjP9MgO.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
          </div>

          <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.name }} {{ item.surname }}</div>
          <div class="grid-cols-1 px-2 sm:grid-cols-1">
            {{ "ticket_details.phone_number" | translate }}:
            {{ item.phoneNumber ? item.phoneNumber : "N/A" }}
          </div>
          <div class="grid-cols-1 px-2 sm:grid-cols-1">
            {{ "ticket_details.date_of_birth" | translate }}:
            {{ item.dateOfBirth ? (item.dateOfBirth | date : "dd.MM.yyyy") : "N/A" }}
          </div>

          <ng-template #backUp> </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="panel relative mt-5 rounded-md bg-white shadow-md dark:bg-gray-900">
    <label>Direction</label>
    <div class="grid grid-cols-2 md:grid-cols-2">
      <label class="inline-flex p-5">
        <input type="radio" name="default_radio" class="form-radio" checked (click)="addDirection(0)" />
        <span *ngIf="transfer && transfer.departure && transfer.destination"> {{ transfer.departure.description }} - {{ transfer.destination.description }}</span>
      </label>
      <label class="inline-flex p-5">
        <input type="radio" name="default_radio" class="form-radio" (click)="addDirection(1)" />
        <span *ngIf="transfer && transfer.departure && transfer.destination"> {{ transfer.destination.description }} - {{ transfer.departure.description }}</span>
      </label>
    </div>
    <app-sale-create #sale></app-sale-create>
  </div>
  <div class="mt-5 grid grid-cols-1 md:grid-cols-1">
    <button class="btn btn-primary" (click)="addPassangersToTicket()">{{ "tickets_info.add_transfer" | translate }}</button>
  </div>
</div>
<div *ngIf="travel">
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end"></div>

  <div *ngIf="travel">
    <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
      <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
        <div>
          <div class="col-span-2 flex flex-col sm:col-span-1">
            <div class="text-white-dark">
              {{ "travel_details.lead_passenger" | translate }}
            </div>
            <div class="text-xl">{{ travel.customer.name }} {{ travel.customer.surname }}</div>
            <div class="text-[15px] italic text-white-dark" style="font-style: italic">
              {{ travel.customer.phoneNumber }}
            </div>
          </div>
        </div>

        <div class="col-span-2 flex sm:col-span-1">
          <div class="flex place-content-center items-center self-center">
            <div>{{ "travel_details.status" | translate }}:</div>
            <ng-template #notPaid>
              <div class="badge bg-slate-200 text-white">
                {{ "travel_details.not_paid" | translate }}
              </div>
            </ng-template>

            <ng-container *ngIf="travel.plan.status; else notPaid">
              <div class="badge bg-success">
                {{ "travel_details.paid" | translate }}
              </div>
            </ng-container>
          </div>
        </div>

        <div class="col-span-2 flex sm:col-span-2">
          <div class="flex place-content-center items-center self-center">
            <div class="ltr:mr-2 rtl:ml-2">{{ "travel_details.active" | translate }}:</div>
            <div class="badge bg-success" *ngIf="travel.plan.active; else notActive">
              {{ "travel_details.active" | translate }}
            </div>
            <ng-template #notActive>
              <div class="badge bg-slate-200 text-white">
                {{ "travel_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-1">
          <div class="flex-col">
            <div>{{ "travel_details.departure_from" | translate }} :</div>
            <div *ngIf="travel.departure">{{ travel.departure.description }}</div>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-3">
          <div class="flex-col">
            <div>{{ "travel_details.destination" | translate }} :</div>
            <!-- <div>{{ travel.destination?.description }}</div> -->
          </div>
        </div>

        <div class="col-span-1 sm:col-span-1">
          <div class="flex-col">
            <div>{{ "travel_details.departure_date" | translate }}:</div>
            <div>{{ travel.start | date : "dd.MM.yyyy" }}</div>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-1">
          <div class="flex-col">
            <div>{{ "travel_details.return_date" | translate }}:</div>
            <div>{{ travel.end | date : "dd.MM.yyyy" }}</div>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-2">
          <div class="flex-col">
            <div>{{ "travel_details.days_nights" | translate }}:</div>
            <div>{{ travel.days }}/{{ travel.nights }}</div>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-1">
          <div class="flex-col">
            <div>{{ "travel_details.hotel" | translate }}:</div>
            <!-- <div class="text-dark">{{ travel.hotel?.name }}</div> -->
          </div>
        </div>

        <div class="col-span-1 sm:col-span-1">
          <div class="flex-col">
            <div>{{ "travel_details.room_description" | translate }}:</div>
            <!-- <div class="text-dark">{{ travel.roomDescription }}</div> -->
          </div>
        </div>

        <div class="col-span-1 sm:col-span-1">
          <div class="flex-col">
            <div>{{ "travel_details.service_type" | translate }}:</div>
            <!-- <div class="text-dark">{{ travel.service?.title }}</div> -->
          </div>
        </div>

        <div class="col-span-2 sm:col-span-4">
          <div class="flex-col">
            <div>{{ "travel_details.number_of_passengers" | translate }}:</div>
            <div class="text-dark">{{ travel.numberOfPassangers }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="passangers.length > 0">
      <div class="text-[20px] text-dark">{{ "travel_details.all_passengers" | translate }}:</div>
      <div class="grid grid-cols-1 justify-center sm:grid-cols-1">
        <ng-container *ngFor="let item of passangers; index as i">
          <div
            class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
          >
            <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
              <img [src]="item.imageUrl ? item.imageUrl : 'https://i.imgur.com/BjP9MgO.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
            </div>

            <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.name }} {{ item.surname }}</div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "travel_details.phone_number" | translate }}:
              {{ item.phoneNumber ? item.phoneNumber : "N/A" }}
            </div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "travel_details.date_of_birth" | translate }}:
              {{ item.dateOfBirth ? (item.dateOfBirth | date : "dd.MM.yyyy") : "N/A" }}
            </div>

            <ng-template #backUp> </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="panel relative mt-5 rounded-md bg-white shadow-md dark:bg-gray-900">
      <label>Direction</label>
      <div class="grid grid-cols-2 md:grid-cols-2">
        <label class="inline-flex p-5">
          <input type="radio" name="default_radio" class="form-radio" checked (click)="addDirection(0)" />
          <span *ngIf="transfer && transfer.departure && transfer.destination"> {{ transfer.departure.description }} - {{ transfer.destination.description }}</span>
        </label>
        <label class="inline-flex p-5">
          <input type="radio" name="default_radio" class="form-radio" (click)="addDirection(1)" />
          <span *ngIf="transfer && transfer.departure && transfer.destination"> {{ transfer.destination.description }} - {{ transfer.departure.description }}</span>
        </label>
      </div>
      <app-sale-create #sale></app-sale-create>
    </div>
    <div class="mt-5 grid grid-cols-1 md:grid-cols-1">
      <button class="btn btn-primary" (click)="addPassangersToTicket()">{{ "tickets_info.add_transfer" | translate }}</button>
    </div>
  </div>
</div>
