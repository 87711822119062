<form [formGroup]="form" (ngSubmit)="save()" class="z-0">
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
    <button type="submit" class="btn btn-success gap-2">
      <icon-save />
      {{ "vaucher.save" | translate }}
    </button>
    <button class="btn btn-info cursor-pointer gap-2" (click)="cancelEdit($event)">
      <icon-save />
      {{ "contract.cancel" | translate }}
    </button>
  </div>
  <div class="panel">
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex-1 text-end">
        <p>{{ "vaucher.company" | translate }}</p>
      </div>
      <div class="flex-1">
        <img src="/assets/images/eccoLogo.png" alt="" class="w-15 h-20" />
      </div>
    </div>

    <div class="mt-8 flex flex-wrap items-center justify-between">
      <div class="mx-4 my-2">
        <div class="space-y-1 text-white-dark">
          <div class="">
            <div class="space-y-1 text-gray-300 dark:text-gray-400">
              <div class="flex h-40 w-80 flex-col justify-center rounded-lg bg-gray-200 p-4 text-center">
                <input class="form-input" formControlName="agent" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-4 my-2 lg:w-auto">
        <div class="space-y-1 text-white-dark">
          <div class="w-full">
            <div class="space-y-1 text-white-dark">
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">28.Novembar br.54, Novi Pazar 36 300 </span>
                <icon-home />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">020/311-575,335-850 </span>
                <icon-phone />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">eccodoo@gmail.com </span>
                <icon-mail />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">www.ecco.rs </span>
                <icon-chrome />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">žiro račun: 155-32299-16,PIB:100769049 </span>
                <icon-zip-file />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10 flex">
      <div class="ml-10">
        <div class="mt-4 flex items-center gap-2">
          <label for="transport" class="mb-0 w-1/3">{{ "vaucher.transport" | translate }}</label>
          <div class="grid w-2/3 grid-cols-1 sm:grid-cols-1" [ngClass]="form.controls['transport'].touched ? (form.controls['transport'].errors ? 'has-error' : 'has-success') : ''">
            <input class="form-input" id="transport" formControlName="transport" />
            <ng-container *ngIf="form.controls['transport'].touched && form.controls['transport'].errors">
              <small class="mt-1 text-danger">{{ "vaucher.please_provide_transport" | translate }}</small>
            </ng-container>
          </div>
        </div>
        <div class="mt-4 flex items-center gap-2">
          <label for="hotel" class="mb-0 w-1/3">{{ "vaucher.hotel" | translate }}</label>
          <div class="grid w-2/3 grid-cols-1 sm:grid-cols-1" [ngClass]="form.controls['hotel'].touched ? (form.controls['hotel'].errors ? 'has-error' : 'has-success') : ''">
            <input class="form-input" id="hotel" formControlName="hotel" />
            <ng-container *ngIf="form.controls['hotel'].touched && form.controls['hotel'].errors">
              <small class="mt-1 text-danger">{{ "vaucher.please_provide_hotel" | translate }}</small>
            </ng-container>
          </div>
        </div>
        <div class="mt-4 flex items-center gap-2">
          <label for="checkin" class="mb-0 w-1/3">{{ "vaucher.check_in" | translate }}</label>
          <div class="grid w-2/3 grid-cols-1 sm:grid-cols-1" [ngClass]="form.controls['checkin'].touched ? (form.controls['checkin'].errors ? 'has-error' : 'has-success') : ''">
            <input class="form-input" id="checkin" formControlName="checkin" [textMask]="{ mask: mask8 }" />
            <ng-container *ngIf="form.controls['checkin'].touched && form.controls['checkin'].errors">
              <small class="mt-1 text-danger">{{ "vaucher.please_provide_check_in" | translate }}</small>
            </ng-container>
          </div>
        </div>
        <div class="mt-4 flex items-center gap-2">
          <label for="checkout" class="mb-0 w-1/3">{{ "vaucher.check_out" | translate }} </label>
          <div class="grid w-2/3 grid-cols-1 sm:grid-cols-1" [ngClass]="form.controls['checkout'].touched ? (form.controls['checkout'].errors ? 'has-error' : 'has-success') : ''">
            <input class="form-input" id="checkout" formControlName="checkout" [textMask]="{ mask: mask8 }" />
            <ng-container *ngIf="form.controls['checkout'].touched && form.controls['checkout'].errors">
              <small class="mt-1 text-danger">{{ "vaucher.please_provide_check_out" | translate }}</small>
            </ng-container>
          </div>
        </div>

        <div class="mt-4 flex items-center gap-2">
          <label for="room" class="mb-0 w-1/3">{{ "vaucher.rooms" | translate }}</label>
          <div class="grid w-2/3 grid-cols-1 sm:grid-cols-1" [ngClass]="form.controls['roomService'].touched ? (form.controls['roomService'].errors ? 'has-error' : 'has-success') : ''">
            <input class="form-input" id="room" formControlName="roomService" />
            <ng-container *ngIf="form.controls['roomService'].touched && form.controls['roomService'].errors">
              <small class="mt-1 text-danger">{{ "vaucher.please_provide_rooms" | translate }}</small>
            </ng-container>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <label for="service" class="mb-0 w-1/3">{{ "vaucher.service" | translate }}</label>
          <div class="grid w-2/3 grid-cols-1 sm:grid-cols-1" [ngClass]="form.controls['service'].touched ? (form.controls['service'].errors ? 'has-error' : 'has-success') : ''">
            <input class="form-input" id="service" formControlName="service" />
            <ng-container *ngIf="form.controls['service'].touched && form.controls['service'].errors">
              <small class="mt-1 text-danger">{{ "vaucher.please_provide_service" | translate }}</small>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-6 border-[#e0e6ed] dark:border-[#1b2e4b]" />
  </div>
</form>
<div class="panel">
  <div class="table-responsive mt-6">
    <table class="table-striped">
      <thead>
        <tr>
          <th rowspan="4">{{ "vaucher.guests" | translate }}</th>
          <th>{{ "vaucher.name_and_surname" | translate }}</th>
          <th>{{ "vaucher.date_of_birth" | translate }}</th>
          <th>{{ "vaucher.passport_number" | translate }}</th>
          <th>{{ "vaucher.phone_number" | translate }}</th>
        </tr>
      </thead>
      <tbody class="border-1">
        <ng-container *ngFor="let passanger of passangers; let i = index">
          <tr>
            <td>{{ i + 1 }}</td>
            <td><input class="form-input" [(ngModel)]="passanger.fullName" name="fullName{{ i }}" /></td>
            <td><input class="form-input" id="service" placeholder="__/__/____" [textMask]="{ mask: mask8 }" [(ngModel)]="passanger.dateOfBirth" name="dateOfBirth{{ i }}" /></td>
            <td><input class="form-input" id="service" [(ngModel)]="passanger.passport" name="passport{{ i }}" /></td>
            <td><input class="form-input" id="service" [(ngModel)]="passanger.phoneNumber" name="phoneNumber{{ i }}" /></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="mt-10">
    <div class="mx-auto rounded-lg p-10">
      <div class="flex justify-between">
        <div>
          <p><strong>Agent:</strong> Armin Gicic</p>
          <p><strong>Datum:</strong> {{ today }}</p>
          <p><strong>Grad:</strong> Novi Pazar</p>
        </div>
        <div>
          <p><strong>PTT:</strong> ECCO.D00</p>
          <p>__________________</p>
          <p>
            <strong>{{ "vaucher.director" | translate }}:</strong> Esad Gicic
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
