import { Store } from "@ngrx/store";
import { Ticket } from "../models/ticket";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/store/index.selector";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { TicketsService } from "../services/tickets.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { animate, style, transition, trigger } from "@angular/animations";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { TransfersService } from "../../transfers/services/transfers.service.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

interface Passenger {
  reservationNumber: string | null;
  ticketId: number;
  id: number;
  name: string;
  surname: string;
}

@Component({
  moduleId: module.id,
  templateUrl: "./tickets.table.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class TicketsTableComponent extends BaseTableComponent<Ticket> {
  public type = "flight";
  public transfers!: any[];
  public exportApiUrl!: string;

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  constructor(
    private modalService: ModalService,
    public override store: Store<AppState>,
    private ticketsService: TicketsService,
    private route: ActivatedRoute,
    public override translate: TranslateService,
    private transferService: TransfersService,
    public override tableService: TableService,
    private positioningService: PositioningService
  ) {
    super(store, translate, selectFromTo, tableService, `tickets/paginated?type=flight`);
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit() {
    this.route.params.subscribe((params) => {
      this.apiUrl = `tickets/paginated?type=${params["type"]}`;
      this.exportApiUrl = `api/Tickets/export?type=${params["type"]}`;
      super.ngOnInit();
      this.getTransfers();
    });
  }

  public checkUncheck(isChecked: boolean) {
    console.log(isChecked, typeof isChecked);
  }

  override getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("tickets_info.actions"), hide: false } as DatatableColumn,
      { field: "getStatus", title: this.translate.instant("status"), hide: true, sort: false } as DatatableColumn,
      { field: "id", title: "ID", isUnique: true, hide: true } as DatatableColumn,
      { field: "fullName", title: this.translate.instant("tickets_info.full_name"), hide: false, sort: false } as DatatableColumn,
      { field: "reservationNumber", title: this.translate.instant("tickets_info.num_of_reservations"), hide: false } as DatatableColumn,
      { field: "departureId", title: this.translate.instant("tickets_info.departure"), hide: true } as DatatableColumn,
      { field: "numberOfPassangers", title: this.translate.instant("tickets_info.numberOfPassangers"), hide: true } as DatatableColumn,
      { field: "destinationId", title: this.translate.instant("tickets_info.destination"), hide: false } as DatatableColumn,
      { field: "departureDate", title: this.translate.instant("tickets_info.departure_date"), hide: false } as DatatableColumn,
      { field: "arrivalDate", title: this.translate.instant("tickets_info.arrival_date"), hide: true } as DatatableColumn,
      { field: "reservationDate", title: this.translate.instant("tickets_info.reservation_date"), hide: true } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("tickets_info.created_date"), hide: true } as DatatableColumn,
      { field: "checked", title: this.translate.instant("tickets_info.checked"), hide: true, sort: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("tickets_info.details"), hide: false } as DatatableColumn,
    ];
  }

  public exportCols = [
    { field: "id", title: this.translate.instant("tickets-export.id"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("tickets-export.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("tickets-export.customerPhoneNumber"), hide: false } as DatatableColumn,
    { field: "numberOfPassengers", title: this.translate.instant("tickets-export.numberOfPassengers"), hide: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("tickets-export.departure"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("tickets-export.destination"), hide: false } as DatatableColumn,
    { field: "startDate", title: this.translate.instant("tickets-export.startDate"), hide: false } as DatatableColumn,
    { field: "returnDate", title: this.translate.instant("tickets-export.returnDate"), hide: true, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("tickets-export.bruto"), hide: true, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("tickets-export.neto"), hide: true, sort: false } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("tickets-export.saleDate"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("tickets-export.user"), hide: true, sort: false } as DatatableColumn,
  ];
  getReservationNumber(pass: Passenger[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }

  getTransfers() {
    this.transferService.getTransfers().subscribe(
      (res) => {
        this.transfers = res;
      },
      (err) => showMessage(PopupType.Danger, err.toString())
    );
  }

  async deleteTicket(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.ticketsService.deleteTicket(id).subscribe(
        (res) => {
          this.ngOnInit();
        },
        (err) => showMessage(PopupType.Danger, err.toString())
      );
    }
  }
}
