import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseHttpService } from "src/app/service/base.http.service";
import { UploadedFile } from "../models/file";

@Injectable({
  providedIn: "root",
})
export class FileService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  attachDocumentToPlan(planId: number, file: File): Observable<UploadedFile> {
    const formData: FormData = new FormData();
    formData.append("document", file as any);

    return this.http.post<UploadedFile>(`${this.apiUrl}/api/Plans/documents/${planId}`, formData, {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
      }),
    });
  }
  getDocumentsForPlan(planId: number): Observable<UploadedFile[]> {
    return this.http.get<UploadedFile[]>(`${this.apiUrl}/api/Plans/documents/${planId}`);
  }
  deleteFile(fileId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/api/Files/${fileId}`);
  }
}
