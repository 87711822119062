import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TravelsService } from "../services/travels.service";
import { TravelDestination } from "../models/travel-destination";

@Component({
  selector: "app-travel-vouchers",
  templateUrl: "./travel-vouchers.component.html",
})
export class TravelVouchersComponent implements OnInit {
  travelId!: number;
  travelDestinations: TravelDestination[] = [];

  constructor(public travelService: TravelsService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.travelId = parseInt(params.get("id") ?? "0");
      this.getVouchersForTravelDestinations();
    });
  }

  getVouchersForTravelDestinations() {
    this.travelService.getTravelDestinationsWithVouchers(this.travelId).subscribe((response) => {
      this.travelDestinations = response;
    });
  }

  editVoucherUrlTemplate() {
    const travelId = this.travelId;
    return (id: number) => {
      return `/dashboard/travels/${travelId}/voucher/${id}/edit`;
    };
  }
}
