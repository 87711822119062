import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
})
export class EntityFormComponent implements OnInit {
  @Input() editMode!: boolean;
  @Input() entityName!: string;
  @Input() isLoading!: boolean;
  @Output() formSubmit = new EventEmitter<string>();
  
  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      name: [this.entityName || '', Validators.required],
    });
  }
populateForm(data: any): void {
    this.form.patchValue({
      name: data,
    });
}
  submitForm(): void {
    if (this.form.valid) {
      this.formSubmit.emit(this.form.get('name')?.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
