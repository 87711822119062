<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="py-5">
    <div class="grid grid-cols-1 gap-5 md:grid-cols-4">
      <div [ngClass]="form.controls['start'].touched ? (form.controls['start'].errors ? 'has-error' : 'has-success') : ''">
        <label for="destinationStart">{{ "group_travel_create.start" | translate }}</label>
        <input id="destinationStart" type="text" class="form-input" formControlName="start" placeholder="__.__.____" [textMask]="{ mask: dateMask }" (focusout)="setDaysNights()" />
        <ng-container *ngIf="form.controls['start'].touched && form.controls['start'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_start" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['end'].touched ? (form.controls['end'].errors ? 'has-error' : 'has-success') : ''">
        <label for="destinationEnd">{{ "group_travel_create.end" | translate }}</label>
        <input id="destinationEnd" type="text" class="form-input" formControlName="end" placeholder="__.__.____" [textMask]="{ mask: dateMask }" (focusout)="setDaysNights()" />
        <ng-container *ngIf="form.controls['end'].touched && form.controls['end'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_end" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['days'].touched ? (form.controls['days'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customDays">{{ "group_travel_create.days" | translate }}</label>
        <input id="customDays" type="text" placeholder="{{ 'group_travel_create.num_of_days' | translate }}" class="form-input" formControlName="days" />
        <ng-container *ngIf="form.controls['days'].touched && form.controls['days'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_days" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['nights'].touched ? (form.controls['nights'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customNights">{{ "group_travel_create.nights" | translate }}</label>
        <input id="customNights" type="text" placeholder="{{ 'group_travel_create.num_of_nights' | translate }}" class="form-input" formControlName="nights" />
        <ng-container *ngIf="form.controls['nights'].touched && form.controls['nights'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_nights" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['service'].touched ? (form.controls['service'].errors ? 'has-error' : 'has-success') : ''">
        <div class="grid grid-cols-1">
          <label for="customService">{{ "group_travel_create.service" | translate }}</label>
          <app-select-remote id="customService" [type]="PicklistType.Service" [selectControl]="form" labelForId="customService" formControlName="service"></app-select-remote>
          <ng-container *ngIf="form.controls['service'].touched && !form.controls['service'].errors">
            <p class="mt-1 text-[#1abc9c]">{{ "group_travel_create.looks_good" | translate }}</p>
          </ng-container>
          <ng-container *ngIf="form.controls['service'].touched && form.controls['service'].errors">
            <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_type" | translate }}</p>
          </ng-container>
        </div>
      </div>
      <app-destination-autocomplete [form]="form" formControlName="destination"></app-destination-autocomplete>
    </div>
    <div class="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['hotel'].touched ? (form.controls['hotel'].errors ? 'has-error' : 'has-success') : ''">
        <div class="grid grid-cols-1">
          <label for="customHotel">{{ "group_travel_create.hotel" | translate }}</label>
          <app-autocomplete-remote
            [type]="EntityPicklistType.Hotels"
            labelName="name"
            placeholder="{{ 'group_travel_create.enter_hotel' | translate }}"
            [selectControl]="form"
            labelForId="customHotel"
            formControlName="hotel"
            [addCallback]="utilService.hotelsAdd('hotel', form).bind(utilService)"
          ></app-autocomplete-remote>
          <ng-container *ngIf="form.controls['hotel'].touched && !form.controls['hotel'].errors">
            <p class="mt-1 text-[#1abc9c]">{{ "group_travel_create.looks_good" | translate }}</p>
          </ng-container>
          <ng-container *ngIf="form.controls['hotel'].touched && form.controls['hotel'].errors">
            <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_hotel" | translate }}</p>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="mt-2 grid grid-cols-1">
      <div [ngClass]="form.controls['notes'].touched ? (form.controls['notes'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customNotes">{{ "group_travel_create.notes" | translate }}</label>
				<textarea id="customNotes" rows="3" class="form-textarea" placeholder="{{ 'group_travel_create.enter_notes' | translate }}" formControlName="notes"></textarea>
        <ng-container *ngIf="form.controls['notes'].touched && form.controls['notes'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_notes" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="mt-3 grid grid-cols-1">
      <button type="submit" class="btn btn-primary">{{ "group_travel_create.save" | translate }}</button>
    </div>
  </div>
</form>
