import { Component, OnInit } from "@angular/core";
import { slideDownUp } from "../shared/animations";
import { ETouristService } from "../shared/services/etourist.service";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  moduleId: module.id,
  selector: "app-eturist-checker",
  templateUrl: "./etourist-checker.html",
  animations: [
    slideDownUp,
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class EtouristCheckerComponent implements OnInit {
  public checked!: boolean;
  public message!: string;
  constructor(public eTouristService: ETouristService) {}
  ngOnInit() {
    // this.checkAvailability();
  }
  checkAvailability() {
    this.eTouristService.checkAvailability().subscribe((response) => {
      const { check, message } = response;
      this.checked = check;
      this.message = message;
    });
  }
}
