import { Ticket } from '../../tickets/models/ticket';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '../../customers/models/customer';
import { TicketsService } from '../../tickets/services/tickets.service';

@Component({
	selector: 'app-ticket-quick-view',
	templateUrl: './ticket-quick-view.component.html',
	styleUrls: ['./ticket-quick-view.component.css']
})
export class TicketQuickViewComponent implements OnInit {
	@Input() data!: Ticket;
	@Input() id!: number;

	constructor(public translate: TranslateService, private ticketService: TicketsService) { }

	ngOnInit(): void {
		if (this.id) {
			this.ticketService.getTicketData(this.id).subscribe((ticket) => {
				this.data = ticket;
			});
		}
	}

	getReservationNumber(pass: Customer[]) {
		if (pass) {
			const arr = pass.map((passenger) => passenger.reservationNumber);
			return arr.join(", ");
		}
		return "";

	}

}
