import { Observable } from "rxjs";
import { Travel } from "../models/travel";
import { Injectable } from "@angular/core";
import { Customer } from "../../customers/models/customer";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { TravelDestination } from "../models/travel-destination";
import { AuditEntry } from "src/app/shared/components/file-history/models/auditentry";

@Injectable({
  providedIn: "root",
})
export class TravelsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getTravelData(id: number | undefined): Observable<Travel> {
    return this.http.get<Travel>(`${this.apiUrl}/api/travels/${id}`);
  }

  getTravelDestinationsWithVouchers(id: number | undefined): Observable<TravelDestination[]> {
    return this.http.get<TravelDestination[]>(`${this.apiUrl}/api/vouchers/travel/${id}`);
  }

  getPassangersData(id: number | undefined): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.apiUrl}/api/Passangers/travel/${id}`);
  }

  createAccountData(id: any, data: any) {
    return this.http.post(`${this.apiUrl}/api/Travels/accounting/${id}`, data);
  }

  getTransfers() {
    return this.http.get<any[]>(`${this.apiUrl}/api/Transfers`);
  }
  deleteTravel(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/travels/${id}`, { responseType: "text" });
  }
  getTravels(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/Travels`);
  }
  getAuditLogs(entityName: string, id: number): Observable<AuditEntry[]> {
    return this.http.get<AuditEntry[]>(`${this.apiUrl}/api/audit/${entityName}/${id}`);
  }
}
