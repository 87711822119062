<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <button class="btn btn-success gap-2" (click)="saveContract()">
    <icon-save />
    {{ "contract.save" | translate }}
  </button>
  <a type="button" class="btn btn-info cursor-pointer gap-2" (click)="cancelEdit()">
    <icon-save />
    {{ "contract.cancel" | translate }}
  </a>
</div>
<div class="panel" *ngIf="contract">
  <div class="grid text-center text-xl font-bold">
    <p>{{ "contract.travel_contract" | translate }}</p>
    <p>br. {{ contract.uniqueId }} od {{ contract.createDate | date : "dd.MM.yyyy" }}</p>
  </div>
  <div class="mt-4 grid grid-cols-1 gap-1 sm:grid-cols-1">
    <div class="col-span-2 flex flex-col sm:col-span-1">
      <div class="-dark text-white"></div>
      <div class="">{{ "contract.traveller_information" | translate }}</div>
    </div>
    <table class="table-bordered mb-4 table">
      <thead class="bg-gray-100">
        <tr>
          <th>{{ "contract.name_and_surname" | translate }}</th>
          <th>{{ "contract.date_of_birth" | translate }}</th>
          <th>{{ "contract.address" | translate }}</th>
          <th>{{ "contract.contact" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let passenger of passangers; let i = index">
          <tr>
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.fullName" name="fullName{{ i }}" />
            </td>
            <td>
              <input class="form-input border-0" type="text" placeholder="__/__/____" [textMask]="{ mask: mask8 }" [(ngModel)]="passenger.dateOfBirth" name="dateOfBirth{{ i }}" />
            </td>
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.address" name="address{{ i }}" />
            </td>
            <td>
              <input class="form-input border-0" type="text" [(ngModel)]="passenger.phoneNumber" name="phoneNumber{{ i }}" />
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <form [formGroup]="form" (ngSubmit)="saveContract()">
      <div class="col-span-1 flex flex-col gap-3 font-bold sm:col-span-1">
        <div class="">{{ "contract.arrangement_information" | translate }}</div>
        <div class="grid w-1/3 grid-cols-1 sm:grid-cols-1">
          <label for="organizer">Organizer Info</label>
          <input class="border-1 col-5 form-input" type="text" id="organizer" formControlName="organizerInfo" />
        </div>
        <div class="">{{ "contract.agent" | translate }}: PTTU ECCO D.O.O, 28. novembra 54, NP, Licenca OTP 243/2021</div>
        <div class="grid w-1/3 grid-cols-1 sm:grid-cols-1">
          <label for="organizerResponsiblePerson">Organizer Responsible Person</label>
          <input class="border-1 col-5 form-input" type="text" id="organizerResponsiblePerson" formControlName="organizerResponsiblePerson" />
        </div>
      </div>
      <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
        <div class="-dark text-white"></div>
        <div class="">{{ "contract.trip_invoice" | translate }}</div>
      </div>
      <table class="table-responsive table-bordered mb-5 w-full">
        <thead>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
            <th class="border border-gray-300 sm:col-span-1">Id</th>
            <th class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.description" | translate }}</th>
            <th class="border border-gray-300 sm:col-span-1">{{ "contract.sum" | translate }}</th>
            <th class="border border-gray-300 sm:col-span-1">{{ "contract.total" | translate }}</th>
          </tr>
        </thead>
        <tbody class="border-top: 2px">
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">1</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.adult_price" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" formControlName="adultPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="adultNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="adultTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">2</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.adult_price" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" value="2000rsd" formControlName="childPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" value="3" formControlName="childNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="childTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">3</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.suplement_for_trip_data" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" formControlName="suplementTripPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="suplementTripNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="suplementTripTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">4</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.suplement_for_ny_supper" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" formControlName="suplementNYPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="suplementNYNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="suplementNYTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">5</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.air_tax" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" formControlName="aitTaxiPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="aitTaxiNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="aitTaxiTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">6</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.visum" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" formControlName="visumPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="visumNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="visumTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">7</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.tax" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" formControlName="taxPrice" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="taxNumber" />
            </td>
            <td class="border border-gray-300 sm:col-span-1">
              <input class="form-input border-0" type="text" name="name" formControlName="taxTotal" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">8</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.total_in_euro" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" />
            </td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">
              <input class="form-input border-0" type="text" name="name" formControlName="totalInEuro" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1">9</td>
            <td class="border border-gray-300 sm:col-span-1">{{ "contract.total_in_din" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-2">
              <input class="form-input border-0" type="text" name="name" />
            </td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">
              <input class="form-input border-0" type="text" name="name" formControlName="totalInDinars" />
            </td>
          </tr>
          <tr class="ng-star-inserted bg-gray-10 col-span-1 border border-gray-300 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1" colspan="3"></td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">
              {{ "contract.fiscal_receipt_number" | translate }}
              <input class="form-input border-0" type="text" name="name" formControlName="fiscalNumber" />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
    <div class="justify-content-between flex w-full">
      <div class="w-full">{{ "contract.payment_method" | translate }}:</div>
      <div class="w-full">{{ "contract.cash" | translate }}:X</div>
      <div class="w-full">{{ "contract.check" | translate }}:</div>
      <div class="w-full">{{ "contract.other" | translate }}: x</div>
    </div>
    <div class="w-full text-start">
      <p>{{ "contract.note" | translate }}:___________________________. {{ "contract.approved_deffered_payment_until" | translate }} _________________________________________.</p>
    </div>
    <div class="mb-4 w-full text-start">
      <p class="">
        <u>{{ "contract.guarantee" | translate }}:</u>
      </p>
      <p>
        {{ "contract.section_1" | translate }}
      </p>
      <p>
        {{ "contract.complaint" | translate }}<br />
        <u>{{ "contract.statement" | translate }}</u>
      </p>
    </div>
    <div class="justify-content-end mt-5 flex w-full">
      <div class="w-full text-start">
        <p>{{ "contract.signature" | translate }}</p>
        <br />
        <p>_____________________________</p>
      </div>
      <div class="w-full text-end">
        <p>PTTO ECCO d.o.o.</p>
        <br />
        <p>_____________________________</p>
        <p>Direktor Esat Gicic</p>
      </div>
    </div>

    <div class="grid grid-cols-3 text-start sm:grid-cols-1">
      <p class="font-bold">{{ "contract.special_terms" | translate }}:p> <br /></p>

      <p>&nbsp; {{ "contract.section_2" | translate }}</p>
      <br />
      <p class="p-5">
        {{ "contract.5_%" | translate }}<br />
        {{ "contract.10_%" | translate }}<br />
        {{ "contract.20_%" | translate }}<br />
        {{ "contract.40_%" | translate }}<br />
        {{ "contract.80_%" | translate }}a<br />
        {{ "contract.90_%" | translate }}<br />
        {{ "contract.100_%" | translate }}
      </p>
    </div>
    <div class="grid grid-cols-3 text-start sm:grid-cols-1">
      <p>&nbsp; {{ "contract.section_3" | translate }}</p>
      <br />
      <p>&nbsp; {{ "contract.section_4" | translate }}</p>
      <br />
      <p>&nbsp; {{ "contract.section_5" | translate }}</p>
      <br />
      &nbsp; {{ "contract.section_6" | translate }}
    </div>
  </div>
</div>
