import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { TransferPlan } from "../../transfers/models/transfer";
import { TransfersService } from "../../transfers/services/transfers.service.service";

@Component({
  selector: "app-transfer-plan-quickview",
  templateUrl: "./transfer-plan-quickview.component.html",
  styleUrls: ["./transfer-plan-quickview.component.css"],
})
export class TransferPlanQuickviewComponent implements OnInit {
  @Input() data!: TransferPlan;
  @Input() id!: number;

  constructor(public translate: TranslateService, private transferSevice: TransfersService) {}
  getReservationNumber(pass: Customer[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }

  ngOnInit(): void {
    if (this.id) {
      this.transferSevice.getTransferPlan(this.id).subscribe((transferPlan) => {
        this.data = transferPlan;
      });
    }
  }
  calculateTotalPassangers(transferPlans: TransferPlan) {
    return transferPlans.transferPassangers.length;
  }
}
