import { Component, forwardRef, Input } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { PicklistService } from "../../services/picklist.service";
import { PicklistOption } from "../../models/picklist-option";
import { NgSelectComponent } from "@ng-select/ng-select";
import { PicklistType } from "../../enums/picklist-types";
import { Observable } from "rxjs";
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-select-remote",
  templateUrl: "./select-remote.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectRemoteComponent),
      multi: true,
    },
  ],
})
export class SelectRemoteComponent implements ControlValueAccessor {
  public options$!: Observable<PicklistOption[]>;
  public input = null;

  @Input() minTermLength = 2;
  @Input() type!: PicklistType;
  @Input() labelForId!: string;
  @Input() formControlName!: string;
  @Input() selectControl!: FormGroup;
	@Input() setDefaultFirstOption = false;
  constructor(public picklistService: PicklistService, public translate: TranslateService) {}

	ngOnInit(): void {
    this.options$ = this.picklistService.getPicklistOptions(this.type);
    this.options$.subscribe(options => {
      if (this.setDefaultFirstOption && options.length > 0) {
        this.setDefaultValue(options[0]);
      }
    });
  }

  setDefaultValue(option: PicklistOption) {
    this.writeValue(option);
    if (this.selectControl && this.selectControl.get(this.formControlName)) {
      this.selectControl.get(this.formControlName)?.setValue(option);
    }
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.input = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange(_: any) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched() {}
}