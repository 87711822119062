<div class="modal-overlay">
  <div class="modal-content">
    <h1 class="mb-4 text-lg font-semibold">{{ title }}</h1>
    <p class="mb-6">{{ message }}</p>
    <div class="flex justify-end space-x-4">
      <button (click)="onCancel()" class="rounded-lg bg-gray-200 px-4 py-2 text-gray-800 hover:bg-gray-300">
        {{ cancelButtonText }}
      </button>
      <button (click)="onConfirm()" class="rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600">
        {{ confirmButtonText }}
      </button>
    </div>
  </div>
</div>
