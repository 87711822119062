import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-logo",
  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 130 32"
        style="enable-background:new 0 0 130 32;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #d4483b;
          }
          .st1 {
            fill: #2b2d41;
          }
          .st2 {
            fill: #fcda6f;
          }
          .st3 {
            fill: none;
          }
          .st4 {
            fill: #bcd4ce;
          }
          .st5 {
            fill: #d2483a;
          }
          .st6 {
            fill: #a2b9b3;
          }
          .st7 {
            fill: #af4133;
          }
          .st8 {
            fill: #e9b13c;
          }
          .st9 {
            fill: #e9b63f;
          }
          .st10 {
            fill: #eca348;
          }
          .st11 {
            fill: #efd695;
          }
          .st12 {
            fill: #fbcb3e;
          }
          .st13 {
            fill: #fda155;
          }
          .st14 {
            fill: #37302c;
          }
          .st15 {
            fill: #ffffff;
          }
        </style>
        <g>
          <g>
            <path
              class="st0"
              d="M56,27c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4C55,26.3,55,26.1,55,25.8l0-2h-0.6v-0.3l0.6-0.1l0.1-1
					 h0.4v1h1v0.4h-1v2c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2s0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0.4,0V27H56z"
            />
            <path
              class="st0"
              d="M58.7,27c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9
					 c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.3,0,0.5,0,0.7,0.1
					 c0.2,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.4-0.3,0.6
					 s-0.3,0.2-0.5,0.3C59.1,27,58.9,27,58.7,27z M58.7,26.6c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.4
					 c0-0.2,0.1-0.4,0.1-0.7c0-0.3,0-0.6-0.1-0.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.4,0
					 c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.6,0.1,0.7
					 c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2C58.3,26.6,58.5,26.6,58.7,26.6z"
            />
            <path
              class="st0"
              d="M62.2,27c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8v-2.5h0.5v2.4c0,0.3,0.1,0.5,0.2,0.6
					 c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.3v-2.8H64V27h-0.4l-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.3
					 C62.7,27,62.4,27,62.2,27z"
            />
            <path
              class="st0"
              d="M65,27v-3.6h0.4l0.1,0.6c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.1,0,0.2,0
					 c0.1,0,0.1,0,0.2,0V24c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2
					 c-0.1,0.1-0.2,0.2-0.4,0.3V27H65z"
            />
            <path
              class="st0"
              d="M67.9,22.8c-0.1,0-0.1,0-0.1-0.1v-0.5c0-0.1,0-0.1,0.1-0.1h0.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1v0.5
					 c0,0.1,0,0.1-0.1,0.1H67.9z M67.8,27v-3.6h0.5V27H67.8z"
            />
            <path
              class="st0"
              d="M70.5,27c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0v-0.3h1.5
					 c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.2c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-0.5
					 c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5v-0.2c0-0.2,0-0.4,0.1-0.5s0.2-0.2,0.4-0.3
					 c0.2-0.1,0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1v0.3h-1.4c-0.2,0-0.4,0-0.5,0.1
					 c-0.1,0.1-0.2,0.2-0.2,0.4v0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0h0.5c0.3,0,0.6,0.1,0.8,0.2
					 c0.2,0.2,0.3,0.4,0.3,0.7v0.2c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.3C71,27,70.8,27,70.5,27z"
            />
            <path
              class="st0"
              d="M73.8,27c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7l0-2h-0.6v-0.3l0.6-0.1
					 l0.1-1h0.4v1h1v0.4h-1v2c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0.4,0V27H73.8z"
            />
            <path
              class="st0"
              d="M78.1,27c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0v-0.3h1.5
					 c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.2c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-0.5
					 c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.5v-0.2c0-0.2,0-0.4,0.1-0.5s0.2-0.2,0.4-0.3
					 c0.2-0.1,0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0.1v0.3h-1.4c-0.2,0-0.4,0-0.5,0.1
					 c-0.1,0.1-0.2,0.2-0.2,0.4v0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0h0.5c0.3,0,0.6,0.1,0.8,0.2
					 c0.2,0.2,0.3,0.4,0.3,0.7v0.2c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.3C78.6,27,78.4,27,78.1,27z"
            />
            <path
              class="st0"
              d="M81.7,27c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.9
					 c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1s0.5,0,0.7,0.1
					 c0.2,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.6c0.1,0.2,0.1,0.5,0.1,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.4-0.3,0.6
					 c-0.1,0.1-0.3,0.2-0.5,0.3C82.1,27,81.9,27,81.7,27z M81.7,26.6c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.4
					 c0-0.2,0.1-0.4,0.1-0.7c0-0.3,0-0.6-0.1-0.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.4,0
					 c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.6,0.1,0.7
					 c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2C81.3,26.6,81.5,26.6,81.7,26.6z"
            />
            <path
              class="st0"
              d="M84.3,27v-3.1h-0.5v-0.3l0.5-0.1V23c0-0.3,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.4-0.1,0.6-0.1
					 c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0v0.4h-0.6c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.4v0.4h1.1v0.4
					 h-1.1V27H84.3z"
            />
            <path
              class="st0"
              d="M87.7,27c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.7l0-2h-0.6v-0.3l0.6-0.1
					 l0.1-1h0.4v1h1v0.4h-1v2c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.2s0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0.4,0V27H87.7z"
            />
            <path class="st0" d="M89.7,27l-1.1-3.6h0.5l0.9,3.1l0.9-3.1h0.5l0.9,3.1l0.8-3.1h0.5L92.6,27H92l-0.9-2.9L90.3,27H89.7z" />
            <path
              class="st0"
              d="M94.9,27c-0.3,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.7v-0.3c0-0.3,0.1-0.5,0.3-0.7
					 c0.2-0.2,0.5-0.3,0.8-0.3h1.2v-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.6-0.1h-1.1v-0.3
					 c0.2,0,0.3-0.1,0.5-0.1s0.5,0,0.7,0c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.6V27h-0.4l-0.1-0.4
					 c0,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.5,0.1C95.2,27,95,27,94.9,27z M95.1,26.6c0.1,0,0.2,0,0.3,0
					 c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0v-1.1l-1.1,0c-0.3,0-0.4,0.1-0.5,0.2
					 c-0.1,0.1-0.2,0.3-0.2,0.4V26c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2C94.9,26.6,95,26.6,95.1,26.6z"
            />
            <path
              class="st0"
              d="M97.8,27v-3.6h0.4l0.1,0.6c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.1,0,0.2,0
					 c0.1,0,0.1,0,0.2,0V24c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.2
					 c-0.1,0.1-0.2,0.2-0.4,0.3V27H97.8z"
            />
            <path
              class="st0"
              d="M101.9,27c-0.4,0-0.7-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.6-0.1-1.1c0-0.5,0-0.8,0.1-1.1
					 c0.1-0.3,0.3-0.5,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.7
					 c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.1H101c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.3,0.4
					 c0.2,0.1,0.4,0.1,0.7,0.1h1.1v0.3c-0.2,0-0.4,0-0.6,0.1C102.4,27,102.2,27,101.9,27z M101,25.1h1.3c0.2,0,0.3,0,0.4-0.1
					 c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.6,0.1
					 c-0.1,0.1-0.2,0.2-0.3,0.4C101,24.5,101,24.7,101,25.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M40.2,6c0.2,0,0.3,0.1,0.3,0.3v1.9c0,0.2-0.1,0.3-0.3,0.3H37v7.9c0,0.2-0.1,0.3-0.3,0.3h-2.4
					 c-0.2,0-0.3-0.1-0.3-0.3V8.4h-3.2c-0.2,0-0.3-0.1-0.3-0.3V6.3c0-0.2,0.1-0.3,0.3-0.3H40.2z"
            />
            <path
              class="st0"
              d="M40.1,13.7V8.9c0-0.2,0.1-0.3,0.3-0.3h2.2c0.2,0,0.3,0.1,0.3,0.3v4.1c0,1,0.5,1.5,1.3,1.5
					 c0.8,0,1.4-0.5,1.4-1.5V8.9c0-0.2,0.1-0.3,0.3-0.3h2.2c0.2,0,0.3,0.1,0.3,0.3v7.5c0,0.2-0.1,0.3-0.3,0.3H46
					 c-0.2,0-0.3-0.1-0.3-0.3v-1c-0.6,1-1.4,1.5-2.7,1.5C41,16.8,40.1,15.7,40.1,13.7z"
            />
            <path
              class="st0"
              d="M55.4,8.7v2.8c0,0.2-0.1,0.3-0.3,0.3c-0.9-0.2-2.1,0.2-2.1,1.9v2.7c0,0.2-0.1,0.3-0.3,0.3h-2.2
					 c-0.2,0-0.3-0.1-0.3-0.3V8.9c0-0.2,0.1-0.3,0.3-0.3h2.1c0.2,0,0.3,0.1,0.3,0.3v1.8c0.6-1.7,1.6-2.3,2.2-2.3
					 C55.3,8.4,55.4,8.5,55.4,8.7z"
            />
            <path
              class="st0"
              d="M56.3,6.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C56.9,8,56.3,7.3,56.3,6.5z
						M56.3,8.9c0-0.2,0.1-0.3,0.3-0.3h2.2c0.2,0,0.3,0.1,0.3,0.3v7.5c0,0.2-0.1,0.3-0.3,0.3h-2.2c-0.2,0-0.3-0.1-0.3-0.3V8.9z"
            />
            <path
              class="st0"
              d="M67.8,14.2c0,1.8-1.7,2.7-3.9,2.7c-1.4,0-2.6-0.3-3.4-0.8c-0.2-0.1-0.3-0.3-0.2-0.5l0.6-1.2
					 c0.1-0.3,0.3-0.3,0.5-0.2c0.7,0.4,1.4,0.7,2.3,0.7c0.6,0,1.1-0.1,1.1-0.5c0-1.1-4.1-0.4-4.1-3.3c0-1.7,1.4-2.7,3.7-2.7
					 c1.3,0,2.4,0.3,3.1,0.7c0.2,0.1,0.3,0.3,0.2,0.5l-0.5,1.1c-0.1,0.3-0.3,0.3-0.5,0.2c-0.4-0.2-1.1-0.6-2-0.6
					 c-0.7,0-1.1,0.2-1.1,0.5C63.6,11.9,67.8,11.2,67.8,14.2z"
            />
            <path
              class="st0"
              d="M75.5,15.6c0.1,0.2,0,0.4-0.1,0.5c-0.7,0.4-1.5,0.7-2.7,0.7c-1.9,0-3-1.1-3-2.9v-3.2h-1
					 c-0.2,0-0.3-0.1-0.3-0.3V8.9c0-0.2,0.1-0.3,0.3-0.3h1V7.4c0-0.2,0.1-0.3,0.4-0.4l2.1-0.6c0.3-0.1,0.4,0,0.4,0.2v1.9h2.2
					 c0.2,0,0.3,0.1,0.3,0.3v1.6c0,0.2-0.1,0.3-0.3,0.3h-2.2v2.4c0,0.8,0.2,1.2,1,1.2c0.4,0,0.6-0.1,0.9-0.3c0.2-0.1,0.4-0.1,0.5,0.2
					 L75.5,15.6z"
            />
            <path
              class="st0"
              d="M90.2,16.2c0.1,0.3,0,0.4-0.3,0.4h-2.6c-0.2,0-0.3-0.1-0.4-0.3l-0.6-1.6h-4.2l-0.6,1.6
					 c-0.1,0.2-0.2,0.3-0.4,0.3h-2.4c-0.3,0-0.4-0.2-0.3-0.4l4.4-10c0.1-0.2,0.2-0.3,0.4-0.3h2.2c0.2,0,0.3,0.1,0.4,0.3L90.2,16.2z
						M82.9,12.8h2.6l-1.3-3.2L82.9,12.8z"
            />
            <path
              class="st0"
              d="M99.2,9.9c0.2,0.3,0.3,0.7,0.3,1.1c0,1.6-1.5,2.7-4.1,2.7h-0.9c-0.5,0-0.7,0.1-0.7,0.4c0,0.3,0.2,0.4,0.7,0.4
					 H97c2.2,0,3.3,0.8,3.3,2.3c0,1.7-1.5,2.8-4.9,2.8c-3.3,0-4.5-0.7-4.5-2c0-0.8,0.4-1.3,1.4-1.6c-0.6-0.3-0.9-0.8-0.9-1.4
					 c0-0.7,0.4-1.2,1.2-1.5c-0.9-0.5-1.3-1.2-1.3-2.1c0-1.6,1.5-2.7,4.1-2.7c1.1,0,2,0.2,2.7,0.5c0.4-1,1-1.6,2-1.6
					 c0.2,0,0.3,0.1,0.3,0.3v1.6c0,0.2-0.1,0.3-0.3,0.3C99.5,9.5,99.3,9.6,99.2,9.9z M95.6,16.4c-1.6,0-2,0.3-2,0.8
					 c0,0.5,0.5,0.8,2,0.8c1.6,0,2-0.3,2-0.8C97.6,16.7,97.1,16.4,95.6,16.4z M96.8,11c0-0.6-0.6-1-1.5-1c-0.9,0-1.5,0.3-1.5,1
					 c0,0.6,0.6,1,1.5,1C96.2,12,96.8,11.7,96.8,11z"
            />
            <path
              class="st0"
              d="M109,14.6c0.2,0.1,0.3,0.3,0.2,0.5c-0.6,1-2,1.8-3.9,1.8c-2.5,0-4.5-1.5-4.5-4.3c0-2.7,2-4.2,4.5-4.2
					 c2.3,0,4.3,1.3,4.3,4.6h-6c0.2,1.2,1,1.7,1.8,1.7c0.7,0,1.3-0.2,1.6-0.7c0.2-0.2,0.3-0.3,0.5-0.2L109,14.6z M103.6,11.5h3.1
					 c-0.1-0.8-0.6-1.3-1.5-1.3C104.4,10.2,103.8,10.7,103.6,11.5z"
            />
            <path
              class="st0"
              d="M119.2,11.5v4.8c0,0.2-0.1,0.3-0.3,0.3h-2.2c-0.2,0-0.3-0.1-0.3-0.3v-4.1c0-1-0.5-1.5-1.3-1.5
					 c-0.9,0-1.4,0.5-1.4,1.5v4.1c0,0.2-0.1,0.3-0.3,0.3H111c-0.2,0-0.3-0.1-0.3-0.3V8.9c0-0.2,0.1-0.3,0.3-0.3h2.1
					 c0.2,0,0.3,0.1,0.3,0.3v1c0.6-1,1.5-1.5,2.8-1.5C118.3,8.3,119.2,9.5,119.2,11.5z"
            />
            <path
              class="st0"
              d="M127.2,15.6c0.1,0.2,0,0.4-0.1,0.5c-0.7,0.4-1.5,0.7-2.7,0.7c-1.9,0-3-1.1-3-2.9v-3.2h-1
					 c-0.2,0-0.3-0.1-0.3-0.3V8.9c0-0.2,0.1-0.3,0.3-0.3h1V7.4c0-0.2,0.1-0.3,0.4-0.4l2.1-0.6c0.3-0.1,0.4,0,0.4,0.2v1.9h2.2
					 c0.2,0,0.3,0.1,0.3,0.3v1.6c0,0.2-0.1,0.3-0.3,0.3h-2.2v2.4c0,0.8,0.2,1.2,1,1.2c0.4,0,0.6-0.1,0.9-0.3c0.2-0.1,0.4-0.1,0.5,0.2
					 L127.2,15.6z"
            />
          </g>
        </g>
        <g>
          <path
            class="st1"
            d="M22.4,27.2c0,0.5-0.3,1-0.7,1.3c-0.4,0.3-0.9,0.5-1.4,0.5c-4.4,0-8.7,0-13.1,0c-0.9,0-1.6-0.4-1.9-1.2
				 c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.4,0-0.8,0-1.1h2.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.4,0,0.7,0,1.1,0c0.3,0,0.5,0,0.8,0
				 c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
				 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h6.8c0,0,0,0,0,0c0,0,0,0,0,0
				 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				 c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				 c0.8,0,1.5,0,2.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h2.4
				 C22.4,26.3,22.4,26.8,22.4,27.2z"
          />
          <path
            class="st2"
            d="M9.9,29.8C9.9,29.8,9.9,29.8,9.9,29.8c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.2-0.7,0.2c-0.5,0-0.9-0.3-0.9-0.8
				 c0,0,0,0,0-0.1h0.3c0.2,0.5,0.8,0.7,1.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1H9.9z"
          />
          <path
            class="st2"
            d="M19.5,29.8c0,0.4-0.5,0.8-1,0.8c-0.5,0-0.9-0.4-1-0.8h0.3c0,0,0,0,0,0.1c0.1,0.4,0.6,0.6,0.9,0.4
				 c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0-0.1,0-0.1H19.5z"
          />
          <path
            class="st1"
            d="M20.6,28c-0.1,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1c0.1,0.1,0.1,0.1,0.2,0.1
				 c0.2,0,0.3-0.1,0.3-0.3S20.8,28,20.6,28z"
          />
          <path
            class="st1"
            d="M20.3,28.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
				 c0,0,0,0,0-0.1C20.4,28.4,20.4,28.3,20.3,28.2z"
          />
          <g>
            <path
              class="st3"
              d="M9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5z
					 "
            />
            <path class="st3" d="M13.1,1.6C13.1,1.6,13.1,1.6,13.1,1.6C13.1,1.5,13.1,1.5,13.1,1.6C13.1,1.5,13.1,1.5,13.1,1.6z" />
            <path
              class="st3"
              d="M7.7,6.5C7.7,6.5,7.7,6.5,7.7,6.5C7.7,6.5,7.7,6.5,7.7,6.5C7.7,6.5,7.7,6.5,7.7,6.5C7.7,6.5,7.7,6.5,7.7,6.5z
					 "
            />
            <path
              class="st3"
              d="M7.4,10.2C7.4,10.3,7.4,10.3,7.4,10.2c0,0.1,0,0.1,0.1,0.2C7.5,10.4,7.4,10.3,7.4,10.2
					 C7.4,10.3,7.4,10.3,7.4,10.2z"
            />
            <path class="st3" d="M9.3,16.6c0.4,0.3,0.9,0.6,1.4,0.7C10.2,17.1,9.8,16.9,9.3,16.6z" />
            <path class="st3" d="M9.2,4.4C9.1,4.3,9,4.4,9,4.5C8.7,4.7,8.5,5,8.3,5.3C8.5,5,8.7,4.7,9,4.5C9,4.4,9.1,4.3,9.2,4.4z" />
            <path
              class="st3"
              d="M20.7,8.7C20.7,8.7,20.7,8.7,20.7,8.7C20.7,8.7,20.7,8.7,20.7,8.7C20.7,8.7,20.7,8.7,20.7,8.7
					 C20.7,8.7,20.7,8.7,20.7,8.7z"
            />
            <path class="st3" d="M21,8.9C21,8.9,21,8.9,21,8.9L21,8.9C21,8.8,21,8.8,21,8.9C21,8.8,21,8.9,21,8.9z" />
            <path
              class="st3"
              d="M21.1,8.9L21.1,8.9C21.1,8.9,21.1,8.9,21.1,8.9C21.1,8.9,21,8.9,21.1,8.9C21,8.9,21,8.9,21.1,8.9
					 C21,8.9,21.1,8.9,21.1,8.9z"
            />
            <path class="st3" d="M21.2,8.9c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0C21.1,8.9,21.2,8.9,21.2,8.9z" />
            <path class="st3" d="M23.5,9.6C23.4,9.6,23.4,9.6,23.5,9.6C23.4,9.6,23.4,9.6,23.5,9.6z" />
            <path class="st3" d="M23.6,9.8C23.6,9.8,23.6,9.8,23.6,9.8C23.6,9.8,23.6,9.8,23.6,9.8C23.6,9.8,23.6,9.8,23.6,9.8z" />
            <polygon class="st3" points="18.8,5.5 18.8,5.5 18.8,5.5 18.8,5.5 		" />
            <path class="st3" d="M25.1,12.5c0,0.1-0.1,0.1-0.1,0.2C25,12.6,25,12.6,25.1,12.5z" />
            <path class="st3" d="M17.1,15.5c0.1-0.1,0.1-0.2,0.1-0.2C17.1,15.4,17.1,15.5,17.1,15.5z" />
            <path class="st3" d="M16.4,13.8L16.4,13.8C16.4,13.8,16.4,13.8,16.4,13.8C16.4,13.8,16.4,13.8,16.4,13.8z" />
            <path
              class="st3"
              d="M16.5,13.9C16.5,13.9,16.5,13.9,16.5,13.9C16.5,13.8,16.5,13.8,16.5,13.9L16.5,13.9
					 C16.4,13.8,16.5,13.8,16.5,13.9z"
            />
            <path
              class="st3"
              d="M16.7,14.2C16.7,14.2,16.7,14.2,16.7,14.2C16.7,14.1,16.7,14.1,16.7,14.2C16.7,14.1,16.7,14.1,16.7,14.2
					 C16.7,14.2,16.7,14.2,16.7,14.2z"
            />
            <polygon class="st3" points="18.8,14.2 18.8,14.2 18.8,14.2 		" />
            <path class="st3" d="M16.7,13.3c0-0.1-0.1-0.1-0.1-0.2h0c0,0,0,0,0,0l0,0C16.7,13.2,16.7,13.2,16.7,13.3z" />
            <path class="st3" d="M16.6,13.1c0,0,0.1,0,0.1,0C16.7,13.1,16.7,13.1,16.6,13.1z" />
            <path class="st3" d="M12.8,2.7c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0C12.9,2.7,12.9,2.7,12.8,2.7z" />
            <path class="st3" d="M12.7,2.5C12.7,2.5,12.6,2.5,12.7,2.5C12.6,2.5,12.7,2.5,12.7,2.5z" />
            <path class="st3" d="M13.6,2.5c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0c-0.1,0-0.2,0-0.3,0C13.7,2.5,13.6,2.5,13.6,2.5z" />
            <path class="st3" d="M9,16.3C9,16.3,8.9,16.2,9,16.3C8.9,16.2,8.9,16.2,9,16.3C8.9,16.2,9,16.3,9,16.3z" />
            <path class="st3" d="M17.1,15.5c0,0-0.1,0.1-0.1,0.1C17,15.6,17,15.6,17.1,15.5z" />
            <path class="st3" d="M17,15C17,15,17,15,17,15C17,15,17,15,17,15C17,15,17,15,17,15C17,15,17,15,17,15z" />
            <path class="st3" d="M8.6,16.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0C8.6,16.4,8.6,16.3,8.6,16.3z" />
            <path
              class="st3"
              d="M8.7,16.5C8.7,16.5,8.7,16.5,8.7,16.5c0.1,0.1,0.2,0.2,0.3,0.3C8.9,16.7,8.8,16.6,8.7,16.5
					 C8.7,16.5,8.7,16.5,8.7,16.5z"
            />
            <path
              class="st3"
              d="M9.3,17.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.3c-0.2-0.1-0.3-0.2-0.5-0.3
					 C9.5,17.4,9.4,17.3,9.3,17.2z"
            />
            <path class="st3" d="M12.9,20.7c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0c0.2,0,0.4,0,0.6,0C12.7,20.7,12.8,20.7,12.9,20.7z" />
            <path class="st3" d="M14,21C14,21,14,21,14,21C14,21,14,21,14,21z" />
            <path class="st3" d="M10,20.7c-0.4,0-0.9,0-1.3,0.1c-0.1,0-0.2,0-0.3,0c0.1,0,0.2,0,0.3,0C9.1,20.7,9.6,20.7,10,20.7z" />
            <path
              class="st3"
              d="M6.2,21.7c-0.1,0.2-0.2,0.3-0.4,0.2c0,0,0,0,0,0c0,0,0,0,0,0C5.9,21.9,6.1,21.9,6.2,21.7
					 c0.2-0.5,0.5-0.8,1-0.9C6.7,20.9,6.4,21.2,6.2,21.7z"
            />
            <path
              class="st3"
              d="M5.7,21.5C5.7,21.4,5.7,21.4,5.7,21.5c0.1-0.2,0.1-0.3,0.2-0.3C5.8,21.2,5.8,21.3,5.7,21.5
					 C5.7,21.4,5.7,21.4,5.7,21.5c0,0.1,0,0.2,0,0.3C5.6,21.7,5.6,21.6,5.7,21.5z"
            />
            <path
              class="st3"
              d="M13.9,21.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0-0.6,0.1-0.8,0.1c0.3,0,0.6-0.1,0.8-0.1
					 C13.7,21.4,13.8,21.4,13.9,21.3c0.1,0,0.1-0.1,0.1-0.1C14,21.3,14,21.3,13.9,21.3z"
            />
            <path
              class="st3"
              d="M7.3,21c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.3,0c-0.1,0-0.2,0-0.3,0C7.7,20.9,7.5,20.9,7.3,21
					 c-0.1,0-0.1,0.1-0.2,0.1C7.2,21.1,7.2,21,7.3,21z"
            />
            <path
              class="st3"
              d="M7.3,21.5c-0.1,0-0.1-0.1-0.1-0.1C7.2,21.4,7.2,21.4,7.3,21.5c0.2,0.1,0.3,0.1,0.5,0.1
					 c0.6,0.1,1.1,0.1,1.7,0.1c-0.6,0-1.1,0-1.7-0.1C7.6,21.5,7.5,21.5,7.3,21.5z"
            />
            <path class="st3" d="M8.3,20.5c0.2,0,0.3,0,0.5,0C8.6,20.5,8.5,20.5,8.3,20.5z" />
            <path class="st3" d="M20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5z" />
            <path class="st3" d="M20.4,20.5c0.1,0,0.3-0.1,0.3-0.3c0-0.1,0-0.1,0-0.1c0,0,0,0.1,0,0.1C20.7,20.4,20.6,20.5,20.4,20.5z" />
            <path class="st3" d="M20.6,20.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0C20.5,20,20.5,20,20.6,20.1z" />
            <path class="st3" d="M8.8,20c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0C8.7,20,8.8,20,8.8,20z" />
            <path class="st3" d="M8.7,20c-0.1,0-0.2,0-0.4,0C8.4,20,8.5,20,8.7,20L8.7,20z" />
            <path
              class="st3"
              d="M9.6,20.3C9.6,20.3,9.6,20.3,9.6,20.3C9.6,20.3,9.6,20.3,9.6,20.3c0-0.1-0.1-0.2-0.3-0.3c-0.1,0-0.1,0-0.2,0
					 c0.1,0,0.1,0,0.2,0C9.5,20,9.6,20.1,9.6,20.3z"
            />
            <path
              class="st3"
              d="M14.3,19.9C14.3,19.9,14.3,19.9,14.3,19.9C14.3,19.9,14.3,19.9,14.3,19.9C14.3,19.9,14.3,19.9,14.3,19.9
					 C14.3,19.9,14.3,19.9,14.3,19.9C14.3,19.9,14.3,19.9,14.3,19.9C14.3,19.9,14.3,19.9,14.3,19.9z"
            />
            <path class="st3" d="M14.4,19.9C14.4,19.9,14.4,19.9,14.4,19.9C14.4,19.9,14.4,19.9,14.4,19.9C14.4,19.9,14.4,19.9,14.4,19.9z" />
            <path
              class="st3"
              d="M14.5,20C14.5,20,14.5,20,14.5,20C14.4,19.9,14.4,19.9,14.5,20C14.4,19.9,14.4,19.9,14.5,20
					 C14.5,20,14.5,20,14.5,20z"
            />
            <polygon class="st3" points="14.5,20 14.5,20 14.5,20 14.5,20 14.5,20 14.5,20 14.5,20 		" />
            <polygon class="st3" points="14.5,20 14.5,20 14.5,20 		" />
            <polygon class="st3" points="14.5,20 14.5,20 14.5,20 14.5,20 14.5,20 		" />
            <path class="st3" d="M14.4,19.3C14.3,19.3,14.3,19.3,14.4,19.3C14.3,19.3,14.3,19.3,14.4,19.3z" />
            <path class="st3" d="M14.8,19.7C14.8,19.6,14.8,19.6,14.8,19.7C14.8,19.6,14.8,19.6,14.8,19.7z" />
            <path class="st3" d="M23.2,20.2c0,0-0.1,0.1-0.1,0.1C23.2,20.3,23.2,20.3,23.2,20.2z" />
            <path class="st3" d="M23.3,20.1c0,0.1,0,0.1,0,0.2C23.3,20.2,23.3,20.1,23.3,20.1z" />
            <path class="st3" d="M21.7,19.3C21.7,19.3,21.7,19.3,21.7,19.3L21.7,19.3C21.7,19.3,21.7,19.3,21.7,19.3z" />
            <path class="st3" d="M22.8,20.5C22.8,20.5,22.8,20.5,22.8,20.5C22.8,20.4,22.7,20.4,22.8,20.5C22.8,20.4,22.8,20.5,22.8,20.5z" />
            <path
              class="st3"
              d="M22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.5,22.8,20.5,22.8,20.6
					 C22.8,20.5,22.8,20.5,22.8,20.6C22.8,20.5,22.8,20.5,22.8,20.6z"
            />
            <path class="st3" d="M22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6z" />
            <path
              class="st3"
              d="M11.8,19.9C11.8,19.9,11.7,19.9,11.8,19.9C11.7,19.9,11.7,19.9,11.8,19.9C11.7,19.9,11.7,19.9,11.8,19.9
					 C11.7,19.9,11.8,19.9,11.8,19.9z"
            />
            <path class="st3" d="M10.5,9.3C10.5,9.3,10.5,9.4,10.5,9.3C10.5,9.4,10.5,9.3,10.5,9.3z" />
            <path class="st3" d="M6.8,8.4C6.8,8.5,6.8,8.5,6.8,8.4C6.8,8.5,6.8,8.5,6.8,8.4C6.8,8.5,6.8,8.5,6.8,8.4z" />
            <path
              class="st3"
              d="M6.8,8.1C6.8,8.1,6.8,8,6.8,8.1C6.8,8,6.8,8.1,6.8,8.1C6.8,8.1,6.8,8.1,6.8,8.1c0,0.1,0,0.1,0,0.1
					 c0,0.1,0,0.1,0,0.2v0c0,0,0-0.1,0-0.1C6.8,8.2,6.8,8.2,6.8,8.1C6.8,8.1,6.8,8.1,6.8,8.1z"
            />
            <path class="st3" d="M6.9,7.8C6.9,7.9,6.9,7.9,6.9,7.8c0,0.1,0,0.2,0,0.2C6.8,8,6.9,7.9,6.9,7.8C6.9,7.9,6.9,7.9,6.9,7.8z" />
            <path class="st3" d="M6.8,8.8L6.8,8.8C6.8,8.8,6.8,8.8,6.8,8.8C6.8,8.8,6.8,8.8,6.8,8.8z" />
            <path class="st3" d="M6.8,9C6.8,9,6.8,9,6.8,9c0-0.1,0-0.1,0-0.1C6.8,8.9,6.8,9,6.8,9C6.8,9,6.8,9,6.8,9C6.8,9,6.8,9,6.8,9z" />
            <path class="st3" d="M6.8,8.6L6.8,8.6C6.8,8.7,6.8,8.7,6.8,8.6C6.8,8.7,6.8,8.7,6.8,8.6z" />
            <path
              class="st3"
              d="M6.8,9.2C6.8,9.1,6.8,9.1,6.8,9.2C6.8,9.1,6.8,9.1,6.8,9.2C6.8,9.1,6.8,9.1,6.8,9.2C6.8,9.1,6.8,9.1,6.8,9.2z
					 "
            />
            <path class="st3" d="M6.9,9.7c0,0,0-0.1,0-0.1c0,0,0,0,0,0C6.9,9.6,6.9,9.6,6.9,9.7z" />
            <path class="st3" d="M7,10.2C7,10.2,7,10.2,7,10.2c0-0.1,0-0.1,0-0.1C7,10.1,7,10.1,7,10.2C7,10.2,7,10.2,7,10.2z" />
            <path
              class="st3"
              d="M11.3,2.7C11.3,2.8,11.2,2.8,11.3,2.7C11.2,2.8,11.3,2.8,11.3,2.7c0,0,0.1-0.1,0.1-0.1
					 C11.3,2.7,11.3,2.7,11.3,2.7z"
            />
            <path
              class="st3"
              d="M10.6,9.5C10.6,9.5,10.6,9.5,10.6,9.5c0-0.1,0-0.1,0-0.1C10.6,9.4,10.6,9.4,10.6,9.5
					 C10.6,9.5,10.6,9.5,10.6,9.5z"
            />
            <path class="st3" d="M8.7,10.6c0.1,0.1,0.2,0.2,0.3,0.1C8.9,10.7,8.8,10.7,8.7,10.6z" />
            <polygon class="st3" points="8.7,10.6 8.7,10.6 8.7,10.6 		" />
            <path
              class="st3"
              d="M8.9,7.6C8.9,7.6,8.8,7.6,8.9,7.6C8.7,7.8,8.7,7.8,8.6,7.9C8.7,7.8,8.7,7.8,8.9,7.6C8.8,7.6,8.9,7.6,8.9,7.6z
					 "
            />
            <path
              class="st3"
              d="M9.2,7.7C9.2,7.7,9.2,7.7,9.2,7.7C9.2,7.6,9.2,7.6,9.2,7.7C9.2,7.6,9.2,7.6,9.2,7.7C9.2,7.7,9.2,7.7,9.2,7.7z
					 "
            />
            <path class="st3" d="M8.4,10.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0C8.4,10.2,8.4,10.2,8.4,10.1z" />
            <path class="st3" d="M7.4,9c0,0.1,0.1,0.1,0.2,0.2c0.2,0.1,0.4,0.3,0.5,0.5C7.9,9.6,7.7,9.4,7.5,9.3C7.4,9.2,7.4,9.1,7.4,9z" />
            <path class="st3" d="M4.1,10.4C4.1,10.4,4,10.4,4.1,10.4C4,10.4,4,10.4,4.1,10.4C4,10.4,4.1,10.4,4.1,10.4z" />
            <path
              class="st3"
              d="M5,11.6C5,11.6,5,11.6,5,11.6C5,11.6,5,11.6,5,11.6C5,11.6,5,11.6,5,11.6C5,11.6,4.9,11.6,5,11.6
					 C4.9,11.6,5,11.6,5,11.6C5,11.6,5,11.6,5,11.6C5,11.6,5,11.6,5,11.6z"
            />
            <path class="st3" d="M5,11.5C5,11.5,5,11.6,5,11.5C5,11.6,5,11.6,5,11.5C5,11.6,5,11.6,5,11.5C5,11.6,5,11.5,5,11.5z" />
            <path class="st3" d="M6.2,13c-0.1,0-0.1-0.1-0.2-0.1C6.1,12.9,6.2,13,6.2,13z" />
            <path class="st3" d="M10.6,2.5C10.6,2.5,10.6,2.6,10.6,2.5L10.6,2.5C10.6,2.6,10.6,2.5,10.6,2.5z" />
            <polygon class="st3" points="10.5,2.8 10.5,2.8 10.5,2.8 10.5,2.7 10.5,2.7 10.5,2.8 		" />
            <path
              class="st4"
              d="M24.4,23c0,0.1-0.1,0.2-0.2,0.2c0-0.1,0-0.1,0-0.2c0-0.4-0.2-0.6-0.6-0.6c-0.1,0-0.3,0-0.4,0
					 c-0.1,0-0.2,0-0.2-0.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.3,0-0.5c0-0.1,0.2-0.2,0.3-0.3c0.6-0.5,0.6-1.4,0.1-2
					 c-0.5-0.6-1.4-0.6-2-0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.3-0.1-0.6-0.1-1-0.1c-1,0-1.9,0-2.9,0
					 c-0.2,0-0.3,0-0.4-0.2c0-0.1,0.1-0.1,0.2-0.1c1.3-0.7,2.1-1.8,2.5-3.2c0-0.1,0.1-0.2,0.2-0.3c0.5-0.2,0.9-0.6,1.2-1.2
					 c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.1-0.3-0.1-0.4c0,0,0,0-0.1,0c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.3
					 c0.1-0.3,0-0.5-0.3-0.4c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.3-0.5,0.4-0.7
					 c0-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.3,0.1-0.7,0.2-1,0.2c-0.1,0-0.1,0-0.2,0c-0.5,0-0.6,0-0.9-0.4
					 c-0.1-0.1,0-0.2,0-0.3c0.1-1,0.1-2-0.2-2.9c-0.4-1.1-1-2.1-1.8-3c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0-0.1c0.3,0,0.6,0.1,0.9,0.2
					 c0.2,0,0.4,0.1,0.5,0.1c0.2,0.1,0.2,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3,0,0.6,0.3,0.8c0.1,0.1,0.3,0.1,0.4,0.2
					 c0.3,0.1,0.4,0.3,0.5,0.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.4-0.1,0.7,0.2,0.9c0.1,0.1,0.2,0.2,0.4,0.2
					 c0.5,0.2,0.9,0.5,1.3,0.8c0,0,0.1,0,0.1,0.1c0.3,0.2,0.7,0.3,1,0.1c0.2-0.1,0.4-0.1,0.6,0.1c0.2,0.2,0.5,0.3,0.8,0.5
					 c0.3,0.2,0.4,0.4,0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.2,0.4,0.4,0.5
					 c0.2,0.1,0.5,0.3,0.7,0.5c0.5,0.3,0.8,0.3,1.2-0.2c0.2-0.2,0.2-0.2,0.4-0.1c0.3,0.2,0.4,0.4,0.5,0.7C26.8,17,26.3,20.2,24.4,23z"
            />
            <path
              class="st5"
              d="M20.7,20.4C20.7,20.4,20.7,20.4,20.7,20.4C20.7,20.4,20.7,20.4,20.7,20.4L20.7,20.4z M20.7,20.4
					 C20.7,20.4,20.7,20.4,20.7,20.4C20.7,20.4,20.7,20.4,20.7,20.4L20.7,20.4z M20.6,20.4C20.6,20.4,20.6,20.4,20.6,20.4
					 C20.6,20.4,20.6,20.4,20.6,20.4L20.6,20.4z M8.5,20.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c-0.1,0-0.1,0-0.2,0
					 C8.7,20.8,8.6,20.8,8.5,20.8z M5.7,21.6C5.7,21.6,5.7,21.5,5.7,21.6C5.7,21.5,5.7,21.5,5.7,21.6C5.7,21.6,5.7,21.6,5.7,21.6z
						M5.7,21.6C5.7,21.6,5.7,21.6,5.7,21.6C5.7,21.6,5.7,21.6,5.7,21.6C5.7,21.6,5.7,21.6,5.7,21.6z M22.4,21.7c0-1.1-0.9-2.1-2.1-2.1
					 c-1,0-1.9,0-2.9,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.1,0-0.2,0.1c-0.1,0.3-0.3,0.4-0.7,0.3
					 c-0.2-0.1-0.4-0.1-0.7,0c-0.6,0.1-1.1-0.3-1.3-0.8c-0.1-0.2-0.1-0.2-0.3-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1
					 c0,0.1,0,0.1-0.1,0.2c-0.2,0.5-0.8,0.8-1.4,0.7c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.4,0-0.5-0.3
					 c0-0.1-0.1-0.1-0.2-0.1c-0.3,0-0.4-0.2-0.6-0.4c-0.1-0.1-0.1-0.2-0.3-0.2c-0.4,0-0.7,0-1.1,0v0c-0.3,0-0.7,0-1,0
					 c-0.1,0-0.2,0-0.3,0c-1,0.2-1.8,1.1-1.8,2.1c0,0.4,0,0.9,0,1.4c0,0.3,0,0.3,0.3,0.3c0.6,0,1.2,0,1.8,0c0.3,0,0.3,0,0.3-0.3V23
					 c0-0.4,0.1-0.4,0.4-0.4c0.6,0,1.3,0,1.9,0c0.4,0,0.6,0,0.6,0.6c0,0.2,0,0.2,0.2,0.2c0.5,0,0.9,0,1.4,0c0.6,0,1.2,0,1.8,0
					 c1,0,2,0,3,0c0.4,0,0.4,0,0.4-0.4c0-0.3,0.1-0.4,0.4-0.4c0.7,0,1.4,0,2.1,0h0c0.3,0,0.4,0.1,0.4,0.4c0,0.1,0,0.1,0,0.2
					 c0,0.2,0,0.2,0.2,0.2c0.6,0,1.3,0,1.9,0c0.2,0,0.2,0,0.2-0.2C22.4,22.6,22.4,22.1,22.4,21.7z M7,20.8C7,20.8,7,20.8,7,20.8
					 C6.9,20.8,6.9,20.8,7,20.8c-0.1,0-0.1,0.1-0.1,0.1h0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
					 C5.9,21.1,5.9,21,6,21c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2
					 c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					 c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0C8,20,8,20,8,20c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
					 c0,0,0,0,0,0h0c0.1,0,0.1,0,0.2,0l0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
					 c-0.2,0-0.3,0-0.5,0c0,0-0.1,0-0.1,0c-0.3,0-0.6,0-0.9,0.1c-0.1,0-0.1,0-0.2,0.1L7,20.8C7,20.8,7,20.8,7,20.8z M14.1,21.2
					 C14.1,21.2,14.1,21.2,14.1,21.2C14,21.2,14,21.2,14.1,21.2C14,21.2,14,21.2,14.1,21.2c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1
					 c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
					 c-0.9,0.1-1.8,0.1-2.6,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.6,0-1.1,0-1.7-0.1c-0.2,0-0.3,0-0.5-0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.1,0.4-0.1,0.6-0.1
					 c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0
					 c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
					 c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0C14,21,14,21,14,21C14,21,14.1,21.1,14.1,21.2C14.1,21.1,14.1,21.1,14.1,21.2z M19.3,20.5h-1.6
					 c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.2v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.2,0.3-0.2h1.6c0.1,0,0.1,0,0.2,0.1
					 c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2C19.4,20.5,19.4,20.5,19.3,20.5z M20.7,20.3C20.7,20.3,20.7,20.4,20.7,20.3
					 C20.7,20.4,20.7,20.4,20.7,20.3C20.7,20.4,20.7,20.4,20.7,20.3C20.7,20.4,20.7,20.4,20.7,20.3L20.7,20.3c0,0.1,0,0.1,0,0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0.1C20.7,20.2,20.7,20.3,20.7,20.3C20.7,20.3,20.7,20.3,20.7,20.3C20.7,20.3,20.7,20.3,20.7,20.3z M8.7,20.8
					 c-0.1,0-0.1,0-0.2,0C8.6,20.8,8.7,20.8,8.7,20.8c0.1,0,0.1,0,0.2,0C8.8,20.8,8.8,20.8,8.7,20.8z M20.6,20.4L20.6,20.4
					 C20.6,20.4,20.6,20.4,20.6,20.4C20.6,20.4,20.6,20.4,20.6,20.4z M20.7,20.4L20.7,20.4C20.7,20.4,20.7,20.4,20.7,20.4
					 C20.7,20.4,20.7,20.4,20.7,20.4z M20.7,20.4L20.7,20.4C20.7,20.4,20.7,20.4,20.7,20.4C20.7,20.4,20.7,20.4,20.7,20.4z"
            />
            <path
              class="st0"
              d="M22.4,25.3c0,0.1,0,0.1,0,0.2c-0.3,0-0.3,0-0.4,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0.4-0.2,0.8-0.5,1.2c-0.1,0.1-0.2,0.2-0.2,0.3h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
					 c-0.4,0.3-0.8,0.3-1.2,0.3c-4,0-8,0-12,0c-0.2,0-0.4,0-0.6-0.1c-0.5-0.1-0.9-0.3-1.2-0.7c-0.4-0.4-0.6-0.9-0.6-1.4c0,0,0,0,0,0v0
					 c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0-0.6,0-0.5,0.5-0.5c0.6,0,1.1,0,1.7,0c0.2,0,0.2,0,0.2,0.3
					 c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0.1v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
					 c0.4,0,0.7,0,1.1,0c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2,0.2-0.2
					 c0.1,0,0.1,0,0.2,0c2,0,4,0,5.9,0c0.1,0,0.1,0,0.2,0c0.3,0,0.3,0,0.3,0.3c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.8,0,1.5,0,2.3,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0.2-0.2c0.7,0,1.3,0,2,0C22.4,25.1,22.4,25.2,22.4,25.3z"
            />
            <path
              class="st6"
              d="M9.7,18.7c0,0.2-0.2,0.3-0.3,0.2c-0.7,0-1.4,0-2.1,0c-1.4,0-2.6,1.1-2.7,2.4c0,0.3,0,0.7,0,1
					 c0,0.2,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.4-0.1-0.5-0.3c-0.8-1.4-1.2-2.8-1.4-4.4C2,17,1.9,16.1,2,15.2
					 c0.1-1.6,0.5-3,1.1-4.5c0.1-0.2,0.1-0.2,0.3-0.1c0.3,0,0.4,0.2,0.5,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.4,0.5,0.7,0.7
					 C4.9,12.2,5,12.3,5,12.4c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.2-0.4,0.3-0.2,0.6c0.3,0.7,0.8,1.2,1.6,1.3c0.2,0,0.3,0.1,0.3,0.3
					 c0.3,1.1,0.8,2,1.6,2.8C8.8,18.2,9.2,18.4,9.7,18.7z"
            />
            <path
              class="st2"
              d="M25.9,12.4C25.9,12.4,25.9,12.4,25.9,12.4c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.3-0.1-0.5,0
					 c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.2-0.7c0-0.1,0-0.2,0-0.4
					 c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.2
					 c-0.2-0.1-0.4-0.2-0.5-0.4c-0.3-0.2-0.6-0.2-0.9-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0h0c0,0,0,0,0,0c0,0,0,0-0.1,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-0.3-0.2-0.7-0.4-1-0.6C19.1,7.7,19,7.7,19,7.6
					 c-0.1-0.1,0-0.2,0.1-0.4c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1c0.1-0.2,0.1-0.5,0-0.7c0,0,0-0.1-0.1-0.1
					 c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1
					 c0,0-0.1-0.1-0.1-0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0
					 c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.1-0.2,0.4-0.1c0.7,0.3,1.4,0.6,2.1,1c0,0,0,0,0,0c0,0,0,0,0,0c1.1,0.7,2,1.5,2.9,2.5
					 c0.8,1,1.5,2.1,1.9,3.2c0.1,0.2,0.2,0.4,0.2,0.7C25.9,12.2,25.9,12.3,25.9,12.4z"
            />
            <path
              class="st7"
              d="M22.4,25.9c0-0.1,0-0.3,0-0.4c-0.3,0-0.3,0-0.4,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0.4-0.2,0.8-0.5,1.2c-0.1,0.1-0.2,0.2-0.2,0.3h0c0,0,0,0,0,0c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3
					 c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
					 c-0.4,0.3-0.8,0.3-1.2,0.3c-4,0-8,0-12,0c-0.2,0-0.4,0-0.6-0.1c-0.5-0.1-0.9-0.3-1.2-0.7c-0.4-0.4-0.6-0.9-0.6-1.4c0,0,0,0,0,0v0
					 c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0.1,0,0.2,0,0.3c0,0.4,0,0.8,0,1.1c0,0.2,0.1,0.5,0.2,0.7
					 c0.4,0.8,1,1.2,1.9,1.2c4.4,0,8.7,0,13.1,0c0.5,0,1-0.2,1.4-0.5c0.4-0.3,0.7-0.8,0.7-1.3C22.4,26.8,22.4,26.3,22.4,25.9z
						M20.9,28.4C20.9,28.4,20.9,28.4,20.9,28.4L20.9,28.4C20.8,28.5,20.8,28.5,20.9,28.4c-0.1,0.1-0.1,0.1-0.1,0.1
					 c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l0,0c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0C20.9,28.3,20.9,28.3,20.9,28.4C20.9,28.3,20.9,28.3,20.9,28.4C20.9,28.4,20.9,28.4,20.9,28.4z"
            />
            <path
              class="st8"
              d="M18.9,15.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.3-0.3,0.5-0.6,0.8c-0.1,0.1-0.2,0.2-0.3,0.3
					 c-0.4,0.4-0.8,0.6-1.3,0.8c-0.4,0.2-0.8,0.3-1.2,0.4c0,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
					 c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
					 c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
					 c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
					 c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0v0
					 c0,0-0.1-0.1-0.1-0.1h0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.4
					 c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
					 c0.1-0.1,0.2,0,0.2,0.1c0,0,0,0,0.1,0.1h0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
					 c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0.1,1.2,0.1,1.8,0c1-0.2,1.9-0.5,2.7-1c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1
					 c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0,0.1-0.1h0c0,0,0.1-0.1,0.1-0.1v0c0,0,0,0,0,0c0,0,0,0,0-0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1
					 c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.3-0.1-0.5v0l0,0l0,0c0,0,0,0,0,0
					 c0.1,0.1,0.1,0.2,0.2,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0C18.8,15.3,18.8,15.3,18.9,15.3z"
            />
            <path
              class="st9"
              d="M6.3,9.4c0.1,0.4,0.1,0.9,0.3,1.4c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2
					 c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1
					 c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0-0.1c-0.1-0.2-0.2-0.3-0.2-0.5
					 c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0.5-0.9,1-1.6,1.7-2.3
					 c0.3-0.3,0.6-0.7,1-1c0.2-0.1,0.3-0.3,0.5-0.4C6.4,6.9,6.3,7.4,6.3,8C6.2,8.4,6.2,8.9,6.3,9.4z"
            />
            <path
              class="st8"
              d="M9.6,4.5C9.5,4.5,9.5,4.5,9.6,4.5c-0.1,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 v0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
					 C8.4,5.2,8.3,5.2,8.3,5.3c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0v0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0C7.5,6.8,7.2,7.1,7.2,7.4
					 c0,0.2,0.1,0.3,0.1,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0.1,0,0.1C7.2,8.4,7.2,8.8,7.2,9.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
					 c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
					 c0.2,0.4,0.4,0.9,0.7,1.2c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.3v0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0v0
					 c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0-0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0v0c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0
					 c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4C7,7.2,7,7.1,7,7.1
					 c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4C7.4,6,7.6,5.7,7.8,5.4c0.3-0.4,0.6-0.7,0.9-1C8.8,4.3,8.9,4.2,9,4.1
					 c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0.1,0.1,0.1,0.2,0.2,0.4C9.5,4.4,9.5,4.4,9.6,4.5z"
            />
            <path
              class="st10"
              d="M4.5,25.3c0,0.2,0,0.2-0.2,0.2c-0.4,0-0.4,0.1-0.4-0.4c0-0.3,0-0.5,0-0.8h0c0-0.3,0-0.6,0-0.9
					 c0-0.2,0-0.2,0.2-0.2c0.4,0,0.4-0.1,0.4,0.4C4.5,24.1,4.5,24.7,4.5,25.3z"
            />
            <path
              class="st2"
              d="M23.3,19.9C23.3,19.9,23.3,20,23.3,19.9c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0
					 c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
					 c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1
					 c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1s0,0,0,0C22,19,22.2,19,22.4,19h0c0.1,0,0.2,0,0.3,0c0.4,0.1,0.6,0.4,0.6,0.8
					 C23.3,19.8,23.3,19.9,23.3,19.9z"
            />
            <path
              class="st8"
              d="M19.7,14.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
					 c-0.3,0-0.6-0.1-0.8-0.2c-0.5-0.2-0.8-0.5-1.1-0.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0c0,0,0,0,0,0h0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0.2,0.1,0.3,0.2,0.4l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0
					 C19.6,14.4,19.6,14.4,19.7,14.4z"
            />
            <path
              class="st10"
              d="M23.6,25.3c0,0.1,0,0.1-0.1,0.2c-0.4,0-0.4,0-0.4-0.3v-0.8c0,0,0,0,0,0c0-0.3,0-0.7,0-1
					 c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.2C23.6,24,23.6,24.7,23.6,25.3z"
            />
            <path
              class="st10"
              d="M16.3,19.6C16.3,19.6,16.3,19.6,16.3,19.6C16.3,19.6,16.3,19.6,16.3,19.6C16.3,19.6,16.2,19.6,16.3,19.6
					 c-0.2,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2,0c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1
					 c0,0,0,0,0,0v0c0,0,0,0,0,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0v0h0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2v0c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2
					 c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0c0.4-0.1,0.9-0.1,1.3-0.3c0.1,0,0.3,0.1,0.3,0.2c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0.1,0,0.1C16.5,19.4,16.4,19.5,16.3,19.6z"
            />
            <path
              class="st10"
              d="M6.7,12.6c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c-0.3,0-0.7-0.3-0.9-0.6
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.2
					 c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0h0
					 C6.3,13,6.3,13,6.2,13c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.1-0.1-0.2
					 c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0C6.7,12.4,6.7,12.5,6.7,12.6z"
            />
            <path
              class="st10"
              d="M12.3,19.6c-0.1,0.2-0.2,0.3-0.4,0.3c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2,0c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0-0.1,0c0,0,0,0,0-0.1
					 c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.2-0.1-0.2-0.3-0.2c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.1-0.3
					 c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0.5,0.2,1.1,0.3,1.6,0.3C12.4,19.3,12.4,19.4,12.3,19.6z"
            />
            <path
              class="st8"
              d="M13.2,1.5C13.2,1.5,13.2,1.5,13.2,1.5C13.2,1.5,13.1,1.5,13.2,1.5C13.1,1.5,13.1,1.5,13.2,1.5
					 c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.8,0.2-1.1,0.5c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0c0,0-0.1,0.1-0.1,0.1
					 c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C11,2.9,11,2.8,11,2.7c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.4-0.5
					 c0.3-0.3,0.7-0.5,1.2-0.6c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0C13.1,1.4,13.2,1.4,13.2,1.5z"
            />
            <path
              class="st8"
              d="M14.6,2.4C14.6,2.4,14.6,2.4,14.6,2.4C14.6,2.5,14.6,2.5,14.6,2.4c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0
					 c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1
					 c-0.1,0-0.2,0.1-0.3,0.1h0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
					 c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1
					 c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0.3-0.1,0.5-0.2,0.8-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					 C14.7,2.3,14.7,2.3,14.6,2.4z"
            />
            <path
              class="st8"
              d="M10.6,2.2C10.6,2.2,10.6,2.3,10.6,2.2C10.6,2.3,10.6,2.3,10.6,2.2c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1
					 c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.3,0-0.5,0.1-0.6,0.4c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.1c0-0.2,0.1-0.5,0.2-0.7
					 c0.1-0.2,0.3-0.4,0.5-0.5C10.6,2.1,10.6,2.1,10.6,2.2C10.6,2.2,10.6,2.2,10.6,2.2z"
            />
            <path
              class="st10"
              d="M9.2,24.8c0,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3C9.1,24.5,9.2,24.6,9.2,24.8z
					 "
            />
            <path
              class="st10"
              d="M6.9,11.5L6.9,11.5C6.9,11.5,6.9,11.6,6.9,11.5c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1
					 c-0.2-0.1-0.3-0.2-0.4-0.4c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.2c0,0,0,0,0.1,0c0.2,0.1,0.3,0.2,0.5,0.2
					 C6.9,11.4,6.9,11.4,6.9,11.5C6.9,11.5,6.9,11.5,6.9,11.5z"
            />
            <path class="st11" d="M18.8,24.9c0,0.1-0.1,0.2-0.2,0.2C18.7,25,18.8,24.9,18.8,24.9z" />
            <path class="st11" d="M18.9,24.7c0-0.1-0.1-0.2-0.3-0.2c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0C18.7,24.5,18.8,24.6,18.9,24.7z" />
            <path
              class="st8"
              d="M10.5,2.7C10.5,2.7,10.5,2.8,10.5,2.7C10.5,2.8,10.5,2.8,10.5,2.7C10.5,2.8,10.5,2.8,10.5,2.7L10.5,2.7
					 C10.5,2.8,10.5,2.8,10.5,2.7C10.5,2.7,10.5,2.7,10.5,2.7L10.5,2.7C10.5,2.7,10.5,2.7,10.5,2.7C10.5,2.7,10.5,2.7,10.5,2.7
					 C10.5,2.7,10.5,2.7,10.5,2.7C10.5,2.7,10.5,2.7,10.5,2.7C10.5,2.7,10.5,2.7,10.5,2.7z"
            />
            <path
              class="st8"
              d="M9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5C9.6,4.5,9.6,4.5,9.6,4.5z
					 "
            />
            <polygon class="st8" points="9.6,4.5 9.6,4.5 9.6,4.5 		" />
            <path
              class="st12"
              d="M19.6,13c0.2,0,0.4,0,0.5,0c0.3-0.1,0.5-0.2,0.7-0.5c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.5,0-0.8,0
					 c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1,0,0.2-0.1c0.3-0.2,0.5-0.4,0.7-0.6c-0.1,0-0.1,0-0.2,0
					 c-0.6,0.1-1.2,0.2-1.7,0c-0.5-0.1-1.1-0.2-1.7-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0.3-0.1,0.7-0.1,1-0.1
					 c0.1,0,0.2,0,0.2,0c-0.1-0.3-0.1-0.3-0.1-0.6c0.2-0.9,0.1-1.8-0.2-2.7C17.1,6,16.2,4.8,14.8,4c-0.1-0.1-0.2-0.1-0.3,0
					 C14.4,4,14.3,4,14.2,3.9c-0.1-0.1-0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0
					 c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0,0-0.1-0.1-0.1-0.1
					 c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
					 c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1h0
					 c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c-0.1-0.1,0-0.2,0.1-0.3
					 C12.8,2.1,12.9,2,13,1.9c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.4,0.1-0.8,0.2-1.1,0.5c0,0-0.1,0.1-0.1,0.1
					 c0,0-0.1,0.1-0.1,0.1v0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0
					 c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 C10.8,3,10.7,3.1,10.6,3c0,0,0,0,0,0c0,0,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0-0.1,0-0.1h0c-0.3,0-0.5,0.1-0.6,0.4c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3
					 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1v0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0l0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0h0c0,0-0.1,0-0.1,0
					 c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0h0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1
					 c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1C8.4,5.2,8.3,5.2,8.3,5.3c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.2
					 c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0
					 v0c0,0,0,0,0,0.1c0,0,0,0,0,0.1v0c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7C7.7,8.1,7.5,8,7.4,7.9
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0.1,0,0.1C7.2,8.4,7.2,8.8,7.2,9.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
					 c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0.2,0.4,0.4,0.9,0.7,1.2c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.5-0.2
					 c-0.2-0.1-0.2-0.1-0.3,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
					 c0,0.1,0,0.2,0,0.3c0,0.8,0.1,1.5,0.4,2.3c0.6,1.5,1.6,2.5,3.2,2.9c0.8,0.2,1.5,0.3,2.3,0.3c0.3,0,1-0.1,1.4-0.1c0,0-0.1,0-0.1,0
					 c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
					 c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0
					 c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
					 c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1
					 c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1h0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
					 c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2,0,0.2,0.1c0,0,0,0,0.1,0.1h0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0
					 c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0.1,1.2,0.1,1.8,0c1-0.2,1.9-0.5,2.7-1
					 c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0,0.1-0.1h0
					 c0,0,0.1-0.1,0.1-0.1v0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0
					 s0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c-0.1-0.2-0.1-0.3-0.1-0.5v0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2,0-0.3c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0h0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0.1,0.4,0.1,0.6,0.2
					 c0.4,0,0.7,0,1.1-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.2-0.3C19.4,13,19.5,13,19.6,13z M10.6,9.5
					 C10.6,9.5,10.6,9.5,10.6,9.5C10.6,9.5,10.6,9.5,10.6,9.5C10.6,9.5,10.6,9.5,10.6,9.5C10.6,9.5,10.6,9.5,10.6,9.5
					 C10.6,9.5,10.6,9.6,10.6,9.5C10.5,9.6,10.5,9.6,10.6,9.5c0,0.1,0,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1
					 c-0.4,0.2-0.7,0.5-1.1,0.8h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
					 c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1C8.2,9.9,8.1,9.8,8.1,9.7
					 C7.9,9.6,7.7,9.4,7.5,9.3c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.2-0.2c0.4-0.3,0.7-0.5,1-0.9c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0
					 c0,0,0,0,0,0C9,7.5,9,7.5,9.1,7.6c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
					 c0,0,0,0.1,0.1,0.1c0.3,0.5,0.7,1,1.1,1.4c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 C10.6,9.4,10.6,9.4,10.6,9.5C10.6,9.4,10.6,9.4,10.6,9.5C10.6,9.4,10.6,9.4,10.6,9.5C10.6,9.4,10.6,9.4,10.6,9.5
					 C10.6,9.4,10.6,9.4,10.6,9.5C10.6,9.4,10.6,9.5,10.6,9.5C10.6,9.5,10.6,9.5,10.6,9.5L10.6,9.5z M11.6,8.8c-0.5,0-0.9-0.4-0.9-0.9
					 c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C12.5,8.4,12.1,8.8,11.6,8.8z"
            />
            <path
              class="st1"
              d="M10.6,9.4C10.6,9.4,10.6,9.4,10.6,9.4C10.6,9.4,10.6,9.4,10.6,9.4C10.6,9.4,10.6,9.4,10.6,9.4
					 C10.6,9.4,10.6,9.4,10.6,9.4C10.6,9.4,10.6,9.4,10.6,9.4C10.5,9.4,10.5,9.4,10.6,9.4c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0C10,8.8,9.6,8.4,9.3,7.8c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0C9,7.5,9,7.5,8.9,7.6c0,0-0.1,0.1-0.1,0.1C8.7,7.8,8.7,7.8,8.6,7.9c-0.3,0.3-0.7,0.6-1,0.9
					 C7.4,8.9,7.4,9,7.4,9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1
					 c0.2,0.1,0.4,0.3,0.5,0.5C8.1,9.8,8.2,9.9,8.3,10c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.2
					 c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0.3-0.3,0.7-0.6,1.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
					 c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0C10.6,9.6,10.6,9.5,10.6,9.4C10.6,9.5,10.6,9.5,10.6,9.4
					 C10.6,9.5,10.6,9.5,10.6,9.4C10.6,9.5,10.6,9.5,10.6,9.4L10.6,9.4C10.6,9.5,10.6,9.5,10.6,9.4z M9,10.1c-0.1,0-0.1,0-0.1,0
					 C8.8,9.9,8.6,9.8,8.5,9.6c0.3,0,0.7,0.1,1,0.1C9.4,9.9,9.2,10,9,10.1z M8,9c0.4-0.3,0.7-0.6,1-0.9c0.2,0.4,0.5,0.8,0.9,1.1
					 C9.2,9.2,8.6,9.2,8,9z"
            />
            <path
              class="st13"
              d="M14,20.9C13.9,20.9,13.9,20.9,14,20.9c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
					 c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0-0.8,0c-0.4,0-0.7,0-1.1,0c-0.4,0-0.9,0-1.3,0.1
					 c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0.1,0,0.1
					 c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.1c0.2,0.1,0.3,0.1,0.5,0.1c0.6,0.1,1.1,0.1,1.7,0.1c0.1,0,0.2,0,0.3,0c0.9,0,1.8,0,2.6-0.1
					 c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1-0.1,0.1-0.1C14.1,21.2,14.1,21.1,14,20.9
					 C14,21,14,21,14,20.9z"
            />
            <path
              class="st1"
              d="M6.2,21.7c0.2-0.5,0.5-0.8,1-0.9c0.1,0,0.1,0,0.2-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0,0,0.1,0,0.1,0
					 c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.3-0.3
					 c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.5-1,0.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1
					 c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0C5.9,21.9,6.1,21.9,6.2,21.7z"
            />
            <path class="st14" d="M17.4,20.3C17.4,20.3,17.4,20.3,17.4,20.3c0,0.1,0.1,0.2,0.1,0.2C17.4,20.5,17.4,20.4,17.4,20.3z" />
            <path
              class="st1"
              d="M20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5
					 C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5
					 C20.4,20.5,20.4,20.5,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1
					 c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2C20.2,20.4,20.3,20.5,20.4,20.5z"
            />
            <path class="st2" d="M8,9c0.6,0.1,1.2,0.2,1.9,0.2C9.5,8.9,9.2,8.6,9,8.1C8.7,8.5,8.4,8.8,8,9z" />
            <path class="st7" d="M8.9,10.1c0,0,0.1,0.1,0.1,0C9.2,10,9.4,9.9,9.5,9.7c-0.4,0-0.7-0.1-1-0.1C8.6,9.8,8.8,9.9,8.9,10.1z" />
            <path
              class="st1"
              d="M21,27.7C21,27.7,21,27.8,21,27.7c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3
					 c0,0,0,0,0,0C21.2,27.5,21,27.6,21,27.7z"
            />
            <path
              class="st11"
              d="M9.8,23.2v0.5c0,0,0,0.1-0.1,0.1H8.3c0,0,0,0,0,0H8.2c0,0-0.1,0-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1L9.8,23.2
					 C9.8,23.2,9.8,23.2,9.8,23.2z"
            />
            <path class="st14" d="M19.3,23.2h-0.1C19.3,23.2,19.3,23.2,19.3,23.2C19.3,23.2,19.3,23.2,19.3,23.2z" />
            <path
              class="st14"
              d="M19.5,23.7C19.5,23.7,19.5,23.7,19.5,23.7l0-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 C19.5,23.4,19.5,23.6,19.5,23.7z"
            />
            <path class="st14" d="M17.8,23.2v0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1C17.8,23.4,17.8,23.3,17.8,23.2z" />
            <path
              class="st11"
              d="M19.5,23.2v0.5c0,0,0,0.1-0.1,0.1h-1.5c0,0-0.1,0-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1L19.5,23.2
					 C19.4,23.2,19.5,23.2,19.5,23.2z"
            />
            <path
              class="st1"
              d="M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20z M14.5,20L14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9
					 c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z
						M8.2,29.8c0.2,0.5,0.8,0.7,1.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1H8.2z M17.8,29.8C17.8,29.8,17.8,29.8,17.8,29.8
					 c0.2,0.4,0.6,0.6,1,0.5c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0-0.1,0-0.1H17.8z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9
					 c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z
						M8.2,29.8c0.2,0.5,0.8,0.7,1.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1H8.2z M17.8,29.8C17.8,29.8,17.8,29.8,17.8,29.8
					 c0.2,0.4,0.6,0.6,1,0.5c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0-0.1,0-0.1H17.8z M14.5,20L14.5,20C14.5,20,14.5,20,14.5,20L14.5,20
					 C14.5,20,14.5,20,14.5,20z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9c0-0.1,0-0.1,0-0.1
					 c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z M14.5,20L14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9
					 c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z
						M6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7L6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7z M14.5,20L14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9
					 C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z M6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7L6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7z
						M23.6,9.8C23.6,9.8,23.6,9.8,23.6,9.8C23.6,9.8,23.6,9.8,23.6,9.8C23.6,9.8,23.6,9.8,23.6,9.8z M20.7,8.7
					 C20.7,8.7,20.7,8.7,20.7,8.7C20.7,8.7,20.7,8.7,20.7,8.7C20.7,8.7,20.7,8.7,20.7,8.7C20.7,8.7,20.7,8.7,20.7,8.7z M18.8,5.5
					 C18.8,5.5,18.8,5.5,18.8,5.5L18.8,5.5C18.8,5.5,18.8,5.5,18.8,5.5z M16.6,13.1L16.6,13.1C16.6,13.1,16.6,13.1,16.6,13.1
					 c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2C16.8,13.4,16.7,13.2,16.6,13.1z M16.4,13.1c0.1,0,0.2,0,0.3,0
					 C16.5,13,16.4,13,16.4,13.1z M16.4,13.7c0-0.1-0.1-0.2-0.2-0.3C16.3,13.5,16.3,13.6,16.4,13.7C16.4,13.7,16.4,13.7,16.4,13.7
					 C16.4,13.8,16.4,13.8,16.4,13.7c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
					 c0,0,0,0,0,0C16.6,14,16.5,13.9,16.4,13.7z M16.3,11.2C16.3,11.2,16.3,11.2,16.3,11.2C16.4,11.2,16.4,11.2,16.3,11.2
					 C16.4,11.2,16.3,11.2,16.3,11.2z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M14.4,19.9
					 C14.4,19.9,14.4,19.9,14.4,19.9L14.4,19.9C14.4,19.9,14.4,19.9,14.4,19.9z M14.3,19.9C14.3,19.9,14.3,19.9,14.3,19.9L14.3,19.9
					 C14.3,19.9,14.3,19.9,14.3,19.9z M10.6,2.9C10.6,3,10.6,3,10.6,2.9C10.6,3,10.6,3,10.6,2.9z M10.5,2.8L10.5,2.8
					 C10.5,2.8,10.5,2.8,10.5,2.8C10.5,2.8,10.5,2.8,10.5,2.8z M10,4.2C10,4.2,10,4.2,10,4.2C10,4.2,10,4.2,10,4.2
					 C10,4.3,10,4.2,10,4.2z M7,10.1L7,10.1C7,10.2,7,10.2,7,10.1C7,10.2,7,10.2,7,10.1z M6.9,9.6C6.9,9.6,6.9,9.6,6.9,9.6
					 c0,0,0,0.1,0,0.1C6.9,9.6,6.9,9.6,6.9,9.6z M6.8,9.1C6.8,9.1,6.8,9.1,6.8,9.1C6.8,9.1,6.8,9.1,6.8,9.1C6.8,9.1,6.8,9.1,6.8,9.1z
						M6.8,9C6.8,9,6.8,9,6.8,9C6.8,9,6.8,9,6.8,9C6.8,9,6.8,9,6.8,9C6.8,9,6.8,9,6.8,9z M6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7L6.8,8.7
					 C6.8,8.7,6.8,8.7,6.8,8.7z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9c0-0.1,0-0.1,0-0.1L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9
					 C6.8,8.9,6.8,8.9,6.8,8.9z M5,11.6L5,11.6C5,11.6,5,11.5,5,11.6C5,11.5,5,11.6,5,11.6z M5,11.6L5,11.6C5,11.6,5,11.6,5,11.6
					 C5,11.6,5,11.6,5,11.6z M6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7L6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9
					 c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z
						M14.5,20L14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20z M6.8,8.7C6.8,8.7,6.8,8.7,6.8,8.7L6.8,8.7
					 C6.8,8.7,6.8,8.7,6.8,8.7z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9
					 C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M14.5,20L14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0
					 C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z M14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M17.8,29.8
					 C17.8,29.8,17.8,29.8,17.8,29.8c0.2,0.4,0.6,0.6,1,0.5c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0-0.1,0-0.1H17.8z M8.2,29.8
					 c0.2,0.5,0.8,0.7,1.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1H8.2z M6.8,8.9C6.8,8.9,6.8,8.8,6.8,8.9c0-0.1,0-0.1,0-0.1
					 c0,0,0,0,0,0C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.8,6.8,8.8,6.8,8.9L6.8,8.9C6.8,8.9,6.8,8.9,6.8,8.9z M14.5,20
					 C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 L14.5,20L14.5,20z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20z
						M14.5,20L14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M19.5,29.8c0,0.4-0.5,0.8-1,0.8c-0.5,0-0.9-0.4-1-0.8h-0.6
					 c0,0,0,0.1,0,0.1c0.1,0.8,0.8,1.4,1.6,1.4c0.8,0,1.5-0.5,1.6-1.3c0,0,0-0.1,0-0.1H19.5z M17.8,29.8C17.8,29.8,17.8,29.8,17.8,29.8
					 c0.2,0.4,0.6,0.6,1,0.5c0.2-0.1,0.3-0.2,0.4-0.4c0,0,0-0.1,0-0.1H17.8z M8.2,29.8c0.2,0.5,0.8,0.7,1.2,0.4
					 c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1H8.2z M9.9,29.8C9.9,29.8,9.9,29.8,9.9,29.8c0,0.2-0.1,0.4-0.3,0.6
					 c-0.2,0.2-0.4,0.2-0.7,0.2c-0.5,0-0.9-0.3-0.9-0.8c0,0,0,0,0-0.1H7.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c0.2,0.5,0.5,0.9,1,1.1
					 c0.9,0.3,1.9-0.3,2.1-1.3c0-0.1,0-0.1,0-0.1H9.9z M8.2,29.8c0.2,0.5,0.8,0.7,1.2,0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1H8.2z
						M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z M14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20C14.5,20,14.5,20,14.5,20
					 C14.5,20,14.5,20,14.5,20z M14.5,20L14.5,20C14.5,20,14.5,20,14.5,20L14.5,20L14.5,20z"
            />
            <path
              class="st1"
              d="M7.3,29.7C7.3,29.7,7.3,29.7,7.3,29.7C7.3,29.7,7.3,29.7,7.3,29.7C7.3,29.8,7.3,29.8,7.3,29.7L7.3,29.7
					 C7.3,29.8,7.3,29.7,7.3,29.7z M18.8,14.2C18.8,14.2,18.8,14.2,18.8,14.2C18.8,14.2,18.8,14.2,18.8,14.2
					 C18.8,14.2,18.8,14.2,18.8,14.2z"
            />
            <path
              class="st1"
              d="M26.9,13.9c-0.5-3-1.9-5.5-4.2-7.5c-1.9-1.7-4.2-2.7-6.8-3c-0.3,0-0.5-0.1-0.8-0.1c0-0.1,0-0.1,0-0.2
					 c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.1-0.2,0-0.3c0.2-0.4,0.1-0.8-0.4-1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1
					 c0-0.4-0.1-0.6-0.5-0.7c-0.2,0-0.4,0-0.6,0c-0.6,0.1-1.1,0.2-1.6,0.6c-0.1,0.1-0.1,0.1-0.2,0c-0.2-0.1-0.4-0.1-0.6,0
					 C10.2,1.5,10,1.6,9.9,1.7C9.5,2.1,9.3,2.6,9.2,3.1c0,0.1-0.1,0.2-0.2,0.3C8.3,3.8,7.7,4.4,7.3,5C7.2,5.2,7.1,5.3,7,5.4
					 c-3.6,2.5-5.4,6-5.6,10.3c0,0.9,0,1.7,0.2,2.6c0.2,1.4,0.7,2.8,1.3,4.1c0.1,0.3,0.4,0.5,0.4,0.8c0.1,0.3,0,0.6,0,0.9
					 c0,0.5,0,1,0,1.5c0,0.4,0.2,0.7,0.7,0.7c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.1,0.1c0,0.2,0,0.4,0,0.7c0,0.5,0.1,1,0.4,1.4
					 c0.5,0.8,1.3,1.4,2.4,1.4h0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h13c1.5,0,2.7-1.2,2.7-2.6c0,0,0,0,0,0
					 c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.2,0.7-0.7c0-0.3,0-0.6,0-0.9
					 c0-0.2,0-0.3,0.1-0.4c0.2-0.3,0.4-0.5,0.6-0.8C26.8,20.5,27.5,17.3,26.9,13.9z M18.2,5C18.2,5,18.2,5,18.2,5C18.2,5,18.2,5,18.2,5
					 c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.2,0.4-0.1c0.7,0.3,1.4,0.6,2.1,1c0,0,0,0,0,0c0,0,0,0,0,0c1.1,0.7,2,1.5,2.9,2.5
					 c0.8,1,1.5,2.1,1.9,3.2c0.1,0.2,0.2,0.4,0.2,0.7c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.3-0.1-0.5,0
					 c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.2-0.7c0-0.1,0-0.2,0-0.4
					 c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0-0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.4-0.2-0.5-0.4
					 c-0.3-0.2-0.6-0.2-0.9-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
					 c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1
					 c-0.3-0.2-0.7-0.4-1-0.6C19.1,7.7,19,7.7,19,7.6c-0.1-0.1,0-0.2,0.1-0.4c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.1
					 c0.1-0.2,0.1-0.5,0-0.7c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1
					 c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C18.2,5.2,18.2,5.1,18.2,5z
						M6.8,8.6C6.8,8.6,6.8,8.6,6.8,8.6L6.8,8.6c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0-0.1,0-0.1
					 c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4C7,7.2,7,7.1,7,7.1c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4C7.4,6,7.6,5.7,7.8,5.4
					 c0.3-0.4,0.6-0.7,0.9-1C8.8,4.3,8.9,4.2,9,4.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0.1,0.1,0.1,0.2,0.2,0.4
					 c0,0,0.1,0.1,0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1
					 c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1v0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
					 c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.5c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0
					 c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0.1,0.1,0.2,0,0.3-0.1C11,2.9,11,2.8,11,2.7c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.4-0.5
					 c0.3-0.3,0.7-0.5,1.2-0.6c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1
					 c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0.3-0.1,0.5-0.2,0.8-0.2
					 c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0
					 c-0.2,0.2-0.4,0.2-0.6,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0
					 c0.1,0,0.2,0,0.4,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2-0.1,0.3C14.3,4,14.4,4,14.5,3.9c0.1-0.1,0.2,0,0.3,0
					 c1.4,0.8,2.4,2,2.9,3.5c0.3,0.9,0.3,1.8,0.2,2.7c-0.1,0.3,0,0.3,0.1,0.6c-0.1,0-0.2,0-0.2,0c-0.4-0.1-0.7,0-1,0.1
					 c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.6-0.1,1.1-0.1,1.7,0.1c0.6,0.1,1.2,0.1,1.7,0
					 c0.1,0,0.1,0,0.2,0c-0.2,0.3-0.4,0.5-0.7,0.6c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2
					 c0.3,0,0.5,0.1,0.8,0c0.2,0,0.4-0.1,0.6-0.1c-0.2,0.3-0.4,0.4-0.7,0.5c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0.1
					 c0,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.3,0.1c-0.4,0.2-0.7,0.2-1.1,0.2c-0.2,0-0.4-0.1-0.6-0.2
					 c-0.1-0.1-0.3-0.1-0.4,0.1c0,0,0,0,0,0c0,0.2,0.1,0.3,0.2,0.4l0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
					 c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
					 c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.1-0.8-0.2
					 c-0.5-0.2-0.8-0.5-1.1-0.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3,0c0,0,0,0-0.1,0
					 c-0.1,0.1-0.1,0.2,0,0.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1
					 c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
					 c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
					 c0,0,0.1,0,0.1,0c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.3-0.3,0.5-0.6,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.4-0.8,0.6-1.3,0.8
					 c-0.4,0.2-0.8,0.3-1.2,0.4c0,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1c-0.5,0.1-1.1,0.1-1.4,0.1c-0.8,0-1.6-0.1-2.3-0.3
					 C9.3,18,8.2,17,7.7,15.5c-0.3-0.7-0.4-1.5-0.4-2.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
					 c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.2,0.3-0.1C7.9,12,8,12.1,8.2,12.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.2-0.2
					 c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0-0.2-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
					 c-0.1-0.1-0.1-0.2-0.2-0.3v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
					 c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
					 c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0-0.1c0,0,0,0,0,0C6.8,8.7,6.8,8.7,6.8,8.6C6.8,8.7,6.8,8.7,6.8,8.6z M17.1,24.5h-6.7
					 c0,0-0.1,0-0.1-0.1V24c0,0,0-0.1,0.1-0.1h6.7c0,0,0.1,0,0.1,0.1L17.1,24.5C17.2,24.5,17.2,24.5,17.1,24.5z M19.5,23.3
					 C19.5,23.3,19.5,23.3,19.5,23.3C19.5,23.3,19.5,23.3,19.5,23.3C19.5,23.3,19.5,23.3,19.5,23.3c0,0.2,0,0.3,0,0.4c0,0,0,0,0,0.1v0
					 c0,0,0,0.1-0.1,0.1h-1.5c0,0-0.1,0-0.1-0.1v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.2,0-0.3,0-0.4v0
					 c0,0,0-0.1,0.1-0.1h0c0,0,0,0,0.1,0h1.3c0,0,0,0,0.1,0c0,0,0,0,0.1,0L19.5,23.3C19.4,23.2,19.5,23.2,19.5,23.3L19.5,23.3
					 C19.5,23.2,19.5,23.2,19.5,23.3z M18.9,24.8C18.9,24.8,18.9,24.8,18.9,24.8C18.8,25,18.7,25,18.7,25c0,0-0.1,0-0.1,0
					 c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0,0.1,0C18.7,24.5,18.8,24.6,18.9,24.8C18.9,24.7,18.9,24.7,18.9,24.8z
						M19.7,22.5L19.7,22.5c-0.7,0-1.4,0-2.1,0c-0.3,0-0.4,0.1-0.4,0.4c0,0.4,0.1,0.4-0.4,0.4c-1,0-2,0-3,0c-0.6,0-1.2,0-1.8,0
					 c-0.5,0-0.9,0-1.4,0c-0.2,0-0.2,0-0.2-0.2c0-0.5-0.1-0.6-0.6-0.6c-0.6,0-1.3,0-1.9,0c-0.3,0-0.4,0.1-0.4,0.4V23
					 c0,0.3,0,0.3-0.3,0.3c-0.6,0-1.2,0-1.8,0c-0.3,0-0.3,0-0.3-0.3c0-0.4,0-0.9,0-1.4c0-1,0.8-1.9,1.8-2.1c0.1,0,0.2,0,0.3,0
					 c0.3,0,0.7,0,1,0v0c0.4,0,0.7,0,1.1,0c0.1,0,0.2,0,0.3,0.2c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.3,0.5,0.3
					 c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3,0c0.5,0.2,1.2-0.1,1.4-0.7c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
					 c0,0,0.1,0,0.1,0c0.3,0,0.3,0,0.3,0.2c0.1,0.6,0.7,0.9,1.3,0.8c0.2-0.1,0.5,0,0.7,0c0.3,0.1,0.6,0,0.7-0.3c0-0.1,0.1-0.1,0.2-0.1
					 c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c1,0,1.9,0,2.9,0c1.2,0,2.1,1,2.1,2.1c0,0.5,0,0.9,0,1.4
					 c0,0.2,0,0.2-0.2,0.2c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2,0-0.2-0.2c0-0.1,0-0.1,0-0.2C20.1,22.6,20,22.5,19.7,22.5z M8.3,23.8
					 C8.3,23.8,8.3,23.8,8.3,23.8H8.2c0,0-0.1,0-0.1-0.1v-0.5c0,0,0-0.1,0.1-0.1h1.5c0,0,0.1,0,0.1,0.1v0.5c0,0,0,0.1-0.1,0.1H8.3z
						M9.2,24.8c0,0.1-0.2,0.3-0.3,0.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3C9.1,24.5,9.2,24.6,9.2,24.8z M5.3,24.5
					 c-0.1,0-0.2,0-0.2-0.2c0-0.4,0-0.4,0.4-0.4c0.6,0,1.2,0,1.8,0c0.2,0,0.2,0,0.2,0.2c0,0.4,0,0.3-0.3,0.3c-0.3,0-0.6,0-0.9,0
					 c0,0,0,0,0,0C6,24.5,5.6,24.5,5.3,24.5z M11.7,19.5C11.7,19.5,11.7,19.4,11.7,19.5c-0.1-0.1-0.2-0.1-0.3-0.1
					 c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.2-0.1-0.2-0.3-0.2c0,0,0,0-0.1,0
					 c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0
					 c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0.5,0.2,1.1,0.3,1.6,0.3
					 c0.2,0,0.2,0.1,0.2,0.3c-0.1,0.2-0.2,0.3-0.4,0.3c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 C11.8,19.6,11.8,19.5,11.7,19.5z M15.4,19.6c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2,0c0,0,0,0-0.1,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2v0
					 c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0c0.4-0.1,0.9-0.1,1.3-0.3
					 c0.1,0,0.3,0.1,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.3-0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0
					 c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2,0c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.1
					 c0,0,0,0,0,0L15.4,19.6C15.4,19.6,15.4,19.6,15.4,19.6z M6.5,13.2C6.5,13.2,6.5,13.2,6.5,13.2C6.5,13.2,6.5,13.2,6.5,13.2
					 C6.5,13.1,6.5,13.1,6.5,13.2C6.5,13.1,6.5,13.1,6.5,13.2C6.5,13.1,6.5,13.1,6.5,13.2C6.5,13.1,6.5,13.1,6.5,13.2
					 c-0.1-0.1-0.1-0.1-0.1-0.1h0C6.3,13,6.3,13,6.2,13c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.2-0.1-0.3-0.2-0.3-0.4
					 c0-0.1,0-0.1-0.1-0.2c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
					 c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c-0.3,0-0.7-0.3-0.9-0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
					 c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0C6.5,13.4,6.5,13.4,6.5,13.2C6.5,13.3,6.5,13.3,6.5,13.2
					 C6.5,13.2,6.5,13.2,6.5,13.2z M6.4,11.5c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.2c0,0,0,0,0.1,0c0.2,0.1,0.3,0.2,0.5,0.2
					 c0,0,0,0,0,0c0,0,0,0,0,0.1v0c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0.1C6.6,11.8,6.5,11.7,6.4,11.5z M5.1,7.8
					 c0.3-0.3,0.6-0.7,1-1c0.2-0.1,0.3-0.3,0.5-0.4C6.4,6.9,6.3,7.4,6.3,8c-0.1,0.5-0.1,1,0,1.4c0.1,0.4,0.1,0.9,0.3,1.4c0,0,0,0-0.1,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0.1-0.1,0.1
					 c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
					 c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0-0.1c-0.1-0.2-0.2-0.3-0.2-0.5c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
					 c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
					 c0,0-0.1,0-0.1,0c0,0,0,0,0,0C3.9,9.3,4.5,8.5,5.1,7.8z M4.3,25.5c-0.4,0-0.4,0.1-0.4-0.4c0-0.3,0-0.5,0-0.8h0c0-0.3,0-0.6,0-0.9
					 c0-0.2,0-0.2,0.2-0.2c0.4,0,0.4-0.1,0.4,0.4c0,0.6,0,1.2,0,1.7C4.5,25.5,4.5,25.5,4.3,25.5z M4.5,21.3c0,0.3,0,0.7,0,1
					 c0,0.2,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.4-0.1-0.5-0.3c-0.8-1.4-1.2-2.8-1.4-4.4C2,17,1.9,16.1,2,15.2
					 c0.1-1.6,0.5-3,1.1-4.5c0.1-0.2,0.1-0.2,0.3-0.1c0.3,0,0.4,0.2,0.5,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.4,0.5,0.7,0.7
					 C4.9,12.2,5,12.3,5,12.4c0,0.1,0.1,0.2,0.1,0.3c-0.3,0.2-0.4,0.3-0.2,0.6c0.3,0.7,0.8,1.2,1.6,1.3c0.2,0,0.3,0.1,0.3,0.3
					 c0.3,1.1,0.8,2,1.6,2.8c0.4,0.4,0.8,0.6,1.3,0.9c0,0.2-0.2,0.3-0.3,0.2c-0.7,0-1.4,0-2.1,0C5.9,18.9,4.7,20,4.5,21.3z M22.4,27.2
					 c0,0.5-0.3,1-0.7,1.3c-0.4,0.3-0.9,0.5-1.4,0.5c-4.4,0-8.7,0-13.1,0c-0.9,0-1.6-0.4-1.9-1.2c-0.1-0.2-0.1-0.5-0.2-0.7
					 c0-0.4,0-0.8,0-1.1c0-0.1,0-0.2,0-0.3c0-0.6,0-0.5,0.5-0.5c0.6,0,1.1,0,1.7,0c0.2,0,0.2,0,0.2,0.3c0,0.1,0,0.2,0,0.4
					 c0,0,0,0,0,0.1c0,0,0,0,0,0.1v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.4,0,0.7,0,1.1,0
					 c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2,0.2-0.2c0.1,0,0.1,0,0.2,0c2,0,4,0,5.9,0
					 c0.1,0,0.1,0,0.2,0c0.3,0,0.3,0,0.3,0.3c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.8,0,1.5,0,2.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0.2-0.2
					 c0.7,0,1.3,0,2,0c0.1,0,0.2,0,0.2,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4C22.4,26.3,22.4,26.8,22.4,27.2z M22.5,24.4
					 C22.5,24.5,22.4,24.5,22.5,24.4l-2.3,0.1c0,0-0.1,0-0.1-0.1V24c0,0,0-0.1,0.1-0.1h2.3c0,0,0.1,0,0.1,0.1V24.4z M22.8,20.6
					 C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6
					 C22.8,20.6,22.8,20.6,22.8,20.6C22.8,20.6,22.8,20.6,22.8,20.6c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
					 c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0h0c0,0,0,0,0,0c0,0,0,0,0-0.1
					 s0,0,0,0C22,19,22.2,19,22.4,19h0c0.1,0,0.2,0,0.3,0c0.4,0.1,0.6,0.4,0.6,0.8c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
					 c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1C23,20.5,22.9,20.6,22.8,20.6
					 C22.8,20.6,22.8,20.6,22.8,20.6z M23.4,25.5c-0.4,0-0.4,0-0.4-0.3v-0.8c0,0,0,0,0,0c0-0.3,0-0.7,0-1c0-0.1,0.1-0.2,0.2-0.2
					 c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.2c0,0.7,0,1.3,0,2C23.6,25.4,23.5,25.5,23.4,25.5z M24.4,23c0,0.1-0.1,0.2-0.2,0.2
					 c0-0.1,0-0.1,0-0.2c0-0.4-0.2-0.6-0.6-0.6c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.2-0.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.3,0-0.5
					 c0-0.1,0.2-0.2,0.3-0.3c0.6-0.5,0.6-1.4,0.1-2c-0.5-0.6-1.4-0.6-2-0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1
					 c-0.3-0.1-0.6-0.1-1-0.1c-1,0-1.9,0-2.9,0c-0.2,0-0.3,0-0.4-0.2c0-0.1,0.1-0.1,0.2-0.1c1.3-0.7,2.1-1.8,2.5-3.2
					 c0-0.1,0.1-0.2,0.2-0.3c0.5-0.2,0.9-0.6,1.2-1.2c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.1-0.3-0.1-0.4c0,0,0,0-0.1,0
					 c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.3,0-0.5-0.3-0.4c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0-0.3,0.1
					 c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.3-0.5,0.4-0.7c0-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.3,0.1-0.7,0.2-1,0.2
					 c-0.1,0-0.1,0-0.2,0c-0.5,0-0.6,0-0.9-0.4c-0.1-0.1,0-0.2,0-0.3c0.1-1,0.1-2-0.2-2.9c-0.4-1.1-1-2.1-1.8-3c-0.1,0-0.1-0.1-0.2-0.1
					 c0,0,0,0,0-0.1c0.3,0,0.6,0.1,0.9,0.2c0.2,0,0.4,0.1,0.5,0.1c0.2,0.1,0.2,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
					 c-0.1,0.3,0,0.6,0.3,0.8c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.1,0.4,0.3,0.5,0.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5
					 c-0.2,0.4-0.1,0.7,0.2,0.9c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0.2,0.9,0.5,1.3,0.8c0,0,0.1,0,0.1,0.1c0.3,0.2,0.7,0.3,1,0.1
					 c0.2-0.1,0.4-0.1,0.6,0.1c0.2,0.2,0.5,0.3,0.8,0.5c0.3,0.2,0.4,0.4,0.2,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.1,0.5-0.1,0.8
					 c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.5,0.3,0.7,0.5c0.5,0.3,0.8,0.3,1.2-0.2c0.2-0.2,0.2-0.2,0.4-0.1
					 c0.3,0.2,0.4,0.4,0.5,0.7C26.8,17,26.3,20.2,24.4,23z"
            />
            <path
              class="st11"
              d="M7.2,24.5c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0c-0.3,0-0.7,0-1,0c-0.1,0-0.2,0-0.2-0.2c0-0.4,0-0.4,0.4-0.4
					 c0.6,0,1.2,0,1.8,0c0.2,0,0.2,0,0.2,0.2C7.5,24.5,7.6,24.5,7.2,24.5z"
            />
            <path
              class="st11"
              d="M22.5,24v0.5c0,0,0,0.1-0.1,0.1h-2.3c0,0-0.1,0-0.1-0.1V24c0,0,0-0.1,0.1-0.1L22.5,24
					 C22.4,23.9,22.5,23.9,22.5,24z"
            />
            <path
              class="st11"
              d="M17.2,24v0.5c0,0,0,0.1-0.1,0.1h-6.7c0,0-0.1,0-0.1-0.1V24c0,0,0-0.1,0.1-0.1L17.2,24
					 C17.2,23.9,17.2,23.9,17.2,24z"
            />
            <path
              class="st1"
              d="M11.6,7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9C12.5,7.4,12.1,7,11.6,7z
						M11.2,7.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0.2,0,0.3,0.1,0.3,0.3
					 C11.6,7.7,11.4,7.8,11.2,7.8z"
            />
            <path
              class="st1"
              d="M7.9,6.7C7.9,6.7,7.9,6.7,7.9,6.7c-0.1,0-0.1,0-0.1,0c0,0,0,0,0,0C7.5,6.8,7.2,7.1,7.2,7.4
					 c0,0.2,0.1,0.3,0.1,0.4C7.5,8,7.7,8.1,7.9,8.1c0.4,0,0.7-0.3,0.7-0.7C8.6,7,8.3,6.7,7.9,6.7z M8,7.4c-0.2,0-0.3-0.1-0.3-0.3
					 C7.8,7,7.9,6.8,8,6.8c0.2,0,0.3,0.1,0.3,0.3C8.3,7.3,8.2,7.4,8,7.4z"
            />
            <path
              class="st15"
              d="M11.2,7.8c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.1
					 c0,0.1-0.1,0.1-0.1,0.2C10.9,7.7,11.1,7.8,11.2,7.8z"
            />
            <circle class="st15" cx="8" cy="7.1" r="0.3" />
            <path
              class="st10"
              d="M18.9,24.8C18.9,24.8,18.9,24.8,18.9,24.8c-0.1,0.2-0.1,0.2-0.2,0.3c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.3-0.3
					 c0-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0,0.1,0C18.7,24.5,18.8,24.6,18.9,24.8C18.9,24.7,18.9,24.7,18.9,24.8z"
            />
            <path
              class="st1"
              d="M19.3,20h-1.5c0,0,0,0,0,0h-0.2c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					 c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0,0.1,0h1.6c0.1,0,0.3-0.1,0.3-0.3C19.5,20.1,19.4,20,19.3,20z"
            />
          </g>
          <path class="st1" d="M4.5,28.6C4.5,28.6,4.5,28.6,4.5,28.6L4.5,28.6z" />
          <path
            class="st1"
            d="M7.4,30.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				 C7.3,29.9,7.4,30,7.4,30.1z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuLogoComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
