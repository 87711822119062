import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { StatisticsYear } from "../models/statisticsyear";
import { StatisticsMoney } from "../models/statisticsmoney";
import { GenericResponse } from "src/app/shared/models/generics";
import { map, Observable } from "rxjs";
import { YearlyPopularDestinations } from "../models/yearlypopulardestinations";
import { StatisticOrganizer, StatisticOrganizerProfit } from "../models/statisticorganizer";
import { OverallStatistics } from "../models/overallstatistics";
import { StatisticsYearlyEarnings } from "../models/yearlyearning";
import { StatisticsYearlyDebt } from "../models/yearlydebt";
import { MonthlyEarningsStatistics } from "../models/monthlyearnings";
import { MonthlyDebtStatistics } from "../models/monthlydebt";
import { DailyEarningsStatistics } from "../models/dailyearnings";
import { DailyDebtStatistics } from "../models/dailydebt";
import { StatisticsMonthly } from "../models/statisticmoneymonthly";
import { StatisticsDaily } from "../models/statisticmoneydaily";
import { MostPopularCountriesByMonth } from "../models/mostpopularcountriesbymonth";

@Injectable({
  providedIn: "root",
})
export class StatisticsMoneyService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getstatistics() {
    return this.http.get<StatisticsYear>(`${this.apiUrl}/api/Statistics/money/stats`);
  }
  getStatisticsMoneyWithHeaders(pageNumber: number, pageSize: number, fromDate: string, toDate: string, filterType: string) {
    const apiUrl = `${this.apiUrl}/api/Statistics/money/plans/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("from", fromDate).set("to", toDate).set("filterType", filterType);

    return this.http.get<StatisticsMoney[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<StatisticsMoney> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  getYearlyPopularDestinations(year: number, numberOfDestinations: number) {
    return this.http.get<YearlyPopularDestinations>(`${this.apiUrl}/api/Statistics/yearlyPopularDestinations?year=${year}&numberOfDestinations=${numberOfDestinations}`);
  }
  getOrganizerStatistics(numberOfOrganizers: number) {
    return this.http.get<StatisticOrganizer[]>(`${this.apiUrl}/api/Statistics/organizers/stats?numberOfOrganizers=${numberOfOrganizers}`);
  }
  getstatisticsorganizerprofit(numberOfOrganizers: number) {
    return this.http.get<StatisticOrganizerProfit[]>(`${this.apiUrl}/api/Statistics/organizers-profit/stats?numberOfOrganizers=${numberOfOrganizers}`);
  }
  getOverallStatistics(from: string, to: string) {
    const apiUrl = `${this.apiUrl}/api/Statistics/overall`;
    const params = new HttpParams().set("from", from).set("to", to);
    return this.http.get<OverallStatistics>(apiUrl, { params });
  }
	
	getyearlyearningstatistics() {
    return this.http.get<StatisticsYearlyEarnings>(`${this.apiUrl}/api/Statistics/money/stats/earnings`);
  }
	getyearlydebtstatistics() {
    return this.http.get<StatisticsYearlyDebt>(`${this.apiUrl}/api/Statistics/money/stats/debt`);
  }
	getearningsmonthstatistics(year: number) {
		return this.http.get<MonthlyEarningsStatistics>(`${this.apiUrl}/api/Statistics/earnings/monthly?year=${year}`);
	}
	getdebtmonthstatistics(year: number) {
		return this.http.get<MonthlyDebtStatistics>(`${this.apiUrl}/api/Statistics/debt/monthly?year=${year}`);
	}
	getmoneymonthstatistics(year: number):Observable<StatisticsMonthly> {
		return this.http.get<StatisticsMonthly>(`${this.apiUrl}/api/Statistics/money/stats/monthly?year=${year}`);
	}
	
	getearningsdailystatistics(year: number, month: number) {
		return this.http.get<DailyEarningsStatistics>(`${this.apiUrl}/api/Statistics/earnings/daily?year=${year}&month=${month}`);
	}
	getdebtdailystatistics(year: number, month: number) {
		return this.http.get<DailyDebtStatistics>(`${this.apiUrl}/api/Statistics/dept/daily?year=${year}&month=${month}`);
	}
	getmoneydailystatistics(year: number, month: number) {
		return this.http.get<StatisticsDaily>(`${this.apiUrl}/api/Statistics/money/stats/daily?year=${year}&month=${month}`);
	}
	getMostPopularCountriesByMonth(year: number, numberOfCountries: number) {
    return this.http.get<MostPopularCountriesByMonth>(`${this.apiUrl}/api/Statistics/monthlyPopularCountries?year=${year}&numberOfCountries=${numberOfCountries}`);
  }
}
