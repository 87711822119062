import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
	moduleId: module.id,
	selector: "icon-menu-bus",
	template: `
    <ng-template #template>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        width="100%"
        height="100%"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
        [ngClass]="class"
      >
        <g>
          <path
            fill="currentColor"
            d="M17.8,11.4C17.5,10,17.3,8.5,17,7c-0.1-0.6-0.4-0.9-1.1-0.9c-4.2,0-8.4,0-12.6,0c-0.8,0-1.1,0.3-1.1,1.1
              c0,1.6,0,3.3,0,4.9v0.7h2.4c0-1.5,0.7-2.4,2-2.4c0.5,0,1,0.2,1.4,0.6c0.5,0.5,0.6,1.1,0.5,1.8c1.3,0,2.5,0,3.7,0c0.1,0,0.2,0,0.2,0
              c0.1-1.6,0.8-2.5,2.1-2.4c1.1,0.1,2,1.1,1.8,2.4C17.3,13.1,17.9,12.5,17.8,11.4z M6.1,9.5H3.2V7.1h2.9V9.5z M10,9.5H7.1V7.1H10V9.5
              z M13.9,9.5H11V7.1h2.9V9.5z M14.9,9.4V7.1c0.3,0,0.5,0,0.8,0c0.2,0,0.4,0.2,0.4,0.3c0.2,1,0.4,2,0.5,3C16,10.1,15.5,9.8,14.9,9.4z"
          />
          <path
            fill="currentColor"
            d="M6.6,11c-0.8,0-1.4,0.7-1.4,1.4c0,0.9,0.6,1.5,1.5,1.5c0.8,0,1.4-0.7,1.4-1.5C8,11.6,7.4,11,6.6,11z M6.6,13.2
              c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.8,0.7-0.8c0.4,0,0.7,0.3,0.7,0.7C7.3,12.8,7,13.1,6.6,13.2z"
          />
          <path
            fill="currentColor"
            d="M14.4,11c-0.8,0-1.5,0.6-1.5,1.5c0,0.8,0.6,1.4,1.4,1.5c0.8,0,1.5-0.7,1.5-1.5C15.8,11.6,15.1,11,14.4,11z
              M14.4,13.2c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.8,0.4,0.8,0.7C15.1,12.8,14.7,13.2,14.4,13.2z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuBusComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;

	constructor(private viewContainerRef: ViewContainerRef) { }

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}