import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Customer } from "../models/customer";
import { BaseHttpService } from "src/app/service/base.http.service";
import { GenericResponse } from "src/app/shared/models/generics";
import * as moment from "moment";
import { CustomerStats } from "../models/customer-stat";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { CustomerPlan } from "../models/customer-plan";

@Injectable({
  providedIn: "root",
})
export class CustomersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getCustomers(pageNumber: number, pageSize: number, filterType: string): Observable<Customer[]> {
    const apiUrl = `${this.apiUrl}/api/customers/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("filterType", filterType);

    return this.http.get<Customer[]>(apiUrl, { params });
  }

  getCustomersWithHeaders(pageNumber: number, pageSize: number, filterType: string): Observable<GenericResponse<Customer>> {
    const apiUrl = `${this.apiUrl}/api/customers/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("filterType", filterType);

    return this.http.get<Customer[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Customer> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  createCustomer(customer: Customer) {
    return this.http.post<Customer>(`${this.apiUrl}/api/customers`, customer);
  }

  editCustomer(id: number, customer: Customer) {
    const data = {
      ...customer,
      dateOfBirth: moment(customer.dateOfBirth).toISOString(),
      passportIssuingDate: moment(customer.passportIssuingDate).toISOString(),
      passportExpirationDate: moment(customer.passportExpirationDate).toISOString(),
    };
    return this.http.put<Customer>(`${this.apiUrl}/api/customers/${id}`, data);
  }
  getCustomerStats(id: number, from: string, to: string) {
    const params = new HttpParams().set("from", from).set("to", to);
    return this.http.get<CustomerStats>(`${this.apiUrl}/api/Statistics/customers/stats/${id}`, { params });
  }
  getCustomerWithHeaders(customerId: number, pageNumber: number, pageSize: number, filterType: string, from: Date, to: Date | undefined) {
    const apiUrl = `${this.apiUrl}/api/Statistics/customers/${customerId}/paginated`;
    const params = new HttpParams()
      .set("filterType", filterType)
      .set("pageSize", pageSize.toString())
      .set("pageNumber", pageNumber.toString())
      .set("from", DateTimeFormatter.formatDateToString(from))
      .set("to", DateTimeFormatter.formatDateToString(to ?? null));

    return this.http.get<CustomerPlan[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<CustomerPlan> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }
	getCustomerDetails(customerId: number): Observable<Customer> {
    return this.http.get<Customer>(`${this.apiUrl}/api/customers/${customerId}`);
  }
	mergeCustomer(rootId: number, listOfIds: number[]): Observable<any> {
		const url = `${this.apiUrl}/api/customers/merge`;
		const body = { rootId, listOfIds };
		return this.http.post(url, body, { responseType: 'text' });
	}
	getCustomersAll() {
		return this.http.get<Customer[]>(`${this.apiUrl}/api/customers`);
	}
}
