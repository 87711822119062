import { Observable } from "rxjs";
import * as moment from "moment";
import { Contract } from "../models/contract";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ContractService } from "../services/contract.service";
import { Customer } from "src/app/domain/customers/models/customer";

@Component({
	selector: "app-contract-edit",
	templateUrl: "./contract.edit.component.html",
})
export class ContractEditComponent implements OnInit {
	@Input() contract!: Contract;
	@Input() cancelEditUrl!: string;
	@Input() passangers!: Customer[];
	@Input() updateContractFn!: (data: any) => Observable<Contract>;
	contractId!: number;
	travelId!: number;
	form!: FormGroup;

	mask8 = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
	constructor(public fb: FormBuilder, public route: ActivatedRoute, public contractService: ContractService, public router: Router, public translate: TranslateService) { }

	initForm() {
		this.form = this.fb.group({
			passengers: this.fb.array([]),
			tripOrganizer: [""],
			complaint: [""],
			adultPrice: [],
			adultNumber: [],
			adultTotal: [0],
			childPrice: [],
			childNumber: [],
			childTotal: [0],
			suplementTripPrice: [],
			suplementTripNumber: [],
			suplementTripTotal: [0],
			suplementNYPrice: [],
			suplementNYNumber: [],
			suplementNYTotal: [0],
			aitTaxiPrice: [],
			aitTaxiNumber: [],
			aitTaxiTotal: [0],
			visumPrice: [],
			visumNumber: [],
			visumTotal: [104],
			taxPrice: [],
			taxNumber: [],
			taxTotal: [0],
			totalInEuro: [0],
			totalInDinars: [0],
			fiscalNumber: [""],
			organizerInfo: [""],
			organizerResponsiblePerson: [""],
		});
	}
	get adultTotal(): number {
		const adultPrice = this.form.get("adultPrice")?.value || 0;
		const adultNumber = this.form.get("adultNumber")?.value || 0;
		if (adultPrice && adultNumber) return adultPrice * adultNumber;
		return 0;
	}

	get childTotal(): number {
		const childPrice = this.form.get("childPrice")?.value || 0;
		const childNumber = this.form.get("childNumber")?.value || 0;
		if (childPrice && childNumber) return childPrice * childNumber;
		return 0;
	}

	get suplementTripTotal(): number {
		const suplementTripPrice = this.form.get("suplementTripPrice")?.value || 0;
		const suplementTripNumber = this.form.get("suplementTripNumber")?.value || 0;
		if (suplementTripPrice && suplementTripNumber) return suplementTripPrice * suplementTripNumber;
		return 0;
	}

	get suplementNYTotal(): number {
		const suplementNYPrice = this.form.get("suplementNYPrice")?.value || 0;
		const sumplementNYNumber = this.form.get("sumplementNYNumber")?.value || 0;
		if (suplementNYPrice && sumplementNYNumber) return suplementNYPrice * sumplementNYNumber;
		return 0;
	}

	get aitTaxiTotal(): number {
		const aitTaxiPrice = this.form.get("aitTaxiPrice")?.value || 0;
		const aitTaxiNumber = this.form.get("aitTaxiNumber")?.value || 0;
		if (aitTaxiPrice && aitTaxiNumber) return aitTaxiPrice * aitTaxiNumber;
		return 0;
	}

	get taxTotal(): number {
		const taxPrice = this.form.get("taxPrice")?.value || 0;
		const taxNumber = this.form.get("taxNumber")?.value || 0;
		if (taxPrice && taxNumber) return taxPrice * taxNumber;
		return 0;
	}

	get totalInEuro(): number {
		const totalInEuro = this.totalInDinars / 117.5;
		return totalInEuro;
	}

	get totalInDinars(): number {
		const adultTotal = this.adultTotal;
		const childTotal = this.childTotal;
		const suplementTripTotal = this.suplementTripTotal;
		const suplementNYTotal = this.suplementNYTotal;
		const aitTaxiTotal = this.aitTaxiTotal;
		const visumTotal = 104;
		const taxTotal = this.taxTotal;
		return adultTotal + childTotal + suplementTripTotal + suplementNYTotal + aitTaxiTotal + visumTotal + taxTotal;
	}

	ngOnInit(): void {
		this.initForm();
		this.subscribeToFormChanges();
	}

	saveContract() {
		if (this.form.invalid) {
			showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
			this.form.markAllAsTouched();
			return;
		}
		const contract = this.extractContractFromForm();
		this.updateContractFn(contract).subscribe(
			(response) => {
				showMessage(PopupType.Success, this.translate.instant("popup.contract_updated_successfully"));
				this.router.navigate([this.cancelEditUrl]);
			},
			(error) => {
				showMessage(PopupType.Danger, error.toString());
			}
		);
	}

	subscribeToFormChanges(): void {
		this.form.get("adultPrice")?.valueChanges.subscribe(() => {
			this.calculateAdultTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("adultNumber")?.valueChanges.subscribe(() => {
			this.calculateAdultTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("childPrice")?.valueChanges.subscribe(() => {
			this.calculateChildTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("childNumber")?.valueChanges.subscribe(() => {
			this.calculateChildTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("suplementTripPrice")?.valueChanges.subscribe(() => {
			this.calculateSuplementTripTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("suplementTripNumber")?.valueChanges.subscribe(() => {
			this.calculateSuplementTripTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("suplementNYPrice")?.valueChanges.subscribe(() => {
			this.calculateSuplementNYTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("suplementNYNumber")?.valueChanges.subscribe(() => {
			this.calculateSuplementNYTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("aitTaxiPrice")?.valueChanges.subscribe(() => {
			this.calculateAitTaxiTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("aitTaxiNumber")?.valueChanges.subscribe(() => {
			this.calculateAitTaxiTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("taxPrice")?.valueChanges.subscribe(() => {
			this.calculateTaxTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
		this.form.get("taxNumber")?.valueChanges.subscribe(() => {
			this.calculateTaxTotal();
			this.calculateTotalInDinars();
			this.calculateTotalInEuro();
		});
	}

	calculateAdultTotal(): void {
		const adultPrice = this.form.get("adultPrice")?.value || 0;
		const adultNumber = this.form.get("adultNumber")?.value || 0;
		const adultTotal = adultPrice * adultNumber;
		if (adultTotal) this.form.get("adultTotal")?.setValue(adultTotal);
		else this.form.get("adultTotal")?.setValue(0);
	}

	calculateChildTotal(): void {
		const childPrice = this.form.get("childPrice")?.value || 0;
		const childNumber = this.form.get("childNumber")?.value || 0;
		const childTotal = childPrice * childNumber;
		if (childTotal) this.form.get("childTotal")?.setValue(childTotal);
		else this.form.get("childTotal")?.setValue(0);
	}

	calculateSuplementTripTotal(): void {
		const suplementTripPrice = this.form.get("suplementTripPrice")?.value || 0;
		const suplementTripNumber = this.form.get("suplementTripNumber")?.value || 0;
		const suplementTripTotal = suplementTripPrice * suplementTripNumber;
		if (suplementTripTotal) this.form.get("suplementTripTotal")?.setValue(suplementTripTotal);
		else this.form.get("suplementTripTotal")?.setValue(0);
	}

	calculateSuplementNYTotal(): void {
		const suplementNYPrice = this.form.get("suplementNYPrice")?.value || 0;
		const suplementNYNumber = this.form.get("suplementNYNumber")?.value || 0;
		const suplementNYTotal = suplementNYPrice * suplementNYNumber;
		if (suplementNYTotal) this.form.get("suplementNYTotal")?.setValue(suplementNYTotal);
		else this.form.get("suplementNYTotal")?.setValue(0);
	}

	calculateAitTaxiTotal(): void {
		const aitTaxiPrice = this.form.get("aitTaxiPrice")?.value || 0;
		const aitTaxiNumber = this.form.get("aitTaxiNumber")?.value || 0;
		const aitTaxiTotal = aitTaxiPrice * aitTaxiNumber;
		if (aitTaxiTotal) this.form.get("aitTaxiTotal")?.setValue(aitTaxiTotal);
		else this.form.get("aitTaxiTotal")?.setValue(0);
	}

	calculateTaxTotal(): void {
		const taxPrice = this.form.get("taxPrice")?.value || 0;
		const taxNumber = this.form.get("taxNumber")?.value || 0;
		const taxTotal = taxPrice * taxNumber;
		if (taxTotal) this.form.get("taxTotal")?.setValue(taxTotal);
		else this.form.get("taxTotal")?.setValue(0);
	}

	calculateTotalInDinars(): void {
		const adultTotal = this.adultTotal;
		const childTotal = this.childTotal;
		const suplementTripTotal = this.suplementTripTotal;
		const suplementNYTotal = this.suplementNYTotal;
		const aitTaxiTotal = this.aitTaxiTotal;
		const visumTotal = 104;
		const taxTotal = this.taxTotal;
		const totalInDinars = adultTotal + childTotal + suplementTripTotal + suplementNYTotal + aitTaxiTotal + visumTotal + taxTotal;
		if (totalInDinars) this.form.get("totalInDinars")?.setValue(totalInDinars);
		else this.form.get("totalInDinars")?.setValue(0);
	}

	calculateTotalInEuro(): void {
		const totalInDinars = this.totalInDinars || 0;
		const totalInEuro = totalInDinars / 117.5; // TODO: Change this
		const totalInEuroFixed = parseFloat(totalInEuro.toFixed(2));
		if (!isNaN(totalInEuroFixed)) {
			this.form.get("totalInEuro")?.setValue(totalInEuroFixed);
		} else {
			this.form.get("totalInEuro")?.setValue(0);
		}
	}

	fillForm(contract: any) {
		this.form.get("adultPrice")?.setValue(contract.priceForAdult);
		this.form.get("adultNumber")?.setValue(contract.numberOfAdoults);
		this.form.get("childPrice")?.setValue(contract.priceForChild);
		this.form.get("childNumber")?.setValue(contract.numberOfChildren);
		this.form.get("suplementTripPrice")?.setValue(contract.supplementForTravelPrice);
		this.form.get("suplementTripNumber")?.setValue(contract.supplementForTravelAmount);
		this.form.get("suplementNYPrice")?.setValue(contract.supplementForNYPrice);
		this.form.get("suplementNYNumber")?.setValue(contract.supplementForNYAmount);
		this.form.get("aitTaxiPrice")?.setValue(contract.airportTaxPrice);
		this.form.get("aitTaxiNumber")?.setValue(contract.airportAmount);
		this.form.get("visumPrice")?.setValue(contract.visumPrice);
		this.form.get("visumNumber")?.setValue(contract.visumAmount);
		this.form.get("taxPrice")?.setValue(contract.taxPrice);
		this.form.get("taxNumber")?.setValue(contract.taxAmount);
		this.form.get("totalInEuro")?.setValue(contract.totalInEuroPrice);
		this.form.get("totalInDinars")?.setValue(contract.totalInEuroAmount);
		this.form.get("fiscalNumber")?.setValue(contract.fiscalRecipetId);
		this.form.get("organizerInfo")?.setValue(contract.organizerInfo);
		this.form.get("organizerResponsiblePerson")?.setValue(contract.organizerResponsiblePerson);
	}

	extractContractFromForm() {
		this.passangers = this.passangers.map((passenger: any) => {
			passenger.name = passenger.fullName.split(" ")[0];
			passenger.surname = passenger.fullName.split(" ")[1];
			passenger.dateOfBirth = moment(passenger.dateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD");
			return passenger;
		});
		const {
			adultPrice,
			adultNumber,
			childPrice,
			childNumber,
			suplementTripPrice,
			suplementTripNumber,
			suplementNYPrice,
			suplementNYNumber,
			aitTaxiPrice,
			aitTaxiNumber,
			visumPrice,
			visumNumber,
			taxPrice,
			taxNumber,
			totalInEuro,
			totalInDinars,
			fiscalNumber,
			organizerInfo,
			organizerResponsiblePerson,
		} = this.form.value;

		const contract = {
			priceForAdult: adultPrice,
			numberOfAdoults: adultNumber,
			priceForChild: childPrice,
			numberOfChildren: childNumber,
			supplementForTravelPrice: suplementTripPrice,
			supplementForTravelAmount: suplementTripNumber,
			supplementForNYPrice: suplementNYPrice,
			supplementForNYAmount: suplementNYNumber,
			airportTaxPrice: aitTaxiPrice,
			airportAmount: aitTaxiNumber,
			visumPrice: visumPrice,
			visumAmount: visumNumber,
			taxPrice: taxPrice,
			taxAmount: taxNumber,
			totalInEuroPrice: parseInt(totalInEuro),
			priceTotal: totalInDinars,
			fiscalRecipetId: fiscalNumber,
			passengers: this.passangers,
			organizerInfo: organizerInfo,
			organizerResponsiblePerson: organizerResponsiblePerson,
		};

		return contract;
	}

	cancelEdit() {
		this.router.navigate([this.cancelEditUrl]);
	}
}
