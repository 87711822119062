
import { Destination } from '../models/destination';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/shared/services/util.service';
import { EntityPicklistType } from 'src/app/shared/enums/picklist-types';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'app-edit-destination',
  templateUrl: './edit-destination.component.html',
})
export class EditDestinationComponent   implements OnInit{
  @Input() destination: Destination = {} as Destination;
  @Input() isLoading!: boolean;
  @Input() editMode!: boolean;
  @Output() formSubmit = new EventEmitter<Destination>();
  form!: FormGroup;
  EntityPicklistType = EntityPicklistType;
  constructor(private fb: FormBuilder, public utilService: UtilService) {
  }
  ngOnInit(): void {
    this.initForm();
  }

  initForm():void {
    this.form = this.fb.group({
      description: [''],
      country: [''],
      countryId: [''],
    });
  }

  populateForm(destination: Destination) {
    this.form.patchValue({
      description: destination.description || '',
      countryId: destination.countryId || '',
      country: destination.country || '',
    });
  }
  submitForm() :void{
    if (this.form.valid) {
      const formValue = this.form.value;
        formValue.countryId = formValue.country.id;
    
      this.formSubmit.emit(formValue);
    } else {
      this.form.markAllAsTouched();
    }
  }
}