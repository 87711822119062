import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
	moduleId: module.id,
	selector: "icon-menu-other-services",
	styleUrls: ["../icons.css"],

	template: `
    <ng-template #template>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<g>
    <path fill="currentColor" d="M12.2,1.4c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3C15.2,2.8,13.9,1.4,12.2,1.4z M14,4.8
        c-0.1,0.1-0.1,0.1-0.2,0.1h-1.3v1.3c0,0.2-0.1,0.3-0.3,0.3h0c-0.2,0-0.3-0.1-0.3-0.3V4.9h-1.3c-0.2,0-0.3-0.1-0.3-0.3
        c0-0.2,0.1-0.3,0.3-0.3h1.3V3c0-0.2,0.1-0.3,0.3-0.3h0c0.2,0,0.3,0.1,0.3,0.3v1.3h1.3c0.2,0,0.3,0.1,0.3,0.3
        C14.1,4.6,14.1,4.7,14,4.8z"/>
    <path fill="currentColor" d="M6.7,15.7c-1.1-1.1-2.1-2.1-3.2-3.2c0,0,0,0-0.1-0.1c0.3-0.3,0.5-0.7,0.8-1c0.6-0.7,1.4-1.1,2.3-1.3
        c0.8-0.2,1.5-0.1,2.2,0.2c0.1,0.1,0.3,0.1,0.4,0.1c1.4,0,2.8,0,4.2,0.1c0.3,0,0.5,0.1,0.8,0.2c0.4,0.2,0.5,0.6,0.4,1
        c-0.1,0.4-0.5,0.7-0.9,0.6c-1,0-2.1,0-3.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.2,0.3,0.3c0.1,0,0.2,0,0.4,0
        c0.9,0,1.9,0,2.8,0c0.8,0,1.4-0.5,1.5-1.3c0-0.1,0-0.1,0-0.2c-0.1-0.2,0-0.3,0.2-0.5c0.8-0.8,1.6-1.6,2.4-2.4
        c0.3-0.3,0.6-0.3,0.9-0.3c0.6,0.1,0.9,0.8,0.5,1.3c-0.4,0.5-0.8,1-1.2,1.5c-0.6,0.8-1.3,1.6-1.9,2.4c-0.5,0.6-1.1,1-1.8,1.1
        c-0.5,0.1-1,0.1-1.5,0.1c-1.1,0-2.2,0-3.3,0c-0.4,0-0.8,0-1.2,0.1c-0.3,0-0.5,0.2-0.7,0.4C7.2,15.4,7,15.5,6.7,15.7z"/>
    <path fill="currentColor" d="M0.8,14.5c0.4-0.4,0.8-0.8,1.3-1.2c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.5-0.2,0.7,0c1.1,1.1,2.2,2.2,3.4,3.3
        c0.2,0.2,0.2,0.5,0,0.6C6,17.4,5.4,18,4.8,18.6C3.4,17.2,2.1,15.9,0.8,14.5z"/>
</g>
</svg>

    </ng-template>
  `,
})
export class IconMenuOtherServicesComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;

	constructor(private viewContainerRef: ViewContainerRef) { }

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}