import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupTravel } from '../../group-travels/models/group-travel';


@Component({
	selector: 'app-group-travel-quick-view',
	templateUrl: './group-travel-quick-view.component.html',
	styleUrls: ['./group-travel-quick-view.component.css']
})
export class GroupTravelQuickViewComponent {
	@Input() data!: GroupTravel;

	constructor(public translate: TranslateService) { }

}
