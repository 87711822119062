import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
	moduleId: module.id,
	selector: "icon-worker-location",
	template: `
    <ng-template #template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#A0DEFF" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
          </svg>
    </ng-template>
  `,
})
export class IconWorkerLocationComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;
	constructor(private viewContainerRef: ViewContainerRef) { }
	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}
