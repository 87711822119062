export class StyleUtil {
  static getTabWidth(activeTab: number, isExistsAnnouncement: boolean): string {
    switch (activeTab) {
      case 1:
        return isExistsAnnouncement ? "12%" : "13%";
      case 2:
        return isExistsAnnouncement ? "37%" : "48%";
      case 3:
        return isExistsAnnouncement ? "60%" : "60%";
      case 4:
        return isExistsAnnouncement ? "90%" : "85%";
      default:
        return "15%";
    }
  }
}
