import { Store } from "@ngrx/store";
import { Component, OnInit } from "@angular/core";
import { DailySummary } from "../models/dailysummary";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { DailyWorkerSummary } from "../models/dailyworkersummary";
import { DailySummaryService } from "../service/daily-summary.service";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
@Component({
  selector: "app-daily-worker-summary",
  templateUrl: "./daily-worker-summary.component.html",
})
export class DailyWorkerSummaryComponent implements OnInit {

  from: Date = new Date("2024-05-25");
  pageSize = 10;
  totalRows = 100;
  cols: DatatableColumn[] = [];
  items: DailyWorkerSummary[] = [];
  tabs: Array<{ id: number; label: string }> = [];
  activeTab!: number;
  filteredItems: DailySummary[] = [];

  constructor(private dailySummaryService: DailySummaryService, private translate: TranslateService, public store: Store<AppState>) {}

  ngOnInit() {
    this.getColumns();
    this.updateAndLoadData();
  }

  loadDailySummaries() {
    const formattedDate = this.from.toISOString();
    this.dailySummaryService.getDailyWorkerSummaryForDate(formattedDate).subscribe(
      (data) => {
        this.items = data;
      },
      (error) => {
        console.error("Error fetching daily summaries", error);
      }
    );
  }

  getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("daily_summary.id"), isUnique: true, hide: false } as DatatableColumn,
      { field: "agent", title: this.translate.instant("daily_summary.agent"), hide: false } as DatatableColumn,
      { field: "customer", title: this.translate.instant("daily_summary.customer"), hide: false } as DatatableColumn,
      { field: "timestamp", title: this.translate.instant("daily_summary.timestamp"), hide: false } as DatatableColumn,
      { field: "totalSum", title: this.translate.instant("daily_summary.totalSum"), hide: false } as DatatableColumn,
    ];
  }

  updateAndLoadData() {
    const formattedDate = this.from.toISOString();
    this.dailySummaryService.getDailyWorkerSummaryForDate(formattedDate).subscribe((data) => {
      this.items = data;
      this.tabs = this.items.map((item) => ({
        id: item.id,
        label: item.agent,
      }));
      this.changeFilteredDate(this.tabs[0].id);
    });
  }

  changeFilteredDate(tab: number) {
    this.activeTab = tab;
    const selectedAgent = this.items.find((item) => item.id === tab);
    this.filteredItems = selectedAgent ? selectedAgent.dailySummaries : [];
  }

  onTabChange(tab: number) {
    this.changeFilteredDate(tab);
  }

  getSumOfSuma(): number {
    return this.filteredItems.reduce((totalSum, summary) => totalSum + summary.totalSum, 0);
  }

  updateColumn(col: any) {
    // Implement column update logic
  }
}
