<div class="main-section relative font-nunito text-sm font-normal antialiased" [ngClass]="[store.sidebar ? 'toggle-sidebar' : '', store.menu, store.layout, store.rtlClass]">
  <!-- screen loader -->
  <div *ngIf="store.isShowMainLoader" class="screen_loader animate__animated fixed inset-0 z-[60] grid place-content-center bg-[#fafafa] dark:bg-[#060818]">
    <icon-app-loader></icon-app-loader>
  </div>

  <div class="text-black dark:text-white-dark">
    <div *ngIf="showTopButton" class="fixed bottom-6 z-50 ltr:right-6 rtl:left-6">
      <button type="button" class="btn btn-outline-primary animate-pulse rounded-full bg-[#fafafa] p-2 dark:bg-[#060818] dark:hover:bg-primary" (click)="goToTop()">
        <icon-arrow-top></icon-arrow-top>
      </button>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
