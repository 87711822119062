import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
	moduleId: module.id,
	selector: "icon-eye-closed",
	template: `
    <ng-template #template>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="class" transform="rotate(180)">
        <path d="M4.5 15.5C7.5 9 16.5 9 19.5 15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.8162 12.1825L19.5 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 10.625V7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.18383 12.1825L4.5 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </ng-template>
  `,
})
export class IconEyeClosedComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;
	constructor(private viewContainerRef: ViewContainerRef) { }
	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}