import { Component } from "@angular/core";

@Component({
  moduleId: module.id,
  selector: "app-footer",
  templateUrl: "./footer.html",
})
export class FooterComponent {
  currYear: number = new Date().getFullYear();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
