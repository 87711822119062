import * as moment from "moment";
import { Injectable } from "@angular/core";
import { Payment } from "../models/payment";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { PaymentDocument } from "../models/payment-document";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class PaymentService extends BaseHttpService {
	constructor(private http: HttpClient) {
		super();
	}


	getPaymentPrintingData(id: number | undefined) {
		return this.http.get<any>(`${this.apiUrl}/api/Payments/print-invoice/${id}`);
	}

	savePayment(payment: Payment) {
		payment.paymentDate = moment(payment.paymentDate, "dd.MM.yyyy").toISOString();
		return this.http.post<Payment>(`${this.apiUrl}/api/Payments`, payment);
	}

	updatePayment(payment: Payment) {
		payment.paymentDate = moment(payment.paymentDate, "dd.MM.yyyy").toISOString();
		return this.http.put<Payment>(`${this.apiUrl}/api/Payments/${payment.id}`, payment);
	}

	deletePayment(id: number) {
		return this.http.delete(`${this.apiUrl}/api/payments/${id}`);
	}
	getPaymentDocument(id: number) {
		return this.http.get<PaymentDocument>(`${this.apiUrl}/api/Payments/payment?id=${id}`);
	}
	sendPayment(id: number): (data: FormData) => Observable<any> {
		return (data: FormData) => {
			data.append("PlanId", id.toString());
			return this.http.post<any>(`${this.apiUrl}/api/Plans/send`, data);
		};
	}
}

