import { Store } from "@ngrx/store";
import { Sale } from "../models/sale";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { ActivatedRoute, Router } from "@angular/router";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TableService } from "src/app/shared/services/table.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { IconMenuPlaneComponent } from "src/app/shared/icon/menu/icon-menu-plane";
import { IconMenuTravelComponent } from "src/app/shared/icon/menu/icon-menu-travel";
import { IconMenuTransferComponent } from "src/app/shared/icon/menu/icon-menu-transfer";
import { IconMenuGroupTravelComponent } from "src/app/shared/icon/menu/icon-menu-group-travel";
import { IconMenuOtherServicesComponent } from "src/app/shared/icon/menu/icon-menu-other-services";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  templateUrl: "./sales.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class SalesTableComponent extends BaseTableComponent<Sale> implements OnInit {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  selectedOption = "None";
  public sales: Sale[] = [];
  public plans!: any[];
  public isDraftShown = false;

  constructor(
    public override translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private route: ActivatedRoute,
    private positioningService: PositioningService,
    private router: Router
  ) {
    super(store, translate, selectFromTo, tableService, "statistics/home");
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.route.params.subscribe((params) => {
      this.apiUrl = `statistics/home?type=${params["type"]}`;
      super.ngOnInit();
    });
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override onSelectionChange(val: string) {
    if (val === "Draft") {
      this.isDraftShown = true;
      return;
    }
    this.isDraftShown = false;
    super.onSelectionChange(val);
  }
  getIconAndTooltip(entityName: string) {
    switch (entityName) {
      case "Ticket":
        return IconMenuPlaneComponent;
      case "Travel":
        return IconMenuTravelComponent;
      case "GroupTravel":
        return IconMenuGroupTravelComponent;
      case "Transfer":
        return IconMenuTransferComponent;
      case "PlanService":
        return IconMenuOtherServicesComponent;
      default:
        return null;
    }
  }

  navigateToDetails(value: any) {
    switch (value.entity_name) {
      case "Ticket":
        this.router.navigate([`dashboard/tickets/${value.type}/${value.entityId}`]);
        break;
      case "Travel":
        this.router.navigate([`dashboard/travels/${value.entityId}`]);
        break;
      case "GroupTravel":
        this.router.navigate([`dashboard/group-travels/${value.entityId}`]);
        break;
      case "Transfer":
        this.router.navigate([`dashboard/transfers/${value.entityId}`]);
        break;
      case "PlanService":
        this.router.navigate([`dashboard/otherservices/${value.entityId}`]);
        break;
      default:
        break;
    }
  }

  navigateToEdit(value: any) {
    switch (value.entity_name) {
      case "Ticket":
        this.router.navigate([`dashboard/tickets/${value.type}/edit/${value.entityId}`]);
        break;
      case "Travel":
        this.router.navigate([`dashboard/travels/edit/${value.entityId}`]);
        break;
      case "GroupTravel":
        this.router.navigate([]); // TODO: Add when update/edit logic is added
        break;
      case "Transfer":
        this.router.navigate([]); // TODO: Add when update/edit logic is added
        break;
      case "PlanService":
        this.router.navigate([`dashboard/otherservices/edit/${value.entityId}`]);
        break;
      default:
        break;
    }
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: "ID", isUnique: true, hide: true } as DatatableColumn,
      { field: "entityId", title: this.translate.instant("sales_table.entity"), hide: false } as DatatableColumn,
      { field: "customerId", title: this.translate.instant("sales_table.customer"), hide: false } as DatatableColumn,
      { field: "destinations", title: this.translate.instant("sales_table.destination.name"), hide: false } as DatatableColumn,
      { field: "number_of_passangers", title: this.translate.instant("sales_table.num_of_passangers"), hide: true } as DatatableColumn,
      { field: "getStatus", title: this.translate.instant("sales_table.status"), hide: true } as DatatableColumn,
      { field: "bruto",title: this.translate.instant("sales_table.bruto"), hide: true } as DatatableColumn,
      { field: "depth", title:this.translate.instant("sales_table.depth"), hide: true } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("sales_table.sold"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("sales_table.actions"), hide: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "clientName", title: this.translate.instant("sales-export.clientName"), isUnique: true, hide: true } as DatatableColumn,
    { field: "clientPhoneNumber", title: this.translate.instant("sales-export.clientPhoneNumber"), hide: false, sort: false } as DatatableColumn,
    { field: "email", title: this.translate.instant("sales-export.email"), hide: false } as DatatableColumn,
    { field: "service", title: this.translate.instant("sales-export.service"), hide: false } as DatatableColumn,
    { field: "serviceDate", title: this.translate.instant("sales-export.serviceDate"), hide: false } as DatatableColumn,
    { field: "purchaseCount", title: this.translate.instant("sales-export.purchaseCount"), hide: false } as DatatableColumn,
    { field: "travel", title: this.translate.instant("sales-export.travel"), hide: false } as DatatableColumn,
    { field: "flightTicket", title: this.translate.instant("sales-export.flightTicket"), hide: false } as DatatableColumn,
    { field: "busTicket", title: this.translate.instant("sales-export.busTicket"), hide: false } as DatatableColumn,
    { field: "groupTravel", title: this.translate.instant("sales-export.groupTravel"), hide: false } as DatatableColumn,
    { field: "otherServices", title: this.translate.instant("sales-export.otherServices"), hide: false } as DatatableColumn,
    { field: "profession", title: this.translate.instant("sales-export.profession"), hide: false } as DatatableColumn,
    { field: "birthDate", title: this.translate.instant("sales-export.birthDate"), hide: false } as DatatableColumn,
    { field: "passportNumber", title: this.translate.instant("sales-export.passportNumber"), hide: false } as DatatableColumn,
    { field: "createdDate", title: this.translate.instant("sales-export.createdDate"), hide: false } as DatatableColumn,
    { field: "totalBrutoEarnings", title: this.translate.instant("sales-export.totalBrutoEarnings"), hide: false } as DatatableColumn,
    { field: "totalNettoEarnings", title: this.translate.instant("sales-export.totalNettoEarnings"), hide: false } as DatatableColumn,
  ];
}
