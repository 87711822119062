import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OtherServices } from "../../models/otherservices";
import { OtherServicesService } from "../../services/other-services.service";

@Component({
  selector: "app-other-services.details",
  templateUrl: "./other-services.details.component.html",
})
export class OtherServicesDetailsComponent {
  isShowingSidebar = false;
  protected id!: number;
  protected otherService!: OtherServices;
  public planid!: number;
	public entityname = "planservices";

  constructor(private otherserviceService: OtherServicesService, private route: ActivatedRoute, public translate: TranslateService) {}
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchOtherServiceData();
      }
    });
  }

  fetchOtherServiceData() {
    this.otherserviceService.getOtherServiceData(this.id).subscribe((response) => {
      this.otherService = response;
			this.planid = this.otherService.planId;
    });
  }
}
