import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-pencil",
  template: `
    <ng-template #template>
      <svg *ngIf="fill" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="class">
        <path
          opacity="0.5"
          d="M15.636 6.53479C16.7878 5.38301 16.7878 3.51561 15.636 2.36383C14.4843 1.21206 12.6169 1.21206 11.4651 2.36383L10.7998 3.02911C10.8089 3.05662 10.8184 3.08451 10.8282 3.11277C11.072 3.81562 11.5321 4.737 12.3976 5.60252C13.2631 6.46803 14.1845 6.92811 14.8874 7.17196C14.9155 7.18172 14.9432 7.19113 14.9706 7.2002L15.636 6.53479Z"
          fill="currentColor"
        />
        <path
          d="M10.8289 3.00049L10.8003 3.02913C10.8094 3.05664 10.8189 3.08453 10.8287 3.11279C11.0725 3.81564 11.5326 4.73703 12.3981 5.60254C13.2636 6.46805 14.185 6.92813 14.8879 7.17198C14.9157 7.18166 14.9433 7.19099 14.9704 7.19998L8.55008 13.6203C8.11723 14.0532 7.90076 14.2696 7.66212 14.4558C7.38061 14.6753 7.07602 14.8636 6.75373 15.0172C6.48052 15.1474 6.19014 15.2442 5.60938 15.4378L2.54688 16.4586C2.26108 16.5539 1.94599 16.4795 1.73297 16.2665C1.51995 16.0534 1.44557 15.7384 1.54084 15.4526L2.56167 12.3901C2.75526 11.8093 2.85205 11.5189 2.98226 11.2457C3.13585 10.9234 3.3241 10.6188 3.54367 10.3373C3.7298 10.0987 3.94623 9.88225 4.37906 9.44942L10.8289 3.00049Z"
          fill="currentColor"
        />
      </svg>

      <svg *ngIf="!fill" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="class">
        <path
          d="M15.2869 3.15178L14.3601 4.07866L5.83882 12.5999L5.83881 12.5999C5.26166 13.1771 4.97308 13.4656 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.32181 19.8021L2.05445 20.6042C1.92743 20.9852 2.0266 21.4053 2.31063 21.6894C2.59466 21.9734 3.01478 22.0726 3.39584 21.9456L4.19792 21.6782L7.47918 20.5844L7.47919 20.5844C8.25353 20.3263 8.6407 20.1973 9.00498 20.0237C9.43469 19.8189 9.84082 19.5679 10.2162 19.2751C10.5344 19.0269 10.8229 18.7383 11.4001 18.1612L11.4001 18.1612L19.9213 9.63993L20.8482 8.71306C22.3839 7.17735 22.3839 4.68748 20.8482 3.15178C19.3125 1.61607 16.8226 1.61607 15.2869 3.15178Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          opacity="0.5"
          d="M14.36 4.07812C14.36 4.07812 14.4759 6.04774 16.2138 7.78564C17.9517 9.52354 19.9213 9.6394 19.9213 9.6394M4.19789 21.6777L2.32178 19.8015"
          stroke="currentColor"
          stroke-width="1.5"
        />
      </svg>
    </ng-template>
  `,
})
export class IconPencilComponent {
  @Input() fill: boolean = false;
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
