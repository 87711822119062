<div *ngIf="columnChart">
  <apx-chart
    *ngIf="
      columnChart.series &&
      columnChart.chart &&
      columnChart.colors &&
      columnChart.dataLabels &&
      columnChart.stroke &&
      columnChart.plotOptions &&
      columnChart.grid &&
      columnChart.xaxis &&
      columnChart.yaxis &&
      columnChart.tooltip
    "
    [series]="columnChart.series"
    [chart]="columnChart.chart"
    [colors]="columnChart.colors"
    [dataLabels]="columnChart.dataLabels"
    [stroke]="columnChart.stroke"
    [plotOptions]="columnChart.plotOptions"
    [grid]="columnChart.grid"
    [xaxis]="columnChart.xaxis"
    [yaxis]="columnChart.yaxis"
    [tooltip]="columnChart.tooltip"
    class="mt-3 rounded-lg bg-white dark:bg-black"
  >
  </apx-chart>
</div>
