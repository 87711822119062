import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { StyleUtil } from "src/app/shared/utils/style.util";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Travel } from "src/app/domain/travels/models/travel";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CheckoutService } from "../../services/checkout.service";
import { Organizer } from "src/app/domain/organizers/models/oragnizers";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { Customer, PassangerTypes } from "../../../domain/customers/models/customer";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { TravelCreateComponent } from "src/app/domain/travels/travel.create/travel.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
import { TravelDestinationCreateComponent } from "src/app/domain/travels/travel-destination.create/travel-destination.create.component";

@Component({
  templateUrl: "./travel-checkout.edit.component.html",
})
export class TravelCheckoutEditComponent implements OnInit {
  @ViewChild("mainDestination") mainDestination!: TravelDestinationCreateComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("travelBase") travelComponent!: TravelCreateComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  public allPassangers: Customer[] = [];
  public travelDestinations: TravelDestination[] = [];
  public editDestination!: TravelDestination | undefined;
  organizer!: Organizer;
  public activeTab = 1;
  note!: string;
  public id!: number;
  public sale!: SaleBase;
  public travel!: Travel;
  public customer!: Customer;
  public isExistsAnnouncement!: boolean;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public tabSizeHandler = StyleUtil.getTabWidth;

  constructor(private router: Router, private route: ActivatedRoute, public checkoutService: CheckoutService, public translate: TranslateService) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.route.queryParams.subscribe((params) => {
        const activeTab = parseInt(params["activeTab"]);
        this.activeTab = activeTab;
        if (this.router.url.includes("draft/edit") || this.router.url.includes("draft/accept")) {
          this.isExistsAnnouncement = true;
          this.fetchEntities();
        } else {
          this.isExistsAnnouncement = false;
        }
      });
    });
  }

  fetchEntities() {
    this.checkoutService.fetchTravel(this.id).subscribe((response) => {
      const { passangers, customer, plan, travelDestinations } = response;
      if (passangers) {
        const customerPassangersIndex = passangers?.findIndex((p) => p.id === customer.id);
        if (customerPassangersIndex !== -1) {
          passangers[customerPassangersIndex].lifeStage = PassangerTypes.mainCustomer;
          passangers[customerPassangersIndex].mainCustomer = true;
          this.customer = passangers[customerPassangersIndex];
        }
        this.passangerList.push(passangers);
      }

      this.saleComponent.initForm(DateTimeFormatter.formatPlanDates({ ...plan, customer: customer, customerId: customer.id }));
      this.sale = this.saleComponent.getSale();
      this.note = plan.notes ?? "";

      this.travelComponent.initForm(DateTimeFormatter.formatTravelDates(response));
      this.travel = this.travelComponent.getTravel();

      for (const travelDestination of travelDestinations) {
        if (travelDestination.isMainDestination) {
          this.mainDestination.initForm(travelDestination);
          continue;
        }
        this.travelDestinations.push(
          DateTimeFormatter.formatTravelDestinationDates({
            ...travelDestination,
          } as TravelDestination)
        );
      }
    });
  }

  onNotify(data: any) {
    this.mainDestination.setStartAndEnd(data);
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }

  createReservationObject(): any {
    const { start, end, type, days, nights, organizer, departure } = this.travel;
    const { bruto, neto, avans, dueDate, paymentTypeId } = this.sale;
    const { id } = this.customer;
    const isoDueDate = moment(dueDate, "DD.MM.YYYY").toISOString();

    // Extracting necessary IDs
    const organizerId: number = organizer?.id;
    const departureId: number | undefined = departure ? departure?.id : undefined;

    const passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, lifeStage: e.lifeStage };
    });

    if (this.sale.withAvans === 0) {
      this.sale.avans = undefined;
    }

    const destinations = [...this.travelDestinations, { ...this.mainDestination.getTravel(), isMainDestination: true } as TravelDestination];

    const newReservation = {
      start: moment(start, "DD.MM.YYYY").toISOString(),
      end: moment(end, "DD.MM.YYYY").toISOString(),
      days,
      nights,
      typeId: type?.id,
      customerId: id,
      numberOfPassangers: passangers.length,
      plan: { bruto, neto, avans, dueDate: isoDueDate, customerId: id, notes: this.note, paymentTypeId },
      planId: this.sale.id,
      passangers,
      departureId,
      organizerId,
      travelDestinations: destinations.map((p) => {
        const { id, travelId, destination, hotel, start: travelStart, end: travelEnd, service, days, nights, roomDescription } = p;
        return {
          id,
          start: moment(travelStart, "DD.MM.YYYY").toISOString(),
          end: moment(travelEnd, "DD.MM.YYYY").toISOString(),
          days,
          nights,
          roomDescription,
          serviceId: service?.id,
          destinationId: destination?.id,
          hotelId: hotel?.id,
          travelId: travelId,
        };
      }),
    };

    return newReservation;
  }

  updateTravelDraft() {
    this.travel = this.travelComponent.getTravel();

    const travelDraft = this.createReservationObject();
    if (this.editDestination != null) {
      showMessage(PopupType.Danger, "Please save destination before proceeding");
      return;
    }

    if (!this.travelComponent.checkIfValid()) {
      showMessage(PopupType.Danger, "Travel hasn't been defined fully");
      return;
    }

    if (!this.mainDestination.checkIfValid()) {
      showMessage(PopupType.Danger, "Main destination is missing some info please add them");
      return;
    }

    if (!this.passangerList.checkValidity()) {
      showMessage(PopupType.Danger, "Passanger Validation failed");
      return;
    }

    if (!this.saleComponent.checkIfValid()) {
      showMessage(PopupType.Danger, "Sale object is not valid");
      return;
    }

    this.checkoutService.updateTravelDraft(this.id, { ...travelDraft, ...travelDraft.plan }).subscribe(
      (r) => {
        showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
        this.proceedToNextStage(this.activeTab + 1);
      },
      (error) => {
        showMessage(PopupType.Danger, error);
      }
    );
  }

  handleVoucherSent() {
    this.proceedToNextStage(this.activeTab + 1);
  }

  proceedToNextStage(nextStep: number) {
    if (nextStep == 2) {
      this.customer = this.passangerList.getPassangers().find((e) => e.lifeStage === PassangerTypes.mainCustomer) as Customer;
      if (this.customer.name === undefined || this.customer.surname === undefined || !this.passangerList.checkValidity()) {
        showMessage(PopupType.Danger, "Main customer is required or please save the passanger");
        return;
      }
    }

    if (nextStep == 3 && this.editDestination != null) {
      showMessage(PopupType.Danger, "Please save destination before proceeding");
      return;
    }

    if (nextStep === 3) {
      if (!this.isExistsAnnouncement) {
        this.activeTab = 4;
        return;
      }
    }

    if (this.isExistsAnnouncement && nextStep === 3) {
      this.activeTab = 4;
      return;
    }

    if (!this.isExistsAnnouncement && nextStep === 3) {
      this.activeTab = 4;
      return;
    }

    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  returnBack() {
    this.activeTab = this.activeTab - 1;
    if (!this.isExistsAnnouncement && this.activeTab == 3) {
      this.activeTab = 2;
    }
  }

  toEditDestination(destination: TravelDestination) {
    this.editDestination = undefined;
    setTimeout(() => {
      this.editDestination = destination;
    });
  }

  addDestination() {
    this.travelDestinations.push({
      guid: crypto.randomUUID(),
      id: undefined,
      start: undefined,
      end: undefined,
      days: undefined,
      nights: undefined,
      notes: undefined,
      service: undefined,
      destination: undefined,
      hotel: undefined,
      travelId: undefined,
      serviceId: undefined,
      destinationId: undefined,
      hotelId: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      roomDescription: undefined,
      voucher: undefined,
      isMainDestination: false,
    });
  }

  removeDestination(id: string | undefined) {
    this.editDestination = undefined;
    this.travelDestinations = this.travelDestinations.filter((e) => e.guid != id);
    showMessage(PopupType.Success, "Destination removed successfully");
  }

  handleDestination(destination: TravelDestination) {
    const updatedDestinations = this.travelDestinations.map((e) => {
      if (e.guid === destination.guid) {
        return { ...e, ...destination };
      }
      return e;
    });

    this.travelDestinations = updatedDestinations;

    showMessage(PopupType.Success, "Destination saved successfully");
    this.editDestination = undefined;
  }
}
