import { Component, OnInit } from "@angular/core";
import { DailySaleDocument } from "../../models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { SalesService } from "../../services/sales.service";


@Component({
  selector: "app-daily-sale.document",
  templateUrl: "./daily-sale.document.component.html",
})
export class DailySaleDocumentComponent implements OnInit {
  constructor(private salesService: SalesService, private route: ActivatedRoute, private router: Router) {}
  dailySaleDocument!: DailySaleDocument;
  cashRegisterId!: number;
  totalPrice = 0;
  denomination = [5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1];
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.cashRegisterId = params.get("id") ? parseInt(params.get("id")!) : 0;
      this.getDailySaleDocument(this.cashRegisterId);
    });
  }

  getDailySaleDocument(id: number) {
    this.salesService.getDailySaleDocument(id).subscribe((response) => {
      this.dailySaleDocument = response;
      this.dailySaleDocument.items.forEach((item) => {
        this.totalPrice += item.price;
      });
    });
  }
  editDocument() {
    this.router.navigate([`/dashboard/daily-sale/document/edit/${this.cashRegisterId}`]);
  }
}
