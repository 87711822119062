<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="name">{{ editMode ? 'Update' : 'Add' }} Name</label> 
    <input id="name" type="text" placeholder="Enter name" class="form-input" formControlName="name" />
    <small class="text-danger" *ngIf="form.controls['name'].touched && form.controls['name'].errors"></small>
  </div>
  
  <button type="submit" class="btn btn-primary w-full" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingTemplate">{{ editMode ? 'Update' : 'Save' }}</ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle"></span> {{"loading" | translate}}
    </ng-template>
  </button>
</form>
