import { Office } from "../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { filter, Observable, of, switchMap } from "rxjs";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FileUploadWithPreview } from "file-upload-with-preview";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OfficeBranchService } from "../services/office-branch.service";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ETouristCity, ETouristMunicipality, ETouristStreet } from "src/app/shared/models/etourist";

@Component({
	selector: "app-office-branch-location-form",
	templateUrl: "./office-branch-location.form.component.html",
})
export class OfficeBranchLocationFormComponent implements OnInit {
	@Output() submissionDone: EventEmitter<any> = new EventEmitter();

	public office!: Office;

	public form!: FormGroup;
	public imageUpload!: FileUploadWithPreview;

	public optionsMunicipality$!: Observable<ETouristMunicipality[]>;
	public optionsCities$!: Observable<ETouristCity[]>;
	public optionsStreets$!: Observable<ETouristStreet[]>;

	options = ["admin", "seller"];

	@Input() id!: number;
	constructor(public fb: FormBuilder, public officeBranchService: OfficeBranchService, public eTouristService: ETouristService, public translate: TranslateService) { }

	initForm(office: Office): void {
		const { municipality, municipalityId, city, cityId, street, streetId } = office || ({} as Office);

		this.form = this.fb.group({
			municipality: [{ nameLatin: municipality, municipalityId: municipalityId }, Validators.required],
			city: [{ nameLatin: city, cityId: cityId }, Validators.required],
			street: [{ nameLatin: street, streetId: streetId }, Validators.required],
		});

		this.setupValueChanges();
	}

	submitForm(): void {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}
		this.fetchOffice();
		this.officeBranchService.updateOffice(this.id, this.fetchOffice()).subscribe(
			(response) => {
				showMessage(PopupType.Success, this.translate.instant("popup.worker_updated_successfully"));
				this.initForm({} as Office);
				this.submissionDone.emit(null);
			},
			(error) => {
				showMessage(PopupType.Danger, error.toString());
			}
		);
	}

	ngOnInit(): void {
		if (this.id !== undefined) {
			this.officeBranchService.getOfficeById(this.id).subscribe((office) => {
				this.office = office;
				this.initForm(office);
			});
		} else {
			this.initForm({} as Office);
		}

		this.fetchOptions();
	}

	fetchOptions() {
		this.optionsMunicipality$ = this.eTouristService.getMunicipality();
	}

	setupValueChanges() {
		this.form
			.get("municipality")
			?.valueChanges.pipe(
				filter((value) => !!value),
				switchMap((municipality: ETouristMunicipality) => {
					this.form.get("city")?.reset();
					this.form.get("city")?.enable();
					this.form.get("street")?.reset();
					this.form.get("street")?.disable();
					return this.eTouristService.getCities(municipality.identificationNumber);
				})
			)
			.subscribe((cities) => {
				this.optionsCities$ = of(cities);
			});

		this.form
			.get("city")
			?.valueChanges.pipe(
				filter((value) => !!value),
				switchMap((city: ETouristCity) => {
					this.form.get("street")?.reset();
					this.form.get("street")?.enable();
					return this.eTouristService.getStreets(city.identificationNumber);
				})
			)
			.subscribe((streets) => {
				this.optionsStreets$ = of(streets);
			});
	}

	changed(value: any) {
		console.log(value);
	}

	fetchOffice(): Office {
		const { name, email, guid, phoneNumber, logoURL, bankAccount, pib, siteURL, senderEmail, senderName, adminEmail, password } = this.office;
		const { municipality, city, street } = this.form.getRawValue();

		return {
			name,
			email,
			guid,
			phoneNumber,
			municipality: municipality.nameLatin,
			city: city.nameLatin,
			street: street.nameLatin,
			municipalityId: municipality.municipalityId,
			cityId: city.localityId,
			streetId: street.streetId,
			logoURL,
			bankAccount,
			pib,
			siteURL,
			senderEmail,
			senderName,
			adminEmail,
			password,
		} as Office;
	}
}
