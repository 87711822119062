import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
	moduleId: module.id,
	selector: "icon-top-right",
	template: `
    <ng-template #template>
  	 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                </svg>
    </ng-template>
  `,
})
export class IconTopRightComponent {
	@ViewChild("template", { static: true }) template: any;
	constructor(private viewContainerRef: ViewContainerRef) { }
	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}
