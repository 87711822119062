import Swal from "sweetalert2";
import { PopupType } from "../enums/popup-types";
export function showMessage(type = PopupType.Primary, msg = "Example notification text.", showCloseButton = true, duration = 3000) {
  const toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: type === PopupType.Danger ? undefined : duration, // Set timer to null for danger type
    customClass: {
      popup: type,
    },
    width: "35rem",
    showCloseButton: showCloseButton,
  });
  toast.fire({
    title: msg,
  });
}
