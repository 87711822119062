import { FormGroup } from "@angular/forms";

export function brutoLessThanNetoValidator(group: FormGroup) {
  const brutoControl = group.get("bruto");
  const netoControl = group.get("neto");

  if (brutoControl && netoControl) {
    const bruto = parseFloat(brutoControl.value);
    const neto = parseFloat(netoControl.value);

    if (neto >= bruto) {
      brutoControl.setErrors({ brutoLessThanNeto: true });
      return { netoLessThanBruto: true };
    } else {
      // Clear the error if the validation passes
      if (brutoControl.errors && brutoControl.errors["brutoLessThanNeto"]) {
        delete brutoControl.errors["brutoLessThanNeto"];
        if (Object.keys(brutoControl.errors).length === 0) {
          brutoControl.setErrors(null);
        }
      }
    }
  }

  return null;
}
