import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-destination",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M1.5,11.3C1.5,11.3,1.5,11.3,1.5,11.3c0.4-0.4,1-0.4,1.4,0c0.2,0.3,0.4,0.6,0.7,0.9c0.3,0.4,0.6,0.8,0.9,1.2
		c0.1,0.1,0.2,0.2,0.3,0.2c1.4,0,2.7,0,4.1,0c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4,0-0.7,0
		c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.5,0.7,0.7,1c0.1,0.1,0.2,0.1,0.2,0.1c0.3,0,0.6,0,0.9,0v-8c-0.7,0.1-1.4,0.2-2,0.7
		C8.2,8.3,8.1,8.4,7.9,8.3c-0.6-0.5-1.3-0.6-2-0.7c-0.8-0.1-1.5,0-2.3,0.4C3.5,8,3.3,8.1,3.2,8.2C3.1,8.3,2.9,8.4,2.8,8.3
		C2.6,8.2,2.6,8.1,2.6,7.9c0-1.4,0.5-2.7,1.4-3.7c1.1-1.4,2.6-2.3,4.4-2.7C9,1.4,9.6,1.3,10.2,1.2c0,0,0.1,0,0.1,0
		c0.1-0.3-0.1-0.6,0.3-0.6c0.2,0,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c1.6,0.1,3.1,0.6,4.4,1.5c1.5,1,2.6,2.4,3,4.3
		c0.1,0.3,0.1,0.7,0.1,1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3,0c-0.7-0.6-1.5-0.7-2.4-0.7c-0.6,0-1.3,0.1-1.8,0.4
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0c-0.6-0.4-1.3-0.6-2-0.6c0,2.7,0,5.3,0,8c0.1,0,0.2,0,0.3,0c2,0,4.1,0,6.1,0
		c0.4,0,0.7,0.1,1,0.5c0.1,0.2,0.1,0.4,0.1,0.7c-0.1,0.4-0.3,0.6-0.7,0.7c-0.1,0-0.1,0-0.2,0.1c0.1,0.4,0.1,0.8,0.2,1.2
		c0,0.1,0,0.3,0.1,0.4c0,0.2-0.1,0.3-0.3,0.4c-0.2,0-0.3-0.1-0.3-0.3c-0.1-0.5-0.2-1-0.3-1.5c0-0.1-0.1-0.2-0.2-0.2
		c-1.9,0-3.8,0-5.7,0c0,0-0.1,0-0.1,0c0,0.1,0,0.1,0,0.2c0,0.5,0,0.9,0,1.4c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3,0-0.3-0.2
		c0-0.1,0-0.2,0-0.3c0-0.4,0-0.9,0-1.3c0-0.1,0-0.1,0-0.2c-0.1,0-0.2,0-0.2,0c-1.5,0-3,0-4.5,0c-0.2,0-0.2,0-0.2,0.2
		c-0.1,0.5-0.2,0.9-0.2,1.4c0,0.2-0.2,0.4-0.4,0.3c-0.2,0-0.3-0.2-0.2-0.4c0.1-0.5,0.2-1.1,0.3-1.6c0-0.1,0-0.1,0-0.2
		c-0.4-0.6-0.9-1.3-1.4-1.9c-0.6-0.8-1.2-1.7-1.8-2.5c0-0.1-0.1-0.1-0.1-0.2C1.2,12.2,1.2,11.7,1.5,11.3z M12.9,7.4
		C12.9,7.4,12.9,7.4,12.9,7.4c-0.1-1.4-0.2-2.7-0.7-3.9c-0.2-0.5-0.4-1-0.8-1.4c-0.4-0.4-1-0.5-1.4,0c-0.3,0.3-0.5,0.7-0.7,1
		C8.7,4.5,8.5,5.9,8.4,7.4c0,0,0,0,0,0C9.9,6.8,11.4,6.8,12.9,7.4z M9.3,1.9C8.5,2,7.8,2.3,7.1,2.6c-1.7,0.8-3,2-3.6,3.8
		C3.4,6.7,3.3,7,3.2,7.4c1.6-0.6,3.1-0.6,4.6,0C8.1,4.2,8.2,3.8,9.3,1.9z M18.1,7.4C18.1,7.4,18.1,7.3,18.1,7.4
		c-0.1-1.1-0.6-2.1-1.2-2.9c-1.1-1.4-2.6-2.2-4.3-2.5C12.4,2,12.2,2,12,1.9c1.2,1.7,1.4,3.6,1.5,5.5C15.1,6.8,16.6,6.8,18.1,7.4z
		 M11.5,16.9c2,0,3.9,0,5.9,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0-0.3,0
		c-3.8,0-7.6,0-11.3,0c-0.2,0-0.4,0-0.5-0.2c-1-1.4-1.9-2.7-2.9-4c-0.2-0.3-0.4-0.4-0.6-0.2c-0.2,0.1-0.2,0.3,0,0.6
		c1,1.4,2,2.8,3,4.3c0.2,0.2,0.3,0.3,0.6,0.3C7.6,16.9,9.5,16.9,11.5,16.9z M8.6,15.6c-0.3-0.4-0.6-0.8-0.9-1.2
		c-0.1-0.1-0.2-0.1-0.3-0.1c-0.7,0-1.5,0-2.2,0c-0.1,0-0.1,0-0.2,0c0.3,0.4,0.6,0.9,0.9,1.3c0,0,0.1,0.1,0.2,0.1c0.5,0,1.1,0,1.6,0
		C8,15.6,8.3,15.6,8.6,15.6z"
          />
          <path
            class="st1"
            d="M12.9,7.4c-1.5-0.6-3-0.6-4.5,0c0,0,0,0,0,0c0.1-1.4,0.3-2.8,0.9-4.2c0.2-0.4,0.4-0.7,0.7-1
		c0.4-0.5,0.9-0.4,1.4,0C11.8,2.5,12,3,12.2,3.5C12.7,4.8,12.9,6,12.9,7.4C12.9,7.4,12.9,7.4,12.9,7.4z"
          />
          <path
            class="st1"
            d="M9.3,1.9C8.2,3.8,8.1,4.2,7.8,7.4c-1.5-0.7-3-0.7-4.6,0C3.3,7,3.4,6.7,3.5,6.3c0.6-1.8,1.9-3,3.6-3.8
		C7.8,2.3,8.5,2,9.3,1.9z"
          />
          <path
            class="st1"
            d="M18.1,7.4c-1.5-0.6-3-0.6-4.5,0c-0.1-1.9-0.4-3.8-1.5-5.5c0.2,0,0.3,0,0.5,0.1c1.7,0.4,3.2,1.2,4.3,2.5
		C17.5,5.4,17.9,6.3,18.1,7.4C18.1,7.3,18.1,7.4,18.1,7.4z"
          />
          <path
            class="st1"
            d="M11.5,16.9c-1.9,0-3.9,0-5.8,0c-0.3,0-0.4-0.1-0.6-0.3c-1-1.4-2-2.8-3-4.3c-0.2-0.3-0.2-0.5,0-0.6
		c0.2-0.1,0.4-0.1,0.6,0.2c1,1.3,1.9,2.7,2.9,4c0.1,0.2,0.3,0.2,0.5,0.2c3.8,0,7.6,0,11.3,0c0.1,0,0.2,0,0.3,0
		c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2,0-0.3,0C15.4,16.9,13.4,16.9,11.5,16.9z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuDestinationComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
