<div class="grid grid-cols-1 gap-5 pt-3 md:grid-cols-2">
  <div class="col-md-6" *ngIf="popularCountriesByTicket && popularCountriesByTicket.length > 0">
    <h3>{{ "chart_statistics.popular_countries_by_ticket" | translate }}</h3>
    <div *ngIf="radarCharts['popularCountriesByTicket']">
      <apx-chart
        [series]="radarCharts['popularCountriesByTicket'].series"
        [chart]="radarCharts['popularCountriesByTicket'].chart"
        [colors]="radarCharts['popularCountriesByTicket'].colors"
        [dataLabels]="radarCharts['popularCountriesByTicket'].dataLabels"
        [stroke]="radarCharts['popularCountriesByTicket'].stroke"
        [plotOptions]="radarCharts['popularCountriesByTicket'].plotOptions"
        [grid]="radarCharts['popularCountriesByTicket'].grid"
        [xaxis]="radarCharts['popularCountriesByTicket'].xaxis"
        [yaxis]="radarCharts['popularCountriesByTicket'].yaxis"
        [tooltip]="radarCharts['popularCountriesByTicket'].tooltip"
        class="mb-3 mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>
  <div class="col-md-6" *ngIf="popularCountriesByTravel && popularCountriesByTravel.length > 0">
    <h3>{{ "chart_statistics.popular_countries_by_travel" | translate }}</h3>
    <div *ngIf="radarCharts['popularCountriesByTravel']">
      <apx-chart
        [series]="radarCharts['popularCountriesByTravel'].series"
        [chart]="radarCharts['popularCountriesByTravel'].chart"
        [colors]="radarCharts['popularCountriesByTravel'].colors"
        [dataLabels]="radarCharts['popularCountriesByTravel'].dataLabels"
        [stroke]="radarCharts['popularCountriesByTravel'].stroke"
        [plotOptions]="radarCharts['popularCountriesByTravel'].plotOptions"
        [grid]="radarCharts['popularCountriesByTravel'].grid"
        [xaxis]="radarCharts['popularCountriesByTravel'].xaxis"
        [yaxis]="radarCharts['popularCountriesByTravel'].yaxis"
        [tooltip]="radarCharts['popularCountriesByTravel'].tooltip"
        class="mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>
</div>
<div class="grid grid-cols-1 gap-5 pt-3 md:grid-cols-2">
  <div class="col-md-6" *ngIf="popularCountriesByGroupTravel && popularCountriesByGroupTravel.length > 0">
    <h3>{{ "chart_statistics.popular_countries_by_grouptravel" | translate }}</h3>
    <div *ngIf="radarCharts['popularCountriesByGroupTravel']">
      <apx-chart
        [series]="radarCharts['popularCountriesByGroupTravel'].series"
        [chart]="radarCharts['popularCountriesByGroupTravel'].chart"
        [colors]="radarCharts['popularCountriesByGroupTravel'].colors"
        [dataLabels]="radarCharts['popularCountriesByGroupTravel'].dataLabels"
        [stroke]="radarCharts['popularCountriesByGroupTravel'].stroke"
        [plotOptions]="radarCharts['popularCountriesByGroupTravel'].plotOptions"
        [grid]="radarCharts['popularCountriesByGroupTravel'].grid"
        [xaxis]="radarCharts['popularCountriesByGroupTravel'].xaxis"
        [yaxis]="radarCharts['popularCountriesByGroupTravel'].yaxis"
        [tooltip]="radarCharts['popularCountriesByGroupTravel'].tooltip"
        class="mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>

  <div class="col-md-6" *ngIf="popularCountriesByTransfer && popularCountriesByTransfer.length > 0">
    <h3>{{ "chart_statistics.popular_countries_by_transfer" | translate }}</h3>
    <div *ngIf="radarCharts['popularCountriesByTransfer']">
      <apx-chart
        [series]="radarCharts['popularCountriesByTransfer'].series"
        [chart]="radarCharts['popularCountriesByTransfer'].chart"
        [colors]="radarCharts['popularCountriesByTransfer'].colors"
        [dataLabels]="radarCharts['popularCountriesByTransfer'].dataLabels"
        [stroke]="radarCharts['popularCountriesByTransfer'].stroke"
        [plotOptions]="radarCharts['popularCountriesByTransfer'].plotOptions"
        [grid]="radarCharts['popularCountriesByTransfer'].grid"
        [xaxis]="radarCharts['popularCountriesByTransfer'].xaxis"
        [yaxis]="radarCharts['popularCountriesByTransfer'].yaxis"
        [tooltip]="radarCharts['popularCountriesByTransfer'].tooltip"
        class="mt-3 rounded-lg bg-white dark:bg-black"
      ></apx-chart>
    </div>
  </div>
</div>
