import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
	moduleId: module.id,
	selector: "icon-menu-group-travel",
	styleUrls: ["../icons.css"],

	template: `
    <ng-template #template>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
<style type="text/css">
    .st0{fill:#2B2D41;}
</style>
<g>
    <g>
        <path fill="currentColor" d="M19.3,11.9c-0.2-0.6-0.6-1.2-1.1-1.6c-0.3-0.2-0.6-0.4-0.9-0.6c0.8-1,0.9-1.9,0.1-2.7
            c-0.6-0.7-1.7-0.8-2.4-0.2c-0.4,0.3-0.6,0.7-0.7,1.1c-0.1,0.7,0.1,1.3,0.7,1.8c-0.6,0.2-1.1,0.6-1.5,1C13,10,12.2,9.5,11.3,9.2
            c1.3-0.9,1.3-2.5,0.5-3.5c-0.8-1-2.3-1.1-3.3-0.4C8,5.8,7.7,6.3,7.7,7c-0.1,0.9,0.3,1.7,1,2.2C8.2,9.4,7.8,9.6,7.4,9.8
            c-0.4,0.3-0.7,0.6-1,0.9c-0.2-0.2-0.4-0.4-0.6-0.6C5.5,10,5.3,9.9,5,9.8C6,8.9,5.8,7.7,5.1,7C4.4,6.3,3.3,6.3,2.6,7
            C2.2,7.3,2,7.7,2,8.2c0,0.6,0.2,1.1,0.7,1.5c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.5,0.2-1,0.6-1.3,1c-0.3,0.4-0.5,0.8-0.6,1.2v0.4
            c0.3,0.7,0.5,0.8,1.2,0.8h3.8v0.5c0,0.9,0.6,1.4,1.5,1.4h5.8c0.9,0,1.4-0.6,1.4-1.4v-0.5h0.2c1.3,0,2.5,0,3.8,0
            C19.1,13.2,19.6,12.6,19.3,11.9z M8.2,7.2c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C11.8,8.2,11,9,10,9C9,9,8.2,8.2,8.2,7.2z
             M2.6,8.3c0-0.7,0.6-1.2,1.3-1.2c0.7,0,1.2,0.6,1.2,1.3c0,0.7-0.6,1.2-1.3,1.2C3.1,9.6,2.6,9,2.6,8.3z M6,11.5
            c-0.1,0.4-0.3,0.7-0.4,1.1H1.7c-0.4,0-0.6-0.2-0.5-0.6c0.4-1,1.1-1.6,2.1-1.8c1.1-0.2,2,0.2,2.7,1C6.1,11.3,6.1,11.4,6,11.5z
             M13.7,13.9c0,0.4-0.4,0.7-0.8,0.7H7.1c-0.5,0-0.8-0.3-0.9-0.9c0-0.8,0.1-1.6,0.5-2.3c0.8-1.3,2.4-2.1,3.9-1.8
            c1.6,0.3,2.8,1.5,3.1,3.1C13.8,13.1,13.8,13.5,13.7,13.9z M14.9,8.3c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2
            c0,0.7-0.6,1.3-1.2,1.3C15.5,9.6,14.9,9,14.9,8.3z M18.3,12.6c-1.1,0-2.2,0-3.3,0h-0.7c-0.2-0.4-0.3-0.8-0.4-1.2
            c0-0.1,0-0.2,0.1-0.2c1.1-1.5,3.4-1.4,4.5,0.1c0.1,0.2,0.2,0.4,0.3,0.7C18.9,12.4,18.7,12.6,18.3,12.6z"/>
    </g>
</g>
</svg>

    </ng-template>
  `,
})
export class IconMenuGroupTravelComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;

	constructor(private viewContainerRef: ViewContainerRef) { }

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}