import { Component, Input } from "@angular/core";
import { Voucher } from "src/app/domain/travels/models/voucher";
@Component({
  selector: "app-voucher-template",
  templateUrl: "./voucher-template.component.html",
})
export class VoucherTemplateComponent {
  @Input() voucher!: Voucher;
  today: string = new Date().toLocaleDateString("sr-RS");
}
