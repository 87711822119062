import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-company",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M18.8,18.7c0.2,0,0.3,0,0.5,0c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5c-0.1,0-0.2,0-0.2,0
		 c-6,0-12,0-18.1,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.4-0.2-0.4-0.5c0-0.3,0.2-0.5,0.4-0.5c0.2,0,0.3,0,0.5,0c0-0.1,0-0.2,0-0.3
		 c0-4.7,0-9.5,0-14.2c0-0.7,0.3-1,0.9-1.2C6,2.1,9.8,1.3,13.6,0.4c0.8-0.2,1.3,0.2,1.3,1c0,0.6,0,1.1,0,1.7c0,0.2,0,0.3,0.2,0.3
		 c1,0.5,2,1,3,1.5c0.5,0.2,0.7,0.6,0.7,1.1c0,4.1,0,8.3,0,12.4C18.8,18.5,18.8,18.6,18.8,18.7z M13.9,18.7c0-5.8,0-11.6,0-17.4
		 c-0.1,0-0.1,0-0.1,0C10,2.2,6.2,3.1,2.5,3.9C2.3,4,2.2,4.1,2.2,4.3c0,4.7,0,9.4,0,14.1c0,0.1,0,0.2,0,0.3c1.3,0,2.6,0,3.9,0
		 c0-0.1,0-0.2,0-0.3c0-0.6,0-1.1,0-1.7c0-0.6,0.4-1,1-1c0.6,0,1.3,0,1.9,0c0.5,0,1,0.4,1,1c0,0.6,0,1.1,0,1.7c0,0.1,0,0.1,0,0.2
		 C11.3,18.7,12.6,18.7,13.9,18.7z M14.9,4.4c0,4.8,0,9.5,0,14.3c1,0,1.9,0,2.9,0c0-0.1,0-0.2,0-0.3c0-3.9,0-7.9,0-11.8
		 c0-0.6-0.1-0.9-0.7-1.1C16.4,5.2,15.7,4.8,14.9,4.4z M7.1,16.8c0,0.7,0,1.3,0,1.9c0.6,0,1.3,0,1.9,0c0-0.6,0-1.3,0-1.9
		 C8.4,16.8,7.7,16.8,7.1,16.8z"
          />
          <path
            class="st1"
            d="M13.9,18.7c-1.3,0-2.6,0-3.9,0c0-0.1,0-0.2,0-0.2c0-0.6,0-1.1,0-1.7c0-0.6-0.4-1-1-1c-0.6,0-1.3,0-1.9,0
		 c-0.6,0-1,0.4-1,1c0,0.6,0,1.1,0,1.7c0,0.1,0,0.2,0,0.3c-1.3,0-2.6,0-3.9,0c0-0.1,0-0.2,0-0.3c0-4.7,0-9.4,0-14.1
		 c0-0.2,0.1-0.3,0.3-0.3C6.2,3.1,10,2.2,13.7,1.3c0,0,0.1,0,0.1,0C13.9,7.1,13.9,12.9,13.9,18.7z M5.1,8c0.2,0,0.3,0,0.5,0
		 c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1c0-0.3-0.2-0.5-0.5-0.5C5.3,6,5,6,4.6,6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0,0.7,0,1
		 C4.2,7.8,4.4,8,4.6,8C4.8,8,5,8,5.1,8z M8.1,6C8.1,6,8.1,6,8.1,6C7.9,6,7.7,6,7.6,6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0,0.7,0,1
		 C7.1,7.8,7.3,8,7.6,8c0.3,0,0.6,0,1,0C8.8,8,9,7.8,9,7.5c0-0.3,0-0.7,0-1C9,6.2,8.8,6.1,8.5,6C8.4,6,8.2,6,8.1,6z M11,8
		 C11,8,11,8,11,8c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1c0-0.3-0.2-0.4-0.5-0.4c-0.3,0-0.7,0-1,0
		 c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0,0.7,0,1C10,7.8,10.2,8,10.5,8C10.6,8,10.8,8,11,8z M5.1,10.9C5.1,10.9,5.1,10.9,5.1,10.9
		 c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1C6.1,9.2,5.9,9,5.6,9C5.3,9,5,9,4.6,9C4.4,9,4.2,9.2,4.2,9.4
		 c0,0.3,0,0.7,0,1c0,0.3,0.2,0.5,0.5,0.5C4.8,10.9,5,10.9,5.1,10.9z M8.1,9C8.1,9,8.1,9,8.1,9C7.9,9,7.7,9,7.6,9
		 C7.3,9,7.1,9.2,7.1,9.4c0,0.3,0,0.6,0,1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.6,0,1,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1
		 C9,9.1,8.8,9,8.5,9C8.4,9,8.2,9,8.1,9z M11,10.9C11,10.9,11,10.9,11,10.9c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1
		 c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.7,0-1,0C10.2,9,10,9.2,10,9.4c0,0.3,0,0.7,0,1c0,0.3,0.2,0.5,0.5,0.5C10.6,10.9,10.8,10.9,11,10.9
		 z M5.1,13.8C5.1,13.8,5.1,13.8,5.1,13.8c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1c0-0.3-0.2-0.5-0.5-0.5
		 c-0.3,0-0.7,0-1,0c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0,0.7,0,1c0,0.3,0.2,0.5,0.5,0.5C4.8,13.8,5,13.8,5.1,13.8z M8.1,13.8
		 C8.1,13.8,8.1,13.8,8.1,13.8c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.6,0-1,0
		 c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0,0.7,0,1c0,0.3,0.2,0.5,0.5,0.5C7.7,13.8,7.9,13.8,8.1,13.8z M11,13.8C11,13.8,11,13.8,11,13.8
		 c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.3,0-0.7,0-1c0-0.3-0.2-0.4-0.5-0.5c-0.3,0-0.7,0-1,0c-0.3,0-0.5,0.2-0.5,0.5
		 c0,0.3,0,0.7,0,1c0,0.3,0.2,0.5,0.5,0.5C10.6,13.8,10.8,13.8,11,13.8z"
          />
          <path
            class="st1"
            d="M14.9,4.4c0.8,0.4,1.5,0.8,2.3,1.1c0.5,0.2,0.7,0.5,0.7,1.1c0,3.9,0,7.9,0,11.8c0,0.1,0,0.2,0,0.3
		 c-1,0-1.9,0-2.9,0C14.9,13.9,14.9,9.2,14.9,4.4z"
          />
          <path class="st1" d="M7.1,16.8c0.6,0,1.3,0,1.9,0c0,0.6,0,1.3,0,1.9c-0.6,0-1.3,0-1.9,0C7.1,18,7.1,17.4,7.1,16.8z" />
          <path
            class="st0"
            d="M5.1,8C5,8,4.8,8,4.6,8C4.4,8,4.2,7.8,4.2,7.5c0-0.3,0-0.7,0-1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.7,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0,0.7,0,1C6.1,7.8,5.9,8,5.6,8C5.4,8,5.3,8,5.1,8z"
          />
          <path
            class="st0"
            d="M8.1,6c0.2,0,0.3,0,0.5,0C8.8,6.1,9,6.2,9,6.5c0,0.3,0,0.7,0,1C9,7.8,8.8,8,8.5,8c-0.3,0-0.6,0-1,0
		 C7.3,8,7.1,7.8,7.1,7.5c0-0.3,0-0.7,0-1c0-0.3,0.2-0.5,0.5-0.5C7.7,6,7.9,6,8.1,6C8.1,6,8.1,6,8.1,6z"
          />
          <path
            class="st0"
            d="M11,8c-0.2,0-0.3,0-0.5,0C10.2,8,10,7.8,10,7.5c0-0.3,0-0.7,0-1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.7,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.4c0,0.3,0,0.7,0,1c0,0.3-0.2,0.4-0.5,0.5C11.3,8,11.1,8,11,8C11,8,11,8,11,8z"
          />
          <path
            class="st0"
            d="M5.1,10.9c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.7,0-1C4.2,9.2,4.4,9,4.6,9c0.3,0,0.7,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0,0.7,0,1c0,0.3-0.2,0.5-0.5,0.5C5.4,10.9,5.3,10.9,5.1,10.9C5.1,10.9,5.1,10.9,5.1,10.9z"
          />
          <path
            class="st0"
            d="M8.1,9c0.2,0,0.3,0,0.5,0C8.8,9,9,9.1,9,9.4c0,0.3,0,0.7,0,1c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.6,0-1,0
		 c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.6,0-1C7.1,9.2,7.3,9,7.6,9C7.7,9,7.9,9,8.1,9C8.1,9,8.1,9,8.1,9z"
          />
          <path
            class="st0"
            d="M11,10.9c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.7,0-1C10,9.2,10.2,9,10.5,9c0.3,0,0.7,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0,0.7,0,1c0,0.3-0.2,0.5-0.5,0.5C11.3,10.9,11.1,10.9,11,10.9C11,10.9,11,10.9,11,10.9z"
          />
          <path
            class="st0"
            d="M5.1,13.8c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.7,0-1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.7,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0,0.7,0,1c0,0.3-0.2,0.5-0.5,0.5C5.5,13.8,5.3,13.8,5.1,13.8C5.1,13.8,5.1,13.8,5.1,13.8z"
          />
          <path
            class="st0"
            d="M8.1,13.8c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.7,0-1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.6,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0,0.7,0,1c0,0.3-0.2,0.5-0.5,0.5C8.4,13.8,8.2,13.8,8.1,13.8C8.1,13.8,8.1,13.8,8.1,13.8z"
          />
          <path
            class="st0"
            d="M11,13.8c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0-0.7,0-1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.7,0,1,0
		 c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0,0.7,0,1c0,0.3-0.2,0.5-0.5,0.5C11.3,13.8,11.1,13.8,11,13.8C11,13.8,11,13.8,11,13.8z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuCompanyComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
