<div class="flex h-[calc(100vh_-_400px)] justify-center gap-1 sm:h-[calc(100vh_-_400px)]">
  <div class="panel w-[350px] space-y-4 p-4 xl:relative xl:block xl:h-auto ltr:rounded-r-none ltr:xl:rounded-r-md rtl:rounded-l-none rtl:xl:rounded-l-md">
    <div class="h-full flex-col pb-16">
      <div class="pb-5">
        <div class="grid grid-cols-2">
          <h3 class="w-full text-lg font-semibold ltr:ml-3 rtl:mr-3">{{ title | translate }}</h3>
          <div class="flex items-end justify-end">
            <button type="button" class="text-primary" (click)="openModal()">
              <icon-plus class="rotate-90 opacity-70 hover:opacity-100" />
            </button>
          </div>
        </div>
      </div>
      <div class="mb-5 h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b]"></div>
      <ng-scrollbar class="relative h-full grow ltr:pr-3.5 rtl:pl-3.5" appearance="compact">
        <div class="space-y-1">
          <div
            class="flex h-10 w-full items-center justify-between rounded-md p-2 font-medium hover:bg-white-dark/10 hover:text-primary dark:hover:bg-[#181F32] dark:hover:text-primary"
            *ngFor="let item of picklistType"
          >
            <div class="flex w-full items-center">
              <div class="flex w-full items-center">
                {{ item.title }}
              </div>
              <div class="flex items-end justify-end gap-1">
                <button (click)="openModalForEditing(item.id)">
                  <icon-edit class="opacity-70 hover:opacity-100"></icon-edit>
                </button>
                <button (click)="openModalForDelete(item.id)">
                  <icon-trash class="opacity-70 hover:opacity-100"></icon-trash>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>

<modal #modal>
  <ng-template #modalHeader>
    <div class="!font-medium">{{ modalTitle }}</div>
  </ng-template>
  <ng-template #modalBody>
    <form [formGroup]="form" class="text-sm">
      <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
        <div [ngClass]="form.controls['title'].touched ? (form.controls['title'].errors ? 'has-error' : 'has-success') : ''">
          <label for="name">{{ "settings.title" | translate }}</label>
          <input id="name" type="text" placeholder="{{ 'settings.title' | translate }}" class="form-input" formControlName="title" />
          <ng-container *ngIf="form.controls['title'].touched && form.controls['title'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "settings.please_provide_title" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
        <div [ngClass]="form.controls['description'].touched ? (form.controls['description'].errors ? 'has-error' : 'has-success') : ''">
          <label for="description">{{ "settings.description" | translate }}</label>
          <input id="description" type="text" placeholder="{{ 'settings.description' | translate }}" class="form-input" formControlName="description" />
          <ng-container *ngIf="form.controls['description'].touched && form.controls['description'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "settings.please_provide_description" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="mt-8 flex items-center justify-end">
        <button type="submit" class="btn btn-primary" (click)="addItem()">{{ modalButton }}</button>
      </div>
    </form>
  </ng-template>
</modal>

<modal #deleteConfirmationModal class="custom-modal">
  <ng-template #modalBody>
    <div class="flex items-center justify-center text-base font-medium text-[#1f2937] dark:text-white-dark/70">
      <div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#f1f2f3] dark:bg-white/10">
        <icon-bell-larger></icon-bell-larger>
      </div>
    </div>
    <div class="flex items-center justify-center p-5">
      <p>{{ "settings.delete" | translate }}</p>
    </div>
    <div class="flex items-center justify-center gap-5">
      <button type="button" class="btn btn-outline-danger w-16" (click)="delete()">Yes</button>
      <button type="button" class="btn btn-primary w-16" (click)="deleteConfirmationModal.close()">No</button>
    </div>
  </ng-template>
</modal>
