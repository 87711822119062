import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { BaseHttpService } from "src/app/service/base.http.service";
import { GenericResponse } from "src/app/shared/models/generics";
import { Sale } from "../../sales/models/sale";
import { Office } from "../models/office-branch";

@Injectable({
  providedIn: "root",
})
export class OfficeBranchService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getOffices(): Observable<Office[]> {
    return this.http.get<Office[]>(`${this.apiUrl}/api/OfficeBranches`);
  }

  createOffice(formData: Office) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches`;
    return this.http.post<Office>(apiUrl, formData);
  }

  getOfficeById(id: number) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/${id}`;
    return this.http.get<any>(apiUrl);
  }

  updateOffice(id: number, updatedWorker: Office) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/${id}`;
    return this.http.put<Office>(apiUrl, updatedWorker);
  }

  uploadOfficeLogo(id: number, logo: File) {
    const formData: FormData = new FormData();
    formData.append("logoImage", logo);
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/logo/${id}`;
    return this.http.post<Office>(apiUrl, formData);
  }

  getWorkerSalesWithHeaders(
    userId: number,
    pageNumber: number,
    pageSize: number
    // filterType: string,
    // from: Date | undefined,
    // to: Date | undefined
  ): Observable<GenericResponse<Sale>> {
    const apiUrl = `${this.apiUrl}/api/Statistics/money/plans/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("userId", userId.toString()).set("filterType", "None").set("from", "2020-12-31");

    return this.http.get<Sale[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Sale> = {
          body: response.body ?? ([] as Sale[]),
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }
}
