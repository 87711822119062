<div class="col-span-1 sm:col-span-1">
	<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
    <button type="button" class="btn btn-info gap-2" (click)="sendInvoice()">
      <icon-send />
      {{ "invoice_preview.send" | translate }}
    </button>

    <button type="button" class="btn btn-primary gap-2" (click)="printInvoice()">
      <icon-printer />
      {{ "invoice_preview.print" | translate }}
    </button>
		<button type="button" class="btn btn-warning gap-2" (click)="finishSending()">
      <icon-x />
      {{ "invoice_preview.close" | translate }}
    </button>
  </div>
  <div class="panel relative rounded-lg rounded-md border border-white-dark/20 bg-white p-4 shadow-md dark:bg-gray-900" id="payment">
		<div class="d-flex justify-content-between align-items-center">
      <div class="flex flex-wrap justify-start">
        <img src="/assets/images/eccoLogo.png" alt="" class="w-15 h-10" />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">28.Novembar br.54,Novi Pazar 36 300 </span>
        <icon-home />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">020/311-575,335-850 </span>
        <icon-phone />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">"jdj" </span>
        <icon-mail />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">www.ecco.rs </span>
        <icon-chrome />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">žiro račun: 155-32299-16,PIB:100769049 </span>
        <icon-zip-file />
      </div>
    </div>
    <div class="mt-1 flex">
      <div>
        <div class="flex items-center">
          <label for="number" class="mt-1 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.createdAt" | translate }}:</label>
          <div class="w-2/3 lg:w-[250px]" name="invoiceNo">
            <div>
              <div class="flex">
                <div class="mr-4">{{ plan.createdAt | date : "dd.MM.yyyy" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkin" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.customerFullName" | translate }}:</label>
          <div class="w-2/3 lg:w-[250px]">{{ plan.customer.name + " " + plan.customer.surname }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="hotel" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.email" | translate }}:</label>
          <div class="w-2/3 lg:w-[250px]">{{ plan.customer.email }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.total" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">
						{{ ((plan.bruto || 0) - (plan.neto || 0) - (plan.avans || 0)) | currency : "EUR" }}
          </div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.avans" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ plan.avans ?? 0 | currency : "EUR" }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.paymentType" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ plan.paymentType.title }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.paymentDate" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ plan.paymentType.createdAt | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>
    </div>
    <hr class="my-2 border-[#e0e6ed] dark:border-[#1b2e4b]" />
    <div class="mt-4">
      <div class="mx-auto rounded-lg">
        <div class="flex justify-between">
          <div>
            <p>
              <strong>{{ "paymentdetails.dateOfPayment" | translate }}:</strong> {{ getCurrentDate() | date : "dd.MM.yyyy" }}
            </p>
            <p>
              <strong>{{ "paymentdetails.director" | translate }}:</strong> Esad Gicic
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<modal #modal class="modal-top custom-modal">
  <ng-template #modalBody>
    <ng-container *ngIf="plan">
       <app-file-send
        [title]="'invoicesendemail' | translate"
        fileType="payment"
        [passangers]="[plan.customer]"
        [sendDocumentFn]="paymentService.sendPayment(this.plan.id)"
        [messageRs]="'sending_contract_RS_message' | translate"
        [messageEn]="'sending_contract_EN_message' | translate"
        (notifyWhenSend)="finishSending()"
      > 
        <table class="mb-4 w-full table-auto">
          <thead>
            <tr>
              <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
							<th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border px-4 py-2">{{ plan.customer.name 	}}</td>
              <td class="border px-4 py-2">{{ plan.customer.surname }}</td>
							<td class="border px-4 py-2">{{ plan.customer.email }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive mb-3 w-full border-gray-400">
          <thead class="border-top: 2px">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
              <th class="border border-gray-300 sm:col-span-1">{{ "contractsendemail.createdate" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "contractsendemail.pricetotal" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
              <td class="border border-gray-300 sm:col-span-1">{{ plan.createdAt | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300 sm:col-span-1"> {{ ((plan.bruto || 0) - (plan.neto || 0) - (plan.avans || 0)) | currency : "EUR" }}</td>
            </tr>
          </tbody>
          <thead class="border-top: 2px">
            <tr class="ng-star-inserted border border-gray-300 bg-gray-100">
              <th class="border border-gray-300">{{ "contractsendemail.checkin" | translate }}</th>
              <th class="border border-gray-300">{{ "contractsendemail.checkout" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
              <td class="border border-gray-300">{{ plan.paymentType.title}}</td>
              <td class="border border-gray-300">{{plan.paymentType.createdAt | date : "dd.MM.yyyy" }}</td>
            </tr>
          </tbody>
        </table>
      </app-file-send> 
    </ng-container>
  </ng-template>
</modal>

