import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Organizer, OrganizerStatistics } from "../models/oragnizers";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericResponse } from "src/app/shared/models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Sale } from "../../sales/models/sale";

@Injectable({
  providedIn: "root",
})
export class OrganizersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  storeData: any;

  getOragnizersWithHeaders(pageNumber: number, pageSize: number, filterType: string) {
    const apiUrl = `${this.apiUrl}/api/organizers/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("from", "2021-03-24").set("filterType", filterType);
    // .set('sortProperty', 'numberOfPassangers')
    // .set('sortType', 'Asc')

    return this.http.get<Organizer[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Organizer> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  getOrganizersRecords(id: number, pageNumber: number, pageSize: number, filterType: string) {
    const apiUrl = `${this.apiUrl}/api/organizers/plans/${id}`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("from", "2021-03-24").set("filterType", filterType);
    // .set('sortProperty', 'numberOfPassangers')
    // .set('sortType', 'Asc')

    return this.http.get<Sale[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Sale> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }
  getOrganizersStatistics(id: number) {
    return this.http.get<OrganizerStatistics>(`${this.apiUrl}/api/organizers/statistics/${id}`);
  }

	addOrganizer(data: any) {
		return this.http.post<Organizer>(`${this.apiUrl}/api/organizers`, data);
	}

	updateOrganizer(data: any, id: number) {
		return this.http.put<Organizer>(`${this.apiUrl}/api/organizers/${id}`, data);
	}

	getOrganizer(id: number) {
		return this.http.get<Organizer>(`${this.apiUrl}/api/organizers/entities/${id}`);
	}
	searchOrganizerByIdWithTerm(organizerid: number, term: string) {
		return this.http.get<Organizer[]>(`${this.apiUrl}/api/Organizers/search/${organizerid}?term=${term}`);
	}
	mergeOrganizers(rootId: number, listOfIds: number[]): Observable<any> {
		const url = `${this.apiUrl}/api/Organizers/merge`;
		const body = { rootId, listOfIds };
		return this.http.post(url, body, { responseType: 'text' });
	}
	getOrganizernew() {
		return this.http.get<Organizer[]>(`${this.apiUrl}/api/organizers`);
	}
	
}
