import { AuditEntry } from "./models/auditentry";
import { Component, Input, OnInit } from "@angular/core";
import { TravelsService } from "src/app/domain/travels/services/travels.service";

@Component({
  selector: "app-file-history",
  templateUrl: "./file-history.component.html",
})
export class FileHistoryComponent implements OnInit {
  @Input() isShowingSidebar = false;
  @Input() entityname: any = "";
  @Input() id!: number;
  showFileHistory = false;
  showCustomizer = false;
  travel!: string;
  auditEntries: AuditEntry[] = [];

  constructor(private travelservice: TravelsService) {}

  ngOnInit(): void {
    this.fetchAuditLogs();
  }
  fetchAuditLogs() {
    this.travelservice.getAuditLogs(this.entityname, this.id).subscribe((data) => {
      this.auditEntries = data;
    });
  }
  toggleFileHistory() {
    this.showFileHistory = !this.showFileHistory;
  }
  parseModifiedColumns(entry: AuditEntry): any[] {
    try {
      if (!entry.modifiedColumns) {
        return [];
      }
      const sanitizedString = entry.modifiedColumns.trim();
      if (sanitizedString === "") {
        return [];
      }
      return JSON.parse(sanitizedString);
    } catch (e) {
      return [];
    }
  }
}
