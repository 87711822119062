import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "../store/index.selector";
// import { environment } from '@environments/environment'; // Make sure to adjust this import based on your project structure

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(public storeData: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.storeData
      .select((d) => d.auth.user)
      .pipe(
        switchMap((user) => {
          if (user) {
            const headers = request.headers.set("Authorization", `Bearer ${user.token}`);
            const authReq = request.clone({ headers });
            request = authReq;
          }
          return next.handle(request);
        }),
      );
  }
}
