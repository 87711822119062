import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Contract } from "../../documents/contract/models/contract";
import { ContractService } from "../../documents/contract/services/contract.service";

@Component({
  selector: "app-group-travel-contract-edit",
  templateUrl: "./group-travel-contract-edit.component.html",
})
export class GroupTravelContractEditComponent implements OnInit {
  contractId!: number;
  contract!: Contract;
  form!: FormGroup;
  passangers!: Customer[];
  groupTravelId!: any;
  cancelEditUrl!: string;
  constructor(public fb: FormBuilder, public route: ActivatedRoute, public contractService: ContractService, public router: Router) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.contractId = Number(params["contractId"]);
      this.groupTravelId = Number(params["id"]);
      this.cancelEditUrl = `/dashboard/group-travels/${this.groupTravelId}/contract`;
      this.fetchContractDate();
    });
  }
  fetchContractDate() {
    this.contractService.getContractByGroupTravel(this.groupTravelId).subscribe(
      (response) => {
        this.contract = response;
        this.passangers = response.passengers.map((passenger: any) => {
          passenger.fullName = passenger.name + " " + passenger.surname;
          passenger.dateOfBirth = new Date(passenger.dateOfBirth).toLocaleString("sr-RS", { day: "2-digit", month: "2-digit", year: "numeric" });
          return passenger;
        });
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }
}
