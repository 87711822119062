<ol class="flex font-semibold text-gray-500 dark:text-white-dark">
  <li>
    <a href="javascript:;" class="hover:text-gray-500/70 dark:hover:text-white-dark/70">
      <icon-menu-dashboard class="shrink-0 group-hover:!text-primary"></icon-menu-dashboard>
    </a>
  </li>
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="before:px-1.5 before:content-['/']">
    <a [routerLink]="breadcrumb.url" [ngClass]="{ 'text-black': last, 'dark:text-white-light': last, 'hover:text-black/70': !last, 'dark:hover:text-white-light/70': !last }">
      {{ breadcrumb.label | translate }}
    </a>
  </li>
</ol>
