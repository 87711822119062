import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";
import { GroupTravelDestination, GroupTravelPlan } from "../../group-travels/models/group-travel";

@Component({
  selector: "app-group-travel-plan-quickview",
  templateUrl: "./group-travel-plan-quickview.component.html",
  styleUrls: ["./group-travel-plan-quickview.component.css"],
})
export class GroupTravelPlanQuickviewComponent {
  @Input() data!: GroupTravelPlan;
  @Input() id!: number;

  public mainDestination!: GroupTravelDestination;

  constructor(public translate: TranslateService, private groupTravelService: GroupTravelsService) {}
  getReservationNumber(pass: Customer[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }

  numberOfPassangers() {
    if (this.data && this.data.groupTravelPassangers) {
      return this.data.groupTravelPassangers.length;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.id) {
      this.groupTravelService.getGroupTravelPlans(this.id).subscribe((travelPlan) => {
        this.data = travelPlan;
        this.mainDestination = travelPlan.groupTravel.groupTravelDestinations.find((d) => d.isMainDestination) as GroupTravelDestination;
      });
    }
  }
}
