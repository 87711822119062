import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseHttpService } from "src/app/service/base.http.service";
import { CalendarEvent } from "../models/event";

@Injectable({
  providedIn: "root",
})
export class CalendarService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getEvents(year: number, month: number) {
    const apiUrl = `${this.apiUrl}/api/plans/${year}/${month}`;
    return this.http.get<CalendarEvent[]>(apiUrl);
  }
}
