<div class="panel" id="voucher" *ngIf="voucher">
  <div class="d-flex justify-content-between align-items-center">
    <div class="flex-1 text-end">
      <p>{{ "vaucher.company" | translate }}</p>
    </div>
    <div class="flex-1" *ngIf="voucher.officeBranch && voucher.officeBranch.logoImage">
      <img [src]="'http://' + voucher.officeBranch.logoImage.url" alt="" class="w-15 h-20" />
    </div>
  </div>
  <div class="mt-8 flex flex-wrap items-center justify-between">
    <div class="my-2">
      <div class="space-y-1 text-white-dark">
        <div class="">
          <div class="space-y-1 text-gray-300 dark:text-gray-400">
            <div class="flex h-40 w-80 flex-col justify-center rounded-lg bg-gray-200 p-4 text-center">
              <p class="font-bold text-blue-900">{{ voucher.agent }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-4 my-2 lg:w-auto">
      <div class="space-y-1 text-white-dark">
        <div class="w-full" *ngIf="voucher.officeBranch">
          <div class="space-y-1 text-white-dark">
            <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.street && voucher.officeBranch.city">
              <span class="mr-2">{{ voucher.officeBranch.street }}, {{ voucher.officeBranch.city }} </span>
              <icon-home />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.phoneNumber">
              <span class="mr-2">{{ voucher.officeBranch.phoneNumber }} </span>
              <icon-phone />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.email">
              <span class="mr-2">{{ voucher.officeBranch.email }} </span>
              <icon-mail />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.siteURL">
              <span class="mr-2">{{ voucher.officeBranch.siteURL }} </span>
              <icon-chrome />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">žiro račun: {{ voucher.officeBranch.bankAccount }},PIB: {{ voucher.officeBranch.pib }} </span>
              <icon-zip-file />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-10 flex">
    <div>
      <div class="flex items-center">
        <label for="number" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "vaucher.transport" | translate }}:</label>
        <div class="w-2/3 lg:w-[250px]" name="invoiceNo">{{ voucher.transport }}</div>
      </div>
      <div class="mt-4 flex items-center">
        <label for="hotel" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "vaucher.hotel" | translate }}:</label>
        <div class="w-2/3 lg:w-[250px]">{{ voucher.hotelLabel }}</div>
      </div>
      <div class="mt-4 flex items-center">
        <label for="checkin" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "vaucher.check_in" | translate }}:</label>
        <div class="w-2/3 lg:w-[250px]">{{ voucher.checkin | date : "dd/MM/yyyy" }}</div>
      </div>
      <div class="mt-4 flex items-center">
        <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "vaucher.check_out" | translate }}: </label>
        <div class="w-2/3 lg:w-[250px]">{{ voucher.checkout | date : "dd/MM/yyyy" }}</div>
      </div>

      <div class="mt-4 flex items-center">
        <label for="room" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "vaucher.rooms" | translate }}: </label>
        <div class="w-2/3 lg:w-[250px]">{{ voucher.roomService }}</div>
      </div>

      <div class="mt-4 flex items-center">
        <label for="service" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "vaucher.service" | translate }}:</label>
        <div class="w-2/3 lg:w-[250px]">{{ voucher.service }}</div>
      </div>
    </div>
  </div>
  <hr class="my-6 border-[#e0e6ed] dark:border-[#1b2e4b]" />

  <div class="table-responsive mt-6">
    <table class="table-striped">
      <thead>
        <tr>
          <th rowspan="4">{{ "vaucher.guests" | translate }}</th>
          <th>{{ "vaucher.name_and_surname" | translate }}</th>
          <th>{{ "vaucher.date_of_birth" | translate }}</th>
          <th>{{ "vaucher.passport_number" | translate }}</th>
          <th>{{ "vaucher.phone_number" | translate }}</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let passanger of voucher.passengers; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ passanger?.name }} {{ passanger?.surname }}</td>
            <td>{{ passanger?.dateOfBirth | date : "dd.MM.yyyy" }}</td>
            <td>{{ passanger?.passport }}</td>
            <td>{{ passanger?.phoneNumber }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="mt-10">
    <div class="mx-auto rounded-lg py-10">
      <div class="flex justify-between">
        <div>
          <p><strong>Agent:</strong> {{ voucher.createdByFullName }}</p>
          <p>
            <strong>{{ "vaucher.date" | translate }}:</strong> {{ today }}
          </p>
          <p>
            <strong>{{ "vaucher.city" | translate }}:</strong> Novi Pazar
          </p>
        </div>
        <div>
          <p><strong>PTT:</strong> {{ voucher.officeBranch ? voucher.officeBranch.name : "" }}</p>
          <p>__________________</p>
          <p>
            <strong>{{ "vaucher.director" | translate }}:</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
