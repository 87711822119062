import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class UtilService extends BaseHttpService {
  private countrySelectedSubject = new BehaviorSubject<number | null>(null);
  public countrySelected$ = this.countrySelectedSubject.asObservable();

  constructor(private http: HttpClient) {
    super();
  }
  destinationAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/destinations`, { description: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }
  addDestinationWithCountry(key: string, form: FormGroup, modal: any) {
    return (value: string) => {
      this.openModal(modal);
      this.countrySelected$.subscribe((countryId: number | null) => {
        if (countryId !== null) {
          this.http.post(`${this.apiUrl}/api/destinations`, { description: value, countryId: countryId }).subscribe((res) => {
            form.get(key)?.setValue(res);
            this.closeModal(modal);
          });
        }
      });
    };
  }
  closeModal(modal: any) {
    modal.close();
  }
  openModal(modal: any) {
    modal.open();
  }
  selectCountry(countryId: number) {
    this.countrySelectedSubject.next(countryId);
  }
  organizerAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/organizers`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }

  companiesAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/companies`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }

  hotelsAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/hotels`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }
  countriesAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/Countries`, { nameSR: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }
}
