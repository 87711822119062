<div class="chart-container" *ngIf="simpleColumnStackedSeries">
	<apx-chart
		[series]="simpleColumnStackedSeries.series"
		[chart]="simpleColumnStackedSeries.chart"
		[dataLabels]="simpleColumnStackedSeries.dataLabels"
		[stroke]="simpleColumnStackedSeries.stroke"
		[colors]="simpleColumnStackedSeries.colors"
		[responsive]="simpleColumnStackedSeries.responsive"
		[plotOptions]="simpleColumnStackedSeries.plotOptions"
		[xaxis]="simpleColumnStackedSeries.xaxis"
		[yaxis]="simpleColumnStackedSeries.yaxis"
		[grid]="simpleColumnStackedSeries.grid"
		[tooltip]="simpleColumnStackedSeries.tooltip"
		class="rounded-lg bg-white dark:bg-black"
	>
	</apx-chart>
</div>