import * as moment from "moment";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Ticket } from "src/app/domain/tickets/models/ticket";
import { CheckoutService } from "../services/checkout.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { SimpleTicket } from "./../../domain/tickets/models/ticket";
import { animate, style, transition, trigger } from "@angular/animations";
import { Customer, PassangerTypes } from "../../domain/customers/models/customer";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { TicketCreateComponent } from "src/app/domain/tickets/ticket.create/ticket.create.component";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";

@Component({
  templateUrl: "./ticket.checkout.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class TicketCheckoutComponent implements OnInit {
  @ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("ticket") ticketComponent!: TicketCreateComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  @ViewChild("modal") modal!: ModalComponent;

  public customer!: Customer;
  public ticket!: Ticket;
  public sale!: SaleBase;
  public id!: number;
  note!: string;
  public activeTab = 1;
  public type!: string;
  public mainCustomer!: Customer;
  public simpleTicket!: SimpleTicket;
  currentPlan!: any;

  constructor(private router: Router, private route: ActivatedRoute, public translate: TranslateService, public checkoutService: CheckoutService) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.type = this.router.url.includes("flight") ? "flight" : "bus";

      if (this.router.url.includes("/edit")) {
        this.fetchEntities();
      }
    });
  }

  fetchEntities() {
    this.checkoutService.fetchTicket(this.id).subscribe((response) => {
      const { id, passangers, customer, plan, numberOfPassangers, oneWay, departureDate, arrivalDate, reservationDate, type, company, departure, destination } = response;
      const customerPassangersIndex = passangers.findIndex((p) => p.id === customer.id);
      const { reservationNumber } = passangers[customerPassangersIndex];

      if (customerPassangersIndex !== -1) {
        passangers.splice(customerPassangersIndex, 1);
      }
      this.passangerList.push(passangers);
      this.saleComponent.initForm(plan);

      this.mainCustomer = {
        ...customer,
        reservationNumber: reservationNumber,
        lifeStage: PassangerTypes.mainCustomer,
        guid: crypto.randomUUID(),
        imageUrl: "/assets/images/man.svg",
      } as Customer;
      if (this.passangerList) {
        this.passangerList.setMainCustomer(this.mainCustomer);
      }
      this.ticketComponent.setTicket({
        id,
        oneWay,
        departureDate,
        arrivalDate,
        reservationNumber,
        numberOfPassangers,
        reservationDate,
        type,
        company,
        departure,
        destination,
      });
    });
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      this.customer = this.passangerList.getPassangers().find((e) => e.lifeStage === PassangerTypes.mainCustomer) as Customer;
      if (this.customer.name === undefined || this.customer.surname === undefined) {
        showMessage(PopupType.Danger, this.translate.instant("popup.main_customer_is_required"));
        return;
      }
    }
    this.simpleTicket = this.ticketComponent.getTicket();

    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }
  submitTicket() {
    if (this.saleComponent.checkIfValid() && this.ticketComponent.checkIfValid()) {
      this.sale = this.saleComponent.getSale();
      this.simpleTicket = this.ticketComponent.getTicket();
      showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
      if (this.id) {
        this.checkoutService.updateTicket(this.id, this.createReservationObject()).subscribe(() => {
          this.router.navigate(["dashboard/sales"]);
        });
      } else {
        this.checkoutService.createTicket(this.createReservationObject()).subscribe((r) => {
          this.currentPlan = r.plan;
          showMessage(PopupType.Success, this.translate.instant("ticket_created_successfully"));
          this.modal.open();
        });
      }
    } else {
      showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
    }
  }

  createReservationObject(): any {
    const { oneWay, departureDate, type, arrivalDate, departure, destination, company, reservationDate } = this.simpleTicket;
    const { id, reservationNumber } = this.customer;
    const { bruto, neto, avans, dueDate, paymentType, paymentTypeId } = this.sale;
    const destinationId: number | undefined = destination ? destination.id : undefined;
    const departureId: number = departure.id;
    const companyId: number = company.id;
    const passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, reservationNumber: e.reservationNumber, lifeStage: e.lifeStage };
    });
    const isCustomerInList = passangers.some((p) => p.customerId === id);
    if (!isCustomerInList) {
      passangers.push({ customerId: id, reservationNumber: reservationNumber, lifeStage: PassangerTypes.adults });
    }
    const avansValue = this.sale.withAvans === 0 ? undefined : avans;
    const newReservation = {
      oneWay,
      departureDate: moment(departureDate, "DD.MM.YYYY hh:mm").toISOString(),
      arrivalDate: oneWay ? null : moment(arrivalDate, "DD.MM.YYYY").toISOString(),
      reservationNumber: reservationNumber,
      reservationDate: moment(reservationDate, "DD.MM.YYYY").toISOString(),
      type: this.type,
      customerId: id,
      plan: { bruto, neto, avans: avansValue, dueDate: moment(dueDate, "DD.MM.YYYY hh:mm").toISOString(), customerId: id, notes: this.note, paymentType, paymentTypeId },
      planId: this.sale.id,
      passangers,
      departureId,
      destinationId,
      companyId,
    };
    return newReservation;
  }
}
