import * as moment from "moment";
import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { BookService } from "../services/book.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { SalesService } from "../../sales/services/sales.service";

@Component({
	selector: "app-book",
	templateUrl: "./book.component.html",
})
export class BookComponent {
	@ViewChild("addSaleInCashRegister") addSaleModal!: ModalComponent;

	plans: any[] = [];
	plansWithoutRecordsInBook: any[] = [];
	checked!: boolean;
	selectedPlans: any[] = [];
	date = "2022-05-31";
  searchTask = "";
	constructor(public saleService: SalesService, public translate: TranslateService, public bookService: BookService) { }
	pager = {
		currentPage: 1,
		totalPages: 0,
		pageSize: 10,
		totalCount: 0,
		startIndex: 0,
		endIndex: 0,
	};

	ngOnInit() {
		this.getTravelsWithRecordInBook(1, 10, "2023-12-31");
	}

	searchTasks() {
		console.log("search..");
	}
	getPager() {
		if (this.plans.length) {
			this.pager.totalPages = this.pager.pageSize < 1 ? 1 : Math.ceil(this.plans.length / this.pager.pageSize);
			if (this.pager.currentPage > this.pager.totalPages) {
				this.pager.currentPage = 1;
			}
			this.pager.startIndex = (this.pager.currentPage - 1) * this.pager.pageSize;
			this.pager.endIndex = Math.min(this.pager.startIndex + this.pager.pageSize - 1, this.plans.length - 1);
			this.plans = this.plans.slice(this.pager.startIndex, this.pager.endIndex + 1);
		} else {
			this.plans = [];
			this.pager.startIndex = -1;
			this.pager.endIndex = -1;
		}
	}

	openModal() {
		this.addSaleModal.open();
	}

	handleDateChange(event: any) {
		this.date = moment(event.target.value).format("YYYY-MM-DD");
	}

	getTravelsWithRecordInBook(pageNumber: number, pageSize: number, from: string) {
		this.bookService.getTravelsWithRecordInBook(pageNumber, pageSize, from).subscribe(
			(res) => {
				this.plans = res.body;
				const pagination = JSON.parse(res.headers.get("X-Pagination") || "");
				this.pager.totalPages = pagination.TotalPages;
				this.pager.currentPage = pagination.CurrentPage;
				this.pager.pageSize = pagination.PageSize;
				this.pager.totalCount = pagination.TotalCount;
				this.getPager();
			},
			(error) => {
				showMessage(PopupType.Danger, error.toString());
			}
		);
	}

	addPlansInEvidenceBook() {
		this.selectedPlans.forEach((element) => {
			this.createAccounting(element.id);
		});
		this.bookService.updateEvidenceBook(this.fetchPlans(), 823).subscribe((res) => {
			showMessage(PopupType.Secundary, this.translate.instant("popup.plans_added_in_evidence_book_successfully"));
		});
	}

	addPlanInEvidenceBook(id: number) {
		const plan = this.plansWithoutRecordsInBook.find((plan) => plan.id === id);
		if (!plan.checked) {
			this.selectedPlans.push(plan);
			plan.checked = true;
		} else {
			this.selectedPlans = this.selectedPlans.filter((plan) => plan.id !== id);
			plan.checked = false;
		}
	}

	fetchPlans() {
		const travels = this.selectedPlans.map((plan) => {
			return {
				start: plan.start,
				end: plan.end,
				numberOfPassangers: plan.numberOfPassangers,
				travelingExpenses: plan.travelingExpenses,
				days: plan.days,
				nights: plan.nights,
				roomDescription: "roomDescription",
				active: plan.active,
				customerId: plan.customerId,
				planId: plan.id,
				departureId: plan.departureId,
				organizerId: plan.organizerId,
				memorandumId: plan.memorandumId,
				createdById: plan.createdById,
				typeId: plan.typeId,
				passangersIds: plan.passangers.map((passanger: any) => passanger.id),
				travelDestinations: plan.travelDestinations.map((destination: any) => destination.id),
				createdAt: plan.createdAt,
				updatedAt: plan.updatedAt,
			};
		});
		return travels;
	}

	fetchAccounting() {
		const accounting = {
			confirmationNumber: 1,
			insuranceNumber: 3,
			dateOfConfirmationIssuing: "2024-05-13T19:48:54.870Z",
			dateOfInsuranceIssuingL: "2024-05-13T19:48:54.870Z",
			sumInRSD: 0,
			isUpToDate: true,
			createdAt: "2024-05-13T19:48:54.870Z",
			uodatedAt: "2024-05-13T19:48:54.870Z",
		};
		return accounting;
	}

	createAccounting(travelId: number) {
		this.bookService.createAccouting(this.fetchAccounting(), travelId).subscribe(
			(res) => {
				showMessage(PopupType.Secundary, this.translate.instant("popup.accounting_data_created_successfully"));
			},
			(error) => {
				showMessage(PopupType.Danger, error.toString());
			}
		);
	}
}
