export const environment = {
  production: false,
  apiUrl: "https://development.turistagent.com",
  port: 4200,
  loadConfig: () => {
    return fetch("/assets/config.json")
      .then((response) => response.json())
      .then((config) => {
        environment.apiUrl = config.apiUrl;
        environment.port = config.port;
      });
  },
};
