import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PicklistOption } from "../models/picklist-option";
import { BaseHttpService } from "src/app/service/base.http.service";
import { EntityPicklistType, PicklistType } from "../enums/picklist-types";

@Injectable({
  providedIn: "root",
})
export class PicklistService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  getPicklistOptions(type: PicklistType) {
    const apiUrl = `${this.apiUrl}/api/picklists/type?delimeter=${type}`;
    return this.http.get<PicklistOption[]>(apiUrl);
  }

  getPicklistOptionsForEntity(type: EntityPicklistType, query: string) {
    const apiUrl = `${this.apiUrl}/api/search/${type}?input=${query}`;
    return this.http.get<PicklistOption[]>(apiUrl);
  }
}
