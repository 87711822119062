import * as moment from "moment";
import { Voucher } from "../models/voucher";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Customer } from "../../customers/models/customer";
import { TravelsService } from "../services/travels.service";
import { TravelDestination } from "../models/travel-destination";
import { VoucherService } from "../../documents/voucher/services/voucher.service";

@Component({
  selector: "app-travel-voucher-edit",
  templateUrl: "./travel-voucher.edit.component.html",
})
export class TravelVoucherEditComponent implements OnInit {
  voucher!: Voucher;
  passangers!: Customer[];
  travelId!: number;
  voucherId!: number;
  cancelVoucherUrlTemplate!: string;
  travelDestinations!: TravelDestination[];

  constructor(public travelService: TravelsService, private route: ActivatedRoute, public voucherService: VoucherService) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.travelId = Number(params.get("id"));
      this.voucherId = Number(params.get("voucherId"));
      this.cancelVoucherUrlTemplate = `/dashboard/travels/${this.travelId}/voucher-list`;
      this.getVoucherData();
    });
  }

  getVoucherData() {
    this.voucherService.getVoucher(this.voucherId).subscribe((response) => {
      this.passangers = response.passengers;
      this.passangers = this.passangers.map((passanger: Customer) => {
        passanger.fullName = passanger.name + " " + passanger.surname;
        passanger.dateOfBirth = passanger.dateOfBirth ? moment(passanger.dateOfBirth).format("DD/MM/YYYY") : undefined;
        return passanger;
      });
      this.voucher = response;
    });
  }
}
