
import * as moment from "moment";
import { Sale } from "../../sales/models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Passanger } from "../../tickets/models/ticket";
import { Transfer } from "../../transfers/models/transfer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TransfersService } from "../services/transfers.service.service";

@Component({
  selector: "app-transfer-passanger",
  templateUrl: "./transfer-passanger.component.html",
})
export class TransferPassangerComponent implements OnInit {
  @ViewChild("passangerList") passangerList: any;
  @ViewChild("sale") sales: any;
  activeTab = 1;
  passengers!: Passanger[];
  sale!: Sale;
  transferId!: number | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private transferService: TransfersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      this.transferId = id ? parseInt(id) : undefined;
      this.fetchTransfer(this.transferId);
    });
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }

  async submitTransfer() {
    try {
      await this.getPassangersToTransfer();
      this.addPassangersToTransfer();
    } catch (error) {
      showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
    }
  }

  async getPassangersToTransfer() {
    this.passengers = this.passangerList.getPassangers();
    this.sale = this.sales.getSale();
    this.passangerList.checkValidity();
    this.sale.dueDate = moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss");
  }

  fetchTransfer(id: number | undefined) {
    if (id) {
      this.transferService.getTransferData(id).subscribe((res: any) => {
        this.transfer = res;
      });
    }
  }

  getPassangerIds(): any[] {
    return this.passengers.map((p: any) => {
      return { customerId: p.id, lifeStage: p.lifeStage };
    });
  }

  addPassangersToTransfer() {
    const data = {
      transferId: this.transferId,
      plan: this.sale,
      passangers: this.getPassangerIds(),
    };
    this.transferService.addPassangerToTransfer(this.transferId, data).subscribe(
      (res) => {
        showMessage(PopupType.Success, this.translate.instant("transfer.added_passangers_successfully"));
        this.router.navigate([`/dashboard/transfers/${this.transferId}`]);
      },
      (err) => {
        showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
      }
    );
  }

  toggleDirection() {
    this.isForwardDirection = !this.isForwardDirection;
  }

  addDirection(id: number) {
    if (id === 1) this.transferId = this.transfer.returnTransfer?.id;
    else this.transferId = this.transfer.id;
  }
}