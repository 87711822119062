<div [formGroup]="selectControl ?? mockFormName">
  <ng-select
    [items]="options$ | async"
    [bindLabel]="labelName"
    [hideSelected]="true"
    [trackByFn]="trackByFn"
    [addTag]="saveNewOption.bind(this)"
    [minTermLength]="minTermLength"
    [loading]="optionsLoading"
    typeToSearchText="{{ 'autocomplete_type_to_search' | translate : { minNum: minTermLength } }}"
    addTagText="{{ 'add_tag_text' | translate }}"
    [typeahead]="optionsInput$"
    [placeholder]="placeholder"
    [labelForId]="labelForId"
    [formControlName]="formControlName"
    (change)="changed($event)"
    (add)="onAdd($event)"
    #autocomplete
  >
  </ng-select>
</div>
