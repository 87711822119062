import { Store } from "@ngrx/store";
import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  moduleId: module.id,
  templateUrl: "./error404.html",
})
export class Error404Component {
  store: any;
  constructor(public router: Router, public storeData: Store<any>) {
    this.initStore();
  }
  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }
}
