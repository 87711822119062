<div class="chart-container" *ngIf="simpleColumnStackedOrganizer">
  <apx-chart
    [series]="simpleColumnStackedOrganizer.series"
    [chart]="simpleColumnStackedOrganizer.chart"
    [colors]="simpleColumnStackedOrganizer.colors"
    [dataLabels]="simpleColumnStackedOrganizer.dataLabels"
    [stroke]="simpleColumnStackedOrganizer.stroke"
    [plotOptions]="simpleColumnStackedOrganizer.plotOptions"
    [grid]="simpleColumnStackedOrganizer.grid"
    [xaxis]="simpleColumnStackedOrganizer.xaxis"
    [yaxis]="simpleColumnStackedOrganizer.yaxis"
    class="mt-3 rounded-lg bg-white dark:bg-black"
  >
  </apx-chart>
</div>
