import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { BaseHttpService } from "src/app/service/base.http.service";
import { GenericResponse } from "src/app/shared/models/generics";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";

@Injectable({
  providedIn: "root",
})
export class BookService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getTravelsWithRecordInBook(pageNumber: number, pageSize: number, from: string) {
    const apiUrl = `${this.apiUrl}/api/travels/paginated`;

    const params = new HttpParams().set("from", "2022-12-30").set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("filterType", "WithRecordInBook");

    return this.http.get<any[]>(apiUrl, { params, observe: "response" }).pipe(
      map(({ body, headers }) => {
        const customResponse: GenericResponse<any> = {
          body: body ?? [],
          headers: headers,
        };
        return customResponse;
      })
    );
  }

  updateEvidenceBook(plan: any, id: number) {
    return this.http.put(`${this.apiUrl}/api/travels/plan/${id}`, plan);
  }

  createAccouting(accounting: any, travelId: number) {
    return this.http.post(`${this.apiUrl}/api/travels/accounting/${travelId}`, accounting);
  }
}
