import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TravelWithPlan } from '../../travels/models/travel';
import { Customer } from '../../customers/models/customer';
import { TravelsService } from '../../travels/services/travels.service';


interface Passenger {
	reservationNumber: string | null;
	ticketId: number;
	id: number;
	name: string;
	surname: string;
}

@Component({
	selector: 'app-travel-quick-view',
	templateUrl: './travel-quick-view.component.html',
	styleUrls: ['./travel-quick-view.component.css']
})
export class TravelQuickViewComponent implements OnInit {
	@Input() data!: TravelWithPlan;
	@Input() id!: number;

	constructor(public translate: TranslateService, private travelService: TravelsService) { }

	ngOnInit(): void {
		if (this.id) {
			this.travelService.getTravelData(this.id).subscribe((travel) => {
				this.data = travel;
			});
		}
	}


	getReservationNumber(pass: Customer[]) {
		if (pass) {
			const arr = pass.map((passenger) => passenger.reservationNumber);
			return arr.join(", ");
		}
		return "";

	}

}
