<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div [ngClass]="form.controls['current_password'].touched ? (form.controls['current_password'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="current_password">{{ "user_profile.current_password" | translate }}</label>
    <input id="current_password" type="text" placeholder="{{ 'user_profile.current_password' | translate }}" class="form-input" formControlName="current_password" />
    <small class="text-danger" *ngIf="form.controls['current_password'].touched && form.controls['current_password'].errors">{{ "user_profile.please_provide_current_password" | translate }}</small>
  </div>
  <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="password">{{ "user_profile.password" | translate }}</label>
    <input id="password" type="text" placeholder="{{ 'user_profile.password' | translate }}" class="form-input" formControlName="password" />
    <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">
      <small class="text-danger" *ngIf="form.controls['password'].errors?.['required']">{{ "user_profile.enter_valid_password" | translate }}</small>
      <small class="text-danger" *ngIf="form.controls['password'].errors?.['invalidPassword']">{{ "user_profile.password_must_be_at_least_8_characters" | translate }}</small>
    </div>
  </div>
  <div [ngClass]="form.controls['confirm_password'].touched ? (form.controls['confirm_password'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="confirm_password">{{ "user_profile.confirm_password" | translate }}</label>
    <input id="confirm_password" type="password" placeholder="{{ 'user_profile.confirm_password' | translate }}" class="form-input" formControlName="confirm_password" />
    <small class="text-danger" *ngIf="form.errors?.['notSame'] && form.controls['confirm_password'].touched"> {{ "user_profile.passwords_not_match" | translate }} </small>
  </div>
  <button type="submit" class="btn btn-primary w-full">{{ "user_profile.save" | translate }}</button>
</form>
