import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericResponse } from "src/app/shared/models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { GroupTravel, GroupTravelPlan } from "../models/group-travel";
import { TravelDestination } from "../../travels/models/travel-destination";

@Injectable({
  providedIn: "root",
})
export class GroupTravelsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getGroupTravelsWithHeader(pageNumber: number, pageSize: number, filterType: string) {
    const apiUrl = `${this.apiUrl}/api/grouptravels/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("from", "2021-03-24").set("filterType", filterType);
    // .set('sortProperty', 'numberOfPassangers')
    // .set('sortType', 'Asc')

    return this.http.get<GroupTravel[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<GroupTravel> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  getGroupTravelData(id: number) {
    return this.http.get<GroupTravel>(`${this.apiUrl}/api/GroupTravels/${id}`);
  }

  getGroupTravelPlans(id: number) {
    return this.http.get<GroupTravelPlan>(`${this.apiUrl}/api/GroupTravelPlans/${id}`);
  }

  createGroupTravel(groupTravel: GroupTravel) {
    return this.http.post<GroupTravel>(`${this.apiUrl}/api/GroupTravels`, groupTravel);
  }

  updateGroupTravel(id: number, groupTravel: GroupTravel) {
    return this.http.put<GroupTravel>(`${this.apiUrl}/api/GroupTravels/${id}`, groupTravel);
  }

  createGroupTravelClone(id: number) {
    return this.http.post<GroupTravel>(`${this.apiUrl}/api/GroupTravels/clone/${id}`, {});
  }

  addGroupTravelPassanger(data: any) {
    return this.http.post<any>(`${this.apiUrl}/api/GroupTravelPlans/passangers-plan`, data);
  }
  getGroupTravels(): Observable<GroupTravel[]> {
    return this.http.get<GroupTravel[]>(`${this.apiUrl}/api/GroupTravels`);
  }
  deleteGroupTravel(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/GroupTravels/grouptravel/${id}`, { responseType: "text" });
  }
  getGroupTravelDestinations(id: number) {
    return this.http.get<any>(`${this.apiUrl}/api/GroupTravelDestinations/${id}`);
  }
  updateTravelDestination(id: number, travelDestination: TravelDestination): Observable<TravelDestination> {
    return this.http.put<TravelDestination>(`${this.apiUrl}/api/GroupTravelDestinations/${id}`, travelDestination);
  }
  deleteGroupTravelDestination(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/GroupTravelDestinations/${id}`, { responseType: "text" });
  }
  createTravelDestination(travelDestination: TravelDestination): Observable<TravelDestination> {
    return this.http.post<TravelDestination>(`${this.apiUrl}/api/GroupTravelDestinations`, travelDestination);
  }
}
