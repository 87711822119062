<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div class="flex items-center gap-5">
        <strong class="text-xl text-[#1b2e4b]">{{ "daily_action_for" | translate }} {{ from | date : "longDate" }}</strong>
      </div>
      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">Table Columns</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table [columns]="cols" [filename]="'Group Travel Table Export'" [rawData]="filteredItems"> </app-export-table>
      </div>
    </div>
    <div class="mb-5 mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a] print:hidden">
      <a
        *ngFor="let tab of tabs"
        href="javascript:;"
        class="-mb-[1px] flex items-center border-transparent p-5 py-3 !outline-none transition duration-300 hover:border-b hover:!border-secondary hover:text-secondary"
        [ngClass]="{ '!border-secondary': activeTab === tab.id, 'text-secondary': activeTab === tab.id }"
        (click)="onTabChange(tab.id)"
      >
        {{ tab.label }}
      </a>
    </div>
    <div class="relative">
      <div class="datatable">
        <ng-datatable
          [rows]="filteredItems"
          [columns]="cols"
          [sortable]="true"
          [pageSize]="pageSize"
          [totalRows]="totalRows"
          skin="whitespace-nowrap table-hover"
          [paginationInfo]="'paginationInfoLabel' | translate"
          [noDataContent]="'empty_tables' | translate"
        >
          <ng-template slot="timeStamp" let-value="data">
            {{ value.timeStamp | date : "HH:mm" }}
          </ng-template>
          <ng-template slot="totalSum" let-value="data">
            {{ value.totalSum | currency : "EUR" }}
          </ng-template>
        </ng-datatable>
      </div>
      <div class="grid grid-cols-6 items-center p-7 sm:grid-cols-12">
        <strong class="col-span-5 pr-2 text-right text-lg text-[#1b2e4b] sm:col-span-10">Ukupna Suma: </strong>
        <span class="col-span-1 pr-5 text-center text-[#1b2e4b] sm:col-span-2">{{ getSumOfSuma() | currency : "EUR" }}</span>
      </div>
    </div>
  </div>
</div>
