import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { Office } from "../models/office-branch";
import { Component, ViewChild } from "@angular/core";
import { AppState } from "src/app/store/index.selector";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TableService } from "src/app/shared/services/table.service";
import { OfficeBranchService } from "../services/office-branch.service";
import { selectFromTo } from 'src/app/store/datepicker/datetime.selector';
import { OfficeBranchFormComponent } from "../office-branch.form.component/office-branch.form.component";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  selector: "app-office-branch-table",
  templateUrl: "./office-branch.table.component.html",
})
export class OfficeBranchTableComponent extends BaseTableComponent<Office> {
  @ViewChild("addOfficeLocationModal") addOfficeLocationModal!: ModalComponent;
  @ViewChild("createOfficeModal") createOfficeModal!: ModalComponent;
  @ViewChild("officeBranchForm") officeBranchForm!: OfficeBranchFormComponent;
  @ViewChild("deleteConfirmationModal")
  deleteConfirmationModal!: ModalComponent;

  public offices: Office[] = [];
  public isEdit = false;

  public branchId!: number | undefined;
  public userIdForDelete!: number;
  public office!: any[];

  constructor(
    private officeBranchService: OfficeBranchService,
    private route: ActivatedRoute,
    public override store: Store<AppState>,
    public override translate: TranslateService,
    public override tableService: TableService
  ) {
    super(store, translate, selectFromTo, tableService, `OfficeBranches/paginated`);
  }

  override ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.apiUrl = `OfficeBranches/paginated?type=${params["type"]}`;
      super.ngOnInit();
    });
  }

  override getColumns(): void {
    this.cols = [
      {
        field: "id",
        title: this.translate.instant("office-branch-info.id"),
        isUnique: true,
        hide: true,
      } as DatatableColumn,
      {
        field: "name",
        title: this.translate.instant("office-branch-info.name"),
        hide: false,
      } as DatatableColumn,
      {
        field: "bankAccount",
        title: this.translate.instant("office-branch-info.bankAccount"),
        hide: true,
      } as DatatableColumn,
      {
        field: "city",
        title: this.translate.instant("office-branch-info.city"),
        hide: true,
      } as DatatableColumn,
      {
        field: "email",
        title: this.translate.instant("office-branch-info.email"),
        hide: false,
      } as DatatableColumn,
      {
        field: "guid",
        title: this.translate.instant("office-branch-info.guid"),
        hide: false,
      } as DatatableColumn,
      {
        field: "logoURL",
        title: this.translate.instant("office-branch-info.logoURL"),
        hide: true,
      } as DatatableColumn,
      {
        field: "municipality",
        title: this.translate.instant("office-branch-info.municipality"),
        hide: true,
      } as DatatableColumn,
      {
        field: "phoneNumber",
        title: this.translate.instant("office-branch-info.phoneNumber"),
        hide: false,
      } as DatatableColumn,
      {
        field: "pib",
        title: this.translate.instant("office-branch-info.pib"),
        hide: false,
      } as DatatableColumn,
      {
        field: "siteURL",
        title: this.translate.instant("office-branch-info.siteURL"),
        hide: false,
      } as DatatableColumn,
      {
        field: "street",
        title: this.translate.instant("office-branch-info.street"),
        hide: true,
      } as DatatableColumn,
      {
        field: "actions",
        title: this.translate.instant("office-branch-info.actions"),
        hide: false,
      } as DatatableColumn,
    ];
  }

  openModalAddingOfficeLocation(id: number) {
    this.branchId = id;
    this.addOfficeLocationModal.open();
  }

  closeLocationsModal() {
    this.branchId = undefined;
    this.addOfficeLocationModal.close();
    this.fetchItems();
  }

  closeModal() {
    this.createOfficeModal.close();
    this.fetchItems();
  }
  openModalForCreating() {
    this.branchId = undefined;
    this.createOfficeModal.open();
  }

  openModalForEditing(id: number) {
    this.branchId = id;
    this.createOfficeModal.open();
  }

  openModalForDeleting(id: number) {
    this.deleteConfirmationModal.open();
    this.userIdForDelete = id;
  }

  deleteOfficeBranch() {
    this.offices = this.offices.filter((office: Office) => office.id !== this.userIdForDelete);
    showMessage(PopupType.Success, this.translate.instant("popup.office_branch_deleted_successfully"));

    this.deleteConfirmationModal.close();
  }
}
