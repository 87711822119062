import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
	moduleId: module.id,
	selector: "icon-logo",
	template: `
    <ng-template #template>
      <svg
         version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1080 1080"
        class="mr-[-4%] w-[150px] h-[150px]"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #2b2d41;
          }
          .st1 {
            fill: #fcda6f;
          }
          .st2 {
            fill: none;
          }
          .st3 {
            fill: #bcd4ce;
          }
          .st4 {
            fill: #d2483a;
          }
          .st5 {
            fill: #d4483b;
          }
          .st6 {
            fill: #a2b9b3;
          }
          .st7 {
            fill: #af4133;
          }
          .st8 {
            fill: #e9b13c;
          }
          .st9 {
            fill: #e9b63f;
          }
          .st10 {
            fill: #eca348;
          }
          .st11 {
            fill: #efd695;
          }
          .st12 {
            fill: #fbcb3e;
          }
          .st13 {
            fill: #fda155;
          }
          .st14 {
            fill: #37302c;
          }
          .st15 {
            fill: #ffffff;
          }
        </style>
        <g>
<path class="st0" d="M764.2,847c-0.7,14.5-8,26.2-18.9,35.5c-11.1,9.5-24.2,13.9-38.8,13.9c-119.6,0-239.2,0.1-358.9-0.1
 c-24.3,0-42.6-11.3-52.9-34c-2.8-6.1-4.1-12.5-4.3-19c-0.3-10.3-0.5-20.7-0.2-31h66.1c0.1,0.4,0.2,0.8,0.4,1.2
 c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.4,0.4,0.7,0.6
 c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.2,1,0.3c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.1,1.2,0.2
 c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.4,0c9.7,0.1,19.4,0,29.1,0.1c7,0,14-0.2,21.1,0.5c3.6,0.3,7.3,0,10.9-0.5
 c0.4-0.1,0.9-0.1,1.3-0.3c0.3-0.1,0.5-0.1,0.8-0.2c0.2,0,0.3-0.1,0.5-0.2h0c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0
 c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.2l0,0c0.3-0.2,0.6-0.4,0.8-0.6c0.3-0.2,0.6-0.5,0.8-0.8c0,0,0,0,0,0
 c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0,0,0,0,0-0.1c0.1-0.4,0.3-0.8,0.4-1.2h185.9c0,0,0,0,0,0.1
 c0.1,0.4,0.2,0.8,0.4,1.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.2,0.2,0.3
 c0,0.1,0.1,0.2,0.2,0.2c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
 c0.3,0.2,0.5,0.3,0.9,0.5h0c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0
 c0.1,0,0.2,0.1,0.3,0.1c0.4,0.1,0.8,0.2,1.3,0.2c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0.1c0.3,0,0.6,0,1,0c20.6,0.2,41.2,0.1,61.8,0
 c0.4,0,0.7,0,1.1,0c0.2,0,0.4,0,0.6-0.1c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.2c0.4-0.1,0.8-0.2,1.2-0.4
 c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.3,0.9-0.5c0,0,0.1,0,0.2-0.1c0.2-0.1,0.4-0.3,0.6-0.5c0.1,0,0.2-0.1,0.3-0.2
 c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0-0.1,0.1-0.3,0.2-0.4
 c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7h64.5C764.6,823.8,764.8,835.4,764.2,847z"/>
<path class="st1" d="M421.3,917.8C421.3,917.8,421.3,917.8,421.3,917.8c-0.6,6.7-3.8,12-8.8,16.2c-5.2,4.4-11.2,6.6-18.1,6.4
 c-12.9,0-24.2-9.3-25.7-21.2c0-0.4-0.1-0.9-0.2-1.4h7.4c5,14.9,20.8,19.9,32.1,10.2c2.6-2.2,4.6-5,5.6-8.3c0.2-0.6,0.4-1.3,0.5-2
 H421.3z"/>
<path class="st1" d="M684.6,917.8c-0.4,11.7-13.2,22.6-26.3,22.5c-13.5-0.1-26-10.7-26.5-22.5h7.4c0.1,0.6,0.2,1.2,0.4,1.7
 c3.8,10.6,15.4,16,26,12c5.5-2.1,9.2-6,11.3-11.5c0.3-0.7,0.5-1.4,0.6-2.2H684.6z"/>
<path class="st0" d="M714.7,869.6c-3.7,0-6.9,2.5-7.8,6c1.3,1.5,2.1,3.5,2.1,5.7c0,0.7-0.1,1.3-0.2,2c1.5,1.6,3.6,2.6,6,2.6
 c4.5,0,8.1-3.6,8.1-8.1C722.8,873.3,719.2,869.6,714.7,869.6z"/>
<path class="st0" d="M706.8,875.6c-1.6-1.9-4.1-3.2-6.8-3.2c-4.9,0-8.9,4-8.9,8.9c0,4.9,4,8.9,8.9,8.9c4.2,0,7.8-3,8.7-6.9
 c0.1-0.6,0.2-1.3,0.2-2C708.9,879.1,708.1,877.2,706.8,875.6z"/>
<g>
 <path class="st2" d="M413.8,224.7c-0.5-0.4-1-0.7-1.5-1.1c0,0,0,0,0,0C412.8,223.9,413.3,224.3,413.8,224.7
	 C413.8,224.7,413.8,224.7,413.8,224.7z"/>
 <path class="st2" d="M510,143.6C510,143.6,510,143.6,510,143.6c-0.2-0.2-0.3-0.4-0.5-0.5C509.7,143.2,509.8,143.4,510,143.6z"/>
 <path class="st2" d="M360.2,278.7c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0C360.1,278.9,360.2,278.8,360.2,278.7z"/>
 <path class="st2" d="M353,381.9c0.2,0.7,0.4,1.3,0.6,2c0.3,0.8,0.6,1.6,0.9,2.4c-0.3-0.8-0.6-1.6-0.9-2.4
	 C353.4,383.2,353.2,382.5,353,381.9z"/>
 <path class="st2" d="M405.7,555.7c12,9,25.2,15.5,39.7,19.4C430.9,571.2,417.7,564.7,405.7,555.7z"/>
 <path class="st2" d="M402.4,220.7c-2.5-0.6-4.8,0.4-7,2.7c-6.9,7.3-13.2,15-18.7,23.1c5.6-8.1,11.9-15.8,18.7-23.1
	 C397.7,221.1,399.9,220.1,402.4,220.7z"/>
 <path class="st2" d="M718.9,339.9c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.9-0.8-1.4-1.2c0.5,0.4,0.9,0.8,1.4,1.2
	 C718.6,339.7,718.8,339.8,718.9,339.9z"/>
 <path class="st2" d="M726.5,343.9c-0.1,0-0.2,0-0.2,0h0c-0.4-0.1-0.7-0.2-1.1-0.3C725.5,343.7,726,343.8,726.5,343.9z"/>
 <path class="st2" d="M727.8,344.2L727.8,344.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.7-0.1c-0.1,0-0.2,0-0.4-0.1
	 C726.9,344,727.3,344.1,727.8,344.2z"/>
 <path class="st2" d="M732.4,343.9c-1.5,0.3-2.9,0.4-4.3,0.3c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0
	 C729.3,344.3,730.8,344.2,732.4,343.9z"/>
 <path class="st2" d="M793.4,365c-0.8-0.8-1.6-1.6-2.5-2.3C791.8,363.5,792.6,364.2,793.4,365z"/>
 <path class="st2" d="M797.3,370.2c-0.3-0.5-0.5-0.9-0.8-1.4c0.1,0.1,0.1,0.2,0.2,0.3C796.9,369.5,797.1,369.9,797.3,370.2z"/>
 <path class="st2" d="M665.7,251c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0C665.6,251,665.7,251,665.7,251z"/>
 <path class="st2" d="M838.2,444.4c-1.2,1.4-2.4,2.9-3.6,4.3C835.8,447.2,837,445.7,838.2,444.4z"/>
 <path class="st2" d="M617.8,527.3c1.5-1.8,2.4-4.3,2.5-6.8C620.2,523,619.3,525.5,617.8,527.3z"/>
 <path class="st2" d="M600.5,479L600.5,479c-0.1-0.1-0.2-0.2-0.2-0.3C600.4,478.8,600.5,478.9,600.5,479z"/>
 <path class="st2" d="M602,481.2c0,0,0.1,0.1,0.1,0.2c-0.5-0.8-1-1.5-1.6-2.3v0C601,479.7,601.5,480.4,602,481.2z"/>
 <path class="st2" d="M609.1,491c-0.5-0.5-0.9-1.1-1.3-1.7c-0.3-0.3-0.5-0.7-0.8-1.1c0.2,0.4,0.5,0.7,0.8,1.1
	 C608.2,489.9,608.6,490.4,609.1,491z"/>
 <path class="st2" d="M666.6,491.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C666.4,491.4,666.5,491.4,666.6,491.4z"/>
 <path class="st2" d="M608.8,466.1c-1.1-1.9-2.2-3.8-3.2-5.7h0c0,0,0,0,0,0l0,0C606.6,462.4,607.7,464.3,608.8,466.1z"/>
 <path class="st2" d="M606.4,460.5c1.1,0.2,2,0.6,2.8,1.2C608.5,461.1,607.5,460.7,606.4,460.5z"/>
 <path class="st2" d="M501.6,174.8c0.9,0.4,2,0.5,3.3,0.6c2,0,3.8-0.2,5.7-0.5c-1.8,0.3-3.7,0.5-5.7,0.5
	 C503.7,175.4,502.6,175.2,501.6,174.8z"/>
 <path class="st2" d="M497.5,170.3c-0.5-0.6-0.8-1.1-1.2-1.6C496.7,169.2,497.1,169.7,497.5,170.3z"/>
 <path class="st2" d="M522.2,170.6c1.7-0.8,3.4-1.5,5.2-1.8c3.1-0.4,6.3-1.2,9.4-1.2c-3.1,0-6.3,0.7-9.4,1.2
	 C525.6,169.1,523.9,169.8,522.2,170.6z"/>
 <path class="st2" d="M395.8,547.6c-0.5-0.5-1-0.9-1.5-1.4c0,0,0,0,0,0C394.8,546.7,395.3,547.1,395.8,547.6z"/>
 <path class="st2" d="M617.7,527.5c-0.9,1.1-1.9,2.1-3,3.1C615.7,529.6,616.7,528.6,617.7,527.5z"/>
 <path class="st2" d="M616,511.9c0.3,0.2,0.5,0.4,0.8,0.7c0.2,0.2,0.4,0.3,0.6,0.5c-0.2-0.2-0.4-0.4-0.6-0.5
	 C616.5,512.3,616.3,512.1,616,511.9z"/>
 <path class="st2" d="M386.1,547.9c0.2,1.1,0.7,2.3,1.3,3.4c0.2,0.4,0.4,0.8,0.6,1.1c-0.2-0.4-0.4-0.7-0.6-1.1
	 C386.9,550.2,386.4,549,386.1,547.9z"/>
 <path class="st2" d="M388.2,552.7c0.2,0.3,0.4,0.7,0.7,1c2.1,2.8,4.2,5.5,6.4,8.2c-2.2-2.7-4.3-5.4-6.4-8.2
	 C388.7,553.4,388.4,553.1,388.2,552.7z"/>
 <path class="st2" d="M404.4,572c2.8,2.9,5.8,5.6,9,8.2c4.1,3.2,8.3,6.1,12.7,8.7c-4.4-2.6-8.6-5.5-12.7-8.8
	 C410.2,577.6,407.2,574.9,404.4,572z"/>
 <path class="st2" d="M502.4,670.1c-2.6-0.2-5.1-0.3-7.7-0.3c-5.6-0.1-11.2-0.1-16.8-0.1c5.6,0,11.2,0,16.8,0.1
	 C497.2,669.8,499.8,669.9,502.4,670.1z"/>
 <path class="st2" d="M534.8,678c-0.5-0.8-1.2-1.5-2.1-2.2C533.6,676.5,534.3,677.3,534.8,678z"/>
 <path class="st2" d="M424,669.7c-11.8,0.1-23.6,0.5-35.4,1.4c-3,0.2-6.1,0.4-9.1,0.7c3-0.2,6.1-0.4,9.1-0.7
	 C400.4,670.2,412.2,669.9,424,669.7z"/>
 <path class="st2" d="M319,695.6c-2.4,5.6-6.1,7.2-10.2,5.3c-0.1-0.1-0.2-0.1-0.3-0.2c0.1,0.1,0.2,0.1,0.3,0.2
	 C313,702.9,316.7,701.2,319,695.6c5.4-12.7,14.7-20.7,26.7-25.7C333.7,674.9,324.4,682.9,319,695.6z"/>
 <path class="st2" d="M305.7,689.6c0.3-0.9,0.8-1.8,1.2-2.7c1.1-2.4,2.4-4.7,3.8-6.9c-1.4,2.2-2.7,4.5-3.8,6.9
	 C306.5,687.9,306.1,688.7,305.7,689.6c-1.1,2.9-1.2,5.5-0.3,7.6C304.6,695.2,304.6,692.6,305.7,689.6z"/>
 <path class="st2" d="M531.6,686.5c-3.2,1.8-6.7,3-10.3,3.4c-7.7,1-15.4,2.1-23.1,2.8c7.7-0.7,15.4-1.7,23.1-2.7
	 C524.9,689.4,528.4,688.3,531.6,686.5c1.6-0.9,2.8-2.1,3.4-3.2C534.4,684.4,533.2,685.5,531.6,686.5z"/>
 <path class="st2" d="M350.7,676.9c5.4-2.4,11.2-3,16.9-3.8c3-0.5,6.1-0.7,9.1-1c-3.1,0.2-6.1,0.5-9.1,1
	 C362,673.9,356.1,674.5,350.7,676.9c-2.1,0.9-3.9,2.1-4.7,4C346.9,678.9,348.6,677.8,350.7,676.9z"/>
 <path class="st2" d="M350.2,689.6c-1.7-0.8-3.2-1.8-4.1-3.2C346.9,687.8,348.5,688.8,350.2,689.6c4.2,2.1,8.8,2.6,13.3,3.2
	 c15.2,2,30.5,2.1,45.8,2.4c-15.3-0.4-30.6-0.5-45.8-2.5C359,692.2,354.4,691.7,350.2,689.6z"/>
 <path class="st2" d="M378.7,664.2c4.2-0.1,8.4-0.1,12.5-0.1C387,664.1,382.8,664.1,378.7,664.2z"/>
 <path class="st2" d="M710.5,664.6C710.5,664.6,710.5,664.6,710.5,664.6c-0.2,0-0.4,0-0.6,0C710.1,664.6,710.3,664.6,710.5,664.6z"
	 />
 <path class="st2" d="M710.6,664.6c3.5-0.1,7-3.5,6.9-6.9c0-1.4-0.5-2.8-1.2-3.9c0.7,1.1,1.2,2.4,1.2,3.9
	 C717.5,661.1,714.1,664.5,710.6,664.6z"/>
 <path class="st2" d="M713.6,651.5c-0.9-0.4-1.9-0.7-3-0.7c-1,0-1.9,0.2-2.7,0.5c0.8-0.3,1.7-0.5,2.7-0.5
	 C711.7,650.8,712.7,651.1,713.6,651.5z"/>
 <path class="st2" d="M392.3,649.6c-1.7,0-3.4,0-5.1,0c0,0,0,0,0,0C388.9,649.6,390.6,649.6,392.3,649.6z"/>
 <path class="st2" d="M387.2,649.6c-3.2,0-6.5-0.1-9.7-0.1C380.7,649.5,384,649.6,387.2,649.6L387.2,649.6z"/>
 <path class="st2" d="M413.2,656.8c0,1-0.2,1.9-0.5,2.7C413,658.6,413.2,657.7,413.2,656.8c0-3.5-2.9-6.8-7.1-7
	 c-1.5-0.1-2.9-0.1-4.4-0.2c1.5,0,2.9,0.1,4.4,0.2C410.3,650,413.1,653.3,413.2,656.8z"/>
 <path class="st2" d="M543.1,646.5c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
	 c0.1,0.1,0.3,0.2,0.4,0.3C542.8,646.3,542.9,646.4,543.1,646.5z"/>
 <path class="st2" d="M543.9,647.1c0,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.2C543.6,646.9,543.7,647,543.9,647.1z"/>
 <path class="st2" d="M546.7,648.5c-0.2,0-0.3-0.1-0.5-0.2c0,0,0,0-0.1,0c0,0,0,0,0.1,0C546.4,648.4,546.5,648.5,546.7,648.5z"/>
 <path class="st2" d="M547.4,648.8L547.4,648.8c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0
	 C547.3,648.7,547.3,648.7,547.4,648.8C547.4,648.7,547.4,648.7,547.4,648.8L547.4,648.8z"/>
 <path class="st2" d="M547.7,648.8L547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8z"/>
 <path class="st2" d="M547.7,648.8L547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8
	 C547.7,648.8,547.7,648.8,547.7,648.8L547.7,648.8z"/>
 <path class="st2" d="M543.7,630c-0.9,0.2-1.7,0.4-2.4,0.6C542,630.4,542.8,630.2,543.7,630z"/>
 <path class="st2" d="M556.4,640.8c-0.3-0.9-0.4-1.8-0.2-2.7C556,639,556.1,639.9,556.4,640.8z"/>
 <path class="st2" d="M786.7,656.4c-0.6,1.3-1.4,2.6-2.3,3.8C785.3,658.9,786,657.7,786.7,656.4z"/>
 <path class="st2" d="M788.4,651.2c-0.3,1.4-0.7,2.8-1.3,4.2C787.7,654,788.1,652.6,788.4,651.2z"/>
 <path class="st2" d="M746.4,630.1c-0.3,0.3-0.6,0.7-0.8,1h0C745.8,630.7,746.1,630.4,746.4,630.1z"/>
 <path class="st2" d="M774.6,663.1c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.3-0.3-0.6-0.4-0.8C774,661.9,774.3,662.5,774.6,663.1z"/>
 <path class="st2" d="M775.6,665.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c-0.2-0.4-0.3-0.7-0.5-1.1c0-0.1-0.1-0.2-0.1-0.2
	 C775.1,664.1,775.4,664.7,775.6,665.2z"/>
 <path class="st2" d="M776.5,667C776.5,667,776.5,667,776.5,667c-0.2-0.5-0.5-1.1-0.7-1.6C776,665.9,776.2,666.5,776.5,667z"/>
 <path class="st2" d="M472.7,646.6c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c0.2,0.1,0.5,0.2,0.9,0.3
	 C472.1,646.4,472.4,646.5,472.7,646.6z"/>
 <path class="st2" d="M437.9,356.5c0.6,0.6,1.1,1.2,1.4,2C439,357.7,438.5,357.1,437.9,356.5z"/>
 <path class="st2" d="M336.6,332.8c0,0.7,0,1.3-0.1,2c0-0.3,0-0.7,0-1.1C336.5,333.5,336.5,333.1,336.6,332.8z"/>
 <path class="st2" d="M337.2,322.7c0-0.5,0.1-1,0.2-1.5c0,0.3-0.1,0.7-0.1,1.1c0,0.2,0,0.5-0.1,0.8c-0.1,1.1-0.2,2.2-0.3,3.3
	 c-0.2,2-0.3,4-0.4,6v-0.2c0-1.3,0.1-2.6,0.2-3.9c0.1-1.7,0.2-3.4,0.4-5.1C337.2,323.1,337.2,322.9,337.2,322.7z"/>
 <path class="st2" d="M338.1,315.9c-0.1,0.5-0.1,1.1-0.2,1.6c-0.2,1.3-0.3,2.5-0.4,3.7c0.1-1.3,0.3-2.5,0.4-3.8
	 C337.9,317,338,316.4,338.1,315.9z"/>
 <path class="st2" d="M336.5,341.4v1.5c0-0.6,0-1.2,0-1.9C336.5,341.2,336.5,341.3,336.5,341.4z"/>
 <path class="st2" d="M336.8,349c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.3,0-0.6,0-0.8c0,0.4,0,0.7,0,1.1c0,0.4,0,0.8,0.1,1.2
	 C336.7,348.6,336.7,348.8,336.8,349z"/>
 <path class="st2" d="M336.5,337.9v2c0-0.2,0-0.4,0-0.6C336.4,338.8,336.4,338.3,336.5,337.9z"/>
 <path class="st2" d="M337,352.2c0-0.3-0.1-0.6-0.1-1c0-0.3,0-0.7-0.1-1c0,0.4,0,0.7,0.1,1.1C337,351.6,337,351.9,337,352.2z"/>
 <path class="st2" d="M339,366.7c-0.2-1.1-0.4-2.2-0.6-3.3c0,0,0,0,0,0C338.6,364.6,338.8,365.7,339,366.7z"/>
 <path class="st2" d="M342.4,380.6c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.5-0.3-1-0.4-1.6c0.1,0.5,0.3,1.1,0.4,1.6
	 C342.1,379.9,342.3,380.3,342.4,380.6z"/>
 <path class="st2" d="M458.7,176.3c-0.3,0.3-0.6,0.5-0.9,0.8C458.1,176.8,458.4,176.6,458.7,176.3c1.2-1,2.3-2.2,3.4-3.3
	 C461,174.2,459.9,175.3,458.7,176.3z"/>
 <path class="st2" d="M439.6,362.3c0.1-0.4,0.2-0.8,0.3-1.3c0-1-0.2-1.9-0.5-2.6c0.4,0.7,0.6,1.6,0.5,2.6
	 C439.8,361.5,439.7,361.9,439.6,362.3z"/>
 <path class="st2" d="M388.3,390.8c2.9,4,5.5,5.1,8.6,3.2C393.7,395.9,391.1,394.8,388.3,390.8z"/>
 <path class="st2" d="M388.2,390.7C388.2,390.7,388.2,390.7,388.2,390.7c-0.1-0.1-0.1-0.2-0.2-0.2
	 C388.1,390.6,388.1,390.6,388.2,390.7z"/>
 <path class="st2" d="M393.6,308.4c-0.8,0.6-1.5,1.4-2.3,2.5c-2,2.8-4.1,5.4-6.3,7.9c2.2-2.5,4.3-5.2,6.3-7.9
	 C392.1,309.8,392.8,309,393.6,308.4z"/>
 <path class="st2" d="M402.5,312c-0.1-0.2-0.2-0.4-0.4-0.7c-0.4-0.7-0.8-1.2-1.3-1.7c0.4,0.5,0.9,1.1,1.3,1.7
	 C402.2,311.6,402.3,311.8,402.5,312z"/>
 <path class="st2" d="M379.7,378.7c0.8,1,1.5,2,2.3,3c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.1-0.2-0.3-0.3-0.4
	 C381.3,380.7,380.5,379.7,379.7,378.7z"/>
 <path class="st2" d="M351.5,349c0,1.9,1.6,4,4.7,6.3c5.3,4,10.2,8.4,14.7,13.1c-4.5-4.7-9.4-9.1-14.7-13.1
	 C353.1,353,351.5,351,351.5,349z"/>
 <path class="st2" d="M261.3,386.2c-0.3-0.2-0.6-0.5-0.9-0.7c-0.1-0.1-0.2-0.1-0.3-0.2C260.5,385.6,260.9,385.9,261.3,386.2z"/>
 <path class="st2" d="M286.4,418.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
	 c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.4-0.3C286.1,418.5,286.3,418.4,286.4,418.3z"/>
 <path class="st2" d="M287.1,417.8c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.1-0.1
	 C286.9,417.9,287,417.9,287.1,417.8z"/>
 <path class="st2" d="M320.5,457.1c-1.5-0.8-3-1.6-4.4-2.5C317.5,455.5,319,456.4,320.5,457.1z"/>
 <path class="st2" d="M440,169.9c-0.2,0.8-0.3,1.7-0.5,2.5h0C439.6,171.6,439.8,170.8,440,169.9z"/>
 <path class="st2" d="M438.6,176.6C438.6,176.6,438.6,176.7,438.6,176.6L438.6,176.6c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0
	 C438.5,176.5,438.5,176.6,438.6,176.6C438.6,176.6,438.6,176.6,438.6,176.6z"/>
 <path class="st3" d="M819.3,733c-1.3,2-2.3,4.2-5.3,6.4c-0.1-1.9-0.2-3-0.3-4.2c-1.1-9.7-6.8-15.3-16.6-15.9
	 c-3.6-0.2-7.3-0.2-10.9,0c-3.1,0.1-4.3-1.1-4.2-4.2c0.1-6.8,0.1-13.6-0.1-20.4c-0.1-4.3-2.1-9.2-0.7-12.8c1.3-3.3,6.2-4.9,9.2-7.7
	 c15.3-14.1,16-38.1,1.7-53.6c-14.2-15.3-38.2-16.3-53.6-2.1c-1.1,1-2.2,2-3,3.1c-1.7,2.3-3.4,2.8-6.2,1.8
	 c-8.6-3.1-17.5-3.4-26.5-3.4c-26.4,0.1-52.8-0.1-79.2,0.1c-5.2,0.1-9-1-10.7-6.4c0.7-1.9,2.8-2.2,4.4-3.1
	 c35.8-19.2,57.9-49.1,68.2-88c1-3.7,2.3-5.7,5.9-7.3c15-6.5,25.7-17.8,33.6-31.9c1.6-3,2.9-6.1,5.1-8.8c4.3-5.5,3.4-8.4-3.1-11.7
	 c-0.6-0.3-1.2-0.8-1.9-1.3c3.5-4.8,6.9-9.4,10.1-14.1c1.8-2.6,3.3-5.4,4.6-8.2c3.4-7.5,0-12.5-8.2-12.1c-4.1,0.2-8.2,1.1-12.2,1.8
	 c-3.1,0.5-6.1,1.1-9.2,1.6c-1.1-2.3,0.8-3.3,1.6-4.5c4-6.1,7-12.7,9.7-19.5c1.3-3.3,2.2-6.6-0.6-9.5c-2.6-2.8-5.7-2-8.8-0.9
	 c-9,2.9-18,5.6-27.5,6.7c-1.4,0.2-2.9,0.4-4.3,0.5c-14,1.1-16.7-0.4-23.8-12.3c-1.9-3.1-0.9-6.1-0.5-9.2c3.9-27.3,2-54.1-6.2-80.4
	 c-9.9-31.4-26.6-58.5-50.5-81.2c-1.4-1.3-2.8-2.7-4.1-4.1c-0.2-0.3-0.2-0.9-0.3-1.7c8.8,0.6,17.1,2.9,25.6,4.7
	 c5,1,9.8,2.5,14.7,3.8c5.2,1.4,5.1,1.5,3,6c-0.8,1.8-1.8,3.5-2.4,5.3c-3.3,8.9-0.5,17.1,7.7,21.8c3.3,1.9,7,3.5,10.6,4.7
	 c7.6,2.5,10,9.3,13,15.6c1.1,2.3-0.4,4.7-1.6,6.9c-2.4,4.5-5.1,8.8-7.2,13.4c-4.8,10.4-2.8,18.6,6.1,25.8
	 c3.2,2.6,6.8,4.8,10.5,6.5c12.6,5.7,24.4,12.9,35.1,21.7c0.7,0.6,1.6,1.1,2.4,1.7c8.6,6.8,17.9,8.4,27.8,3.5
	 c6.5-3.2,12-2.3,17.5,2c6.5,5,13.4,9.5,20.7,13.4c8,4.3,9.6,9.7,6.3,18.3c-1.1,2.7-2.3,5.3-3.4,8c-2.9,7.4-4.1,14.9-2.2,22.7
	 c1.5,6.1,2.9,12.2,4.8,18.3c1.7,5.5,4.9,9.9,9.8,13.2c6.3,4.1,12.4,8.4,18.8,12.5c13,8.4,22.3,7,32.5-5c5.1-6,4.8-5.5,12-1.6
	 c7.9,4.2,11.7,10,13.2,18.9C886.2,568.7,870.5,654.3,819.3,733z"/>
 <path class="st4" d="M716.9,660.3c0.1-0.2,0.2-0.4,0.2-0.6C717,659.9,717,660.1,716.9,660.3L716.9,660.3z M716.6,660.9
	 c0.1-0.2,0.2-0.4,0.3-0.6C716.8,660.5,716.7,660.7,716.6,660.9L716.6,660.9z M715.8,661.9c0.1-0.1,0.1-0.2,0.2-0.3
	 C715.9,661.7,715.9,661.8,715.8,661.9L715.8,661.9z M384,671.5c1.5-0.1,3-0.2,4.6-0.3c1.5-0.1,2.9-0.2,4.4-0.3
	 c-1.5,0.1-2.9,0.2-4.4,0.3C387.1,671.3,385.6,671.4,384,671.5z M304.9,693c0-0.4,0.1-0.9,0.2-1.3c0,0.1,0,0.2-0.1,0.3
	 C305,692.3,304.9,692.7,304.9,693z M304.8,694.1c0-0.4,0-0.7,0.1-1.1c0,0,0,0,0,0C304.8,693.4,304.8,693.8,304.8,694.1z
		M764.6,695.7c0-29.8-25.2-58.6-58.5-58.1c-26.6,0.3-53.3,0.1-80,0.1c-1.2,0-2.4,0-3.6,0c-2.5-0.1-4,0.9-4.9,3.3
	 c-3.5,8.7-10.1,13.3-19.3,14.3c-2.1,0.2-3.3,0.9-4.2,3.1c-3.7,9.4-9.3,12.2-18.8,8.5c-6.4-2.4-12.1-2.9-18.9-1.4
	 c-15.6,3.5-31.1-7.1-34.7-22.5c-1.5-6.6-1.5-6.6-8.4-6.6c-1.2,0-2.4,0-3.6,0c-2.2,0-3.6,0.9-4.2,3.2c-0.4,1.6-0.9,3.3-1.5,4.8
	 c-5.5,14.5-22.9,23-37.5,17.9c-3.3-1.2-6-1.2-9,0.2c-2.6,1.2-5.3,2.3-8.3,2.6c-7.8,0.9-12-1.2-14.7-8.6c-1.1-3-2.9-3.6-5.3-4
	 c-7-1.1-12.1-5-15.4-11c-1.8-3.3-3.9-4.3-7.5-4.2c-10.2,0.2-20.4,0-30.5,0v0c-9.5,0-18.9,0-28.4,0c-2.6,0-5.3,0.2-7.9,0.7
	 c-27.8,5.2-48.5,29.5-49.2,57.8c-0.3,12.3-0.1,24.7-0.1,37.1c0,7.1,0.1,7.2,7.4,7.3c16.7,0.1,33.4,0,50.1,0c7.9,0,7.9,0,7.9-8
	 v-1.5c0.1-9.6,2.5-12.2,11.9-12.2c17.4-0.1,34.9,0.2,52.3-0.1c11.6-0.2,16.1,0.9,15.6,15.3c-0.2,6.3,0.1,6.4,6.5,6.5
	 c12.6,0.1,25.2-0.2,37.8-0.5c16.2-0.4,32.5,0.1,48.7,0.4c27.1,0.4,54.3,0.2,81.4,0.2c12.1,0,10.3,0.8,10.5-10.5
	 c0.1-8.6,2.6-11.1,11.4-11.2c19.1-0.1,38.3,0,57.4,0h0.7c7.6,0.2,10.3,3,10.4,10.7c0,1.7,0,3.4,0,5.1c0.2,5.3,0.5,5.8,5.9,5.8
	 c17.4,0.1,34.9,0.1,52.3,0c5.8,0,5.9-0.2,6-6.3C764.6,721.4,764.5,708.6,764.6,695.7z M341.1,672.1c-0.2,0.1-0.5,0.2-0.7,0.4
	 c-0.1,0.1-0.3,0.1-0.4,0.2c-0.9,0.5-1.8,1-2.6,1.5h0c-1.3,0.8-2.6,1.7-3.8,2.6c-0.5,0.3-0.9,0.7-1.4,1.1c-0.3,0.2-0.6,0.5-0.9,0.8
	 c-0.3,0.2-0.6,0.5-0.8,0.7c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.4-0.8,0.8-1.2,1.2c-1.7,1.8-3.3,3.7-4.7,5.8
	 c-0.4,0.5-0.7,1-1,1.5c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.5-0.6,1-0.9,1.5c-0.9,1.6-1.8,3.3-2.5,5c-0.2,0.5-0.4,1-0.7,1.4
	 c-0.2,0.4-0.5,0.9-0.7,1.2c-0.2,0.4-0.5,0.7-0.8,1c0,0,0,0,0,0c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.6,0.5-0.9,0.7
	 c-0.3,0.2-0.6,0.3-1,0.5c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.5,0-1-0.1-1.6-0.2c-0.4-0.1-0.9-0.2-1.4-0.5
	 c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.8-0.4-1.4-0.9-2-1.5c-0.5-0.6-1-1.3-1.3-2
	 c-0.1-0.2-0.1-0.3-0.1-0.5c-0.2-0.8-0.4-1.7-0.4-2.6c0-0.4,0-0.7,0.1-1.1c0-0.4,0.1-0.9,0.2-1.3c0,0,0-0.1,0-0.2
	 c0-0.2,0.1-0.4,0.1-0.7h0c0,0,0-0.1,0-0.1c0.1-0.3,0.2-0.7,0.3-1c0.2-0.4,0.4-0.9,0.6-1.3c0.2-0.4,0.4-0.9,0.6-1.3
	 c0.1-0.3,0.3-0.6,0.4-0.8c0.4-0.9,0.9-1.8,1.4-2.7c0.6-1.2,1.3-2.3,2-3.4c0.9-1.4,1.8-2.7,2.8-4c0.3-0.4,0.6-0.7,0.9-1.1
	 c1.5-1.9,3.1-3.7,4.9-5.4c0.5-0.5,0.9-0.9,1.4-1.3c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.4,0.9-0.8,1.4-1.2c2.4-2,4.8-3.8,7.5-5.4
	 c1-0.6,2-1.2,3-1.7c1-0.6,2.1-1.1,3.1-1.6c0.7-0.3,1.4-0.7,2.1-1c1.1-0.5,2.2-0.9,3.2-1.4c1.1-0.4,2.2-0.8,3.3-1.2
	 c0.7-0.2,1.5-0.5,2.2-0.7c1.1-0.3,2.2-0.6,3.3-0.9c1.1-0.3,2.2-0.5,3.4-0.8c2.3-0.5,4.5-0.9,6.8-1.2c0.6-0.1,1.1-0.1,1.7-0.2
	 c1.7-0.2,3.4-0.4,5.2-0.5c0.8-0.1,1.6-0.1,2.3-0.2c1.5-0.1,3.1-0.2,4.6-0.3c1.1,0,2.1-0.1,3.2-0.1c0,0,0,0,0,0h0.1
	 c1.9,0,3.7,0,5.6,0l4.2,0c0,0,0,0,0,0c1.7,0,3.4,0,5.1,0c3.1,0,6.3-0.1,9.4,0c1.5,0,2.9,0.1,4.4,0.2c0.9,0,1.7,0.2,2.5,0.6
	 c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.2,0.7,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.3,0.5,0.5
	 c0.1,0.2,0.3,0.3,0.4,0.5c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.3,0.9c0,0.2,0.1,0.4,0.1,0.6
	 c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6c0,0.5,0,0.9-0.1,1.4c-0.1,0.4-0.2,0.9-0.4,1.3c-1.1,2.5-3.6,4.3-7,4.6c-1.7,0.1-3.4,0.1-5.1,0.1
	 c-3.1,0-6.2,0-9.4,0c-4.2,0-8.4,0-12.5,0.1c-0.7,0-1.4,0-2.1,0c-8.7,0.3-17.3,1.1-25.7,3.8c-1.8,0.6-3.4,1.2-5.1,1.9l-2.2,1
	 C342.7,671.3,341.9,671.7,341.1,672.1z M535.5,682.1c-0.1,0.4-0.3,0.8-0.5,1.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.3,0.4-0.5,0.7
	 c-0.2,0.3-0.5,0.5-0.8,0.8c-0.6,0.5-1.3,1-2.1,1.5c-3.1,1.8-6.7,3-10.3,3.4c-2.9,0.4-5.8,0.8-8.7,1.1c-2.9,0.4-5.8,0.7-8.7,1
	 c-1.9,0.2-3.9,0.4-5.8,0.6c-2.4,0.2-4.8,0.4-7.1,0.5c-24.1,1.4-48.3,3.1-72.5,2.3c-1.6,0-3.1-0.1-4.6-0.1c-1.6,0-3.1-0.1-4.7-0.1
	 c-15.3-0.3-30.6-0.5-45.7-2.4c-4.5-0.6-9.2-1.1-13.4-3.2c-0.4-0.2-0.8-0.4-1.2-0.7c-0.4-0.2-0.8-0.5-1.1-0.7
	 c-0.3-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.6-0.7-1c-0.2-0.4-0.4-0.7-0.5-1.2c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.4-0.1-0.9,0-1.4
	 c0-0.1,0-0.3,0.1-0.4c0,0,0,0,0-0.1c0-0.4,0.1-0.8,0.3-1.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.2-0.5,0.4-0.7
	 c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.6c0.4-0.4,1-0.8,1.6-1.2c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.3,1-0.5
	 c5.4-2.4,11.3-3,16.9-3.8c3-0.4,6.1-0.7,9.1-1c0.9-0.1,1.8-0.1,2.7-0.2c1.5-0.1,3-0.2,4.6-0.3c1.5-0.1,3-0.2,4.6-0.3
	 c1.5-0.1,2.9-0.2,4.4-0.3c7.4-0.5,14.9-0.8,22.3-1c2.9-0.1,5.8-0.1,8.6-0.1c10.2-0.1,20.4,0,30.7,0c7.7,0,15.4,0,23.2,0
	 c5.6,0,11.2,0,16.8,0.1c2.6,0,5.1,0.1,7.7,0.3c1.7,0.1,3.3,0.2,5,0.4c0.4,0,0.9,0.1,1.3,0.1c1.6,0.1,3.1,0.3,4.7,0.5
	 c1.8,0.2,3.6,0.4,5.4,0.7c1.8,0.3,3.6,0.5,5.4,0.8c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6,0.1,0.9,0.2c0.4,0.1,0.7,0.2,1.1,0.3
	 c0.2,0,0.4,0.1,0.6,0.2c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.3,1.1,0.4c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.2,0.6,0.3,1,0.5
	 c0.3,0.2,0.6,0.4,0.9,0.6c0.9,0.7,1.6,1.5,2.1,2.2c0.3,0.4,0.5,0.9,0.6,1.4C535.8,680.3,535.8,681.2,535.5,682.1z M678.7,664.2
	 h-45.1c-1.2,0-2.4-0.3-3.4-0.9c-2.5-1-3.9-3-3.9-6v-0.5c0-0.1,0-0.3,0-0.5c0-0.1,0-0.3,0-0.5c0,0,0,0,0-0.1
	 c0.5-3.5,3.5-6.3,7.2-6.3h45.1c2,0,3.8,0.8,5.2,2.2c1.3,1.3,2.2,3.2,2.2,5.2c0,2-0.8,3.9-2.2,5.2
	 C682.5,663.4,680.7,664.2,678.7,664.2z M717.3,658.8c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0,0,0,0
	 c-0.1,0.2-0.2,0.4-0.3,0.6v0c-0.2,0.3-0.3,0.5-0.5,0.8c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0
	 c-0.1,0.1-0.3,0.3-0.4,0.4c0,0,0,0,0,0c-0.5,0.5-1,0.9-1.6,1.2c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0.1-0.6,0.3-1,0.4
	 c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.3,0-0.5,0.1-0.8,0.1h-0.1c-0.2,0-0.4,0-0.5,0c0,0-0.1,0-0.1,0
	 c-0.2,0-0.4,0-0.6-0.1h-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c-0.2-0.1-0.5-0.2-0.7-0.3
	 c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3
	 c-0.4-0.6-0.8-1.2-1.1-1.9c-0.2-0.7-0.4-1.5-0.4-2.2c0-0.7,0.1-1.4,0.4-2.1c0.1-0.4,0.3-0.8,0.5-1.1c0.1-0.2,0.3-0.4,0.4-0.7
	 c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.4-0.4,0.7-0.6c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.1,0.5-0.3,0.8-0.4c0.8-0.3,1.7-0.5,2.7-0.5
	 c1.1,0,2.1,0.3,3,0.7c0.4,0.2,0.8,0.4,1.2,0.7c0.3,0.2,0.6,0.5,0.9,0.8c0.2,0.2,0.4,0.5,0.6,0.7c0.4,0.5,0.7,1.1,0.9,1.8
	 c0.2,0.7,0.3,1.4,0.3,2.1c0,0.3,0,0.6-0.1,1C717.4,658.7,717.4,658.8,717.3,658.8z M388.6,671.2c-1.5,0.1-3,0.2-4.6,0.3
	 C385.6,671.4,387.1,671.3,388.6,671.2c1.5-0.1,2.9-0.2,4.4-0.3C391.5,670.9,390.1,671,388.6,671.2z M715.8,661.9L715.8,661.9
	 c0.1-0.1,0.2-0.2,0.2-0.3C715.9,661.7,715.9,661.8,715.8,661.9z M716.6,660.9L716.6,660.9c0.1-0.2,0.2-0.4,0.3-0.6
	 C716.8,660.5,716.7,660.7,716.6,660.9z M716.9,660.3L716.9,660.3c0.1-0.2,0.2-0.4,0.2-0.6C717,659.9,717,660.1,716.9,660.3z"/>
 <path class="st5" d="M764.6,794.7c-0.1,1.9-0.2,3.9-0.3,5.8c-9,0.1-8.9,0.1-10.3,9.2c0,0.4-0.1,0.8-0.2,1.3
	 c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.5-0.3,1-0.4,1.6c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.3-0.1,0.7-0.1,1c-0.8,12.2-5.5,22.6-12.6,32.4
	 c-1.8,2.5-4.6,4.3-5.9,7.1h-0.1c-0.3,0-0.6,0-0.9,0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2c0,0,0,0-0.1,0
	 c-0.3,0.1-0.6,0.2-0.9,0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.3,0.2-0.6,0.4-0.8,0.6c-0.3,0.2-0.6,0.5-0.8,0.7c-0.2,0.2-0.5,0.5-0.7,0.8
	 c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.2,0.5-0.2,0.7
	 c-1,0.2-1.9,0.5-2.8,1.1c-10.2,6.9-21.7,8.9-33.8,8.9c-109.5,0-219,0-328.4,0c-5.6,0-11.1-0.3-16.6-1.4
	 c-12.8-2.5-23.7-8.7-32.2-18.3c-9.8-11.1-16-24-16.4-39.3c0-0.2,0-0.3,0-0.5v-0.2c0-0.3,0-0.5,0-0.8V811c0-0.2,0-0.3,0-0.5
	 c0-0.4,0-0.8-0.1-1.2c0-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4-0.1-0.5c-0.1-0.4-0.2-0.8-0.3-1.1c0-0.1-0.1-0.3-0.2-0.5
	 c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.2-0.4-0.4-0.7-0.6l0,0c0,0,0,0,0,0
	 c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.2-1-0.5-1.6-0.6c-1-16.6-0.9-13.1,12.4-13.3c15.2-0.2,30.5-0.1,45.8,0
	 c6.7,0,6.8,0.1,6.8,7c0,3.4,0,6.8,0,10.2c0,0.6,0,1.1,0.1,1.7c0,0.5,0.1,1,0.1,1.4v0.1c0,0.3,0.1,0.6,0.1,0.9
	 c0.1,0.4,0.1,0.7,0.2,1c0.1,0.4,0.2,0.8,0.4,1.2c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.4,0.4,0.6
	 c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.4,0.4,0.7,0.6c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.2,1,0.3
	 c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.1,1.2,0.2c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.4,0c9.7,0.1,19.4,0,29.1,0.1
	 c7,0,14-0.2,21.1,0.5c3.6,0.3,7.3,0,10.9-0.5c0.4-0.1,0.9-0.1,1.3-0.3c0.3,0,0.5-0.1,0.8-0.2c0.2,0,0.3-0.1,0.5-0.2h0
	 c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.2l0,0c0.3-0.2,0.6-0.4,0.8-0.6
	 c0.3-0.2,0.6-0.5,0.8-0.8c0,0,0,0,0,0c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0,0,0,0,0-0.1
	 c0.2-0.4,0.3-0.8,0.4-1.2c0.1-0.2,0.1-0.5,0.2-0.8v0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
	 c0-0.4,0.1-0.9,0.1-1.4c0.2-4.8,0.2-9.7,0-14.5c-0.1-3.3,1.2-5,4.7-4.8c1.5,0.1,2.9,0,4.4,0c54.3,0,108.5,0,162.8,0
	 c2,0,3.9,0,5.8,0c7.1,0.1,7.1,0.1,7.2,7.3c0,3.9-0.1,7.7,0.1,11.6c0,0.6,0.1,1.2,0.2,1.7c0,0.2,0.1,0.5,0.1,0.7c0,0,0,0,0,0
	 c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0,0,0s0,0,0,0.1c0.1,0.4,0.2,0.8,0.4,1.1c0,0.1,0.1,0.2,0.1,0.2
	 c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.2,0.3,0.3,0.4,0.5
	 c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.3,0.2,0.5,0.3,0.9,0.5h0
	 c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1
	 c0.4,0.1,0.8,0.2,1.3,0.2c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0.1c0.3,0,0.6,0,1,0c20.6,0.2,41.2,0.1,61.8,0c0.4,0,0.7,0,1.1,0
	 c0.2,0,0.4,0,0.6-0.1c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.2c0.4-0.1,0.8-0.2,1.2-0.4c0.1,0,0.2-0.1,0.3-0.1
	 c0.3-0.1,0.6-0.3,0.9-0.5c0,0,0.1,0,0.2-0.1c0.2-0.1,0.4-0.3,0.6-0.5c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
	 c0.2-0.2,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3
	 c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.7c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3
	 c0-0.3,0.1-0.7,0.1-1c0.2-4.6,0.2-9.2,0-13.8c-0.1-3.6,1-5.4,4.9-5.3c18.2,0.1,36.3,0.1,54.5,0C763.2,790,764.8,791.3,764.6,794.7
	 z"/>
 <path class="st6" d="M415.9,613.3c-1.1,5.4-4.2,6.9-9.3,6.8c-19.1-0.2-38.3,0.2-57.4-0.1c-37.8-0.7-70.5,30.3-75.3,66.6
	 c-1.2,9-0.7,17.9-0.9,26.8c-0.1,5.6-0.1,5.7-5.5,5.8c-2.4,0-4.9-0.3-7.2,0.1c-6.9,1.2-10.6-1.5-14.1-7.8
	 c-20.6-37.6-33.4-77.6-39.1-120.1c-3.2-24.1-4.4-48.2-2.7-72.4c3-42.7,13.2-83.5,31.3-122.3c2.4-5.1,2.4-4.8,7.6-3.8
	 c7,1.3,11.8,5.1,14.7,11.6c1.6,3.5,3.3,7,5,10.5c4,8.1,9.8,14.5,17.9,18.5c3.5,1.8,5.6,4,6.6,7.9c0.8,3.2,2.4,6.3,3.6,9.4
	 c-8.4,4.3-10,7.9-6.2,16.4c8.5,19.1,22.8,31.7,43.1,36.8c5.3,1.3,7.9,3.1,9.3,8.8c7.2,29.6,21.1,55.5,43.4,76.6
	 C391,599.1,403,606.9,415.9,613.3z"/>
 <path class="st1" d="M859.9,442.6c-0.3-0.2-0.6-0.3-0.9-0.5c-1.1-0.5-2-1-2.8-1.4c-5.5-2.6-9.2-3-13-0.7c-1.2,0.7-2.4,1.7-3.7,3
	 c-0.4,0.4-0.8,0.8-1.2,1.3c-1.3,1.4-2.4,2.9-3.6,4.3c-1,1.2-1.9,2.4-3,3.4c-2,2.1-3.6,3.1-5.4,3c-1.1-0.1-2.4-0.5-3.9-1.4
	 c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.6c-6.7-4.1-13.2-8.6-19.8-12.9c-2.3-1.6-3.4-4-4.1-6.7
	 c-1.5-6.1-3-12.2-4.6-18.3c-0.9-3.4-0.1-6.6,0.9-9.7c0.2-0.5,0.3-1.1,0.5-1.6c0.2-0.5,0.4-1,0.5-1.5c1.1-2.9,2.5-5.7,3.6-8.6
	 c3.2-8.3,2.8-16.6-1-23.5c-0.2-0.3-0.4-0.7-0.6-1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.5-0.8-0.8-1.2c-0.1-0.1-0.2-0.3-0.3-0.4
	 c-0.2-0.3-0.5-0.6-0.8-0.9c-0.3-0.4-0.7-0.8-1.1-1.2c-0.8-0.8-1.6-1.6-2.5-2.3c0,0,0,0,0,0c-0.9-0.7-1.9-1.4-2.9-2
	 c-3.6-2.2-7.2-4.5-10.8-6.8c-5-3.2-9.9-6.5-14.8-9.7c-8-5.4-16.6-6.7-25.4-2.1c-0.4,0.2-0.9,0.4-1.3,0.6c-1.1,0.5-2.2,0.9-3.3,1.1
	 c-1.6,0.3-3.1,0.4-4.6,0.3h0c-0.5,0-0.9-0.1-1.4-0.2c-0.5-0.1-0.9-0.2-1.4-0.4c-0.4-0.1-0.8-0.3-1.1-0.4c-0.3-0.1-0.7-0.3-1-0.5
	 c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.7-0.4c0,0,0,0-0.1,0c-0.3-0.2-0.6-0.3-0.9-0.5c-0.1-0.1-0.3-0.2-0.4-0.3
	 c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.2-0.2-0.4-0.3l-0.1-0.1c-0.2-0.1-0.3-0.3-0.5-0.4
	 c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.9-0.8-1.4-1.2c0,0-0.1-0.1-0.2-0.1c-0.7-0.6-1.4-1.2-2.1-1.7c-0.5-0.4-1-0.8-1.6-1.2
	 c-0.2-0.2-0.5-0.4-0.7-0.5c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.6-0.5-1-0.7
	 c-0.3-0.2-0.6-0.5-1-0.7c-0.8-0.6-1.6-1.1-2.4-1.7c-9.1-6.2-18.9-11.3-28.7-16.3c-4.8-2.4-7.5-4.1-8.6-6.1
	 c-1.4-2.6-0.1-5.7,3.1-11.7c0.3-0.5,0.6-1.1,0.9-1.6c1-1.8,1.9-3.5,2.9-5.2c0.5-0.9,1-1.8,1.5-2.7c3.9-6.7,3.5-13.2,0-20
	 c-0.5-1-1-1.9-1.5-2.8c-0.5-0.8-0.9-1.6-1.4-2.4c-0.1-0.2-0.3-0.4-0.4-0.7c-0.3-0.6-0.7-1.1-1.1-1.7c-0.2-0.3-0.4-0.6-0.6-0.9
	 c-0.4-0.6-0.9-1.2-1.3-1.8c-0.4-0.5-0.8-1-1.3-1.5c-0.1-0.2-0.3-0.3-0.4-0.5c-0.5-0.6-1.1-1.2-1.6-1.7c-0.7-0.7-1.5-1.4-2.3-2
	 l-1-0.8c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2c-0.2-0.1-0.4-0.3-0.7-0.4c0,0,0,0,0,0s0,0,0,0
	 c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-2.4-1.4-5-2.4-8-3.2c-0.9-0.2-1.9-0.6-2.8-1.1
	 c-0.4-0.2-0.8-0.4-1.2-0.6c-0.3-0.2-0.7-0.4-1-0.6c-1.5-1.1-2.2-2.6-1.5-4.5c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.2-0.4,0.3-0.7
	 c0.7-1.7,1.2-3.1,1.7-4.2c2.3-4.8,3.8-4.3,11.2-1.6c19.9,7.5,38.8,16.6,56.5,27.2c0.2,0.1,0.4,0.2,0.5,0.3
	 c0.3,0.2,0.6,0.4,0.9,0.6c29.3,18,55.5,40.5,78.3,67.7c22.5,26.9,40,56.8,53,89.1c2.4,5.9,4.7,12,6.8,18.1
	 C859,437.1,860.2,439.3,859.9,442.6z"/>
 <path class="st7" d="M764.4,812.3c0-3.9-0.1-7.8-0.1-11.7c-9,0.1-8.9,0.1-10.3,9.2c0,0.4-0.1,0.8-0.2,1.3
	 c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.5-0.3,1-0.4,1.6c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.3-0.1,0.7-0.1,1c-0.8,12.2-5.5,22.6-12.6,32.4
	 c-1.8,2.5-4.6,4.3-5.9,7.1h0c0,0,0.1,0,0.1,0c4.8,0,8.7,3.9,8.7,8.8c0,4.8-3.9,8.8-8.7,8.8c-2.8,0-5.2-1.3-6.8-3.3
	 c-0.1-0.2-0.3-0.3-0.4-0.5c-0.5-0.8-0.9-1.6-1.2-2.5c0-0.1-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.4-0.1-0.8-0.1-1.3
	 c0-0.3,0-0.6,0-1c0-0.3,0.1-0.6,0.1-0.9c-1,0.2-1.9,0.5-2.8,1.1c-10.2,6.9-21.7,8.9-33.8,8.9c-109.5,0-219,0-328.4,0
	 c-5.6,0-11.1-0.3-16.6-1.4c-12.8-2.5-23.7-8.7-32.2-18.3c-9.8-11.1-16-24-16.4-39.3c0-0.2,0-0.3,0-0.5v-0.2c0-0.3,0-0.5,0-0.8V811
	 c0-0.2,0-0.3,0-0.5c0-0.4,0-0.8-0.1-1.2c0-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4-0.1-0.5c-0.1-0.4-0.2-0.8-0.3-1.1
	 c0-0.1-0.1-0.3-0.2-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.2-0.4-0.4-0.7-0.6l0,0
	 c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.2-1-0.5-1.6-0.6c-0.2,3-0.3,5.9-0.4,8.9
	 c-0.3,10.3-0.1,20.7,0.2,31c0.2,6.5,1.5,12.9,4.3,19c10.4,22.7,28.6,33.9,52.9,34c119.6,0.3,239.2,0.1,358.9,0.1
	 c14.6,0,27.7-4.4,38.8-13.9c10.9-9.3,18.3-21,18.9-35.5C764.8,835.4,764.6,823.8,764.4,812.3z M722.4,880.2
	 c-0.1,0.5-0.3,1-0.6,1.4l0,0c-0.2,0.3-0.4,0.7-0.6,1c-0.2,0.3-0.5,0.6-0.7,0.9c-1.5,1.5-3.5,2.4-5.8,2.4c-2.4,0-4.5-1-6-2.6l0,0
	 c-0.3,1.5-1,2.8-2,4c-0.2,0.3-0.5,0.5-0.8,0.8c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.5,0.3-0.8,0.4
	 c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c0,0,0,0,0,0c-0.3,0-0.7,0-1,0c-4.9,0-8.9-4-8.9-8.9
	 c0-4.9,4-8.9,8.9-8.9c0.4,0,0.8,0,1.3,0.1c0.4,0,0.7,0.1,1.1,0.2c0.1,0,0.2,0,0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3
	 c0.4,0.2,0.8,0.4,1.2,0.6c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.5,0.4c0.4,0.3,0.7,0.6,1,1c0,0,0,0,0,0
	 c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.3-0.8,0.6-1.2c0.2-0.3,0.4-0.7,0.7-1c0.1-0.2,0.3-0.4,0.5-0.6l0.5-0.5c0,0,0.1,0,0.1-0.1
	 c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.3,0.7-0.4c0.2-0.1,0.5-0.2,0.7-0.4c0.8-0.3,1.6-0.6,2.4-0.6c0.3,0,0.6,0,0.9,0
	 c0.4,0,0.8,0,1.2,0.1c0.2,0,0.5,0.1,0.7,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.2c0.4,0.1,0.8,0.3,1.1,0.5
	 c0.6,0.3,1.2,0.8,1.7,1.3c0.2,0.2,0.5,0.4,0.7,0.7c0,0,0,0,0,0.1c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.3,0.6,0.5,1
	 c0.1,0.3,0.3,0.7,0.4,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0,0.8-0.1,1.2C722.6,879.4,722.5,879.8,722.4,880.2z
	 "/>
 <path class="st8" d="M667.4,521c-2,8.8-5.1,17-9.3,24.8c-4.1,7.8-9.3,15-15.2,21.8c-2.3,2.6-4.7,5.1-7.1,7.4
	 c-10.4,10-22.3,17.5-35.2,23.1c-10.4,4.5-21.4,7.8-32.9,10.3c-0.6,0.1-2.8,0.5-6,0.9c-3.2,0.4-7.4,1-12.1,1.6
	 c-0.8,0-1.7,0.1-2.5,0.1c-0.1,0-0.2,0-0.3,0c-1.1,0-2.2,0.1-3.2,0.1c-0.5,0-1,0-1.5,0c-2.5,0-4.8,0-7-0.1c-0.9,0-1.8-0.1-2.6-0.1
	 c-1.3-0.1-2.5-0.1-3.7-0.2c-1.2-0.1-2.4-0.2-3.5-0.3c-1.6-0.1-3.1-0.2-4.7-0.3c-1.4-0.1-2.8-0.2-4.3-0.3c-1.4-0.1-2.9-0.2-4.3-0.3
	 c-2.5-0.1-5-0.3-7.5-0.5c-2.5-0.2-5-0.3-7.5-0.5c-1.2-0.1-2.5-0.2-3.7-0.3c-1.5-0.1-3-0.3-4.5-0.4c-2.2-0.2-4.4-0.5-6.7-0.7
	 c-2.8-0.3-5.7-0.7-8.5-1.2c-2.8-0.4-5.6-1-8.4-1.6c-1.2-0.2-2.3-0.5-3.5-0.8c-0.9-0.2-1.9-0.5-2.8-0.7c-0.9-0.2-1.8-0.5-2.7-0.7
	 c-1.2-0.3-2.4-0.7-3.6-1.1c-4.8-1.5-9.4-3.2-13.8-5.2c-2.2-1-4.4-2.1-6.6-3.2c-1.1-0.6-2.2-1.1-3.2-1.8c-1.1-0.6-2.1-1.2-3.2-1.9
	 c-1.1-0.6-2.2-1.3-3.2-2c0,0,0,0,0,0v0c-1.1-0.7-2.1-1.4-3.2-2.1h0c-1-0.7-2.1-1.5-3.1-2.3c0,0,0,0,0,0c-1-0.8-2.1-1.6-3.1-2.4
	 c-3.2-2.6-6.2-5.3-9-8.2c-3.2-3.2-6.2-6.6-9.1-10.1c-1.1-1.3-2.2-2.7-3.2-4.1c-1.1-1.4-2.1-2.7-3.2-4.1c-0.2-0.3-0.5-0.7-0.7-1
	 c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.4-0.7-0.6-1.1c-0.5-1.1-1-2.3-1.3-3.4c-0.3-1.5-0.1-2.9,1.3-3.9c2.6-2,4.8,0.4,6.8,2.2
	 c0.5,0.5,1,0.9,1.5,1.4h0c0.4,0.3,0.7,0.7,1.1,1l1,1c0,0,0.1,0,0.1,0.1c2.5,2.1,5,4.2,7.6,6.1c1.5,1.1,3,2.2,4.6,3.3
	 c1,0.6,1.9,1.3,2.9,1.9c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.3,0.9,0.5,1.3,0.8c0,0,0.1,0,0.1,0.1c0.4,0.2,0.8,0.5,1.2,0.7
	 c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.2c1.2,0.7,2.4,1.4,3.6,2c0.3,0.2,0.6,0.3,0.9,0.5
	 c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.2,0.6,1.9,0.9c0.3,0.1,0.5,0.2,0.8,0.4c0.8,0.4,1.7,0.8,2.5,1.2
	 c0.1,0,0.2,0.1,0.4,0.2c0,0,0,0,0.1,0c0.4,0.2,0.9,0.4,1.3,0.6c0.6,0.3,1.2,0.5,1.9,0.8c0.6,0.2,1.2,0.5,1.9,0.7
	 c1.3,0.5,2.6,1,3.9,1.4c0.9,0.3,1.8,0.6,2.6,0.9c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.3,0.1,0.4,0.1
	 c0.1,0,0.2,0.1,0.4,0.1c0.9,0.3,1.8,0.5,2.7,0.8c5.5,1.5,11.2,2.6,17.2,3.3c16.9,1.9,33.6,1.9,50.4-1c26.6-4.6,50.8-15,74.1-28.3
	 c2.2-1.3,4.4-2.5,6.6-3.9c1.1-0.7,2.2-1.3,3.2-2c2.1-1.4,4.3-2.7,6.3-4.2c3.1-2.1,6.3-4.3,9.3-6.6c0.8-0.6,1.5-1.2,2.2-1.9h0
	 c1.1-1,2-2.1,3-3.1v0c0,0,0.1-0.1,0.1-0.1c0.4-0.5,0.7-1,1-1.5c0.1-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.3-0.7,0.4-1.1
	 c0.2-0.7,0.4-1.5,0.5-2.3c0-0.4,0.1-0.8,0.1-1.2c0-0.6,0-1.2-0.1-1.8c0-0.3-0.1-0.6-0.1-0.9c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0
	 c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.4-0.3-0.7-0.5-1.1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.5-0.5-0.7-0.7
	 c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7-0.6-1.4-1.2-2.1-1.9c-0.6-0.5-1.2-1.1-1.7-1.7
	 c-0.5-0.6-1.1-1.2-1.6-1.8c-0.2-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.4-0.6-0.7-0.9c-0.1,0-0.1-0.1-0.1-0.2c-0.3-0.4-0.6-0.8-0.9-1.3
	 c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.5-0.5-0.8c-0.4-0.5-0.7-1.1-1-1.6c-0.2-0.3-0.4-0.7-0.6-1c-0.2-0.3-0.4-0.7-0.6-1.1
	 c-0.2-0.4-0.4-0.7-0.6-1.1l-0.5-1c-0.4-0.7-0.7-1.5-1.1-2.2c-0.2-0.5-0.4-0.9-0.6-1.4c-1.9-4.5-3-9-2.1-14v0l0-0.2l0.1-0.3
	 c0,0,0.1,0.1,0.1,0.1c2.2,3.1,4.1,6.7,6.3,9.7c0,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.7,0.8,1.1c0.4,0.6,0.9,1.1,1.3,1.7
	 c0.4,0.6,0.9,1.1,1.3,1.6c0.2,0.2,0.4,0.5,0.6,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.6,0.8,1.3,1.6,2,2.3
	 c0.8,0.9,1.6,1.7,2.5,2.5c0.1,0.1,0.2,0.2,0.4,0.4c0.8,0.8,1.7,1.6,2.6,2.4c0.4,0.4,0.9,0.8,1.4,1.2l0.1,0.1
	 c0.5,0.4,1,0.9,1.5,1.3c0.3,0.2,0.6,0.4,0.8,0.7c0.7,0.6,1.5,1.2,2.3,1.7c0.3,0.2,0.5,0.4,0.8,0.6c1,0.7,2,1.3,2.9,2
	 c1,0.6,2,1.2,2.9,1.8c0,0,0,0,0,0c0.7,0.4,1.3,0.7,2,1.1c0,0,0.1,0,0.1,0.1c0.2,0.2,0.5,0.3,0.8,0.4c0,0,0.1,0,0.1,0
	 c0.6,0.3,1.3,0.6,1.9,0.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0.2,0.6,0.3,0.9,0.4c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.6,0.3,0.9,0.4
	 c0.3,0.1,0.6,0.2,0.9,0.4c0.8,0.3,1.6,0.6,2.4,0.9c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0,0.2,0.1,0.3,0.1c0.9,0.3,1.9,0.6,2.8,0.9
	 c0,0,0.1,0,0.1,0c0.5,0.2,0.9,0.3,1.4,0.4c0.8,0.2,1.6,0.4,2.4,0.6c0.1,0,0.1,0,0.2,0c0.3,0.1,0.6,0.1,0.9,0.2
	 c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0.7,0.2,1.4,0.3,2.1,0.4h0.1c0.1,0,0.2,0,0.4,0.1c0.3,0.1,0.6,0.1,0.9,0.2
	 c1.2,0.2,2.4,0.4,3.7,0.6c1.4,0.2,2.8,0.4,4.2,0.5c0.3,0,0.5,0,0.8,0C664.6,519.8,665.9,519.9,667.4,521z"/>
 <path class="st9" d="M321.2,358.7c1.4,12.3,4.1,24.6,8.2,37.1c-0.5-0.2-1-0.4-1.5-0.6c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.3
	 c-3.3-1.5-5.8-3.4-8.4-5c-1.7-1-3.4-1.9-5.2-2.2c-1.4-0.2-2.8,0.1-4.3,1.1c-1.2,0.7-1.9,1.6-2.4,2.6c-0.9,1.9-0.8,4.2-0.5,6.5
	 c0.6,4.7,1.7,9.3,2.9,14.1c0.2,0.6,0.4,1.3,0.6,2c0.6,2.3,1.4,4.7,2.1,7.1c-2.4,0.1-4.4-0.4-6.4-1c-1-0.3-2-0.7-2.9-1
	 c-1-0.3-1.9-0.7-2.9-0.9c-2.6-0.7-5.2-1.6-7.8-1.6c-0.3,0-0.6,0-0.9,0c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.5,0.1-0.7,0.1
	 c-0.5,0.1-1,0.3-1.5,0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
	 c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.2
	 c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5,0-0.7,0c-0.3,0-0.6-0.2-0.9-0.4c-0.4-0.2-0.8-0.5-1.2-0.8c-2.3-1.7-3.9-4-5.3-6.5
	 c-0.3-0.5-0.6-1-0.8-1.5c-2.3-4.5-4.5-9.1-6.9-13.6c-0.3-0.6-0.7-1.3-1-1.9c-1.1-1.8-2.3-3.5-3.6-5c-0.3-0.4-0.7-0.8-1-1.1
	 c-0.3-0.3-0.7-0.7-1.1-1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1
	 c-0.1-0.1-0.1-0.1-0.2-0.2h0c-0.4-0.3-0.7-0.6-1.1-0.9c-0.2-0.1-0.4-0.2-0.5-0.4c-0.3-0.2-0.6-0.4-0.9-0.6c-0.5-0.3-1-0.6-1.5-1
	 c-0.1-0.1-0.3-0.2-0.5-0.2c-0.5-0.3-1.1-0.6-1.6-0.8c-0.5-0.2-1.1-0.5-1.6-0.7c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.7-0.2-1-0.3
	 c0,0-0.1,0-0.2-0.1c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0c-0.6-0.2-1.3-0.3-1.9-0.5c-0.2,0-0.4-0.1-0.6-0.2
	 c-0.7-0.2-1.5-0.5-2.6-1c-0.3-0.1-0.7-0.2-1-0.4c12.7-23.6,28-44.8,45.8-64.2c8.5-9.3,17.6-18.2,27.4-26.7
	 c4.3-3.7,8.6-7.4,13.1-11c-4.8,14.4-8.1,28.6-9.6,42.7C319.8,332.6,319.7,345.7,321.2,358.7z"/>
 <path class="st8" d="M412.1,223.4c-0.6,0-1.3,0-2,0c-0.5,0-0.9-0.1-1.4-0.2h0c-1.2-0.2-2.4-0.6-3.6-1.3c-0.9-0.5-1.8-0.9-2.6-1.1
	 c-0.5-0.1-0.9-0.1-1.4-0.1c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0.1-0.6,0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0.1-0.7,0.2
	 c-0.2,0.1-0.5,0.2-0.7,0.3v0h0c-0.2,0.1-0.4,0.2-0.7,0.4c-0.2,0.1-0.4,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.3
	 c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.4-0.7,0.6v0c-1.3,1.4-2.5,2.7-3.8,4.1c0,0-0.1,0.1-0.1,0.1c-1.2,1.4-2.4,2.7-3.6,4.2
	 c-1,1.2-2,2.4-2.9,3.5c-0.5,0.6-0.9,1.1-1.4,1.7c-0.9,1.1-1.8,2.3-2.7,3.5c-1.5,2-2.9,4-4.3,6c-0.7,1-1.3,2-2,3
	 c-0.4,0.6-0.8,1.3-1.2,2c-1.4,2.1-2.6,4.3-3.9,6.5c-0.7,1.3-1.4,2.5-2.1,3.8c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.1-0.1,0.3-0.2,0.4
	 c-0.4,0.9-0.9,1.8-1.3,2.6c-0.1,0.3-0.3,0.6-0.4,0.9c-0.6,1.3-1.2,2.6-1.8,3.9c-1.2,2.8-2.3,5.6-3.3,8.5c0,0.1-0.1,0.2-0.1,0.3v0
	 c-0.2,0.7-0.5,1.4-0.6,1.9c-0.2,0.5-0.3,1-0.4,1.5c0,0,0,0,0,0.1c0,0.2,0,0.3-0.1,0.5v0.1c0,0.1,0,0.2,0,0.2v0.1c0,0,0,0.1,0,0.1
	 c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0.1c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1
	 c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0,0,0
	 c0.3,0.1,0.7,0.2,1.2,0.2c0.2,0,0.5,0.1,0.8,0.1h0.1c0.2,0,0.4,0.1,0.6,0.1c-8.8,1.8-15.3,9.6-15.3,18.9c0,4.4,1.5,8.4,3.9,11.7
	 c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.2
	 c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.2-0.3,0.3-0.4,0.5c0,0,0,0,0,0.1c-0.6,0.9-0.9,1.9-1.1,3
	 c-2,10.2-2.5,20.7-1.4,31c0.1,0.9,0.2,1.9,0.3,2.8c0.1,0.8,0.2,1.5,0.3,2.3c0,0.1,0,0.2,0,0.3c0.1,0.7,0.2,1.3,0.3,2
	 c0.2,1.3,0.4,2.5,0.6,3.8c0.4,2.5,0.9,5,1.4,7.4c0.5,2.5,1.1,4.9,1.8,7.4c0.3,1.2,0.7,2.4,1.1,3.7c0.2,0.7,0.4,1.3,0.7,2
	 c0.3,0.8,0.6,1.6,0.8,2.4c4.4,12.2,9.8,23.7,18.5,33.5c-2.4-0.6-4.7-1.4-6.8-2.3c-3.6-1.6-6.8-3.6-9.6-6.5
	 c-2.2-2.2-4.1-4.8-5.6-8.1v0c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.7-0.6-1.3-0.9-2c-0.1-0.1-0.1-0.3-0.2-0.4
	 c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0-0.1,0-0.1c-0.2-0.5-0.4-0.9-0.6-1.4c-0.1-0.2-0.1-0.4-0.2-0.5
	 c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.3-0.3-0.7-0.4-1.1c-0.3-0.6-0.5-1.2-0.7-1.9c0,0,0-0.1,0-0.1
	 c-0.3-0.8-0.6-1.6-0.9-2.4c-0.3-0.9-0.7-1.8-1-2.6c-0.4-1.2-0.8-2.5-1.2-3.7c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.4-0.3-0.8-0.4-1.2
	 c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-0.5-0.3-1-0.4-1.6c-0.1-0.5-0.3-1-0.4-1.5c0-0.1-0.1-0.2-0.1-0.4
	 c0-0.2-0.1-0.3-0.1-0.5c-0.3-1.3-0.7-2.6-1-4c-0.3-1.6-0.7-3.1-1-4.8c-0.2-1.1-0.4-2.2-0.6-3.3c0,0,0,0,0,0v0c0,0,0,0,0,0
	 c-0.2-1.1-0.3-2.2-0.5-3.4c-0.1-0.5-0.1-1.1-0.2-1.7c-0.2-1.1-0.3-2.3-0.4-3.4c-0.1-0.9-0.2-1.7-0.2-2.6v-0.1c0-0.3,0-0.6-0.1-0.9
	 c0-0.4-0.1-0.7-0.1-1.1V350c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.7c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8-0.1-1.2c0-0.3,0-0.7,0-1.1V346
	 c0-0.4,0-0.8,0-1.2c0-0.3,0-0.7,0-1.1v-0.4c0-0.1,0-0.1,0-0.2v-0.2c0-0.6,0-1.2,0-1.9c0-0.1,0-0.2,0-0.4c0-0.4,0-0.9,0-1.3
	 c0-0.5,0-0.9,0-1.4c0-0.1,0-0.3,0-0.4v-0.3c0-1.5,0-3,0.1-4.6c0,0,0-0.1,0-0.2c0.1-2,0.2-4,0.4-6c0-1.1,0.1-2.2,0.3-3.3
	 c0-0.3,0-0.5,0.1-0.8c0.1-1.6,0.3-3.2,0.5-4.8c0.1-0.5,0.1-1.1,0.2-1.6c0.1-1,0.3-1.9,0.4-2.9c0-0.3,0.1-0.5,0.1-0.8
	 c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0c0.6-3.8,1.4-7.4,2.2-11.1c0.4-1.8,0.9-3.6,1.4-5.4c1-3.6,2.1-7.1,3.3-10.5c1.2-3.4,2.6-6.8,4-10.1
	 c4-9.1,9-17.8,14.7-26c7-9.9,15.3-19.1,24.8-27.6c2.7-2.4,5.6-4.8,8.6-6.9c1-0.7,2-1.4,3-2.1c1-0.7,2.1-1.3,3.2-1.9
	 c1.7,3.5,3.5,6.9,5.6,9.9C410.4,221.3,411.2,222.3,412.1,223.4z"/>
 <path class="st10" d="M273.5,794.8c0,5.9-0.1,6.1-6.1,6c-11.3,0-10.1,1.4-10.2-9.9c0-7.5,0-14.9,0-22.4h0.1c0-8.7-0.1-17.4,0-26.1
	 c0.1-5.2,0.3-5.5,5.2-5.5c11.8,0,10.7-1.4,10.8,10.1C273.5,763,273.6,778.9,273.5,794.8z"/>
 <path class="st1" d="M788.8,648c0,0.4,0,0.7-0.1,1.1c-0.1,0.7-0.2,1.4-0.3,2.2c-0.3,1.4-0.7,2.8-1.3,4.2c-0.1,0.3-0.3,0.7-0.4,1
	 c-0.6,1.3-1.4,2.6-2.3,3.8c-0.4,0.6-0.9,1.2-1.4,1.7c-0.5,0.6-1.1,1.1-1.6,1.6c-1.4,1.3-3,2.5-4.8,3.5c-0.2-0.5-0.5-1.1-0.7-1.6
	 c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c-0.2-0.5-0.5-1.1-0.8-1.6c-0.1-0.1-0.1-0.3-0.2-0.5c-0.3-0.6-0.6-1.2-0.9-1.8
	 c-0.3-0.7-0.7-1.4-1.1-2c-0.5-0.8-1-1.7-1.4-2.4c-1-1.6-2-3.2-3.1-4.7c-2.2-3.1-4.6-6-7.2-8.7c-0.6-0.7-1.3-1.4-2-2
	 c-0.5-0.5-1.1-1-1.6-1.5c-0.6-0.5-1.1-1-1.7-1.5c-0.5-0.5-1.1-1-1.7-1.4c-0.6-0.5-1.2-1-1.8-1.4c-0.5-0.4-1-0.8-1.5-1.1
	 c-1-0.7-2-1.4-3-2.1c-0.6-0.4-1.3-0.8-2-1.3c0.3-0.3,0.6-0.7,0.8-1c0.4-0.5,0.9-0.9,1.3-1.4s0.9-0.9,1.4-1.3
	 c4.3-3.8,9.2-5.7,14.5-5.7h0.4c2.3,0,4.7,0.4,7.1,1.2c10.3,3.2,15.9,10.5,17.5,21C788.8,645.2,788.8,646.6,788.8,648z"/>
 <path class="st8" d="M691,495.3c-2.4,2.3-4.9,3.8-7.5,4.8c-2.6,1-5.3,1.6-7.9,2c-1.1,0.1-2.3,0.3-3.4,0.4c-2.5,0.2-5,0.3-7.4,0.2
	 c-7.7-0.2-15.2-2-22.4-5.5c-12.4-6.2-22.3-14.8-30-25.7c-1.2-1.8-2.4-3.5-3.5-5.4c-1.1-1.8-2.2-3.7-3.1-5.7l0,0c0,0,0,0,0,0
	 c0.3,0,0.6,0,0.8,0.1c0.5,0.1,1.1,0.2,1.5,0.4c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.8,0.6,1.2,0.9c0.4,0.4,0.8,0.8,1.2,1.2
	 c1.1,1.2,2.2,2.6,3.5,3.7c3,2.7,6.3,4.9,9.6,6.8c0.7,0.4,1.5,0.8,2.2,1.1c0,0,0,0,0,0c0.6,0.3,1.2,0.5,1.8,0.8
	 c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0.5,0.2,1,0.4,1.5,0.6c0.6,0.2,1.2,0.5,1.8,0.7c0.6,0.2,1.3,0.4,1.9,0.6
	 c0.3,0.1,0.6,0.2,0.8,0.3h0c0.9,0.3,1.9,0.5,2.9,0.7c0.1,0,0.2,0,0.3,0c0.8,0.2,1.7,0.4,2.6,0.5c0.2,0,0.4,0.1,0.6,0.1
	 c0.9,0.1,1.9,0.3,2.8,0.4c1,0.1,2.1,0.2,3.2,0.3h0.2c0,0,0,0,0,0h0.1c0.6,0,1.2,0,1.8,0c0.3,0,0.6-0.1,0.9-0.1
	 c0.2,0,0.5-0.1,0.7-0.2c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.8-0.4c0.6-0.3,1.2-0.8,1.7-1.3c0.2-0.2,0.3-0.4,0.5-0.6
	 c-0.7,5.6,2,9.2,6.5,11.6l0.8,0.4c0.7,0.3,1.5,0.7,2.2,1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0
	 c0.3,0.1,0.7,0.3,1,0.4c0,0,0.1,0,0.1,0c0.3,0.1,0.7,0.2,1,0.3c0.2,0.1,0.4,0.1,0.6,0.2h0c0,0,0.1,0,0.1,0h0
	 c0.2,0.1,0.4,0.1,0.6,0.2c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.7,0.2,1.4,0.3,2.1,0.5c0,0,0.1,0,0.1,0
	 c0.5,0.1,1.1,0.2,1.6,0.3c0.3,0.1,0.7,0.1,1.1,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.8,0.1,1.6,0.2,2.3,0.4
	 c1.9,0.2,3.7,0.5,5.6,0.6C686.2,495.2,688.2,495.2,691,495.3z"/>
 <path class="st10" d="M796,796.5c0,2.5-0.7,3.9-3.6,4.2c-10,1-10.6,0.5-10.6-9.5v-22.4c0,0,0,0,0,0c0-8.9,0.1-17.8-0.1-26.8
	 c-0.1-3.8,1.4-5.3,5.1-4.9c1.4,0.1,2.9,0.1,4.3,0c3.4-0.3,4.8,1.1,4.8,4.6C795.9,759.8,795.9,778.1,796,796.5z"/>
 <path class="st10" d="M597.1,638.4c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0c-0.5,0.1-1.1,0.2-1.7,0.3c-2,0.2-4,0.3-5.9,0
	 c-2.4-0.4-4.5-1.6-6-4.5c-0.6-1.2-2-2.2-3.2-2.9c-2.2-1.4-4.3-1.5-6-0.6c-0.8,0.4-1.5,1.2-2,2.1c-0.4,0.8-0.7,1.7-0.8,2.8
	 c0,0.4-0.1,0.8-0.1,1.3v1.2c0,0.5,0,1.1,0,1.7c-2.3-0.5-3.7-2.1-5.2-3.3c-0.3-0.2-0.6-0.5-1-0.7c-1.9-1.1-3.9-1.8-5.8-1
	 c-0.5,0.2-1,0.6-1.6,1.1c-0.4,0.4-0.7,0.8-0.9,1.2c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.9-0.1,1.8,0.2,2.7c0.2,0.6,0.5,1.2,0.8,1.9
	 c0.9,1.7,1.2,3,0.8,3.9c-0.1,0.3-0.2,0.5-0.4,0.7c-0.4,0.5-1.2,0.9-2.1,1.2c-0.2,0.1-0.4,0.2-0.7,0.3c-2.1,0.7-4.4,0.7-6.5,0.1
	 c0,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0v0h0c0,0,0,0,0,0v0c0,0,0,0,0,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0
	 c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.2c0,0,0,0-0.1,0
	 c0,0,0,0-0.1,0h0c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.3-0.2-0.6-0.3-0.9-0.5c-0.1-0.1-0.3-0.2-0.4-0.3
	 c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1-0.1-0.2-0.2
	 c0,0-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1
	 c-0.2-0.2-0.3-0.4-0.5-0.7c0,0-0.1-0.1-0.1-0.2c-0.2-0.3-0.4-0.6-0.6-1c0,0-0.1-0.1-0.1-0.2c-0.3-0.6-0.6-1.3-0.8-1.9
	 c-0.5-1.6-0.7-3-0.7-4.3v0c0,0,0,0,0,0c0-2.2,0.8-3.8,2.6-4.8c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.5-0.5,2.4-0.6
	 c0.4-0.1,0.9-0.1,1.4-0.2c3.7-0.4,7.5-0.8,11.2-1.3c11.9-1.6,23.6-4,35.1-7.4c3.2-1,7,1.4,9.1,5.1c0.1,0.2,0.3,0.5,0.4,0.8
	 c0,0,0,0.1,0.1,0.1c0.4,1,0.8,2.1,1,3.1C602.8,634.2,600.9,637.3,597.1,638.4z"/>
 <path class="st10" d="M333.5,445.6c-0.2,1.3-0.4,2.5-0.6,3.8c-0.5,4-0.7,8-0.8,12.1c0,1.4,0,2.9,0,4.3c0,6.8,0,13.5,0,20.2
	 c-8.8,0.3-20.2-7.1-25.2-16.2c-0.1-0.2-0.2-0.4-0.3-0.7c-0.2-0.4-0.4-0.8-0.6-1.2c0.5,0,0.9,0.1,1.4,0.1c3.2,0.5,5.9,2.2,9,2.9
	 c1.1,0.2,2.2,0.4,3.2,0.5c1.8,0.1,3.5-0.1,5.2-0.9c2.5-1.1,3.9-3,4.3-5.5c0-0.2,0.1-0.4,0.1-0.6c0.1-0.8,0-1.4-0.2-2
	 c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.4-0.6-0.6
	 c-0.3-0.2-0.6-0.4-0.9-0.6c-0.5-0.3-1-0.5-1.5-0.8h0c-1.5-0.7-3.1-1.4-4.6-2.2c-1.5-0.8-3-1.6-4.4-2.5c-0.6-0.4-1.2-0.8-1.8-1.3
	 c-4.3-3.2-6.9-6.3-9.1-11.3c-0.6-1.4-1.2-3-1.8-4.9c5,1.7,9.8,3.1,14.6,4c3.2,0.5,6.4,0.9,9.5,0.9c1,0,2,0,3,0
	 C333,441.9,333.9,443.2,333.5,445.6z"/>
 <path class="st10" d="M488.4,638.1c-1.6,4.7-6.2,8-11,8.7c-0.9,0.1-1.9,0.2-2.9,0.1c-0.6-0.1-1.2-0.2-1.8-0.3c0,0,0,0,0,0
	 c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.2-0.9-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c0,0-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
	 c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0-0.4c0,0,0,0,0,0c0.1-0.4,0.3-0.8,0.6-1.3c0.2-0.3,0.5-0.7,0.7-1.1c0.2-0.3,0.4-0.7,0.6-1.1
	 c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.7,0.3-1.1c0.3-1.3,0.3-2.5-0.3-3.5c-0.2-0.5-0.6-0.9-1.1-1.3c-1.9-1.7-4.1-1.5-6.1-0.3
	 c-2.1,1.2-4,2.7-6.5,4.5c-0.5,0.3-0.9,0.7-1.5,1c0.1-0.7,0.2-1.3,0.2-1.9c0.1-1,0.3-1.8,0.3-2.5c0-0.3,0-0.6,0-0.9
	 c-0.5-4.3-4.1-6.3-7.9-4.4c-0.7,0.3-1.4,0.7-2,1.2c-0.8,0.6-1.5,1.2-1.9,2c-0.2,0.3-0.3,0.6-0.5,0.8v0c-0.1,0.1-0.2,0.2-0.3,0.3
	 c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.4,0.3-0.9,0.5-1.3,0.7
	 c-0.4,0.2-0.9,0.3-1.4,0.4c-2.4,0.5-5.1-0.1-7.7-0.4c-3.1-0.4-4.6-2.6-4.8-5.6c-0.3-3.2,0.8-5.9,2.9-7.6c1.9-1.5,4.5-2.2,7.2-1.5
	 c0.2,0.1,0.4,0.1,0.7,0.2c14.6,4.6,29.5,7.5,44.7,8.8C488.7,631,490.1,633.1,488.4,638.1z"/>
 <path class="st8" d="M511.1,141.2c0,0.2,0,0.4-0.1,0.7c-0.1,0.5-0.5,1.1-0.9,1.7c-0.1-0.2-0.3-0.4-0.5-0.5
	 c-0.7-0.7-1.5-1.1-2.6-0.9c-11.5,2.4-22,6.8-30.7,15c-1.3,1.2-2.5,2.5-3.7,3.8c-1.2,1.3-2.3,2.6-3.5,4v0c-1.1,1.3-2.3,2.7-3.4,4
	 c-1.2,1.3-2.4,2.7-3.6,3.9c-0.5,0.6-1.1,1.2-1.7,1.7c-0.6,0.5-1.2,1.1-1.8,1.6c-0.3,0.2-0.6,0.5-0.8,0.7c-1.3,1.1-2.5,2.1-3.9,2.9
	 c-0.4,0.2-0.8,0.4-1.3,0.6c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0h-0.1c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.1-0.9,0.2
	 c-0.3,0-0.6,0.1-0.9,0.1c0.7-1.5,1.4-3,2.1-4.6c0.9-2,1.8-3.9,2.8-5.9c2.7-5.8,6.1-10.6,10-14.7c8.2-8.4,18.9-13.3,31.7-15.3
	 c0.9-0.1,1.8-0.3,2.6-0.4c2.9-0.5,5.9-1,8.9-0.8C510,139.5,511.1,140.1,511.1,141.2z"/>
 <path class="st8" d="M551.4,167.3c-0.2,0.3-0.5,0.6-0.8,0.9c-0.3,0.4-0.7,0.7-1,1.1c-0.6,0.6-1.2,1.1-1.9,1.5
	 c-0.4,0.2-0.8,0.4-1.3,0.6c-1.3-1.2-2.6-2.1-4-2.6c-1.8-0.7-3.7-1-5.6-1c-0.8,0-1.6,0-2.4,0.1c-0.7,0.1-1.3,0.1-2,0.2
	 c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.1,0-0.2,0
	 c-0.8,0.1-1.6,0.3-2.4,0.4c-0.4,0.1-0.9,0.1-1.3,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-0.6,0.2-1.2,0.4-1.9,0.7c-0.4,0.2-0.9,0.4-1.3,0.6
	 c-1.1,0.5-2.3,1.1-3.4,1.7c-2.7,1.2-5.4,2.1-8.2,2.6h0c-0.4,0.1-0.9,0.1-1.3,0.2c0,0,0,0,0,0h-0.1c-0.1,0-0.2,0-0.3,0
	 c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0l-1,0.1c-0.5,0-1,0-1.5,0c-0.6,0-1.2,0-1.8-0.1c-0.5-0.1-1-0.2-1.5-0.4c0,0,0,0-0.1,0
	 c-0.3-0.1-0.5-0.2-0.8-0.4c-0.1,0-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.3-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7c-0.4-0.5-0.7-1.1-1-1.8
	 c0.9,0.1,1.9,0.2,2.8,0.2c2.9,0,5.6-0.7,8.3-1.7c1.5-0.6,2.9-1.2,4.3-1.9c0.6-0.3,1.2-0.6,1.9-0.9c1.4-0.7,2.7-1.3,4.1-1.9
	 c7.3-3.1,14.9-5,23.1-4.5c1.3,0.1,2.5,0.2,3.8,0.4c1.4,0.2,2.7,0.6,4,1.3C553,163.5,553.3,165.2,551.4,167.3z"/>
 <path class="st8" d="M441.1,162.2c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.9-0.1,1.3c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.9-0.3,1.7-0.5,2.6
	 c-0.1,0.4-0.2,0.8-0.3,1.3c-0.2,0.8-0.4,1.7-0.5,2.5c-8.9,0.1-14.1,3.9-16.8,12.3c-0.1,0.2-0.1,0.4-0.2,0.6
	 c-0.4,1.4-0.7,2.7-2.5,3.1c1.1-6.7,3.1-12.7,6.4-18.2c3.3-5.4,7.7-10.2,13.5-14.4c0.8,1.9,1.1,3.7,1.2,5.5
	 C441.1,161.6,441.1,161.9,441.1,162.2z"/>
 <path class="st10" d="M403,780.8c-0.2,4.1-4.2,7.8-8.2,7.7c-4.1-0.1-7.5-4-7.3-8.3c0.1-4.6,3.4-7.7,8-7.5
	 C399.8,772.9,403.2,776.5,403,780.8z"/>
 <path class="st10" d="M339.8,417.2v0.2c-0.1,0.4-0.2,0.9-0.4,1.5c-0.9,2.1-1.8,4.2-2.7,6.7c-0.2,0.7-0.5,1.4-0.8,2.1
	 c-4.5-2.7-7.5-6.2-9.9-10.6c-1-1.8-1.8-3.7-2.6-5.7c-0.5-1.3-1-2.7-1.5-4.2c0.6,0.3,1.2,0.5,1.7,0.8c5.3,2.4,9.6,4.5,13.9,6.4
	 c0.5,0.2,0.9,0.4,1.2,0.7C339.4,415.6,339.8,416.3,339.8,417.2z"/>
 <path class="st11" d="M666.9,783.2c-0.9,2.5-2.9,4.4-5.4,5C663.9,787.5,666,785.5,666.9,783.2z"/>
 <path class="st11" d="M667.1,778.4c-0.9-3.2-3.8-5.5-7.2-5.7c-1,0-1.9,0.1-2.8,0.4c0.9-0.3,1.9-0.5,2.9-0.4
	 C663.8,772.7,666.4,774.9,667.1,778.4z"/>
 <path class="st8" d="M438.8,176.3c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1v0c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0v-0.1
	 c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1C438.9,175.9,438.9,176.1,438.8,176.3z"/>
 <path class="st8" d="M413.8,224.7c-0.5-0.3-1-0.7-1.5-1.1c0.4,0,0.7,0.1,0.9,0.3c0.1,0.1,0.2,0.2,0.3,0.4
	 C413.7,224.4,413.7,224.5,413.8,224.7z"/>
 <polygon class="st8" points="413.8,224.7 413.8,224.7 413.8,224.7 		"/>
 <path class="st12" d="M686.9,457c4.9,0.2,9.7-0.1,14.4-1.1c7.9-1.8,15-5,19.9-12.9c-6.4-0.5-11.7,1.4-17.1,2.1
	 c-7.6,0.8-15,0.2-22.4-1.1c-3-0.5-5.4-2.3-5.7-5.7c-0.2-2.8,1.5-4.4,3.9-5.5c1.5-0.7,3.2-1.3,4.7-2.1c8.1-4.1,14.9-9.7,19.7-17.7
	 c-2.1-1.1-3.7-0.5-5.3,0c-15.8,4-31.6,4.3-47.5,1c-15.1-3.1-30.1-5.1-45.4-1.4c-0.9,0.2-1.9,0.2-2.9,0.1c-0.2,0-0.5,0-0.7-0.1
	 c-0.2,0-0.4-0.1-0.7-0.1c-1.5-0.4-2.6-1.4-3.1-3c-0.1-0.2-0.1-0.4-0.2-0.7c-0.1-0.3-0.1-0.6-0.2-0.8c0,0,0,0,0,0
	 c0-0.3-0.1-0.7-0.1-1c0-0.3,0-0.5,0-0.8c0-0.4,0.1-0.7,0.3-1.1c0.3-1,1-1.8,2.2-2.4c1.2-0.7,2.6-1.2,4-1.5
	 c9.5-2.2,19-3.8,28.8-2.2c2.2,0.3,4.5,1.1,6.8-0.1c-3.1-7.1-3.3-8.5-2-16.6c4.3-25.3,2.8-50.2-5-74.7
	 c-13.6-42.4-39.6-75-78.3-97.3c-2.6-1.5-5.1-3.6-8.6-1.2c-2.7,1.9-5.6,1.4-7.7-1.4c-2-2.5-1.5-5.1,1.6-8.3
	 c2.3-2.4,4.6-4.7,7.6-7.6c-4-0.6-7,0.1-10,0.5c-3.3,0.5-6.7,0.7-10.1,0.9c-2.4,0.1-4.3-0.9-4.9-3.4c-0.7-2.6-0.2-4.9,2.1-6.4
	 c1.6-1,3.5-1.7,5.2-2.6c5.6-2.9,11.6-4.9,16.3-9.3c-1.3-1.2-2.6-2.1-4-2.6c-1.8-0.7-3.7-1-5.6-1c-0.8,0-1.6,0-2.4,0.1
	 c-0.7,0.1-1.3,0.1-2,0.2c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0c-0.4,0.1-0.8,0.1-1.2,0.2v0c-0.2,0-0.3,0-0.5,0.1
	 c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.3-2.4,0.4c-0.4,0.1-0.9,0.1-1.3,0.3c-0.2,0.1-0.5,0.1-0.7,0.2
	 c-0.2,0-0.4,0.1-0.6,0.2c-0.4,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.9,0.4-1.3,0.6c-1.1,0.5-2.3,1.1-3.4,1.7c-2.7,1.2-5.4,2.1-8.2,2.6h0
	 c-0.4,0.1-0.9,0.1-1.4,0.2h-0.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0l-1,0.1c-0.5,0-1,0-1.5,0
	 c-0.6,0-1.2,0-1.8-0.1c-0.5-0.1-1-0.2-1.5-0.4c0,0,0,0-0.1,0c-0.3-0.1-0.5-0.2-0.8-0.4c-0.1,0-0.1-0.1-0.2-0.2
	 c-0.2-0.2-0.4-0.3-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7c-0.4-0.5-0.7-1.1-1-1.8c-0.2-0.3-0.5-0.6-0.7-0.9c-0.5-0.6-0.8-1.1-1.2-1.6
	 c-0.2-0.2-0.3-0.5-0.4-0.7c-1.5-2.7-0.8-4.5,2.2-7.7c2.4-2.5,4.9-4.9,7-7.6c2.1-2.7,3.9-5.6,4.9-9.1c-0.1-0.2-0.3-0.4-0.5-0.5
	 c-0.7-0.7-1.5-1.1-2.6-0.9c-11.5,2.4-22,6.8-30.7,15c-1.3,1.2-2.5,2.5-3.7,3.8c-1.2,1.3-2.3,2.6-3.5,4v0c-1.1,1.3-2.3,2.7-3.4,4
	 c-1.2,1.3-2.4,2.7-3.6,3.9c-0.5,0.6-1.1,1.2-1.7,1.7c-0.6,0.5-1.2,1.1-1.8,1.6c-0.3,0.2-0.6,0.5-0.8,0.7c-1.3,1.1-2.5,2.1-3.9,2.9
	 c-0.4,0.2-0.8,0.4-1.3,0.6c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0.1-0.6,0.1-0.9,0.2
	 c-0.3,0-0.6,0.1-0.9,0.1c-4.5,3.1-7.3,3.9-9.2,2c-0.3-0.3-0.6-0.8-0.9-1.3c-0.6-1.2-1.1-2.9-1.4-5c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
	 c0,0,0,0,0,0v-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1v0c0.3-1.1,0.5-2.1,0.8-3.2h0
	 c-8.9,0.1-14.1,3.9-16.8,12.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.4,1.4-0.7,2.7-2.5,3.1c-0.6,2.9-1.1,5.9-1.3,8.8
	 c-0.1,0.9-0.1,1.9-0.1,2.8c0,0.4,0,0.8,0,1.1c0,0.7,0,1.4,0.1,2.1v0.2c0.1,0.7,0.1,1.4,0.3,2.1c0,0.4,0.1,0.7,0.2,1.1
	 c0.2,1.1,0.5,2.1,0.8,3.1c0.1,0.4,0.3,0.8,0.4,1.2c0.2,0.6,0.5,1.2,0.7,1.9c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.3,0.4,0.7,0.5,1
	 c0.3,0.6,0.7,1.2,1.1,1.9c0.2,0.2,0.3,0.5,0.4,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.1,0.5,0.2,0.8c0,0.3,0.1,0.5,0.1,0.8
	 c0,1-0.2,1.9-0.8,2.7c0,0,0,0.1-0.1,0.2c-0.2,0.4-0.5,0.7-0.9,1.1c-0.2,0.2-0.3,0.4-0.5,0.5c-1.3,1.1-2.9,1.5-4.8,1
	 c-1.2-0.3-2.3-0.7-3.4-1.1c-0.5-0.3-1-0.7-1.5-1.1l-0.3-0.2c-0.6,0-1.3,0-2,0c-0.5,0-0.9-0.1-1.4-0.2h0c-1.2-0.2-2.4-0.6-3.6-1.3
	 c-0.9-0.5-1.8-0.9-2.6-1.1c-0.5-0.1-0.9-0.1-1.4-0.1c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0.1-0.6,0.1c0,0-0.1,0-0.1,0
	 c-0.2,0-0.4,0.1-0.7,0.2c-0.2,0.1-0.5,0.2-0.7,0.3v0h0c-0.2,0.1-0.4,0.2-0.7,0.4c-0.2,0.1-0.4,0.3-0.7,0.5
	 c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.4-0.7,0.7c-1.3,1.4-2.5,2.7-3.8,4.1c0,0-0.1,0.1-0.1,0.1
	 c-1.2,1.4-2.4,2.7-3.6,4.2c-1,1.2-2,2.4-2.9,3.5c-0.5,0.6-0.9,1.1-1.4,1.7c-0.9,1.1-1.8,2.3-2.7,3.5c-1.5,2-2.9,4-4.3,6
	 c-0.7,1-1.3,2-2,3c-0.4,0.6-0.8,1.3-1.2,2c-1.4,2.1-2.6,4.3-3.9,6.5c-0.7,1.3-1.4,2.5-2.1,3.8c-0.1,0.2-0.2,0.3-0.2,0.5
	 c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4,0.9-0.9,1.8-1.3,2.6c-0.1,0.3-0.3,0.6-0.4,0.9c-0.6,1.3-1.2,2.6-1.8,3.9c-1.2,2.8-2.3,5.6-3.4,8.5
	 c0,0.1-0.1,0.2-0.1,0.3v0c-0.2,0.7-0.5,1.4-0.6,1.9c-0.2,0.5-0.3,1-0.4,1.5v0.1c0,0.2,0,0.3-0.1,0.5v0.1c0,0.1,0,0.2,0,0.2v0
	 c0,0,0,0,0,0.1v0.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0.1c0,0.1,0.1,0.1,0.2,0.2
	 c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1
	 c0,0,0,0,0,0c0.3,0.1,0.7,0.2,1.2,0.2c0.2,0,0.5,0.1,0.8,0.1h0.1c0.2,0,0.3,0,0.5,0.1c0.3-0.1,0.7-0.1,1-0.2
	 c0.6-0.1,1.3-0.2,1.9-0.2c0.4,0,0.7,0,1.1,0c10.7,0,19.4,8.7,19.4,19.4s-8.7,19.4-19.4,19.4c-6.3,0-11.9-3-15.4-7.7
	 c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.2
	 c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1h0c-0.1,0.2-0.3,0.3-0.4,0.5c0,0,0,0,0,0.1c-0.6,0.9-0.9,1.9-1.1,3
	 c-2,10.2-2.5,20.7-1.4,31c0.1,0.9,0.2,1.9,0.3,2.8c0.1,0.8,0.2,1.5,0.3,2.3c0,0.1,0,0.2,0,0.3c0.1,0.7,0.2,1.3,0.3,2
	 c0.2,1.3,0.4,2.5,0.6,3.8c0.4,2.5,0.9,5,1.4,7.4c0.5,2.5,1.1,4.9,1.8,7.4c0.3,1.2,0.7,2.4,1.1,3.7c0.2,0.7,0.4,1.3,0.7,2
	 c0.3,0.8,0.6,1.6,0.8,2.4c4.4,12.2,9.8,23.7,18.5,33.5c0.3,0.2,0.6,0.4,0.9,0.6c0.6,0.4,1.3,0.6,2,0.9c1.7,0.6,3.5,0.9,5.2,1.4
	 c2.6,0.9,4,2.7,3.9,5.5c-0.1,3.1-1.9,5.1-4.7,5.6c-1.8,0.4-3.9,0.2-5.7-0.2c-4.8-1-9.3-2.7-13.7-4.7c-5.6-2.6-5.7-2.5-7.3,3.2
	 c-0.3,0.9-0.5,1.9-0.7,2.9c-0.3,1.8-0.7,3.5-1,5.2c-0.3,1.9-0.6,3.7-0.8,5.6c-0.2,1.7-0.5,3.4-0.6,5.1c-0.2,1.9-0.4,3.8-0.5,5.7
	 c-0.2,2.5-0.3,5-0.4,7.6c-0.6,21.1,2.8,41.9,10.6,62.1c15.7,40.8,44.6,67.8,87,79.8c20.7,5.9,41.8,7.8,63.2,8.1
	 c9.1,0.1,26.2-1.6,39.5-3.2c-0.8,0-1.7,0.1-2.5,0.1c-0.1,0-0.2,0-0.3,0c-1.1,0-2.2,0.1-3.2,0.1c-0.5,0-1,0-1.5,0
	 c-2.5,0-4.8,0-7-0.1c-0.9,0-1.8-0.1-2.6-0.1c-1.3-0.1-2.5-0.1-3.7-0.2c-1.2-0.1-2.4-0.2-3.5-0.3c-1.6-0.1-3.1-0.2-4.7-0.3
	 c-1.4-0.1-2.8-0.2-4.3-0.3c-1.4-0.1-2.9-0.2-4.3-0.3c-2.5-0.1-5-0.3-7.5-0.5c-2.5-0.2-5-0.3-7.5-0.5c-1.2-0.1-2.5-0.2-3.7-0.3
	 c-1.5-0.1-3-0.3-4.5-0.4c-2.2-0.2-4.4-0.5-6.7-0.7c-2.8-0.3-5.7-0.7-8.5-1.2c-2.8-0.5-5.6-1-8.4-1.6c-1.2-0.2-2.3-0.5-3.5-0.8
	 c-0.9-0.2-1.9-0.5-2.8-0.7c-0.9-0.2-1.8-0.5-2.7-0.7c-1.2-0.3-2.4-0.7-3.6-1.1c-4.8-1.5-9.4-3.2-13.8-5.2c-2.2-1-4.4-2.1-6.6-3.2
	 c-1.1-0.6-2.2-1.1-3.2-1.8c-1.1-0.6-2.1-1.2-3.2-1.8c-1.1-0.6-2.2-1.3-3.2-2c0,0,0,0,0,0c-1.1-0.7-2.1-1.4-3.2-2.1h0
	 c-1-0.7-2.1-1.5-3.1-2.3c0,0,0,0,0,0c-1-0.8-2.1-1.6-3.1-2.4c-3.2-2.6-6.2-5.3-9-8.2c-3.2-3.2-6.2-6.6-9.1-10.1
	 c-1.1-1.3-2.2-2.7-3.2-4.1c-1.1-1.4-2.1-2.7-3.2-4.1c-0.2-0.3-0.5-0.7-0.7-1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.4-0.7-0.6-1.1
	 c-0.5-1.1-1-2.3-1.3-3.4c-0.3-1.5-0.1-2.9,1.3-3.9c2.6-2,4.8,0.4,6.8,2.2c0.5,0.5,1,0.9,1.5,1.4h0c0.4,0.3,0.7,0.7,1.1,1
	 c0.3,0.3,0.7,0.6,1,0.9v0c0,0,0.1,0,0.1,0.1c2.5,2.1,5,4.2,7.6,6.1c1.5,1.1,3,2.2,4.6,3.3c1,0.6,1.9,1.3,2.9,1.9
	 c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.3,0.9,0.5,1.3,0.8c0,0,0.1,0,0.1,0.1c0.4,0.2,0.8,0.5,1.2,0.7c0.1,0.1,0.2,0.1,0.3,0.2
	 c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.2c1.2,0.7,2.4,1.4,3.6,2c0.3,0.2,0.6,0.3,0.9,0.5c0.1,0.1,0.2,0.1,0.3,0.2
	 c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.3,1.2,0.6,1.9,0.9c0.3,0.1,0.5,0.2,0.8,0.4c0.8,0.4,1.7,0.8,2.5,1.2c0.1,0,0.2,0.1,0.4,0.2
	 c0,0,0,0,0.1,0c0.4,0.2,0.9,0.4,1.3,0.6c0.6,0.3,1.2,0.5,1.9,0.8c0.6,0.2,1.2,0.5,1.9,0.7c1.3,0.5,2.6,1,3.9,1.4
	 c0.9,0.3,1.8,0.6,2.6,0.9c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1
	 c0.9,0.3,1.8,0.5,2.7,0.8c5.5,1.5,11.2,2.6,17.2,3.3c16.9,1.9,33.6,1.9,50.4-1c26.6-4.6,50.8-15,74.1-28.3
	 c2.2-1.3,4.4-2.5,6.6-3.9c1.1-0.7,2.2-1.3,3.2-2c2.1-1.4,4.3-2.7,6.3-4.2c3.1-2.1,6.3-4.3,9.3-6.6c0.8-0.6,1.5-1.2,2.2-1.9h0
	 c1.1-1,2-2.1,3-3.1v0c0,0,0.1-0.1,0.1-0.1c0.4-0.5,0.7-1,1-1.5c0.1-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.3-0.7,0.4-1.1
	 c0.2-0.7,0.4-1.5,0.5-2.3c0-0.4,0.1-0.8,0.1-1.2c0-0.6,0-1.2-0.1-1.8c0-0.3-0.1-0.6-0.1-0.9c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0
	 c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.4-0.3-0.7-0.5-1.1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.4-0.5-0.7-0.7h0
	 c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.7-0.6-1.4-1.2-2.1-1.9c-0.6-0.5-1.2-1.1-1.7-1.7
	 c-0.5-0.6-1.1-1.2-1.6-1.8c-0.2-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.4-0.6-0.7-0.9c-0.1,0-0.1-0.1-0.1-0.2c-0.3-0.4-0.6-0.8-0.9-1.3
	 c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.5-0.5-0.8c-0.4-0.5-0.7-1.1-1-1.6c-0.2-0.3-0.4-0.7-0.6-1c-0.2-0.3-0.4-0.7-0.6-1.1
	 c-0.2-0.4-0.4-0.7-0.6-1.1l-0.5-1c-0.4-0.7-0.7-1.5-1.1-2.2c-0.2-0.5-0.4-0.9-0.6-1.4c-1.8-4.3-2.6-8.8-1.9-13.6v0
	 c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c-1.3-3.2-3-6.3-4.2-9.5c-1.1-2.8-1.4-5.6,0.9-8c0.5-0.5,1-0.9,1.5-1.2
	 c2.3-1.3,4.6-0.6,6.9,0.8h0c0.3,0,0.6,0,0.8,0.1c0.5,0.1,1.1,0.2,1.5,0.4c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.8,0.6,1.2,0.9
	 c0.4,0.4,0.8,0.8,1.2,1.2c1.1,1.2,2.2,2.6,3.5,3.7c3,2.7,6.3,4.9,9.6,6.8c0.7,0.4,1.5,0.8,2.2,1.1c0,0,0,0,0,0
	 c0.6,0.3,1.2,0.5,1.8,0.8c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0.5,0.2,1,0.4,1.5,0.6c0.6,0.2,1.2,0.5,1.8,0.7
	 c0.6,0.2,1.3,0.4,1.9,0.6c0.3,0.1,0.6,0.2,0.8,0.3h0c0.9,0.3,1.9,0.5,2.9,0.7c0.1,0,0.2,0,0.3,0c0.8,0.2,1.7,0.4,2.6,0.5
	 c0.2,0,0.4,0.1,0.6,0.1c0.9,0.1,1.9,0.3,2.8,0.4c1,0.1,2.1,0.2,3.2,0.3c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0h0.1c0.6,0,1.2,0,1.8,0
	 c0.3,0,0.6-0.1,0.9-0.1c0.2,0,0.5-0.1,0.7-0.2c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.5-0.2,0.8-0.4c0.6-0.3,1.2-0.8,1.7-1.3
	 c0.2-0.2,0.3-0.4,0.5-0.6c0,0,0,0,0,0c2.7-3.8,6.3-4,10.3-2.2c5.1,2.4,10.5,3.8,16,4.5c9.7,1.3,19.2,0.5,29-4.6
	 c-3.6-0.8-6.2-1.4-8.8-1.9c-5.9-1.2-11.7-3-17.3-5.3c-3.2-1.3-5.4-3.5-4.6-7.2C680.8,457.2,683.8,456.9,686.9,457z M439.9,361.1
	 c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0,0,0.1
	 c-0.2,0.3-0.4,0.5-0.6,0.8c-0.5,0.6-1.1,1.1-1.8,1.4c-0.2,0.1-0.4,0.2-0.6,0.3c-2.6,1.3-5.1,2.6-7.5,4.1
	 c-10.7,6.2-20.5,13.8-29.6,22.3h0c-0.4,0.4-0.7,0.6-1,0.9c-0.3,0.3-0.7,0.5-1,0.7c-1,0.6-2,0.9-2.9,0.9c-0.2,0-0.5,0-0.7-0.1
	 c-0.1,0-0.2,0-0.3-0.1c0,0,0,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1h0c-0.4-0.2-0.9-0.4-1.3-0.7c-0.2-0.1-0.4-0.3-0.6-0.5
	 c-0.3-0.2-0.5-0.5-0.8-0.8c-0.2-0.3-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.3-0.4-0.5c0,0,0-0.1-0.1-0.1
	 c-0.1-0.1-0.1-0.2-0.2-0.2c-0.5-0.7-1-1.4-1.4-2.1c-1.4-2.1-2.8-4.2-4.3-6.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.5-0.7-1-1.1-1.5
	 c-0.4-0.5-0.7-1-1.1-1.5c-0.7-0.9-1.4-1.8-2.1-2.6c-2.2-2.6-4.4-5.2-6.7-7.6c-4.5-4.7-9.4-9.1-14.7-13.1c-0.8-0.6-1.5-1.2-2.1-1.7
	 c-0.6-0.5-1.1-1.1-1.4-1.6c-0.3-0.4-0.5-0.7-0.7-1.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.5-0.2-0.7
	 c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.2,0-0.4,0.1-0.6c0,0,0-0.1,0-0.1c0.3-1.7,1.9-3.3,4.7-5.1c10.7-6.9,20.4-14.9,28.6-24.4
	 c2.2-2.5,4.3-5.2,6.3-7.9c0.4-0.5,0.8-1,1.2-1.4c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.4-0.3,0.6-0.5c2-1.6,4-1.6,5.9-0.1
	 c0,0,0.1,0.1,0.2,0.1v0c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4
	 c0.4,0.5,0.9,1.1,1.3,1.7c0.1,0.2,0.2,0.4,0.4,0.6c0.7,1.3,1.4,2.5,2,3.8c7.9,15,18.2,27.9,31.2,38.7c0.4,0.3,0.8,0.6,1.1,0.9
	 c0.4,0.3,0.7,0.6,1,1v0c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.5c0.2,0.2,0.3,0.4,0.4,0.7c0,0,0.1,0.1,0.1,0.1
	 c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.4
	 c0,0.1,0,0.2,0,0.2V361.1z M467.9,341.8c-13.7,0-24.8-11.1-24.8-24.7c0-13.7,11.1-24.8,24.8-24.8c13.7,0,24.7,11.1,24.7,24.8
	 C492.7,330.7,481.6,341.8,467.9,341.8z"/>
 <path class="st0" d="M439.8,360.1c0-0.2-0.1-0.4-0.1-0.7c0,0,0-0.1,0-0.1c0-0.3-0.2-0.6-0.3-0.8c0-0.1-0.1-0.1-0.1-0.2
	 c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.4-0.5c-0.1-0.2-0.3-0.3-0.4-0.4c-0.3-0.3-0.7-0.7-1-1
	 c-0.4-0.3-0.7-0.6-1.1-0.9c-13-10.8-23.4-23.8-31.2-38.7c-0.7-1.3-1.3-2.6-2-3.8c-0.1-0.2-0.2-0.4-0.4-0.6
	 c-0.4-0.7-0.9-1.2-1.3-1.7c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
	 c0,0-0.1-0.1-0.2-0.1c-1.9-1.5-3.9-1.5-5.9,0.1c-0.8,0.6-1.5,1.4-2.3,2.5c-2,2.7-4.1,5.4-6.3,7.9c-8.3,9.5-17.9,17.5-28.6,24.4
	 c-2.8,1.8-4.4,3.4-4.7,5.1c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5,0.1,0.8c0,0.2,0.1,0.5,0.2,0.7c0.2,0.5,0.5,1,0.9,1.5
	 c0.4,0.5,0.9,1,1.4,1.6c0.6,0.6,1.3,1.1,2.1,1.7c5.2,4,10.1,8.4,14.7,13.1c2.3,2.4,4.6,4.9,6.7,7.6c0.7,0.9,1.4,1.8,2.1,2.6
	 c0.8,1,1.6,2,2.3,3c0.1,0.1,0.2,0.3,0.3,0.4c1.5,2.1,2.8,4.2,4.3,6.3c0.5,0.7,0.9,1.4,1.4,2.1c0,0.1,0.1,0.1,0.2,0.2
	 c0,0,0,0,0.1,0.1c0.2,0.3,0.5,0.6,0.7,0.9c0.2,0.3,0.5,0.6,0.7,0.8c0.3,0.3,0.5,0.5,0.8,0.8c0.2,0.2,0.4,0.3,0.6,0.5
	 c0.4,0.3,0.8,0.5,1.3,0.7c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.5,0.1,0.7,0.1c0.9,0,1.9-0.3,2.9-0.9
	 c0.3-0.2,0.7-0.4,1-0.7c0.3-0.3,0.7-0.5,1-0.9h0c9.1-8.5,18.9-16.1,29.6-22.3c2.4-1.4,5-2.8,7.5-4.1c0.2-0.1,0.4-0.2,0.6-0.3
	 c0.7-0.4,1.3-0.9,1.8-1.4c0.2-0.2,0.4-0.5,0.6-0.8c0,0,0,0,0-0.1c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3
	 c0.1-0.4,0.2-0.8,0.3-1.3v-0.3C439.9,360.6,439.8,360.4,439.8,360.1z M397.1,378.6c-1.4,1.1-2.9,0.4-3.8-0.8
	 c-3-4.2-7.1-7.7-9.8-13.7c9.6,0.9,18.2,1.8,27.8,2.7C406.5,372.3,401.6,375.2,397.1,378.6z M368.8,349
	 c10.1-7.7,18.7-15.4,26.9-24.3c6.8,11.1,14.2,20.9,24.1,31C401.9,354.7,385.9,353.1,368.8,349z"/>
 <path class="st13" d="M532.7,675.8c-0.3-0.2-0.6-0.4-0.9-0.6c-1.3-0.8-2.6-1.3-4.1-1.7c-0.6-0.2-1.1-0.3-1.7-0.4
	 c-0.6-0.1-1.2-0.2-1.7-0.3c-7.3-1.2-14.5-2.1-21.9-2.6c-2.6-0.2-5.1-0.3-7.7-0.3c-5.6-0.1-11.2-0.1-16.8-0.1c-7.7,0-15.4,0-23.2,0
	 c-10.2,0-20.5-0.1-30.7,0c-11.8,0.1-23.6,0.5-35.4,1.4c-3,0.2-6.1,0.4-9.1,0.7c-0.9,0.1-1.8,0.1-2.7,0.2c-3.1,0.2-6.1,0.5-9.1,1
	 c-5.7,0.9-11.5,1.4-16.9,3.8c-2.1,0.9-3.9,2.1-4.7,4c-0.3,0.6-0.5,1.4-0.5,2.2c-0.1,1.3,0.1,2.4,0.7,3.3c0.8,1.5,2.4,2.4,4.1,3.2
	 c4.2,2.1,8.8,2.6,13.3,3.2c15.2,2,30.5,2.1,45.8,2.5c3.1,0.1,6.2,0.2,9.3,0.3c24.2,0.8,48.4-0.9,72.5-2.3c2.4-0.1,4.8-0.3,7.1-0.5
	 c7.7-0.7,15.4-1.7,23.1-2.8c3.6-0.5,7.1-1.6,10.3-3.4c1.6-0.9,2.8-2.1,3.4-3.2c1-1.7,0.9-3.5-0.2-5.2
	 C534.3,677.3,533.6,676.5,532.7,675.8z"/>
 <path class="st0" d="M319,695.6c5.4-12.7,14.7-20.7,26.7-25.7c1.6-0.7,3.3-1.3,5.1-1.9c8.4-2.7,17-3.5,25.7-3.8c0.7,0,1.4,0,2.1,0
	 c4.2-0.1,8.4-0.1,12.5-0.1c3.1,0,6.2,0.1,9.4,0c1.7,0,3.4,0.1,5.1-0.1c3.3-0.3,5.9-2.1,7-4.6c0.4-0.8,0.5-1.7,0.5-2.7
	 c0-3.5-2.9-6.8-7.1-7c-1.5-0.1-2.9-0.1-4.4-0.2c-3.1-0.1-6.3,0-9.4,0c-1.7,0-3.4,0-5.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	 c-3.2,0-6.5-0.1-9.7-0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-1.1,0-2.1,0-3.2,0.1c-12.4,0.6-24.6,2.2-36,7.3
	 c-11.5,5.2-20.8,12.7-27.4,23.1c-1.4,2.2-2.7,4.5-3.8,6.9c-0.4,0.9-0.8,1.7-1.2,2.7c-1.1,2.9-1.2,5.5-0.3,7.6
	 c0.6,1.5,1.7,2.7,3.2,3.5c0.1,0.1,0.2,0.1,0.3,0.2C313,702.9,316.7,701.2,319,695.6z"/>
 <path class="st14" d="M626.2,657.1c0,0.1,0,0.2,0,0.3c0.1,3,1.4,5,3.9,6C627.9,662.2,626.3,659.8,626.2,657.1z"/>
 <path class="st0" d="M708.4,664.2C708.4,664.2,708.5,664.3,708.4,664.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1
	 c0,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c3.5-0.1,7-3.5,6.9-6.9
	 c0-1.4-0.5-2.8-1.2-3.9c-0.7-1-1.6-1.8-2.7-2.3c-0.9-0.4-1.9-0.7-3-0.7c-1,0-1.9,0.2-2.7,0.5c-2.5,1-4.2,3.3-4.3,6.3
	 C703.5,660.5,705.7,663.3,708.4,664.2z"/>
 <path class="st1" d="M368.8,348.9c17.1,4.1,33.1,5.8,50.9,6.6c-9.9-10.1-17.3-19.9-24.1-31C387.5,333.6,378.9,341.2,368.8,348.9z"
	 />
 <path class="st7" d="M393.3,377.8c0.9,1.2,2.4,1.8,3.8,0.8c4.5-3.4,9.5-6.4,14.2-11.8c-9.7-0.9-18.2-1.8-27.8-2.7
	 C386.2,370.1,390.3,373.6,393.3,377.8z"/>
 <path class="st0" d="M726,861.8c-0.1,0.6-0.2,1.3-0.2,1.9c0,4.8,3.9,8.7,8.7,8.7c4.8,0,8.7-3.9,8.7-8.7s-3.9-8.7-8.7-8.7
	 c-0.1,0-0.1,0-0.1,0C730.3,855,726.8,857.9,726,861.8z"/>
 <path class="st11" d="M418.8,738.3v14.4c0,1-0.8,1.8-1.8,1.8h-39.3c-0.4,0-0.7,0-1,0h-2.1c-1,0-1.9-0.8-1.9-1.8v-14.4
	 c0-1,0.8-1.9,1.9-1.9h42.4C417.9,736.5,418.8,737.3,418.8,738.3z"/>
 <path class="st14" d="M680.5,736.5h-3c0.5,0,1,0,1.6,0C679.6,736.4,680.1,736.4,680.5,736.5z"/>
 <path class="st14" d="M683.8,750.3c0,0.7,0,1.4-0.2,1.9v-13.6c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4
	 c0,0.3,0,0.7,0,1.1C683.6,744,683.8,747.2,683.8,750.3z"/>
 <path class="st14" d="M637.6,738.4v14.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3c0-0.5,0-1,0-1.5
	 C637.5,743.5,637.2,740.2,637.6,738.4z"/>
 <path class="st11" d="M683.7,738.3v14.4c0,1-0.8,1.8-1.8,1.8h-42.4c-1,0-1.8-0.8-1.8-1.8v-14.4c0-1,0.8-1.9,1.8-1.9h42.4
	 C682.8,736.5,683.7,737.3,683.7,738.3z"/>
 <path class="st0" d="M547.4,648.7C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7c-0.1,0-0.2,0-0.2,0
	 C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z M547.7,648.8
	 C547.6,648.8,547.6,648.8,547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8
	 C547.7,648.8,547.7,648.8,547.7,648.8z M547.7,648.8L547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8L547.7,648.8L547.7,648.8z
		M336.6,344.8c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M375.9,917.8c5,14.9,20.8,19.9,32.1,10.2c2.6-2.2,4.6-5,5.6-8.3c0.2-0.6,0.4-1.3,0.5-2
	 H375.9z M639.2,917.8c0.1,0.6,0.2,1.2,0.4,1.7c3.8,10.6,15.4,16,26,12c5.5-2.1,9.2-6,11.3-11.5c0.3-0.7,0.5-1.4,0.6-2.2H639.2z
		M547.4,648.7C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7c-0.1,0-0.2,0-0.2,0
	 C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z M336.6,344.8
	 c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M375.9,917.8c5,14.9,20.8,19.9,32.1,10.2c2.6-2.2,4.6-5,5.6-8.3c0.2-0.6,0.4-1.3,0.5-2
	 H375.9z M639.2,917.8c0.1,0.6,0.2,1.2,0.4,1.7c3.8,10.6,15.4,16,26,12c5.5-2.1,9.2-6,11.3-11.5c0.3-0.7,0.5-1.4,0.6-2.2H639.2z
		M547.7,648.8L547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8L547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8z M547.4,648.7
	 C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7c-0.1,0-0.2,0-0.2,0
	 C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z M336.6,344.8
	 c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M547.7,648.8L547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8
	 C547.7,648.8,547.7,648.8,547.7,648.8z M547.4,648.7C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7
	 c-0.1,0-0.2,0-0.2,0C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z
		M336.6,344.8c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M336.4,339.3c0,0.4,0,0.9,0,1.3v-2.7C336.4,338.3,336.4,338.8,336.4,339.3z M547.7,648.8
	 L547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8z M336.6,344.8c0-0.7,0-1.4,0-2.2
	 c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4C336.5,344.1,336.5,344.5,336.6,344.8z
		M336.4,339.3c0,0.4,0,0.9,0,1.3v-2.7C336.4,338.3,336.4,338.8,336.4,339.3z M796.7,369.2c-0.1-0.1-0.1-0.2-0.2-0.3
	 c0.3,0.4,0.6,0.9,0.8,1.4C797.1,369.9,796.9,369.5,796.7,369.2z M718.4,339.5c-0.4-0.4-0.9-0.8-1.4-1.2
	 C717.5,338.7,718,339.1,718.4,339.5c0.2,0.2,0.3,0.3,0.5,0.4C718.8,339.8,718.6,339.7,718.4,339.5z M665.5,250.9
	 C665.5,250.9,665.6,250.9,665.5,250.9L665.5,250.9C665.6,250.9,665.6,250.9,665.5,250.9z M605.6,460.5L605.6,460.5
	 C605.6,460.4,605.6,460.4,605.6,460.5c0.9,1.9,2,3.8,3.1,5.7c1.1,1.9,2.3,3.6,3.5,5.4C609.9,468.1,608.6,463.7,605.6,460.5z
		M598.6,459.5c2.3-1.3,4.6-0.6,6.9,0.8C603.7,458.3,601,458,598.6,459.5z M600.5,478.2c-1.3-3.2-3-6.3-4.2-9.5
	 c0.9,2.4,1.9,4.7,2.8,7.1c0.4,0.9,0.8,1.7,1.2,2.6c0.1,0.1,0.1,0.3,0.2,0.4c0.4,0.8,0.9,1.6,1.5,2.4c0,0,0.1,0.1,0.1,0.2
	 c0.3,0.4,0.6,0.9,0.9,1.4c0.7,1,1.4,2,2.1,3c0,0.1,0.1,0.1,0.1,0.2c0.3,0.5,0.7,1,1,1.4c0.1,0.2,0.3,0.4,0.5,0.7
	 C604.6,484.9,602.7,481.3,600.5,478.2z M598.2,407.8c0,0.2,0.1,0.4,0.1,0.7c0,0,0,0.1,0,0.1C598.3,408.3,598.3,408.1,598.2,407.8z
		M547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8
	 C547.7,648.9,547.7,648.9,547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8L547.7,648.8L547.7,648.8z M547.4,648.7
	 C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7c-0.1,0-0.2,0-0.2,0
	 C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z M546.1,648.3
	 C546.1,648.3,546.2,648.3,546.1,648.3L546.1,648.3C546.1,648.3,546.1,648.3,546.1,648.3z M542.2,645.8c0.1,0.1,0.2,0.2,0.4,0.3v0
	 C542.5,646,542.3,645.9,542.2,645.8z M439.9,181.7c0.3,0.5,0.6,0.9,0.9,1.3C440.5,182.7,440.2,182.3,439.9,181.7z M438.5,176.7
	 L438.5,176.7C438.6,176.7,438.6,176.6,438.5,176.7C438.6,176.6,438.6,176.6,438.5,176.7z M423.5,217.3c0-0.1-0.1-0.2-0.2-0.2
	 c0.2,0.2,0.3,0.5,0.4,0.8C423.7,217.7,423.6,217.5,423.5,217.3z M342,379.5L342,379.5c0.1,0.4,0.2,0.8,0.3,1.2
	 C342.3,380.3,342.1,379.9,342,379.5z M338.4,363.5C338.4,363.5,338.4,363.5,338.4,363.5c0.2,1.1,0.4,2.2,0.6,3.3
	 C338.8,365.7,338.6,364.6,338.4,363.5z M336.9,351.3C336.9,351.3,336.9,351.3,336.9,351.3c0,0.3,0,0.6,0.1,0.9
	 C337,351.9,337,351.6,336.9,351.3z M336.7,348.4c0-0.4,0-0.8-0.1-1.2c0-0.1,0-0.2,0-0.2c0,0.7,0,1.4,0.1,2.1
	 C336.7,348.8,336.7,348.6,336.7,348.4z M336.4,339.3c0,0.2,0,0.4,0,0.6v-2C336.4,338.3,336.4,338.8,336.4,339.3z M336.6,344.8
	 c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2v1.7c0,0.1,0,0.2,0,0.2v0.4C336.5,344.1,336.5,344.5,336.6,344.8z M286.8,418L286.8,418
	 c0.1,0,0.2-0.1,0.3-0.2C287,417.9,286.9,417.9,286.8,418z M285.7,418.8L285.7,418.8C285.7,418.8,285.7,418.8,285.7,418.8
	 C285.7,418.8,285.7,418.8,285.7,418.8z M336.4,339.3c0,0.4,0,0.9,0,1.3v-2.7C336.4,338.3,336.4,338.8,336.4,339.3z M336.6,344.8
	 c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M547.7,648.8L547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8
	 C547.7,648.8,547.7,648.8,547.7,648.8z M336.4,339.3c0,0.2,0,0.4,0,0.6v-2C336.4,338.3,336.4,338.8,336.4,339.3z M336.6,344.8
	 c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M547.4,648.7C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7
	 c-0.1,0-0.2,0-0.2,0C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z
		M547.7,648.8L547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8z M336.6,344.8
	 c0-0.7,0-1.4,0-2.2c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4
	 C336.5,344.1,336.5,344.5,336.6,344.8z M547.4,648.7C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7
	 c-0.1,0-0.2,0-0.2,0C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z
		M639.2,917.8c0.1,0.6,0.2,1.2,0.4,1.7c3.8,10.6,15.4,16,26,12c5.5-2.1,9.2-6,11.3-11.5c0.3-0.7,0.5-1.4,0.6-2.2H639.2z
		M375.9,917.8c5,14.9,20.8,19.9,32.1,10.2c2.6-2.2,4.6-5,5.6-8.3c0.2-0.6,0.4-1.3,0.5-2H375.9z M336.6,344.8c0-0.7,0-1.4,0-2.2
	 c0-0.4,0-0.8,0-1.2c0-0.1,0-0.3,0-0.4c0,0.6,0,1.3,0,1.9v0.2c0,0.1,0,0.2,0,0.2v0.4C336.5,344.1,336.5,344.5,336.6,344.8z
		M547.4,648.7C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7c-0.1,0-0.2,0-0.2,0
	 C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z M547.7,648.8
	 C547.6,648.8,547.6,648.8,547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8
	 C547.7,648.8,547.7,648.8,547.7,648.8z M547.7,648.8L547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8L547.7,648.8L547.7,648.8z
		M684.6,917.8c-0.4,11.7-13.2,22.6-26.3,22.5c-13.5-0.1-26-10.7-26.5-22.5h-17.7c0.1,0.6,0.2,1.4,0.3,2.3
	 c2.9,21.4,21.5,37.7,43,38c22.2,0.4,40.6-15,44.6-37c0.2-1.3,0.4-2.4,0.5-3.3H684.6z M639.2,917.8c0.1,0.6,0.2,1.2,0.4,1.7
	 c3.8,10.6,15.4,16,26,12c5.5-2.1,9.2-6,11.3-11.5c0.3-0.7,0.5-1.4,0.6-2.2H639.2z M375.9,917.8c5,14.9,20.8,19.9,32.1,10.2
	 c2.6-2.2,4.6-5,5.6-8.3c0.2-0.6,0.4-1.3,0.5-2H375.9z M421.3,917.8C421.3,917.8,421.3,917.8,421.3,917.8c-0.6,6.7-3.8,12-8.8,16.2
	 c-5.2,4.4-11.2,6.6-18.1,6.4c-12.9,0-24.2-9.3-25.7-21.2c0-0.4-0.1-0.9-0.2-1.4h-18c0.1,1.5,0.4,3,0.8,4.5
	 c0.3,1.4,0.7,2.8,1.1,4.1c4.4,14.5,13.8,24.7,28.7,29.7c25.6,8.6,52.9-8,57.8-34.6c0.3-1.5,0.5-2.7,0.7-3.7H421.3z M375.9,917.8
	 c5,14.9,20.8,19.9,32.1,10.2c2.6-2.2,4.6-5,5.6-8.3c0.2-0.6,0.4-1.3,0.5-2H375.9z M547.4,648.7
	 C547.4,648.7,547.4,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7c-0.1,0-0.2,0-0.2,0
	 C547.2,648.7,547.3,648.7,547.4,648.7C547.3,648.7,547.3,648.7,547.4,648.7L547.4,648.7L547.4,648.7z M547.7,648.8
	 C547.6,648.8,547.6,648.8,547.7,648.8C547.6,648.8,547.6,648.8,547.7,648.8C547.7,648.8,547.7,648.8,547.7,648.8
	 C547.7,648.8,547.7,648.8,547.7,648.8z M547.7,648.8L547.7,648.8C547.7,648.9,547.7,648.9,547.7,648.8L547.7,648.8L547.7,648.8z"
	 />
 <path class="st0" d="M350.4,916.5C350.4,916.4,350.4,916.4,350.4,916.5c0,0.2,0,0.5,0.1,0.8c0,0.2,0,0.3,0,0.5h0.1
	 C350.6,917.4,350.5,916.9,350.4,916.5z M666.4,491.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0C666.5,491.4,666.4,491.4,666.4,491.4z"/>
 <path class="st0" d="M888,482.3c-13.6-81.4-52-150-114.3-204.5c-53.3-46.6-115.9-72.9-185.8-82.2c-6.9-0.9-13.9-1.6-20.7-2.4
	 c-0.6-2.5,0.8-4.1,1.3-5.9c0.6-2.3,1-4.6-0.5-6.3c-2.6-2.8-1.8-5-0.2-8.1c6.1-11.8,2-21.9-10.3-26.8c-7.6-3-15.6-3.1-23.6-2.6
	 c-1.8,0.1-3.7,1.4-5.6-0.3c0-0.7,0-1.4,0-2.1c0.6-10.8-3.4-16-14.1-18.4c-5.1-1.1-10.1-1.2-15.2-0.7c-16.2,1.7-31.4,6-43.8,17.3
	 c-1.6,1.5-2.8,1.9-4.9,0.4c-5.6-3.7-11.6-2.9-17.3-0.3c-4.2,2-7.9,4.7-11.2,7.9c-11.4,11-18,24.5-20.9,39.9c-0.6,3.4-2,5.5-5,7.3
	 c-18.5,11.3-33.6,26.1-45.6,44.1c-2.6,3.9-5.8,6.9-9.7,9.5c-98.2,68.6-147.9,163.7-153.7,282.5c-1.2,23.8,0.6,47.4,4.3,71
	 c6.1,39.6,17.9,77.2,36.8,112.6c3.7,7,9.8,13.2,11.3,20.9c1.5,7.7,0.2,15.9,0.2,23.9c0,13.6-0.1,27.1,0,40.7
	 c0.2,11.9,6.2,17.8,17.9,18.2c3.9,0.1,7.8,0.1,11.6,0c2.8-0.1,3.9,1,3.9,3.8c-0.1,6.1,0,12.1,0,18.2c0,14.9,3.8,28,11,39.3
	 c12.4,23,36.7,38.5,64.6,38.5h1.9c0-0.2,0-0.3,0-0.5c0-0.3,0-0.6-0.1-0.9c0,0,0,0,0,0.1c0.1,0.4,0.2,0.9,0.3,1.3h357.7
	 c40.3,0,72.9-32.4,73.4-72.6c0.1-0.1,0.1-0.1,0.2-0.2c0.1-1.8,0.1-3.6,0.1-5.4c-0.1-5.8,0.1-11.7,0-17.5c-0.1-3.1,1-4.4,4.2-4.2
	 c3.1,0.2,6.3,0.1,9.5,0c11.8-0.3,17.9-6.3,18-18.1c0.1-8.2,0.2-16.5-0.1-24.7c-0.1-4.2,1.1-7.4,3.8-10.5c6-7,11.6-14.3,16.6-22.1
	 C885.3,662.8,903.7,575.8,888,482.3z M648.6,239.2c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.2-0.4,0.3-0.7c0.7-1.7,1.2-3.1,1.7-4.2
	 c2.3-4.8,3.8-4.3,11.2-1.6c19.9,7.5,38.8,16.6,56.5,27.2c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.2,0.6,0.4,0.9,0.6
	 c29.3,18,55.5,40.5,78.3,67.7c22.5,26.9,40,56.8,53,89.1c2.4,5.9,4.7,12,6.8,18.1c0.7,2.2,2,4.4,1.6,7.7c-0.3-0.2-0.6-0.3-0.9-0.5
	 c-1.1-0.5-2-1-2.8-1.4c-5.5-2.6-9.2-3-13-0.7c-1.2,0.7-2.4,1.7-3.7,3c-0.4,0.4-0.8,0.8-1.2,1.3c-1.2,1.4-2.4,2.9-3.6,4.3
	 c-1,1.2-1.9,2.4-3,3.4c-2,2.1-3.6,3.1-5.4,3c-1.1-0.1-2.4-0.5-3.9-1.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.6
	 c-6.7-4.1-13.2-8.6-19.8-12.9c-2.3-1.6-3.4-4-4.1-6.7c-1.5-6.1-3-12.2-4.6-18.3c-0.9-3.4-0.1-6.6,0.9-9.7c0.2-0.5,0.3-1.1,0.5-1.6
	 c0.2-0.5,0.4-1,0.5-1.5c1.1-2.9,2.5-5.7,3.6-8.6c3.2-8.3,2.8-16.6-1-23.5c-0.3-0.5-0.5-0.9-0.8-1.4c-0.3-0.4-0.5-0.8-0.8-1.2
	 c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.5-0.6-0.8-0.9c-0.3-0.4-0.7-0.8-1.1-1.2c-0.8-0.8-1.6-1.5-2.5-2.3c0,0,0,0,0,0
	 c-0.9-0.7-1.9-1.4-2.9-2c-3.6-2.2-7.2-4.5-10.8-6.8c-5-3.2-9.9-6.5-14.8-9.7c-8-5.4-16.6-6.7-25.4-2.1c-0.4,0.2-0.9,0.4-1.3,0.6
	 c-1.1,0.5-2.2,0.9-3.3,1.1c-1.5,0.3-2.9,0.4-4.3,0.3c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.1,0-0.2,0
	 c-0.2,0-0.4,0-0.7-0.1c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.2,0-0.2,0h0c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.8-0.3-1.1-0.4
	 c-0.3-0.1-0.7-0.3-1-0.5c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.7-0.4c0,0,0,0-0.1,0c-0.3-0.2-0.6-0.3-0.9-0.5
	 c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.2-0.2-0.4-0.3l-0.1-0.1
	 c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.9-0.8-1.4-1.2c0,0-0.1-0.1-0.2-0.1c-0.7-0.6-1.4-1.2-2.1-1.7
	 c-0.5-0.4-1-0.8-1.6-1.2c-0.2-0.2-0.5-0.4-0.7-0.5c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1
	 c-0.3-0.3-0.6-0.5-1-0.7c-0.3-0.2-0.6-0.5-1-0.7c-0.8-0.6-1.6-1.1-2.4-1.7c-9.1-6.2-18.9-11.3-28.7-16.3c-4.8-2.4-7.5-4.1-8.6-6.1
	 c-1.4-2.6-0.1-5.7,3.1-11.7c0.3-0.5,0.6-1.1,0.9-1.6c1-1.8,1.9-3.5,2.9-5.2c0.5-0.9,1-1.8,1.5-2.7c3.9-6.7,3.5-13.2,0-20
	 c-0.5-1-1-1.9-1.5-2.8c-0.5-0.8-0.9-1.6-1.4-2.4c-0.1-0.2-0.3-0.4-0.4-0.7c-0.3-0.6-0.7-1.1-1.1-1.7c-0.2-0.3-0.4-0.6-0.6-0.9
	 c-0.4-0.6-0.9-1.2-1.3-1.8c-0.4-0.5-0.8-1-1.3-1.5c-0.1-0.2-0.3-0.3-0.4-0.5c-0.5-0.6-1.1-1.2-1.6-1.7c-0.7-0.7-1.5-1.4-2.3-2
	 l-1-0.8c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2c-0.2-0.1-0.4-0.3-0.7-0.4c0,0,0,0,0,0s0,0,0,0
	 c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-2.4-1.4-5-2.4-8-3.2c-0.9-0.2-1.9-0.6-2.8-1.1
	 c-0.4-0.2-0.8-0.4-1.2-0.6c-0.3-0.2-0.7-0.4-1-0.6C648.6,242.5,647.9,241.1,648.6,239.2z M336.5,337.9c0-0.1,0-0.3,0-0.4v-0.9
	 c0-0.6,0-1.1,0-1.7c0-0.3,0-0.7,0-1.1c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.2v-0.2c0-1.3,0.1-2.6,0.2-3.9
	 c0.1-1.7,0.2-3.4,0.4-5.1c0-0.1,0-0.3,0-0.5c0-0.5,0.1-1,0.2-1.5c0.1-1.3,0.3-2.5,0.4-3.8c0.1-0.5,0.1-1.1,0.2-1.6
	 c0.1-1,0.3-1.9,0.4-2.9c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0c0.6-3.8,1.4-7.4,2.2-11.1c0.4-1.8,0.9-3.6,1.4-5.4
	 c1-3.6,2.1-7.1,3.3-10.5c1.2-3.4,2.6-6.8,4-10.1c4-9.1,9-17.8,14.7-26c7-9.9,15.3-19.1,24.8-27.6c2.7-2.4,5.6-4.8,8.6-6.9
	 c1-0.7,2-1.4,3-2.1c1-0.7,2.1-1.3,3.2-1.9c1.7,3.5,3.5,6.9,5.6,9.9c0.8,1.1,1.6,2.2,2.4,3.2l0.3,0.2c0.4,0,0.7,0.1,0.9,0.3
	 c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.4c1.1,0.4,2.3,0.8,3.4,1.1c1.9,0.5,3.6,0,4.8-1c0.2-0.1,0.4-0.3,0.5-0.5
	 c0.3-0.4,0.7-0.8,1-1.2c0.5-0.8,0.8-1.8,0.8-2.7c0-0.3,0-0.5-0.1-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.2-0.5-0.3-0.8
	 c-0.1-0.3-0.3-0.5-0.4-0.8c-0.4-0.6-0.7-1.2-1.1-1.9c-0.2-0.3-0.4-0.7-0.5-1c-0.2-0.4-0.4-0.9-0.6-1.3c-0.2-0.6-0.5-1.2-0.7-1.9
	 c-0.1-0.4-0.3-0.8-0.4-1.2c-0.3-1-0.6-2.1-0.8-3.1c-0.1-0.4-0.1-0.7-0.2-1.1c-0.1-0.7-0.2-1.4-0.3-2.1v-0.2
	 c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.4,0-0.8,0-1.1c0-0.9,0-1.8,0.1-2.8c0.2-2.9,0.6-5.8,1.3-8.8c1.1-6.7,3.1-12.7,6.4-18.2
	 c3.3-5.4,7.7-10.2,13.5-14.4c0.8,1.9,1.1,3.7,1.2,5.5c0,0.3,0,0.6,0,0.9c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.9-0.1,1.3
	 c0,0.4-0.1,0.9-0.2,1.3c-0.1,0.9-0.3,1.7-0.5,2.6c-0.1,0.4-0.2,0.8-0.3,1.3c-0.2,0.8-0.3,1.7-0.5,2.5c-0.3,1.1-0.6,2.1-0.8,3.2v0
	 c0.2,0.2,0.2,0.4,0.2,0.6c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1h0c0.3,2.2,0.8,3.8,1.4,5c0.3,0.5,0.6,1,0.9,1.3
	 c1.9,1.9,4.7,1.1,9.2-2c0.7-1.5,1.4-3,2.1-4.6c0.9-2,1.8-3.9,2.8-5.9c2.7-5.8,6.1-10.6,10-14.7c8.2-8.4,18.9-13.3,31.7-15.3
	 c0.9-0.1,1.8-0.3,2.6-0.4c2.9-0.5,5.9-1,8.9-0.8c2,0.2,3,0.8,3,1.9c0,0.2,0,0.4-0.1,0.7c-0.1,0.5-0.5,1.1-0.9,1.7
	 c-1,3.5-2.8,6.4-4.9,9.1c-2.1,2.7-4.6,5.1-7,7.6c-3.1,3.2-3.8,5-2.2,7.7c0.1,0.2,0.3,0.5,0.4,0.7c0.3,0.5,0.7,1,1.2,1.6
	 c0.2,0.3,0.5,0.6,0.7,0.9c0.9,0.1,1.9,0.2,2.8,0.2c2.9,0,5.6-0.7,8.3-1.7c1.5-0.6,2.9-1.2,4.3-1.9c0.6-0.3,1.2-0.6,1.9-0.9
	 c1.4-0.7,2.7-1.3,4.1-1.9c7.3-3.1,14.9-5,23.1-4.5c1.3,0.1,2.5,0.2,3.8,0.4c1.4,0.2,2.7,0.6,4,1.3c2.6,1.3,2.9,3,1,5.1
	 c-0.2,0.3-0.5,0.6-0.8,0.9c-0.3,0.4-0.7,0.7-1,1.1c-0.6,0.6-1.2,1.1-1.9,1.5c-0.4,0.2-0.8,0.4-1.3,0.6c-4.7,4.4-10.7,6.5-16.3,9.3
	 c-1.7,0.9-3.6,1.6-5.2,2.6c-2.4,1.5-2.8,3.8-2.1,6.4c0.7,2.5,2.5,3.5,4.9,3.4c3.4-0.2,6.8-0.4,10.1-0.9c3-0.4,6-1.1,10-0.5
	 c-2.9,2.9-5.3,5.2-7.6,7.6c-3.1,3.2-3.6,5.8-1.6,8.3c2.1,2.8,5,3.3,7.7,1.4c3.5-2.4,6-0.3,8.6,1.2c38.6,22.4,64.6,54.9,78.3,97.3
	 c7.8,24.4,9.4,49.4,5,74.7c-1.4,8-1.2,9.5,2,16.6c-2.3,1.2-4.6,0.4-6.8,0.1c-9.8-1.6-19.3,0-28.8,2.2c-1.4,0.3-2.8,0.8-4,1.5
	 c-1.1,0.6-1.8,1.5-2.2,2.4c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0.1,1c0,0,0,0,0,0c0,0.3,0.1,0.5,0.2,0.8
	 c0,0.2,0.1,0.5,0.2,0.7c0.5,1.4,1.7,2.5,3.1,3c0.2,0,0.4,0.1,0.7,0.1c0.2,0,0.5,0.1,0.7,0.1c1,0,2,0.1,2.9-0.1
	 c15.3-3.6,30.3-1.7,45.4,1.4c15.9,3.3,31.7,3.1,47.5-1c1.6-0.4,3.2-1.1,5.3,0c-4.8,8-11.6,13.6-19.7,17.7
	 c-1.5,0.8-3.1,1.4-4.7,2.1c-2.4,1.1-4.1,2.8-3.9,5.5c0.3,3.4,2.7,5.2,5.7,5.7c7.4,1.4,14.8,2,22.4,1.1c5.4-0.6,10.7-2.5,17.1-2.1
	 c-4.9,7.9-12,11.1-19.9,12.9c-4.8,1.1-9.6,1.3-14.4,1.1c-3-0.1-6.1,0.2-6.9,3.9c-0.8,3.8,1.5,5.9,4.6,7.2
	 c5.6,2.4,11.4,4.1,17.3,5.3c2.6,0.5,5.1,1.1,8.8,1.9c-9.9,5.1-19.3,5.9-29,4.6c-5.5-0.7-10.9-2.2-16-4.5c-4-1.9-7.5-1.7-10.3,2.2
	 c0,0,0,0,0,0c-0.7,5.6,2,9.2,6.5,11.6l0.8,0.4c0.7,0.3,1.5,0.7,2.2,1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0
	 c0.3,0.1,0.7,0.3,1,0.4c0.1,0,0.2,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.4,0.1,0.6,0.2h0c0,0,0.1,0,0.1,0h0
	 c0.2,0.1,0.4,0.1,0.6,0.2c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.2,1.2,0.3c0.7,0.2,1.4,0.3,2.1,0.5c0,0,0.1,0,0.1,0
	 c0.5,0.1,1.1,0.2,1.6,0.3c0.3,0.1,0.7,0.1,1.1,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.8,0.1,1.6,0.2,2.3,0.4
	 c1.9,0.2,3.7,0.5,5.6,0.6c1.9,0.2,3.8,0.2,6.6,0.3c-2.4,2.3-4.9,3.8-7.5,4.8c-2.6,1-5.3,1.6-7.9,2c-1.1,0.1-2.3,0.3-3.4,0.4
	 c-2.5,0.2-5,0.3-7.4,0.2c-7.7-0.2-15.2-2-22.4-5.5c-12.4-6.2-22.3-14.8-30-25.7c-1.2-1.8-2.4-3.5-3.5-5.4
	 c-1.1-1.9-2.2-3.8-3.2-5.7c-2.4-1.5-4.7-2.2-6.9-0.8c-0.5,0.3-1,0.7-1.5,1.2c-2.3,2.4-2,5.2-0.9,8c1.2,3.2,2.9,6.3,4.2,9.5
	 c2.2,3.1,4.1,6.7,6.3,9.7c0,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.7,0.8,1.1c0.4,0.6,0.9,1.1,1.3,1.7c0.4,0.6,0.9,1.1,1.3,1.6
	 c0.2,0.2,0.4,0.5,0.6,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.6,0.8,1.3,1.6,2,2.3c0.8,0.9,1.6,1.7,2.5,2.5
	 c0.1,0.1,0.2,0.2,0.4,0.4c0.8,0.8,1.7,1.6,2.6,2.4c0.4,0.4,0.9,0.8,1.4,1.2l0.1,0.1c0.5,0.4,1,0.9,1.5,1.3
	 c0.3,0.2,0.6,0.4,0.8,0.7c0.7,0.6,1.5,1.2,2.3,1.7c0.3,0.2,0.5,0.4,0.8,0.6c1,0.7,2,1.3,2.9,2c1,0.6,2,1.2,2.9,1.8c0,0,0,0,0,0
	 c0.7,0.4,1.3,0.7,2,1.1c0,0,0.1,0,0.1,0.1c0.2,0.2,0.5,0.3,0.8,0.4c0,0,0.1,0,0.1,0c0.6,0.3,1.3,0.6,1.9,0.9
	 c0.1,0,0.2,0.1,0.3,0.1c0.3,0.2,0.6,0.3,0.9,0.4c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.2,0.9,0.4
	 c0.8,0.3,1.6,0.6,2.4,0.9c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0,0.2,0.1,0.3,0.1c0.9,0.3,1.9,0.6,2.8,0.9c0,0,0.1,0,0.1,0
	 c0.5,0.2,0.9,0.3,1.4,0.4c0.8,0.2,1.6,0.4,2.4,0.6c0.1,0,0.1,0,0.2,0c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0,0,0
	 c0.7,0.2,1.4,0.3,2.1,0.4h0.1c0.1,0,0.2,0,0.4,0.1c0.3,0.1,0.6,0.1,0.9,0.2c1.2,0.2,2.4,0.4,3.7,0.6c1.4,0.2,2.8,0.4,4.2,0.5
	 c0.3,0,0.5,0,0.8,0c1.2,0.1,2.4,0.1,4,1.2c-2,8.8-5.1,17-9.3,24.8c-4.1,7.8-9.3,15-15.2,21.8c-2.3,2.6-4.7,5.1-7.1,7.4
	 c-10.4,10-22.3,17.5-35.2,23.1c-10.4,4.5-21.4,7.8-32.9,10.3c-0.6,0.1-2.8,0.5-6,0.9c-3.2,0.4-7.4,1-12.1,1.6
	 c-13.3,1.6-30.5,3.3-39.5,3.2c-21.4-0.2-42.5-2.2-63.2-8.1c-42.4-12-71.3-38.9-87-79.8c-7.8-20.3-11.2-41-10.6-62.1
	 c0.1-2.5,0.2-5,0.4-7.6c0.1-1.9,0.3-3.8,0.5-5.7c0.2-1.7,0.4-3.4,0.6-5.1c0.2-1.9,0.5-3.7,0.8-5.6c0.3-1.7,0.6-3.5,1-5.2
	 c0.2-1,0.4-2,0.7-2.9c1.6-5.7,1.6-5.8,7.3-3.2c4.4,2.1,8.9,3.8,13.7,4.7c1.9,0.4,3.9,0.6,5.7,0.2c2.8-0.6,4.7-2.5,4.7-5.6
	 c0-2.7-1.3-4.6-3.9-5.5c-1.7-0.5-3.5-0.8-5.2-1.4c-0.7-0.2-1.4-0.5-2-0.9c-0.3-0.2-0.6-0.4-0.9-0.6c-2.4-0.6-4.7-1.4-6.8-2.3
	 c-3.6-1.6-6.8-3.6-9.6-6.5c-2.2-2.2-4.1-4.8-5.6-8.1v0c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.7-0.6-1.3-0.9-2
	 c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0-0.1,0-0.1c-0.2-0.5-0.4-0.9-0.6-1.4
	 c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.3-0.3-0.7-0.4-1.1c-0.3-0.6-0.5-1.2-0.7-1.9
	 c0,0,0-0.1,0-0.1c-0.3-0.8-0.6-1.6-0.9-2.4c-0.3-0.9-0.7-1.8-1-2.6c-0.4-1.2-0.8-2.5-1.2-3.7c-0.1-0.4-0.2-0.8-0.4-1.2
	 c-0.1-0.4-0.3-0.8-0.4-1.2c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.5-0.3-1-0.4-1.6c-0.1-0.5-0.3-1-0.4-1.5
	 c0-0.1-0.1-0.2-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5c-0.3-1.3-0.7-2.6-1-4c-0.3-1.6-0.7-3.1-1-4.8c-0.2-1.1-0.4-2.2-0.6-3.3v0
	 c0,0,0,0,0,0c-0.2-1.1-0.3-2.2-0.5-3.4c-0.1-0.5-0.1-1.1-0.2-1.7c-0.2-1.1-0.3-2.3-0.4-3.4c-0.1-0.9-0.2-1.7-0.2-2.6v-0.1
	 c0-0.3-0.1-0.6-0.1-1c0-0.3,0-0.7-0.1-1V350c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.7c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.3,0-0.6,0-0.8
	 c0,0,0-0.1,0-0.1c0-0.4,0-0.8,0-1.2c0-0.3,0-0.7,0-1.1c0-0.2,0-0.4,0-0.6v-0.2c0-0.6,0-1.2,0-1.9c0-0.1,0-0.2,0-0.4
	 c0-0.4,0-0.9,0-1.3C336.4,338.8,336.4,338.3,336.5,337.9z M620.1,773.2H436.8c-1.1,0-2.1-0.9-2.1-2.1v-12.5c0-1.2,0.9-2.1,2.1-2.1
	 h183.3c1.1,0,2.1,0.9,2.1,2.1v12.5C622.2,772.3,621.3,773.2,620.1,773.2z M683.7,739c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4
	 c0,0.3,0,0.7,0,1.1c-0.2,3.1,0,6.3,0,9.5c0,0.7,0,1.4-0.2,1.9v0.4c0,1-0.8,1.8-1.8,1.8h-42.4c-1,0-1.8-0.8-1.8-1.8v-0.1
	 c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3c0-0.5,0-1,0-1.5c0.1-6.2-0.2-9.5,0.2-11.3v-0.1
	 c0-1,0.8-1.9,1.8-1.9h1c0.5,0,1,0,1.6,0h35.4c0.5,0,1,0,1.6,0c0.5,0,1,0,1.5,0h1.3c1,0,1.8,0.8,1.8,1.9v0.4
	 C683.7,738.8,683.7,738.9,683.7,739z M667.4,780.8c0,0.8-0.2,1.6-0.5,2.4c-0.9,2.5-2.9,4.4-5.4,5c-0.7,0.2-1.5,0.4-2.3,0.3
	 c-4.1-0.1-7.5-4-7.3-8.3c0.1-3.6,2.1-6.2,5.2-7.1c0.9-0.3,1.9-0.5,2.9-0.4c3.8,0,6.4,2.3,7.1,5.7
	 C667.4,779.2,667.5,780,667.4,780.8z M689.9,718.9h-0.7c-19.1,0-38.3,0-57.4,0c-8.8,0-11.3,2.6-11.4,11.2
	 c-0.1,11.4,1.6,10.5-10.5,10.5c-27.1,0-54.3,0.3-81.4-0.2c-16.2-0.3-32.5-0.8-48.7-0.4c-12.6,0.3-25.2,0.6-37.8,0.5
	 c-6.4,0-6.7-0.1-6.5-6.5c0.5-14.4-4-15.6-15.6-15.3c-17.4,0.4-34.9,0.1-52.3,0.1c-9.4,0-11.8,2.6-11.9,12.2v1.5c0,7.9,0,8-7.9,8
	 c-16.7,0-33.4,0-50.1,0c-7.3,0-7.4-0.1-7.4-7.3c0-12.3-0.2-24.7,0.1-37.1c0.7-28.3,21.4-52.6,49.2-57.8c2.6-0.5,5.3-0.7,7.9-0.7
	 c9.5-0.1,18.9,0,28.4,0v0c10.2,0,20.4,0.1,30.5,0c3.5-0.1,5.7,1,7.5,4.2c3.3,5.9,8.4,9.9,15.4,11c2.3,0.4,4.2,0.9,5.3,4
	 c2.6,7.4,6.9,9.5,14.7,8.6c2.9-0.3,5.6-1.4,8.3-2.6c3-1.4,5.7-1.4,9-0.2c14.6,5.1,31.9-3.4,37.5-17.9c0.6-1.6,1.1-3.2,1.5-4.8
	 c0.5-2.3,1.9-3.2,4.2-3.2c1.2,0,2.4,0,3.6,0c6.9,0,6.9,0,8.4,6.6c3.5,15.4,19.1,26,34.7,22.5c6.8-1.5,12.5-1.1,18.9,1.4
	 c9.5,3.6,15.2,0.8,18.8-8.5c0.9-2.2,2-2.9,4.2-3.1c9.3-0.9,15.9-5.5,19.3-14.3c0.9-2.4,2.5-3.4,4.9-3.3c1.2,0,2.4,0,3.6,0
	 c26.6,0,53.3,0.3,80-0.1c33.3-0.4,58.5,28.3,58.5,58.1c0,12.8,0.1,25.7,0,38.5c0,6.2-0.1,6.3-6,6.3c-17.4,0.1-34.9,0-52.3,0
	 c-5.4,0-5.7-0.5-5.9-5.8c0-1.7,0-3.4,0-5.1C700.2,721.9,697.5,719.1,689.9,718.9z M377.6,754.5c-0.4,0-0.7,0-1,0h-2.1
	 c-1,0-1.9-0.8-1.9-1.8v-14.4c0-1,0.8-1.9,1.9-1.9h42.4c1,0,1.8,0.8,1.8,1.9v14.4c0,1-0.8,1.8-1.8,1.8H377.6z M403,780.8
	 c-0.2,4.1-4.2,7.8-8.2,7.7c-4.1-0.1-7.5-4-7.3-8.3c0.1-4.6,3.4-7.7,8-7.5C399.8,772.9,403.2,776.5,403,780.8z M295.2,772.5
	 c-3.8,0.1-5.1-1.3-5-5c0.3-12.1-1.1-10.1,9.9-10.2c16.7-0.1,33.4-0.1,50.1,0c5.5,0,5.6,0.1,5.6,5.7c0,10.8,1.3,9.5-9,9.6
	 c-8,0.1-16,0-24,0c0,0,0,0,0,0C313.6,772.5,304.3,772.3,295.2,772.5z M471.8,635.4c-0.2-0.5-0.6-0.9-1.1-1.3
	 c-1.9-1.7-4.1-1.5-6.1-0.3c-2.1,1.2-4,2.7-6.5,4.5c-0.5,0.3-0.9,0.7-1.5,1c0.1-0.7,0.2-1.3,0.2-1.9c0.1-1,0.3-1.8,0.3-2.5
	 c0-0.3,0-0.6,0-0.9c-0.5-4.3-4.1-6.3-7.9-4.4c-0.7,0.3-1.4,0.7-2,1.2c-0.8,0.6-1.5,1.2-1.9,2c-0.2,0.3-0.3,0.6-0.5,0.8v0
	 c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.6,0.5
	 c-0.4,0.3-0.9,0.5-1.3,0.7c-0.4,0.2-0.9,0.3-1.4,0.4c-2.4,0.5-5.1-0.1-7.7-0.4c-3.1-0.4-4.6-2.6-4.8-5.6c-0.3-3.2,0.8-5.9,2.9-7.6
	 c1.9-1.5,4.5-2.2,7.2-1.5c0.2,0.1,0.4,0.1,0.7,0.2c14.6,4.6,29.5,7.5,44.7,8.8c5.4,0.5,6.8,2.5,5,7.5c-1.6,4.7-6.2,8-11,8.7
	 c-0.9,0.1-1.9,0.2-2.9,0.1c-0.6,0-1.2-0.1-1.8-0.3c0,0,0,0,0,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.4
	 c-0.4-0.2-0.7-0.4-0.9-0.7c0,0-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0-0.4c0,0,0,0,0,0
	 c0.1-0.4,0.3-0.8,0.6-1.3c0.2-0.3,0.5-0.7,0.7-1.1c0.2-0.3,0.4-0.7,0.6-1.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.7,0.3-1.1
	 C472.4,637.7,472.4,636.5,471.8,635.4z M571,639.9c-2.3-0.5-3.7-2.1-5.2-3.3c-0.3-0.2-0.6-0.5-1-0.7c-1.9-1.1-3.9-1.8-5.8-1
	 c-0.5,0.2-1,0.6-1.6,1.1c-0.4,0.4-0.7,0.8-0.9,1.2c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.9-0.1,1.8,0.2,2.7c0.2,0.6,0.5,1.2,0.8,1.9
	 c0.9,1.7,1.2,3,0.8,3.9c-0.1,0.3-0.2,0.5-0.4,0.7c-0.4,0.5-1.2,0.9-2.1,1.2c-0.2,0.1-0.4,0.2-0.7,0.3c-2.1,0.7-4.4,0.7-6.5,0.1
	 c0,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
	 c0,0-0.1,0-0.1,0h0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.2
	 c0,0,0,0-0.1,0c0,0,0,0-0.1,0h0c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.3-0.2-0.6-0.3-0.9-0.5
	 c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1
	 c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.2-0.2
	 c-0.2-0.2-0.5-0.5-0.7-0.8c-0.1-0.1-0.2-0.2-0.3-0.4c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c-0.2-0.2-0.3-0.4-0.5-0.7
	 c0,0-0.1-0.1-0.1-0.2c-0.2-0.3-0.4-0.6-0.6-1c0,0-0.1-0.1-0.1-0.2c-0.3-0.6-0.6-1.3-0.8-1.9c-0.5-1.6-0.7-3-0.7-4.3v0c0,0,0,0,0,0
	 c0-2.2,0.8-3.8,2.6-4.8c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.5-0.5,2.4-0.6c0.4-0.1,0.9-0.1,1.4-0.2c3.7-0.4,7.5-0.8,11.2-1.3
	 c11.9-1.6,23.6-4,35.1-7.4c3.2-1,7,1.4,9.1,5.1c0.1,0.2,0.3,0.5,0.4,0.8c0,0,0,0.1,0.1,0.1c0.4,1,0.8,2.1,1,3.1
	 c0.8,4-1.1,7.1-4.9,8.2c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0-0.1,0c-0.5,0.1-1.1,0.2-1.7,0.3c-2,0.2-4,0.3-5.9,0
	 c-2.4-0.4-4.5-1.6-6-4.5c-0.6-1.2-2-2.2-3.2-2.9c-2.2-1.4-4.3-1.5-6-0.6c-0.8,0.4-1.5,1.2-2,2.1c-0.4,0.8-0.7,1.7-0.8,2.8
	 c0,0.4-0.1,0.8-0.1,1.3v1.2C570.9,638.8,571,639.4,571,639.9z M329,462.6c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.3-0.2-0.4
	 c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.2-0.6-0.4-0.9-0.6c-0.5-0.3-1-0.5-1.5-0.8h0
	 c-1.5-0.7-3.1-1.4-4.6-2.2c-1.5-0.8-3-1.6-4.4-2.5c-0.6-0.4-1.2-0.8-1.8-1.3c-4.3-3.2-6.9-6.3-9.1-11.3c-0.6-1.4-1.2-3-1.8-4.9
	 c5,1.7,9.8,3.1,14.6,4c3.2,0.5,6.4,0.9,9.5,0.9c1,0,2,0,3,0c2.6-0.1,3.5,1.2,3.1,3.6c-0.2,1.3-0.4,2.5-0.6,3.8
	 c-0.5,4-0.7,8-0.8,12.1c0,1.4,0,2.9,0,4.3c0,6.8,0,13.5,0,20.2c-8.8,0.3-20.2-7.1-25.2-16.2c-0.1-0.2-0.2-0.4-0.3-0.7
	 c-0.2-0.4-0.4-0.8-0.6-1.2c0.5,0,0.9,0.1,1.4,0.1c3.2,0.5,5.9,2.2,9,2.9c1.1,0.2,2.2,0.4,3.2,0.5c1.8,0.1,3.5-0.1,5.2-0.9
	 c2.5-1.1,3.9-3,4.3-5.5c0-0.2,0.1-0.4,0.1-0.6C329.2,463.7,329.2,463.1,329,462.6z M325.9,417.1c-1-1.8-1.8-3.7-2.6-5.7
	 c-0.5-1.3-1-2.7-1.5-4.2c0.6,0.3,1.2,0.5,1.7,0.8c5.3,2.4,9.6,4.5,13.9,6.4c0.5,0.2,0.9,0.4,1.2,0.7c0.8,0.6,1.2,1.3,1.1,2.2v0.2
	 c-0.1,0.4-0.2,0.9-0.4,1.5c-0.9,2.1-1.8,4.2-2.7,6.7c-0.2,0.7-0.5,1.4-0.8,2.1C331.3,425,328.3,421.5,325.9,417.1z M290.4,314.4
	 c8.5-9.3,17.6-18.2,27.4-26.7c4.3-3.7,8.6-7.4,13.1-11c-4.8,14.4-8.1,28.6-9.6,42.7c-1.5,13.1-1.5,26.2,0,39.2
	 c1.4,12.3,4.1,24.6,8.2,37.1c-0.5-0.2-1-0.4-1.5-0.6c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.3c-3.3-1.5-5.8-3.4-8.4-5
	 c-1.7-1-3.4-1.9-5.2-2.2c-1.4-0.2-2.8,0.1-4.3,1.1c-1.2,0.7-1.9,1.6-2.4,2.6c-0.9,1.9-0.8,4.2-0.5,6.5c0.6,4.7,1.7,9.3,2.9,14.1
	 c0.2,0.6,0.4,1.3,0.6,2c0.6,2.3,1.4,4.7,2.1,7.1c-2.4,0.1-4.4-0.4-6.4-1c-1-0.3-2-0.7-2.9-1c-1-0.3-1.9-0.7-2.9-0.9
	 c-2.6-0.7-5.2-1.6-7.8-1.6c-0.3,0-0.6,0-0.9,0c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0.1-1,0.3-1.5,0.5
	 c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0.1
	 c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
	 c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.5,0-0.7,0c-0.3,0-0.6-0.2-0.9-0.4c-0.4-0.2-0.8-0.5-1.2-0.8c-2.3-1.7-3.9-4-5.3-6.5
	 c-0.3-0.5-0.6-1-0.8-1.5c-2.3-4.5-4.5-9.1-6.9-13.6c-0.3-0.6-0.7-1.3-1-1.9c-1.1-1.8-2.3-3.5-3.6-5c-0.3-0.4-0.7-0.8-1-1.1
	 c-0.3-0.3-0.7-0.7-1.1-1c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1
	 c-0.1-0.1-0.1-0.1-0.2-0.2h0c-0.3-0.2-0.6-0.5-0.9-0.7c-0.3-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.6-0.4-0.9-0.6c-0.5-0.3-1-0.6-1.5-1
	 c-0.1-0.1-0.3-0.2-0.5-0.2c-0.5-0.3-1.1-0.6-1.6-0.8c-0.5-0.2-1.1-0.5-1.6-0.7c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.7-0.2-1-0.3
	 c0,0-0.1,0-0.2-0.1c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0c-0.6-0.2-1.3-0.3-1.9-0.5c-0.2,0-0.4-0.1-0.6-0.2
	 c-0.7-0.2-1.5-0.5-2.6-1c-0.3-0.1-0.7-0.2-1-0.4C257.3,355,272.5,333.8,290.4,314.4z M267.5,800.9c-11.3,0-10.1,1.4-10.2-9.9
	 c0-7.5,0-14.9,0-22.4h0.1c0-8.7-0.1-17.4,0-26.1c0.1-5.2,0.3-5.5,5.2-5.5c11.8,0,10.7-1.4,10.8,10.1c0.1,15.9,0.1,31.9,0.1,47.8
	 C273.5,800.8,273.4,800.9,267.5,800.9z M273.9,686.6c-1.2,9-0.7,17.9-0.9,26.8c-0.1,5.6-0.1,5.7-5.5,5.8c-2.4,0-4.9-0.3-7.2,0.1
	 c-6.9,1.2-10.6-1.5-14.1-7.8c-20.6-37.6-33.4-77.6-39.1-120.1c-3.2-24.1-4.4-48.2-2.7-72.4c3-42.7,13.2-83.5,31.3-122.3
	 c2.4-5.1,2.4-4.8,7.6-3.8c7,1.3,11.8,5.1,14.7,11.6c1.6,3.5,3.3,7,5,10.5c4,8.1,9.8,14.5,17.9,18.5c3.5,1.8,5.6,4,6.6,7.9
	 c0.8,3.2,2.4,6.3,3.6,9.4c-8.4,4.3-10,7.9-6.2,16.4c8.5,19.1,22.8,31.7,43.1,36.8c5.3,1.3,7.9,3.1,9.3,8.8
	 c7.2,29.6,21.1,55.5,43.4,76.6c10.4,9.9,22.4,17.7,35.3,24.1c-1.1,5.4-4.2,6.9-9.3,6.8c-19.1-0.2-38.3,0.2-57.4-0.1
	 C311.4,619.2,278.7,650.3,273.9,686.6z M764.2,847c-0.7,14.5-8,26.2-18.9,35.5c-11.1,9.5-24.2,13.9-38.8,13.9
	 c-119.6,0-239.2,0.1-358.9-0.1c-24.3,0-42.6-11.3-52.9-34c-2.8-6.1-4.1-12.5-4.3-19c-0.3-10.3-0.5-20.7-0.2-31
	 c0.1-3,0.2-5.9,0.4-8.9c-1-16.6-0.9-13.1,12.4-13.3c15.2-0.2,30.5-0.1,45.8,0c6.7,0,6.8,0.1,6.8,7c0,3.4,0,6.8,0,10.2
	 c0,0.6,0,1.1,0.1,1.7c0,0.5,0.1,1,0.1,1.4v0.1c0,0.3,0.1,0.6,0.1,0.9c0.1,0.4,0.1,0.7,0.2,1c0.1,0.4,0.2,0.8,0.4,1.2
	 c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.4,0.4,0.7,0.6
	 c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.6,0.2,1,0.3c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.1,1.2,0.2
	 c0.4,0,0.9,0.1,1.3,0.1c0.4,0,0.9,0,1.4,0c9.7,0.1,19.4,0,29.1,0.1c7,0,14-0.2,21.1,0.5c3.6,0.3,7.3,0,10.9-0.5
	 c0.4-0.1,0.9-0.1,1.3-0.3c0.3-0.1,0.5-0.1,0.8-0.2c0.2,0,0.3-0.1,0.5-0.2h0c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0
	 c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.2l0,0c0.3-0.2,0.6-0.4,0.8-0.6c0.3-0.2,0.6-0.5,0.8-0.8c0,0,0,0,0,0
	 c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0,0,0,0,0-0.1c0.1-0.4,0.3-0.8,0.4-1.2
	 c0.1-0.2,0.1-0.5,0.2-0.8v0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.4,0.1-0.9,0.1-1.4c0.2-4.8,0.2-9.7,0-14.5
	 c-0.1-3.3,1.2-5,4.7-4.8c1.5,0.1,2.9,0,4.4,0c54.3,0,108.5,0,162.8,0c2,0,3.9,0,5.8,0c7.1,0.1,7.1,0.1,7.2,7.3
	 c0,3.9-0.1,7.7,0.1,11.6c0,0.6,0.1,1.2,0.2,1.7c0,0.2,0.1,0.5,0.1,0.7c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.1,0.6
	 c0,0,0,0,0,0s0,0,0,0.1c0.1,0.4,0.2,0.8,0.4,1.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5
	 c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.4
	 c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.3,0.2,0.5,0.3,0.9,0.5h0c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.1
	 c0.1,0,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.4,0.1,0.8,0.2,1.3,0.2c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0.1
	 c0.3,0,0.6,0,1,0c20.6,0.2,41.2,0.1,61.8,0c0.4,0,0.7,0,1.1,0c0.2,0,0.4,0,0.6-0.1c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.2
	 c0.4-0.1,0.8-0.2,1.2-0.4c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.3,0.9-0.5c0,0,0.1,0,0.2-0.1c0.2-0.1,0.4-0.3,0.6-0.5
	 c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.5,0.6-0.8c0,0,0-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9
	 c0-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.7c0-0.2,0.1-0.5,0.1-0.7
	 c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.7,0.1-1c0.2-4.6,0.2-9.2,0-13.8c-0.1-3.6,1-5.4,4.9-5.3
	 c18.2,0.1,36.3,0.1,54.5,0c3.4,0,5,1.3,4.7,4.7c-0.1,1.9-0.2,3.9-0.3,5.8c0,3.9,0,7.8,0.1,11.7C764.6,823.8,764.8,835.4,764.2,847
	 z M765.8,771.1c0,1.2-0.9,2.1-2.1,2.1h-62c-1.2,0-2.1-0.9-2.1-2.1v-12.5c0-1.2,0.9-2.1,2.1-2.1h62c1.2,0,2.1,0.9,2.1,2.1V771.1z
		M776.5,667c-0.2-0.5-0.5-1.1-0.7-1.6c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
	 c-0.2-0.4-0.3-0.7-0.5-1.1c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.5c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.3-0.3-0.6-0.4-0.8
	 c-0.3-0.7-0.7-1.4-1.1-2c-0.5-0.8-1-1.7-1.4-2.4c-1-1.6-2-3.2-3.1-4.7c-2.2-3.1-4.6-6-7.2-8.7c-0.6-0.7-1.3-1.4-2-2
	 c-0.5-0.5-1.1-1-1.6-1.5c-0.6-0.5-1.1-1-1.7-1.5c-0.5-0.5-1.1-1-1.7-1.4c-0.6-0.5-1.2-1-1.8-1.4c-0.5-0.4-1-0.8-1.5-1.1
	 c-1-0.7-2-1.4-3-2.1c-0.6-0.4-1.3-0.8-2-1.3h0c0.3-0.3,0.6-0.7,0.9-1c0.4-0.5,0.9-0.9,1.3-1.4s0.9-0.9,1.4-1.3
	 c4.3-3.8,9.2-5.7,14.5-5.7h0.4c2.3,0,4.7,0.4,7.1,1.2c10.3,3.2,15.9,10.5,17.5,21c0.2,1.4,0.3,2.8,0.2,4.1c0,0.4,0,0.7-0.1,1.1
	 c-0.1,0.7-0.2,1.4-0.3,2.2c-0.3,1.4-0.7,2.8-1.3,4.2c-0.1,0.3-0.3,0.7-0.4,1c-0.6,1.3-1.4,2.6-2.3,3.8c-0.4,0.6-0.9,1.2-1.4,1.7
	 c-0.5,0.6-1.1,1.1-1.6,1.6C779.9,664.8,778.3,666,776.5,667C776.5,667,776.5,667,776.5,667z M792.3,800.6c-10,1-10.6,0.5-10.6-9.5
	 v-22.4c0,0,0,0,0,0c0-8.9,0.1-17.8-0.1-26.8c-0.1-3.8,1.4-5.3,5.1-4.9c1.4,0.1,2.9,0.1,4.3,0c3.4-0.3,4.8,1.1,4.8,4.6
	 c0,18.3,0,36.7,0,55C796,799,795.2,800.4,792.3,800.6z M819.3,733c-1.3,2-2.3,4.2-5.3,6.4c-0.1-1.9-0.2-3-0.3-4.2
	 c-1.1-9.7-6.8-15.3-16.6-15.9c-3.6-0.2-7.3-0.2-10.9,0c-3.1,0.1-4.3-1.1-4.2-4.2c0.1-6.8,0.1-13.6-0.1-20.4
	 c-0.1-4.3-2.1-9.2-0.7-12.8c1.3-3.3,6.2-4.9,9.2-7.7c15.3-14.1,16-38.1,1.7-53.6c-14.2-15.3-38.2-16.3-53.6-2.1
	 c-1.1,1-2.2,2-3,3.1c-1.7,2.3-3.4,2.8-6.2,1.8c-8.6-3.1-17.5-3.4-26.5-3.4c-26.4,0.1-52.8-0.1-79.2,0.1c-5.2,0.1-9-1-10.7-6.4
	 c0.7-1.9,2.8-2.2,4.4-3.1c35.8-19.2,57.9-49.1,68.2-88c1-3.7,2.3-5.7,5.9-7.3c15-6.5,25.7-17.8,33.6-31.9c1.6-3,2.9-6.1,5.1-8.8
	 c4.3-5.5,3.4-8.4-3.1-11.7c-0.6-0.3-1.2-0.8-1.9-1.3c3.5-4.8,6.9-9.4,10.1-14.1c1.8-2.6,3.3-5.4,4.6-8.2c3.4-7.5,0-12.5-8.2-12.1
	 c-4.1,0.2-8.2,1.1-12.2,1.8c-3.1,0.5-6.1,1.1-9.2,1.6c-1.1-2.3,0.8-3.3,1.6-4.5c4-6.1,7-12.7,9.7-19.5c1.3-3.3,2.2-6.6-0.6-9.5
	 c-2.6-2.8-5.7-2-8.8-0.9c-9,2.9-18,5.6-27.5,6.7c-1.4,0.2-2.9,0.4-4.3,0.5c-14,1.1-16.7-0.4-23.8-12.3c-1.9-3.1-0.9-6.1-0.5-9.2
	 c3.9-27.3,2-54.1-6.2-80.4c-9.9-31.4-26.6-58.5-50.5-81.2c-1.4-1.3-2.8-2.7-4.1-4.1c-0.2-0.3-0.2-0.9-0.3-1.7
	 c8.8,0.6,17.1,2.9,25.6,4.7c5,1,9.8,2.5,14.7,3.8c5.2,1.4,5.1,1.5,3,6c-0.8,1.8-1.8,3.5-2.4,5.3c-3.3,8.9-0.5,17.1,7.7,21.8
	 c3.3,1.9,7,3.5,10.6,4.7c7.6,2.5,10,9.3,13,15.6c1.1,2.3-0.4,4.7-1.6,6.9c-2.4,4.5-5.1,8.8-7.2,13.4c-4.8,10.4-2.8,18.6,6.1,25.8
	 c3.2,2.6,6.8,4.8,10.5,6.5c12.6,5.7,24.4,12.9,35.1,21.7c0.7,0.6,1.6,1.1,2.4,1.7c8.6,6.8,17.9,8.4,27.8,3.5
	 c6.5-3.2,12-2.3,17.5,2c6.5,5,13.4,9.5,20.7,13.4c8,4.3,9.6,9.7,6.3,18.3c-1.1,2.7-2.3,5.3-3.4,8c-2.9,7.4-4.1,14.9-2.2,22.7
	 c1.5,6.1,2.9,12.2,4.8,18.3c1.7,5.5,4.9,9.9,9.8,13.2c6.3,4.1,12.4,8.4,18.8,12.5c13,8.4,22.3,7,32.5-5c5.1-6,4.8-5.5,12-1.6
	 c7.9,4.2,11.7,10,13.2,18.9C886.2,568.7,870.5,654.3,819.3,733z"/>
 <path class="st11" d="M346.7,772.5c-8,0.1-16,0-24,0c0,0,0,0,0,0c-9.2,0-18.4-0.1-27.6,0c-3.8,0.1-5.1-1.3-5-5
	 c0.3-12.1-1.1-10.1,9.9-10.2c16.7-0.1,33.4-0.1,50.1,0c5.5,0,5.6,0.1,5.6,5.7C355.7,773.6,357,772.4,346.7,772.5z"/>
 <path class="st11" d="M765.8,758.6v12.5c0,1.2-0.9,2.1-2.1,2.1h-62c-1.2,0-2.1-0.9-2.1-2.1v-12.5c0-1.2,0.9-2.1,2.1-2.1h62
	 C764.9,756.5,765.8,757.4,765.8,758.6z"/>
 <path class="st11" d="M622.2,758.6v12.5c0,1.2-0.9,2.1-2.1,2.1H436.8c-1.1,0-2.1-0.9-2.1-2.1v-12.5c0-1.2,0.9-2.1,2.1-2.1h183.3
	 C621.3,756.5,622.2,757.4,622.2,758.6z"/>
 <path class="st0" d="M468,292.2c-13.7,0-24.8,11.1-24.8,24.8c0,13.7,11.1,24.7,24.8,24.7c13.7,0,24.7-11.1,24.7-24.7
	 C492.7,303.3,481.6,292.2,468,292.2z M457.9,315c-4.9,0-9-4-9-9c0-2.1,0.7-4.1,2-5.6c1.4-1.7,3.3-2.8,5.5-3.2c0,0,0,0,0,0
	 c0.5-0.1,1-0.1,1.5-0.1c4.9,0,9,4,9,9S462.9,315,457.9,315z"/>
 <path class="st0" d="M367.6,285.4c-0.4,0-0.7,0-1.1,0c-0.6,0-1.3,0.1-1.9,0.2c-0.3,0-0.6,0.1-1,0.2c-8.8,1.8-15.3,9.6-15.3,18.9
	 c0,4.4,1.5,8.4,3.9,11.7c3.5,4.7,9.1,7.7,15.4,7.7c10.7,0,19.4-8.7,19.4-19.4S378.2,285.4,367.6,285.4z M370.4,304.4
	 c-4.3,0-7.8-3.5-7.8-7.8c0-4.3,3.5-7.8,7.8-7.8c4.3,0,7.8,3.5,7.8,7.8C378.2,300.9,374.7,304.4,370.4,304.4z"/>
 <path class="st15" d="M457.9,315c5,0,9-4,9-9c0-5-4-9-9-9c-0.5,0-1,0-1.5,0.1c0,0,0,0,0,0c-2.2,0.4-4.1,1.5-5.5,3.2
	 c-1.2,1.5-2,3.5-2,5.6C448.9,311,453,315,457.9,315z"/>
 <circle class="st15" cx="370.4" cy="296.6" r="7.8"/>
 <path class="st10" d="M667.4,780.8c0,0.8-0.2,1.6-0.5,2.4c-0.9,2.3-3,4.2-5.4,5c-0.7,0.2-1.5,0.4-2.3,0.3c-4.1-0.1-7.5-4-7.3-8.3
	 c0.1-3.6,2.1-6.2,5.2-7.1c0.9-0.2,1.8-0.4,2.8-0.4c3.5,0.1,6.4,2.5,7.2,5.7C667.4,779.2,667.5,780,667.4,780.8z"/>
 <path class="st0" d="M678.7,649.5h-39.8c-0.2,0-0.3,0-0.5,0h-4.9c-3.7,0-6.7,2.7-7.2,6.3c0,0,0,0.1,0,0.1c0,0.3-0.1,0.6-0.1,1
	 c0,0.1,0,0.1,0,0.2c0.1,2.7,1.6,5.1,3.9,6.3c1,0.5,2.2,0.9,3.4,0.9h45.1c4.1,0,7.3-3.3,7.3-7.3C686,652.8,682.8,649.5,678.7,649.5
	 z"/>
</g>
<path class="st0" d="M273.7,885.9C274,885.8,274.1,885.8,273.7,885.9L273.7,885.9z"/>
<path class="st0" d="M352.4,926.4c-0.4-1.3-0.8-2.7-1.1-4.1c-0.3-1.5-0.6-3-0.8-4.5c0-0.2,0-0.3,0-0.5c0-0.3,0-0.6-0.1-0.9
 c0,0,0,0,0,0.1c0.1,0.4,0.2,0.9,0.3,1.3C351.3,920.9,351.8,923.8,352.4,926.4z"/>
</g>
</svg>

    </ng-template>
  `,
})
export class IconLogoComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;
	constructor(private viewContainerRef: ViewContainerRef) { }
	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}
