import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EtouristCheckResponse } from "../models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { ETouristCity, ETouristMunicipality, ETouristStreet } from "../models/etourist";

@Injectable({
  providedIn: "root",
})
export class ETouristService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  checkAvailability(): Observable<EtouristCheckResponse> {
    return this.http.get<EtouristCheckResponse>(`${this.apiUrl}/api/ETourist/credentials/check`);
  }

  getMunicipality(): Observable<ETouristMunicipality[]> {
    return this.http.get<ETouristMunicipality[]>(`${this.apiUrl}/api/ETourist/municipalities`);
  }

  getCities(municipalitiesId: number): Observable<ETouristCity[]> {
    return this.http.get<ETouristCity[]>(`${this.apiUrl}/api/ETourist/localities/${municipalitiesId}`);
  }

  getStreets(cityId: number): Observable<ETouristStreet[]> {
    return this.http.get<ETouristStreet[]>(`${this.apiUrl}/api/ETourist/streets/${cityId}`);
  }
}
