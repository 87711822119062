import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Company, CompanyStatistics } from "../models/company";
import { GenericResponse } from "src/app/shared/models/generics";
import { Sale } from "../../sales/models/sale";

@Injectable({
  providedIn: "root",
})
export class CompaniesService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getCompaniesRecords(id: number, pageNumber: number, pageSize: number, filterType: string) {
    const apiUrl = `${this.apiUrl}/api/companies/plans/${id}`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("from", "2021-03-24").set("filterType", filterType);
    // .set('sortProperty', 'numberOfPassangers')
    // .set('sortType', 'Asc')

    return this.http.get<Sale[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Sale> = {
          body: response.body ?? [],
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }

  getCompanyStatistics(id: number) {
    return this.http.get<CompanyStatistics>(`${this.apiUrl}/api/companies/statistics/${id}`);
  }

  addCompany(data: any) {
    return this.http.post<Company>(`${this.apiUrl}/api/companies`, data);
  }

  updateCompany(id: number, data: any) {
    return this.http.put<Company>(`${this.apiUrl}/api/companies/${id}`, data);
  }

  deleteCompany(id: number) {
    return this.http.delete(`${this.apiUrl}/api/companies/${id}`, { responseType: "text" });
  }

  mergeCompany(rootId: number, listOfIds: number[]): Observable<any> {
    const url = `${this.apiUrl}/api/Companies/merge`;
    const body = { rootId, listOfIds };
    return this.http.post(url, body, { responseType: "text" });
  }
  getCompany() {
    return this.http.get<Company[]>(`${this.apiUrl}/api/companies`);
  }
}
