import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "src/app/auth/model/user";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  updatePassword(data: any) {
    return this.http.post(`${this.apiUrl}/api/users/updatepassword`, data);
  }

  getUserData() {
    return this.http.get<User>(`${this.apiUrl}/api/users/me`);
  }

  updateUser(id: number, data: any) {
    return this.http.put<User>(`${this.apiUrl}/api/users/${id}`, data);
  }
}
