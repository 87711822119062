import { Component, OnInit } from "@angular/core";
import { colDef } from "@bhplugin/ng-datatable";
import { CustomerPlan } from "../models/customer-plan";
import { CustomerStats } from "../models/customer-stat";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomersService } from "../services/customers.service";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-customer-stats",
  templateUrl: "./customer.stats.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class CustomerStatsComponent implements OnInit {
	isShowingSidebar = false;
  protected id!: number;
  protected customer!: CustomerStats;
  fromDate!: string;
  toDate!: string;
	public entityname = "customers";
  public customerpaginated: CustomerPlan[] = [];
  public pageSize = 10;
  public pageNumber = 1;
  public totalRows = 1;
  public from!: Date;
  public to!: Date | undefined;
  public filterType = "None";

  constructor(private customerStatsService: CustomersService, private route: ActivatedRoute, private router: Router, public translate: TranslateService) {}

  ngOnInit() {
    this.fromDate = "2020-01-01";
    this.toDate = "2024-12-31";
    this.to = new Date(2024, 11, 31);
    this.from = new Date(2020, 0, 1); 

    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchCustomerStats();
        this.fetchCustomer();
      }
    });
  }
  changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.fetchCustomerStats();
  }
  fetchCustomerStats() {
    this.customerStatsService.getCustomerStats(this.id, this.fromDate, this.toDate).subscribe((data: CustomerStats) => {
      this.customer = data;
    });
  }
  private fetchCustomer() {
    this.customerStatsService.getCustomerWithHeaders(this.id, this.pageNumber, this.pageSize, this.filterType, this.from, this.to).subscribe((response) => {
      this.customerpaginated = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
  }
  search = "";
	cols = [
		{ field: "customerFullName", title: this.translate.instant("workerinfo.customerFullName"), hide: false },
		{ field: "company.name", title: this.translate.instant("workerinfo.company"), hide: false },
		{ field: "destination.description", title: this.translate.instant("workerinfo.destination"), hide: false },
		{ field: "departure.description", title: this.translate.instant("workerinfo.departure"), hide: false },
		{ field: "payed_in_payments", title: this.translate.instant("workerinfo.payed_in_payments"), hide: false },
		{ field: "entity_name", title: this.translate.instant("workerinfo.entity_name"), hide: false },
		{ field: "neto", title: this.translate.instant("workerinfo.neto"), hide: false },
		{ field: "bruto", title: this.translate.instant("workerinfo.bruto"), hide: false },
		{ field: "status", title: this.translate.instant("workerinfo.status"), hide: false }
	];
  updateColumn(col: colDef) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }
}
