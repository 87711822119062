import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
})
export class MergeComponent<T> {
  @Input() items: T[] = [];
  @Input() selectedItemId!: number;
  @Output() merge = new EventEmitter<number[]>();
  @Input() bindLabel!: string;

  selectedItems: T[] = [];
  isLoading = false;
  maxSelection = 3;

  onMerge() {
    const selectedIds = this.selectedItems.map(item => (item as any).id); 
    if (selectedIds.length > 0 && this.selectedItemId) {
      this.isLoading = true;
      this.merge.emit(selectedIds);
      this.isLoading = false;
    } else {
      console.warn('Please select items to merge');
    }
  }
}
