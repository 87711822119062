import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html'
})
export class SettingsListComponent {

	typeList = ["room-type", "room-content", "arrangement", "service","other-services","payment-type"];
	titleList = ["settings.room_type",  "settings.room_content","settings.arrangements","settings.service","settings.other_service","settings.payment_type"];
}

