import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class PasswordCustomValidators {
  static checkPasswords(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const password = formGroup.get("password");
      const confirmPassword = formGroup.get("confirm_password");

      if (!password || !confirmPassword) {
        return null;
      }

      if (password.value !== confirmPassword.value || confirmPassword.value === "") {
        confirmPassword.setErrors({ notSame: true });
        return { notSame: true };
      } else {
        confirmPassword.setErrors(null);
      }

      return null;
    };
  }

  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const password = control.value;
    if (!password) {
      return null;
    }

    if (!passwordPattern.test(password)) {
      return { invalidPassword: true };
    }

    return null;
  }
}
