import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-hotel",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st1"
            d="M19.5,16.8c0,0.5-0.3,0.8-0.8,0.8h-1.9c-0.5,0-0.8-0.3-0.8-0.8c0-0.4,0-0.8,0-1.1c0,0,0,0,0,0H4v1.1
		 c0,0.6-0.3,0.9-0.9,0.9c-0.6,0-1.2,0-1.7,0c-0.5,0-0.8-0.2-0.9-0.6V9.5c0-0.1,0-0.2,0.1-0.3c0.2-0.6,0.6-1,1.2-1.2C1.9,8,1.9,8,2,8
		 V7.1c0-0.5,0-1,0-1.4c0-0.8,0.3-1.3,0.9-1.8c0.7-0.5,1.5-0.8,2.4-1C7.2,2.4,9.1,2.3,11,2.4c1.4,0.1,2.8,0.2,4.1,0.6
		 c0.8,0.2,1.5,0.5,2.1,1C17.7,4.4,18,4.9,18,5.6c0,0.8,0,1.6,0,2.4c0.1,0,0.3,0.1,0.4,0.1c0.7,0.3,1.1,0.9,1.1,1.7
		 C19.5,12.1,19.5,14.5,19.5,16.8z"
          />
          <g>
            <path
              class="st0"
              d="M19.5,9.8c0-0.8-0.4-1.3-1.1-1.7C18.2,8.1,18.1,8,18,8c0-0.8,0-1.6,0-2.4c0-0.7-0.3-1.2-0.8-1.6
			 c-0.6-0.5-1.4-0.8-2.1-1c-1.3-0.4-2.7-0.6-4.1-0.6c-1.9-0.1-3.8,0-5.7,0.5c-0.8,0.2-1.7,0.5-2.4,1C2.3,4.3,2,4.9,2,5.7
			 c0,0.5,0,1,0,1.4V8C1.9,8,1.9,8,1.8,8.1c-0.6,0.2-1,0.6-1.2,1.2c0,0.1-0.1,0.2-0.1,0.3V17c0.2,0.4,0.5,0.6,0.9,0.6
			 c0.6,0,1.2,0,1.7,0c0.6,0,0.9-0.3,0.9-0.9v-1.1h11.9c0,0,0,0,0,0c0,0.4,0,0.8,0,1.1c0,0.5,0.3,0.8,0.8,0.8h1.9
			 c0.5,0,0.8-0.3,0.8-0.8C19.5,14.5,19.5,12.1,19.5,9.8z M3.1,4.7c0.4-0.4,0.9-0.6,1.4-0.8c1-0.4,2.1-0.6,3.2-0.7
			 c1.3-0.1,2.7-0.1,4,0c1.4,0.1,2.8,0.3,4.2,0.9c0.4,0.2,0.7,0.4,1,0.7c0.2,0.2,0.3,0.4,0.3,0.7c0,0.8,0,1.5,0,2.3
			 c0,0.1,0,0.1,0,0.2h-0.9V7.4c0-0.8-0.4-1.2-1.2-1.2h-3.1c-0.7,0-1.1,0.5-1.1,1.2V8H9.2V7.4c0-0.7-0.5-1.2-1.2-1.2H4.9
			 c-0.7,0-1.2,0.4-1.2,1.2V8h-1V7.8c0-0.7,0-1.5,0-2.2C2.7,5.2,2.9,4.9,3.1,4.7z M15.6,7.3c0,0.2,0,0.4,0,0.6h-4c0-0.2,0-0.4,0-0.7
			 C11.7,7.1,11.8,7,12,7h3.2C15.5,7,15.6,7.1,15.6,7.3z M8.4,7.4c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0.1H4.5c0-0.3,0-0.5,0-0.7
			 C4.5,7,4.7,7,4.9,7H8C8.3,7,8.4,7.1,8.4,7.4z M3.3,16.9h-2v-1.2h2V16.9z M18.7,16.9h-2v-1.2h2V16.9z M18.7,12.9h-7.1
			 c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.1,0,0.2,0h7.1v1.2H1.3v-1.2h7.1c0.4,0,0.5-0.1,0.5-0.4
			 c0-0.2-0.2-0.4-0.5-0.4H1.3v-0.2c0-0.9,0-1.9,0-2.8c0-0.8,0.6-1.2,1.2-1.2c3.6,0,7.2,0,10.9,0c1.4,0,2.8,0,4.3,0
			 c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.4,0.4,0.7C18.7,10.7,18.7,11.8,18.7,12.9z"
            />
            <path
              class="st1"
              d="M10.4,13.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
			 C10.2,12.9,10.4,13.1,10.4,13.3z"
            />
          </g>
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuHotelComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
