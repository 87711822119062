import { setFrom, setFromTo } from './../../store/datepicker/datepicker.actions';
// date.service.ts

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private store: Store<any>) {}

  setFromDate(from: Date) {
    this.store.dispatch(setFrom({ from }));
  }

  setFromToDate(from: Date, to: Date) {
    this.store.dispatch(setFromTo({ from, to }));
  }
}
