import { Store } from "@ngrx/store";
import { Ticket } from "../models/ticket";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Customer } from "../../customers/models/customer";
import { TicketsService } from "../services/tickets.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-ticket-details",
  templateUrl: "./ticket.details.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class TicketDetailsComponent implements OnInit {
  isShowingSidebar = false;
  protected id!: number;
  public planId!: number;
  protected ticket!: Ticket;
  protected passangers: Customer[] = [];
  public entityname = "Tickets";
  @Input() defaultTicket: Ticket = {} as Ticket;
  @Output() submitTicket: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchTicketData();
      this.fetchPassangers();
    });
  }
  constructor(private ticketService: TicketsService, public store: Store<AppState>, public translate: TranslateService, public fb: FormBuilder, private route: ActivatedRoute) {}
  fetchTicketData() {
    this.ticketService.getTicketData(this.id).subscribe((response) => {
      this.ticket = response;
      this.planId = response.planId;
    });
  }
  fetchPassangers() {
    this.ticketService.getPassangersData(this.id).subscribe((response: any) => {
      this.passangers = response;
    });
  }
}
