import * as moment from "moment";
import { Sale } from "../../sales/models/sale";
import { Component, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Passanger } from "../../tickets/models/ticket";
import { ActivatedRoute, Router } from "@angular/router";
import { Transfer } from "../../transfers/models/transfer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { GroupTravelsService } from "../services/group-travels.service";

@Component({
  selector: "app-group-travel-passangers",
  templateUrl: "./group-travel-passangers.component.html",
})
export class GroupTravelPassangersComponent {
  @ViewChild("passangerList") passangerList: any;
  @ViewChild("sale") sales: any;
  activeTab = 1;
  passengers!: Passanger[];
  sale!: Sale;
  groupTravelId!: number | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;

  constructor(
    public router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private groupTravelService: GroupTravelsService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id !== null) {
        this.groupTravelId = parseInt(id);
      }
    });
  }

  proceedToNextStage(nextStep: number | undefined = undefined) {
    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }

  async submitGroupTravel() {
    try {
      await this.getPassangersToGroupTravel();
      this.addGroupTravelPassangers();
    } catch (error) {
      showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_adding_passangers"));
      return;
    }
  }

  async getPassangersToGroupTravel() {
    this.passengers = this.passangerList.getPassangers();
    this.sale = this.sales.getSale();
    this.passangerList.checkValidity();
    this.sale.dueDate = moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss");
  }

  fetchGroupTravelData() {
    const data = {
      groupTravelId: this.groupTravelId,
      passangers: this.getPassangerIds(),
      plan: this.sale,
    };
    return data;
  }

  getPassangerIds(): any[] {
    return this.passengers.map((p: any) => {
      return { customerId: p.id, lifeStage: p.lifeStage };
    });
  }

  addGroupTravelPassangers() {
    const data = this.fetchGroupTravelData();
    this.groupTravelService.addGroupTravelPassanger(data).subscribe(
      (response) => {
        showMessage(PopupType.Success, this.translate.instant("group-travels_info.added_passangers_successfully"));
        this.router.navigate([`/dashboard/group-travels/${this.groupTravelId}`]);
      },
      (error) => {
        showMessage(PopupType.Danger, this.translate.instant("group-travels_info.error_while_adding_passangers"));
      }
    );
  }
}