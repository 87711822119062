import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MonthlyCountryStatisticsDto } from "../models/mostpopularcountriesbymonth";

@Component({
  selector: "app-country-chart",
  templateUrl: "./country-chart.component.html",
})
export class CountryChartComponent implements OnChanges {
  @Input() popularCountriesByTicket!: MonthlyCountryStatisticsDto[];
  @Input() popularCountriesByTravel!: MonthlyCountryStatisticsDto[];
  @Input() popularCountriesByGroupTravel!: MonthlyCountryStatisticsDto[];
  @Input() popularCountriesByTransfer!: MonthlyCountryStatisticsDto[];

  radarCharts: { [key: string]: any } = {};

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {

    if (changes["popularCountriesByTicket"]) {
      this.createRadarChart("popularCountriesByTicket", this.popularCountriesByTicket);
    }
    if (changes["popularCountriesByTravel"]) {
      this.createRadarChart("popularCountriesByTravel", this.popularCountriesByTravel);
    }
    if (changes["popularCountriesByGroupTravel"]) {
      this.createRadarChart("popularCountriesByGroupTravel", this.popularCountriesByGroupTravel);
    }
    if (changes["popularCountriesByTransfer"]) {
      this.createRadarChart("popularCountriesByTransfer", this.popularCountriesByTransfer);
    }
  }

  getRandomHexColor(): string {
    const randomInt = Math.floor(Math.random() * 16777215);
    const hexColor = `#${randomInt.toString(16).padStart(6, '0')}`;
    return hexColor;
  }

  createRadarChart(key: string, data: MonthlyCountryStatisticsDto[]) {

    const categories = data.map((d) => this.translate.instant(`months.${d.month}`));

    const reducingElements = data.reduce((acc: any, a) => {
      a.popularCountries.forEach((c) => {
        if (acc[c.country.nameEN]) {
          acc[c.country.nameEN][a.month - 1] = c.numberOfPlans;
        } else {
          acc[c.country.nameEN] = Array(12).fill(0); 
          acc[c.country.nameEN][a.month - 1] = c.numberOfPlans;
        }
      });

      return acc;
    }, {});


 

    const seriesData = Object.keys(reducingElements).map((p) => {
      return {
        name: p,
        data: reducingElements[p],
        color: this.getRandomHexColor(),
      };
    });

    if (seriesData.length === 0) {
      seriesData.push({
        name: "No Data",
        data: Array(12).fill(0),
        color: "#ffffff"
      });
    }


    this.radarCharts[key] = {
      series: seriesData,
      chart: {
        height: 300,
        type: "radar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#4361ee"],
      xaxis: {
        categories: categories,
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: "#e0e6ed",
            connectorColors: "#e0e6ed",
          },
        },
      },
      tooltip: {
        theme: "light",
      },
    };
  }
}
