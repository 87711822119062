import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Customer } from "src/app/domain/customers/models/customer";
import { DocumentService } from "src/app/shared/services/documents.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from "@angular/forms";


@Component({
  selector: "app-file-send",
  templateUrl: "./file-send.component.html",
})
export class FileSendComponent implements OnInit {
  @Input() passangers!: Customer[];
  @Input() sendDocumentFn!: (data: any) => Observable<any>;
  @Input() fileType!: string;
  @Input() title!: string;

  translationKeys: { [key: string]: string } = {
    contract: "popup.contract_sent_successfully",
    voucher: "popup.voucher_sent_successfully",
    payments: "popup.payments_sent_successfully",
  };

  form!: FormGroup;
  emails: string[] = [];
  isLoading = false;

  @Input() messageEn!: string;
  @Input() messageRs!: string;

  @Output() notifyWhenSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public route: ActivatedRoute, public router: Router, private fb: FormBuilder, public translate: TranslateService) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      messageRS: [this.messageRs],
      messageEN: [this.messageEn],
    });
  }

  public emailValidator(control: FormControl) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(control.value)) {
      return {
        invalidEmail: true,
      };
    }
    return null;
  }

  public validators = [this.emailValidator as ValidatorFn];

  public errorMessages = {
    invalidEmail: this.translate.instant("contractsendemail.email_invalid_format"),
  };

  sendDocument() {
    if (this.emails.length === 0 || !this.form.valid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.emails_are_missing_and_or_form_is_invalid"));
      return;
    }
    this.isLoading = true;
    const { content, css } = DocumentService.getContent(this.fileType);
    const combinedContent = `
    	<style>
    		${css}
    	</style>
    	${content}
    `;
    const { messageRS, messageEN } = this.form.getRawValue();
    const htmlFile = DocumentService.stringToHtmlFile(combinedContent, `${this.fileType}.html`);
    const formData = new FormData();

    formData.append("EmailContent", messageRS);
    formData.append("EmailContentEnglish", messageEN);
    formData.append("Content", htmlFile, htmlFile.name);
    formData.append("AdditionalEmails", this.emails.join(","));

    this.sendDocumentFn(formData).subscribe(() => {
      showMessage(PopupType.Success, this.translate.instant(this.translationKeys[this.fileType]));
      this.isLoading = false;
      this.notifyWhenSend.emit();
    });
  }
}
