import { Injectable, ElementRef } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class PositioningService {
	constructor() { }

	setPosition(dropdownPlaceholder: ElementRef, component: any) {
		if (dropdownPlaceholder && component) {
			const rect = dropdownPlaceholder.nativeElement.getBoundingClientRect();
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
			const position = { top: rect.top + scrollTop, left: rect.left + scrollLeft };
			component.setPosition(position);
		}
	}
}
