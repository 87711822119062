<div class="grid grid-cols-1 gap-5 md:grid-cols-1">
  <div class="flex flex-1 items-center justify-center sm:flex-auto sm:justify-between">
    <app-export-table [exportApi]="exportApiUrl" [filterType]="'none'" [columns]="exportCols" [filename]="'Transfer Table Export'" [sortColumn]="'none'"
		[sortDirection]="'none'"
		[search]="'none'"></app-export-table>

    <div class="mr-2 mt-4 flex flex-1 items-center justify-center sm:flex-auto sm:justify-end">
      <span>{{ "group_travel_details.page" | translate }} {{ currentPage }} {{ "group_travel_details.of" | translate }} {{ totalPages() }}</span
      >&nbsp;&nbsp;
      <p>{{ "group_travel_details.previous" | translate }}&nbsp;</p>
      <button
        type="button"
        class="rounded-md bg-[#f4f4f4] p-1 enabled:hover:bg-primary-light disabled:cursor-not-allowed disabled:opacity-60 dark:bg-white-dark/20 enabled:dark:hover:bg-white-dark/30 ltr:mr-3 rtl:ml-3"
        (click)="previousPage()"
        [disabled]="currentPage === 1"
      >
        <icon-caret-down class="h-5 w-5 rotate-90 rtl:-rotate-90" />
      </button>
      <button
        type="button"
        class="rounded-md bg-[#f4f4f4] p-1 enabled:hover:bg-primary-light disabled:cursor-not-allowed disabled:opacity-60 dark:bg-white-dark/20 enabled:dark:hover:bg-white-dark/30"
        (click)="nextPage()"
        [disabled]="currentPage === totalPages()"
      >
        <icon-caret-down class="h-5 w-5 -rotate-90 rtl:rotate-90" />
      </button>
      <p>&nbsp;{{ "group_travel_details.next" | translate }}</p>
    </div>
  </div>
  <div class="panel">
    <table class="table-responsive mb-3 mt-2 table w-full">
      <thead class="border-top: 2px">
        <tr class="ng-star-inserted col-span-1 border border-gray-400 bg-gray-100 sm:col-span-3">
          <th class="w-1 border border-gray-400 sm:col-span-1">{{ "group_travel_details.serial_number" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.full_name" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.date_of_birth" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.address" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.contact" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.life_stage" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.status" | translate }}</th>
          <th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.total_price" | translate }}</th>
					<th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.avans" | translate }}</th>
					<th class="border border-gray-400 sm:col-span-1">{{ "group_travel_details.paymentType" | translate }}</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <ng-container *ngFor="let passangers of paginatedData; index as i">
          <tr class="ng-star-inserted col-span-1 border border-gray-400 bg-gray-50 sm:col-span-3">
            <td class="w-1 border border-gray-400 sm:col-span-1">{{ i + 1 + (passangers.transferPassangers?.length - 1) * i }}</td>
            <td class="border border-gray-400 sm:col-span-1">{{ passangers.customer.name }} {{ passangers.customer.surname }}</td>
            <td class="border border-gray-400 sm:col-span-1">{{ passangers.customer.dateOfBirth | date : "dd.MM.yyyy" }}</td>
            <td class="border border-gray-400 sm:col-span-1">{{ passangers.customer.address }}</td>
            <td class="border border-gray-400 sm:col-span-1">{{ passangers.customer.phoneNumber }}</td>
            <th class="border border-gray-400 sm:col-span-1">Adult</th>
            <th class="border border-gray-400 sm:col-span-1">
              <span class="badge bg-success" [ngClass]="{ 'bg-danger': !passangers?.plan?.plan?.status, 'bg-success': passangers?.plan?.plan?.status }">
                <span *ngIf="passangers?.plan?.plan?.status; else unpaidTemplate">{{ "group_travel_details.paid" | translate }}</span>
                <ng-template #unpaidTemplate>
                  <span>{{ "group_travel_details.not_paid" | translate }}</span>
                </ng-template>
              </span>
            </th>
            <td class="border border-gray-400 sm:col-span-1">{{ passangers.totalPrice }}</td>
						<td class="border border-gray-400 sm:col-span-1">{{ passangers.avans }}</td>
						<td class="border border-gray-400 sm:col-span-1">{{ passangers.paymentType }}</td>
          </tr>
					<!-- ????  -->
          <ng-container *ngFor="let passanger of passangers.transferPassangers; index as j">
            <tr class="ng-star-inserted col-span-1 border border-gray-700 bg-gray-50 sm:col-span-3" [ngClass]="{ 'bg-gray-300': !passanger?.customer?.id }">
              <td class="w-1 border border-gray-400 sm:col-span-1">{{ i + 1 + (passangers.transferPassangers.length - 1) * i + j + 1 }}</td>
              <td class="border border-gray-400 sm:col-span-1">&nbsp;&nbsp;{{ passanger?.customer?.name }} {{ passanger?.customer?.surname }}</td>
              <td class="border border-gray-400 sm:col-span-1">{{ passanger?.customer?.dateOfBirth | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-400 sm:col-span-1">{{ passanger?.customer?.address }}</td>
              <td class="border border-gray-400 sm:col-span-1">{{ passanger?.customer?.phoneNumber }}</td>
              <td class="border border-gray-400 sm:col-span-1">{{ passanger?.lifeStage }}</td>
              <th class="border border-gray-400 sm:col-span-1" colspan="2">{{passanger.totalPrice}} </th>
							<th class="border border-gray-400 sm:col-span-1" colspan="2">{{passanger.avans}}</th>
							<th class="border border-gray-400 sm:col-span-1" colspan="2">{{passanger.paymentType}} </th>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
