import * as moment from "moment";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Contract } from "../../documents/contract/models/contract";
import { ContractService } from "../../documents/contract/services/contract.service";

@Component({
  selector: "app-travel-contract.edit",
  templateUrl: "./travel-contract.edit.component.html",
})
export class TravelContractEditComponent implements OnInit {
  public contract!: Contract;
  public passangers!: Customer[];
  contractId!: number;
  travelId!: number;
  cancelEditUrl!: string;

  constructor(public route: ActivatedRoute, public contractService: ContractService, public router: Router) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.contractId = parseInt(params["contractId"]);
      this.travelId = parseInt(params["id"]);
      this.cancelEditUrl = `/dashboard/travels/${this.travelId}/contract`;

      this.fetchContractDate();
    });
  }

  fetchContractDate() {
    this.contractService.getTravelContract(this.travelId).subscribe(
      (response) => {
        this.contract = response;
        this.passangers = response.passengers.map((passenger: any) => {
          passenger.fullName = passenger.name + " " + passenger.surname;
          passenger.dateOfBirth = moment(passenger.dateOfBirth).format("DD.MM.YYYY");
          return passenger;
        });
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }
}
