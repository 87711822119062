import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-customer",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M0.8,16.2C1,15.8,1.1,15.4,1.4,15c0.3-0.4,0.8-0.8,1.3-1c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0
				 c-0.4-0.4-0.7-0.9-0.7-1.5c0-0.5,0.2-0.9,0.6-1.2c0.7-0.6,1.8-0.6,2.5,0.1c0.6,0.6,0.8,1.8-0.1,2.7c0.3,0.1,0.5,0.3,0.7,0.4
				 c0.2,0.2,0.4,0.4,0.6,0.5c0.3-0.3,0.6-0.6,1-0.9c0.4-0.3,0.8-0.5,1.2-0.7c-0.7-0.5-1-1.2-1-2.1c0.1-0.6,0.4-1.2,0.9-1.6
				 C9.6,8.9,11,9,11.8,10c0.8,0.9,0.7,2.5-0.5,3.4c0.9,0.3,1.6,0.8,2.2,1.6c0.4-0.5,0.8-0.8,1.4-1c-0.5-0.5-0.8-1-0.6-1.7
				 c0.1-0.5,0.3-0.8,0.7-1.1c0.7-0.6,1.8-0.5,2.4,0.2c0.7,0.7,0.7,1.7-0.1,2.6c0.3,0.2,0.6,0.3,0.9,0.6c0.5,0.4,0.9,0.9,1.1,1.5
				 c0.2,0.7-0.2,1.3-0.9,1.3c-1.2,0-2.5,0-3.7,0c-0.1,0-0.1,0-0.2,0c0,0.2,0,0.3,0,0.5c0,0.8-0.6,1.4-1.4,1.4c-1.9,0-3.8,0-5.6,0
				 c-0.8,0-1.4-0.6-1.4-1.4c0-0.1,0-0.3,0-0.5c-0.1,0-0.2,0-0.2,0c-1.2,0-2.3,0-3.5,0c-0.7,0-0.9-0.1-1.2-0.8
				 C0.8,16.4,0.8,16.3,0.8,16.2z M10,18.6c1,0,1.9,0,2.9,0c0.4,0,0.8-0.3,0.8-0.7c0-0.4,0-0.7,0-1.1c-0.2-1.6-1.4-2.8-3-3.1
				 c-1.5-0.3-3,0.4-3.8,1.7c-0.4,0.7-0.5,1.4-0.5,2.2c0,0.5,0.3,0.8,0.9,0.8C8.1,18.6,9.1,18.6,10,18.6z M10,9.7c-1,0-1.7,0.8-1.7,1.7
				 c0,0.9,0.8,1.7,1.7,1.7c1,0,1.7-0.8,1.7-1.7C11.7,10.5,11,9.7,10,9.7z M14.3,16.7c0.2,0,0.4,0,0.6,0c1.1,0,2.2,0,3.2,0
				 c0.4,0,0.6-0.2,0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.6c-1-1.5-3.2-1.6-4.3-0.1c0,0.1-0.1,0.1-0.1,0.2C14,16,14.1,16.3,14.3,16.7z
					M5.7,16.7C5.9,16.3,6,16,6.1,15.6c0-0.1,0-0.2,0-0.3c-0.7-0.8-1.5-1.2-2.6-1c-1,0.2-1.7,0.8-2.1,1.7c-0.2,0.4,0,0.6,0.4,0.6
				 c1.2,0,2.5,0,3.7,0C5.7,16.7,5.7,16.7,5.7,16.7z M14.8,12.5c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2
				 C15.3,11.3,14.8,11.9,14.8,12.5z M5.2,12.5c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2
				 C4.6,13.8,5.2,13.2,5.2,12.5z"
          />
          <path
            class="st0"
            d="M0.8,5.1c0.1-0.2,0.3-0.3,0.6-0.3c0.4,0,0.8,0,1.2-0.1c0.1,0,0.2,0,0.2-0.1c0.1-0.4,0.3-0.8,0.5-1.2
				 c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0.3,0.8,0.5,1.2c0,0.1,0.1,0.1,0.2,0.2c0.4,0,0.9,0,1.3,0.1
				 c0.2,0,0.4,0.1,0.4,0.3c0.1,0.2,0,0.4-0.1,0.5C6,5.9,5.6,6.2,5.3,6.4C5.2,6.5,5.1,6.6,5.2,6.7c0.1,0.4,0.2,0.8,0.3,1.2
				 c0.1,0.2,0,0.4-0.1,0.5C5.2,8.5,5,8.5,4.9,8.4C4.5,8.2,4.1,7.9,3.8,7.7c-0.1-0.1-0.2-0.1-0.2,0C3.2,7.9,2.8,8.2,2.4,8.4
				 c-0.2,0.1-0.3,0.1-0.5,0C1.8,8.3,1.7,8.2,1.8,7.9C1.9,7.5,2,7.1,2.1,6.7c0.1-0.2,0-0.3-0.1-0.4C1.7,6.1,1.4,5.9,1.1,5.7
				 C1,5.6,0.9,5.5,0.8,5.4C0.8,5.3,0.8,5.2,0.8,5.1z M2.4,7.7c0.3-0.2,0.5-0.3,0.7-0.5c0.3-0.3,0.7-0.3,1,0c0.2,0.2,0.4,0.3,0.7,0.5
				 C4.8,7.3,4.7,7,4.6,6.6c-0.1-0.2,0-0.4,0.2-0.5C5,5.9,5.2,5.7,5.4,5.5c0,0,0.1-0.1,0.2-0.1c-0.4,0-0.8,0-1.1-0.1
				 c-0.2,0-0.3-0.1-0.4-0.3C4,4.9,3.9,4.7,3.8,4.5C3.8,4.3,3.7,4.2,3.6,4C3.5,4.4,3.4,4.7,3.2,5C3.2,5.2,3,5.3,2.8,5.3
				 c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0-0.3,0C2,5.7,2.2,5.9,2.5,6.1c0.2,0.2,0.3,0.4,0.2,0.6C2.6,7,2.5,7.4,2.4,7.7z"
          />
          <path
            class="st0"
            d="M10.1,0.8c0.1,0.1,0.2,0.2,0.2,0.4c0,0.3,0,0.6,0,0.9c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
				 c0-0.3,0-0.6,0-0.9c0-0.2,0-0.3,0.2-0.4C10,0.8,10,0.8,10.1,0.8z"
          />
          <path
            class="st0"
            d="M11.4,8.5c0,0-0.1,0-0.2-0.1c-0.4-0.2-0.7-0.5-1.1-0.7C10,7.6,10,7.6,9.9,7.7C9.5,7.9,9.2,8.2,8.8,8.4
				 c-0.2,0.1-0.3,0.1-0.5,0C8.1,8.3,8.1,8.2,8.1,7.9c0.1-0.4,0.2-0.8,0.3-1.3c0-0.1,0-0.2-0.1-0.2c-0.3-0.3-0.7-0.5-1-0.8
				 C7.2,5.5,7.1,5.3,7.2,5.1c0.1-0.2,0.2-0.3,0.4-0.3c0.4,0,0.9,0,1.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.4,0.3-0.8,0.5-1.2
				 C9.7,3.2,9.8,3.1,10,3.1c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0.3,0.8,0.5,1.2c0,0.1,0.1,0.1,0.2,0.2c0.4,0,0.9,0,1.3,0.1
				 c0.2,0,0.4,0.1,0.4,0.3c0.1,0.2,0,0.3-0.2,0.5c-0.3,0.3-0.7,0.6-1,0.8c-0.1,0.1-0.1,0.1-0.1,0.2c0.1,0.4,0.2,0.9,0.4,1.3
				 C12,8.3,11.8,8.5,11.4,8.5z M12,5.4c-0.4,0-0.7-0.1-1-0.1c-0.3,0-0.5-0.1-0.6-0.4c-0.1-0.3-0.2-0.6-0.4-1C9.9,4.3,9.7,4.7,9.6,5
				 C9.5,5.2,9.4,5.3,9.1,5.3c-0.3,0-0.7,0-1.1,0.1c0.3,0.2,0.6,0.5,0.8,0.7c0.2,0.2,0.3,0.4,0.2,0.6C9,7,8.9,7.2,8.8,7.5
				 c0,0,0,0.1,0,0.2c0.3-0.2,0.5-0.3,0.7-0.4c0.4-0.3,0.7-0.3,1,0c0.2,0.2,0.4,0.3,0.7,0.5c0-0.1,0-0.1,0-0.2
				 c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.2,0-0.4,0.2-0.5C11.4,5.9,11.7,5.7,12,5.4z"
          />
          <path
            class="st0"
            d="M18.3,8.1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0c-0.4-0.2-0.7-0.5-1.1-0.7c-0.1-0.1-0.2-0.1-0.3,0
				 c-0.3,0.2-0.7,0.5-1,0.7c-0.2,0.1-0.3,0.1-0.5,0c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.4,0.2-0.8,0.3-1.2c0-0.1,0-0.2-0.1-0.3
				 c-0.3-0.3-0.7-0.5-1-0.8c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0.2-0.3,0.5-0.3c0.4,0,0.8,0,1.3-0.1c0.1,0,0.2,0,0.2-0.2
				 c0.1-0.4,0.3-0.8,0.5-1.2c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0.3,0.8,0.4,1.2c0,0.1,0.1,0.2,0.3,0.2
				 c0.4,0,0.8,0,1.3,0.1c0.2,0,0.4,0.1,0.4,0.3c0.1,0.2,0,0.3-0.2,0.5c-0.3,0.3-0.7,0.5-1,0.8c-0.1,0.1-0.1,0.1-0.1,0.3
				 c0.1,0.4,0.2,0.8,0.3,1.2C18.2,8,18.2,8,18.3,8.1z M14.4,5.4c0.3,0.2,0.5,0.4,0.8,0.6c0.3,0.2,0.3,0.4,0.2,0.7
				 c-0.1,0.3-0.2,0.6-0.3,1c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.2,0.4-0.2,0.7,0c0.3,0.2,0.6,0.4,0.9,0.6c-0.1-0.4-0.2-0.7-0.3-1.1
				 c-0.1-0.2,0-0.4,0.2-0.6c0.3-0.2,0.5-0.4,0.8-0.7c-0.4,0-0.7-0.1-1.1-0.1c-0.3,0-0.4-0.1-0.5-0.3c-0.1-0.3-0.2-0.7-0.4-1
				 c0,0.1-0.1,0.1-0.1,0.2C16.2,4.5,16.1,4.7,16,5c-0.1,0.2-0.2,0.3-0.5,0.3C15.1,5.4,14.8,5.4,14.4,5.4z"
          />
          <path
            class="st0"
            d="M11.6,4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1,0-0.2,0.1-0.3C11.8,3.2,12,3,12.3,2.7c0.1-0.1,0.2-0.2,0.3-0.3
				 c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.4c-0.4,0.3-0.8,0.7-1.1,1C11.8,3.9,11.7,3.9,11.6,4z"
          />
          <path
            class="st0"
            d="M7.1,2.3c0.1,0.1,0.2,0.1,0.2,0.1c0.4,0.3,0.8,0.7,1.1,1c0.1,0.1,0.2,0.3,0,0.4C8.4,4,8.3,4,8.1,3.9
				 C7.7,3.5,7.4,3.2,7,2.8C6.9,2.7,6.8,2.6,6.9,2.5C6.9,2.4,7,2.4,7.1,2.3z"
          />
          <path
            class="st1"
            d="M10,18.6c-0.9,0-1.9,0-2.8,0c-0.5,0-0.8-0.3-0.9-0.8c0-0.8,0.1-1.5,0.5-2.2c0.8-1.3,2.3-2,3.8-1.7
				 c1.6,0.3,2.8,1.5,3,3.1c0.1,0.4,0,0.7,0,1.1c0,0.4-0.4,0.7-0.8,0.7C11.9,18.6,10.9,18.6,10,18.6z"
          />
          <path class="st1" d="M10,9.7c1,0,1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7C8.3,10.5,9,9.7,10,9.7z" />
          <path
            class="st1"
            d="M14.3,16.7c-0.1-0.4-0.3-0.8-0.4-1.2c0-0.1,0-0.1,0.1-0.2c1.1-1.4,3.3-1.4,4.3,0.1c0.1,0.2,0.2,0.4,0.3,0.6
				 c0.1,0.4,0,0.6-0.4,0.6c-1.1,0-2.2,0-3.2,0C14.7,16.7,14.4,16.7,14.3,16.7z"
          />
          <path
            class="st1"
            d="M5.7,16.7c0,0-0.1,0-0.1,0c-1.2,0-2.5,0-3.7,0c-0.4,0-0.6-0.2-0.4-0.6c0.4-1,1.1-1.6,2.1-1.7
				 c1-0.2,1.9,0.2,2.6,1c0.1,0.1,0.1,0.2,0,0.3C6,16,5.9,16.3,5.7,16.7z"
          />
          <path
            class="st1"
            d="M14.8,12.5c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.6,1.2-1.2,1.2C15.3,13.8,14.8,13.2,14.8,12.5
				 z"
          />
          <path class="st1" d="M5.2,12.5c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2C4.7,11.3,5.2,11.9,5.2,12.5z" />
          <path
            class="st1"
            d="M2.4,7.7c0.1-0.4,0.2-0.7,0.3-1c0.1-0.3,0-0.4-0.2-0.6C2.2,5.9,2,5.7,1.7,5.4c0.1,0,0.2,0,0.3,0
				 c0.3,0,0.6,0,0.9,0C3,5.3,3.2,5.2,3.2,5c0.1-0.3,0.3-0.7,0.4-1c0.1,0.2,0.1,0.4,0.2,0.5C3.9,4.7,4,4.9,4.1,5.1
				 c0.1,0.2,0.2,0.3,0.4,0.3c0.4,0,0.7,0,1.1,0.1C5.5,5.5,5.5,5.5,5.4,5.5C5.2,5.7,5,5.9,4.7,6.1C4.6,6.2,4.5,6.4,4.6,6.6
				 C4.7,7,4.8,7.3,4.9,7.7C4.6,7.5,4.3,7.4,4.1,7.2C3.8,7,3.5,7,3.1,7.2C2.9,7.4,2.7,7.5,2.4,7.7z"
          />
          <path
            class="st1"
            d="M12,5.4c-0.3,0.3-0.6,0.5-0.9,0.7c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0,0,0.1,0,0.2
				 c-0.3-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.7-0.3-1,0C9.3,7.4,9.1,7.5,8.8,7.7c0-0.1,0-0.1,0-0.2C8.9,7.2,9,7,9.1,6.7
				 c0.1-0.3,0-0.5-0.2-0.6C8.6,5.9,8.3,5.6,8,5.4c0.4,0,0.7,0,1.1-0.1c0.2,0,0.4-0.1,0.5-0.3C9.7,4.7,9.9,4.3,10,4
				 c0.1,0.4,0.3,0.7,0.4,1c0.1,0.3,0.3,0.4,0.6,0.4C11.3,5.3,11.6,5.4,12,5.4z"
          />
          <path
            class="st1"
            d="M14.4,5.4c0.4,0,0.8,0,1.1-0.1c0.2,0,0.4-0.1,0.5-0.3c0.1-0.3,0.2-0.6,0.3-0.8c0-0.1,0-0.1,0.1-0.2
				 c0.1,0.4,0.3,0.7,0.4,1c0.1,0.2,0.2,0.3,0.5,0.3c0.3,0,0.7,0,1.1,0.1c-0.3,0.2-0.6,0.5-0.8,0.7c-0.2,0.2-0.3,0.3-0.2,0.6
				 c0.1,0.3,0.2,0.7,0.3,1.1c-0.3-0.2-0.6-0.4-0.9-0.6C16.5,7,16.2,7,16,7.1c-0.3,0.2-0.6,0.4-0.9,0.6c0.1-0.4,0.2-0.7,0.3-1
				 c0.1-0.3,0-0.5-0.2-0.7C14.9,5.9,14.7,5.6,14.4,5.4z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuCustomerComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
