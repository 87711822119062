import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/service/base.http.service';
import { DailySummary } from '../models/dailysummary';
import { Observable } from 'rxjs';
import { DailyWorkerSummary } from '../models/dailyworkersummary';

@Injectable({
  providedIn: 'root'
})
export class DailySummaryService  extends BaseHttpService {

	constructor(private http: HttpClient) {
    super();
  }
	getDailySummaryForDate(from: string): Observable<DailySummary[]> {
    return this.http.get<DailySummary[]>(`${this.apiUrl}/api/users/summary?from=${from}`);
  }
	getDailyWorkerSummaryForDate(from: string): Observable<DailyWorkerSummary[]> {
    return this.http.get<DailyWorkerSummary[]>(`${this.apiUrl}/api/users/summary-admin?from=${from}`);
  }
}
