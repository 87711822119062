import { AbstractControl, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

// Custom validator function to validate date format (DD.MM.YYYY) using Day.js
export function dateFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === "" || control.value === null || control.value === undefined) return null;
    const date = moment(control.value, "DD.MM.YYYY");
    // Check if the value matches the expected date format (DD.MM.YYYY)
    if (!date.isValid()) {
      return { invalidDateFormat: { value: control.value } };
    }

    // Date format is valid
    return null;
  };
}

export function dateTimeFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date = moment(control.value, "DD.MM.YYYY hh:mm");
    // Check if the value matches the expected date format (DD.MM.YYYY)
    if (!date.isValid()) {
      return { invalidDateFormat: { value: control.value } };
    }

    // Date format is valid
    return null;
  };
}
