import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-travel",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path class="st0" d="M7.2,18.5c0.2,0,0.5,0,0.7,0c-0.1,0.2-0.1,0.4-0.3,0.4C7.3,18.8,7.3,18.6,7.2,18.5z" />
          <path class="st0" d="M11.5,18.5c0.2,0,0.4,0,0.7,0c-0.1,0.2-0.1,0.4-0.3,0.4C11.6,18.8,11.6,18.6,11.5,18.5z" />
          <path
            class="st1"
            d="M7.4,19.5c-0.4,0-0.6-0.4-0.8-0.7c-0.1-0.2-0.2-0.4-0.5-0.4c-0.7-0.2-1.1-0.8-1.2-1.5c0-0.1,0-0.1,0-0.2
				 c0-3.3,0-6.7,0-10c0-0.8,0.3-1.3,1-1.6c0.2-0.1,0.5-0.1,0.8-0.2c0.5,0,0.9,0,1.4,0c0-1.2,0-2.4,0-3.7c-0.3,0-0.5,0-0.8,0
				 C7.1,1.2,6.9,1,6.9,0.8c0-0.2,0.1-0.3,0.4-0.3c1.8,0,3.6,0,5.3,0c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.4,0.3c-0.3,0-0.5,0-0.8,0
				 c0,1.2,0,2.4,0,3.7c0.5,0,0.9,0,1.4,0c0.2,0,0.4,0,0.6,0.1c0.7,0.2,1.2,0.8,1.2,1.5c0,0.1,0,0.1,0,0.2c0,3.3,0,6.7,0,10
				 c0,0.8-0.3,1.3-1,1.6c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0.1c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.4,0.6-0.7,0.6
				 c-0.1,0-0.2,0-0.3,0c-0.4,0-0.6-0.3-0.7-0.6c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.5,0-1.1,0-1.6,0
				 c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.4-0.4,0.8-0.8,0.8C7.6,19.5,7.5,19.5,7.4,19.5z M14.4,11.6c0-1.7,0-3.3,0-5c0-0.7-0.4-1.1-1.2-1.1
				 c-1.2,0-2.4,0-3.7,0c-1,0-1.9,0-2.9,0c-0.3,0-0.6,0.1-0.8,0.3C5.7,6,5.6,6.3,5.6,6.6c0,3.3,0,6.7,0,10c0,0.1,0,0.1,0,0.2
				 c0,0.5,0.5,0.9,1,0.9c2.2,0,4.5,0,6.7,0c0.1,0,0.2,0,0.3,0c0.5-0.1,0.8-0.5,0.8-1.1C14.4,15,14.4,13.3,14.4,11.6z M11.2,4.8
				 c0-1.2,0-2.4,0-3.7c-0.8,0-1.6,0-2.4,0c0,1.2,0,2.4,0,3.7C9.6,4.8,10.4,4.8,11.2,4.8z M7.2,18.5c0.1,0.2,0.1,0.4,0.3,0.4
				 c0.2,0,0.2-0.2,0.3-0.4C7.6,18.5,7.4,18.5,7.2,18.5z M11.5,18.5c0.1,0.2,0.1,0.4,0.3,0.4c0.2,0,0.2-0.2,0.3-0.4
				 C12,18.5,11.7,18.5,11.5,18.5z"
          />
          <path
            class="st0"
            d="M14.4,11.6c0,1.7,0,3.3,0,5c0,0.6-0.3,1-0.8,1.1c-0.1,0-0.2,0-0.3,0c-2.2,0-4.5,0-6.7,0c-0.5,0-0.9-0.4-1-0.9
				 c0-0.1,0-0.1,0-0.2c0-3.3,0-6.7,0-10c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c1,0,1.9,0,2.9,0c1.2,0,2.4,0,3.7,0
				 c0.7,0,1.2,0.4,1.2,1.1C14.4,8.3,14.4,10,14.4,11.6z M12.1,10.5c0,1,0,1.9,0,2.9c0,0.3,0,0.6,0,0.9c0,0.2,0.1,0.3,0.3,0.3
				 c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.1,0-0.2c0-2.4,0-4.9,0-7.3c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.3,0.3
				 c0,0.1,0,0.1,0,0.2C12.1,8.1,12.1,9.3,12.1,10.5z M7.9,10.6c0-1.2,0-2.4,0-3.6c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.3-0.3
				 c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0,0.1,0,0.2c0,2.4,0,4.9,0,7.3c0,0.1,0,0.1,0,0.2c0,0.2,0.2,0.3,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3
				 c0-0.1,0-0.1,0-0.2C7.9,13,7.9,11.8,7.9,10.6z M10.3,12.2c0-1.2,0-2.4,0-3.7c0-0.1,0-0.1,0-0.2c0-0.2-0.2-0.3-0.3-0.3
				 C9.8,8,9.7,8.2,9.7,8.3c0,0.1,0,0.1,0,0.2c0,2.4,0,4.9,0,7.3c0,0.1,0,0.1,0,0.2c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
				 c0-0.1,0-0.1,0-0.2C10.3,14.6,10.3,13.4,10.3,12.2z"
          />
          <path
            class="st1"
            d="M12.1,10.5c0-1.2,0-2.4,0-3.6c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0,0.4,0.2c0,0.1,0,0.2,0,0.2
				 c0,2.4,0,4.9,0,7.3c0,0.1,0,0.1,0,0.2c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.3,0-0.6,0-0.9
				 C12.1,12.5,12.1,11.5,12.1,10.5z"
          />
          <path
            class="st1"
            d="M7.9,10.6c0,1.2,0,2.4,0,3.6c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.2
				 c0-0.1,0-0.1,0-0.2c0-2.4,0-4.9,0-7.3c0-0.1,0-0.2,0-0.2c0.1-0.2,0.2-0.2,0.3-0.2c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.1,0,0.2
				 C7.9,8.1,7.9,9.3,7.9,10.6z"
          />
          <path
            class="st1"
            d="M10.3,12.2c0,1.2,0,2.4,0,3.6c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
				 c0-0.1,0-0.1,0-0.2c0-2.4,0-4.9,0-7.3c0-0.1,0-0.1,0-0.2C9.7,8.2,9.8,8,10,8c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.1,0,0.2
				 C10.3,9.8,10.3,11,10.3,12.2z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuTravelComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
