<div class="col-span-1 sm:col-span-1" *ngIf="showDocumentPanel">
  <div class="panel relative rounded-lg rounded-md border border-white-dark/20 bg-white p-4 shadow-md dark:bg-gray-900" id="payment">
    <div class="d-flex justify-content-between align-items-center">
      <div class="flex flex-wrap justify-start">
        <img src="/assets/images/eccoLogo.png" alt="" class="w-15 h-10" />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">28.Novembar br.54,Novi Pazar 36 300 </span>
        <icon-home />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">020/311-575,335-850 </span>
        <icon-phone />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">eccodoo@gmail.com </span>
        <icon-mail />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">www.ecco.rs </span>
        <icon-chrome />
      </div>
      <div class="flex flex-wrap justify-end">
        <span class="mr-2">žiro račun: 155-32299-16,PIB:100769049 </span>
        <icon-zip-file />
      </div>
    </div>
    <div class="mt-1 flex">
      <div>
        <div class="flex items-center">
          <label for="number" class="mt-1 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.previouspayments" | translate }}:</label>
          <div class="w-2/3 lg:w-[250px]" name="invoiceNo">
            <div *ngIf="paymentDocument && paymentDocument.paymentDetails">
              <div *ngFor="let payment of parsePaymentDetails(paymentDocument.paymentDetails)" class="flex">
                <div class="mr-4">{{ payment.PayedAt }}</div>
                <div>{{ payment.Amount | currency : "EUR" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkin" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.customerFullName" | translate }}:</label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.customerFullName }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="hotel" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.travelTitle" | translate }}:</label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.travelTitle }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.totalPrice" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.totalPrice }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.toBePaid" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.toBePaid }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.avans" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.avans | number : "1.2-2" }}</div>
        </div>
				<div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.paymentType" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.paymentType }}</div>
        </div>
        <div class="mt-4 flex items-center">
          <label for="checkout" class="mb-0 flex-1 ltr:mr-2 rtl:ml-2">{{ "paymentdetails.paymentDate" | translate }}: </label>
          <div class="w-2/3 lg:w-[250px]">{{ paymentDocument?.paymentDate | date : "dd/MM/yyyy" }}</div>
        </div>
      </div>
    </div>
    <hr class="my-2 border-[#e0e6ed] dark:border-[#1b2e4b]" />
    <div class="mt-4">
      <div class="mx-auto rounded-lg">
        <div class="flex justify-between">
          <div>
            <p>
              <strong>{{ "paymentdetails.dateOfPayment" | translate }}:</strong> {{ getCurrentDate() | date : "dd.MM.yyyy" }}
            </p>
            <p>
              <strong>{{ "paymentdetails.director" | translate }}:</strong> Esad Gicic
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
