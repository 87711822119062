import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/service/base.http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseHttpService{

  constructor(private http: HttpClient) {
		super();
	}
	
	getPicklistType(delimeter: string) {

		const apiUrl = `${this.apiUrl}/api/picklists/type`;

		const params = new HttpParams()
		.set('delimeter', delimeter);

		return this.http.get<any[]>(apiUrl, { params });
	}

	addPicklistItem(data: any) {
		const apiUrl = `${this.apiUrl}/api/picklists`;

		return this.http.post(apiUrl, data);
	}

	updatePicklistItem(data: any, id: number) {
		const apiUrl = `${this.apiUrl}/api/picklists/${id}`;

		return this.http.put(apiUrl, data);
	}
	deletePicklistItem(id: number) {
		const apiUrl = `${this.apiUrl}/api/picklists/${id}`;
		return this.http.delete(apiUrl);
	}
}


