import { Component, OnInit } from "@angular/core";
import { DailySaleDocument } from "../../models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { SalesService } from "../../services/sales.service";


@Component({
  selector: "app-daily-sale-edit",
  templateUrl: "./daily-sale.edit.component.html",
})
export class DailySaleEditComponent implements OnInit {
  constructor(private salesService: SalesService, private route: ActivatedRoute, private router: Router) {}
  dailySaleDocument!: DailySaleDocument;
  cashRegisterId!: number;
  totalPrice = 0;
  all = 0;
  values = [5000, 2000, 1000, 500, 200, 100, 50, 20, 10, 5, 2, 1];
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.cashRegisterId = params.get("id") ? parseInt(params.get("id")!) : 0;
      this.getDailySaleDocument(this.cashRegisterId);
    });
  }

  getDailySaleDocument(id: number) {
    this.salesService.getDailySaleDocument(id).subscribe((response) => {
      this.dailySaleDocument = response;
      this.dailySaleDocument.items.forEach((item) => {
        this.totalPrice += item.price;
      });
    });
  }

  updateDocument() {
    this.salesService.updateDailySaleDocument(this.cashRegisterId, this.dailySaleDocument).subscribe((response) => {
      console.log(response);
    });
  }

  saveDocument() {
    this.dailySaleDocument.denomination.map((denom, index) => {
      this.all += denom * this.values[index];
    });
    this.updateDocument();
  }

  calculate() {
    this.all = 0;
    this.dailySaleDocument.denomination.map((denom, index) => {
      this.all += denom * this.values[index];
    });
  }
}
