import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-book-of-evidence",
  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            fill: #ffffff;
          }
          .st1 {
            fill: #2b2d41;
          }
        </style>
        <g>
          <path
            class="st0"
            d="M19.1,6.9v10.6c0,0.4-0.1,0.4-0.4,0.4H1.3c-0.4,0-0.4-0.1-0.4-0.4V6.9c0-0.4,0.1-0.5,0.5-0.5
				 c0.3,0,0.5,0,0.8,0c0,0,0,0,0.1,0C2.1,6,2.4,5.8,2.8,5.7C4.1,5.2,5.6,5.1,7,5.2c0.9,0.1,1.7,0.3,2.6,0.5c0,0,0.1,0,0.1,0
				 c0.1-0.3,0.1-0.6,0.2-0.9c0.4-1.4,1.3-2.2,2.6-2.7C12.8,2,13,2.1,13,2.5v2.6c0,0,0,0,0.1,0c1.3,0,3.3,0.3,4.5,0.8
				 c0.2,0.1,0.2,0.2,0.2,0.4v0.3h0.8c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.3,0.3C19.1,6.8,19.1,6.9,19.1,6.9z"
          />
          <g>
            <path
              class="st1"
              d="M19.1,6.8c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0-0.3,0h-0.8V6.2c0-0.2-0.1-0.3-0.2-0.4
					 c-1.2-0.4-3.2-0.7-4.5-0.8c0,0,0,0-0.1,0V2.5c0-0.3-0.2-0.5-0.5-0.4c-1.3,0.5-2.2,1.4-2.6,2.7C9.8,5.1,9.8,5.4,9.7,5.7
					 c0,0-0.1,0-0.1,0C8.8,5.5,7.9,5.3,7,5.2c-1.5-0.1-2.9,0-4.3,0.5C2.4,5.8,2.1,6,2.2,6.4c-0.1,0-0.1,0-0.1,0c-0.3,0-0.5,0-0.8,0
					 c-0.4,0-0.5,0.1-0.5,0.5v10.6c0,0.4,0.1,0.4,0.4,0.4h17.4c0.4,0,0.4-0.1,0.4-0.4V6.9C19.1,6.9,19.1,6.8,19.1,6.8z M12.7,12.8
					 c0.2-0.1,0.3-0.2,0.3-0.4c0-2.2,0-4.5,0-6.7h0c0.1,0,0.1,0,0.2,0c1,0,2.7,0.3,3.6,0.5c0.4,0.1,0.4,0.1,0.4,0.5c0,0.1,0,0.2,0,0.3
					 v8.7c0,0.1,0,0.1,0,0.2c-2.3-0.5-4.6-0.5-6.9,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1C10.5,14.3,11.4,13.4,12.7,12.8z M10.7,4.4
					 C11,3.7,11.7,3,12.4,2.8V3c0,3,0,6,0,9c0,0.2,0,0.3-0.2,0.3c-0.7,0.3-1.3,0.8-1.8,1.5c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0
					 c0-0.1,0-0.1,0-0.2c0-2.4,0-4.9,0-7.3C10.3,5.7,10.3,5.1,10.7,4.4z M2.8,7c0-0.2,0-0.3,0-0.5c0-0.2,0-0.2,0.2-0.3
					 c1-0.4,2-0.5,3-0.5c1.2,0,2.4,0.2,3.5,0.6c0.1,0,0.2,0.1,0.2,0.3c0,3,0,6.1,0,9.1V16c-1.2-0.2-2.3-0.4-3.5-0.4
					 c-1.2,0-2.3,0.1-3.5,0.4V7z M18.5,17.4H1.4v-0.2c0-3.3,0-6.6,0-9.8c0-0.2,0-0.2,0.2-0.2c0.5,0,0.5,0,0.5,0.5c0,2.9,0,5.7,0,8.6
					 c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.2,0.4,0.1c1.2-0.3,2.5-0.5,3.7-0.4c1.2,0,2.4,0.1,3.5,0.4c0.1,0,0.2,0,0.3,0
					 c1.6-0.4,3.2-0.5,4.8-0.4c0.8,0.1,1.7,0.2,2.5,0.4c0.2,0,0.4-0.1,0.4-0.3c0-0.1,0-0.1,0-0.2v-9h0.7V17.4z"
            />
            <path
              class="st1"
              d="M14.6,12.7c-0.2,0-0.5,0-0.7,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.5,0,1,0,1.5,0
					 c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3C15.1,12.8,14.9,12.7,14.6,12.7z"
            />
            <path
              class="st1"
              d="M14.6,8.8c-0.3,0-0.5,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.5,0,1,0,1.5,0
					 c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3C15.1,8.8,14.9,8.8,14.6,8.8z"
            />
            <path
              class="st1"
              d="M14.6,10.2c0.2,0,0.5,0,0.7,0c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.5,0-1,0-1.5,0
					 c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3C14.1,10.2,14.4,10.2,14.6,10.2z"
            />
            <path
              class="st1"
              d="M6.2,12.2c0.5,0,1.1,0,1.6,0c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.4,0.3c-1.1,0-2.2,0-3.3,0
					 c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.4-0.3C5.1,12.2,5.7,12.2,6.2,12.2z"
            />
            <path
              class="st1"
              d="M6.2,8.8c-0.5,0-1,0-1.6,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3
					 c0.1,0,0.1,0,0.2,0c1.1,0,2.1,0,3.2,0c0.2,0,0.3,0,0.4,0.2c0.1,0.2-0.1,0.4-0.3,0.4C7.3,8.8,6.8,8.8,6.2,8.8z"
            />
            <path
              class="st1"
              d="M6.2,10.8c-0.5,0-1,0-1.6,0c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3
					 c0,0,0.1,0,0.2,0c1,0,2.1,0,3.1,0c0.1,0,0.2,0,0.2,0c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.2,0.3c-0.1,0-0.2,0-0.2,0
					 C7.3,10.8,6.7,10.8,6.2,10.8z"
            />
          </g>
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuBookOfEvidenceComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
