import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/service/base.http.service';
import { OtherServices } from '../models/otherservices';
import { GenericResponse } from 'src/app/shared/models/generics';
import { map, Observable } from 'rxjs';
import { Sale } from '../../sales/models/sale';

@Injectable({
	providedIn: 'root'
})
export class OtherServicesService extends BaseHttpService {

	constructor(private http: HttpClient) {
		super();
	}

	getOtherServicesWithHeaders(pageNumber: number, pageSize: number, filterType: string) {
		const apiUrl = `${this.apiUrl}/api/planService/paginated`;
		const params = new HttpParams()
			.set("pageNumber", pageNumber.toString())
			.set("pageSize", pageSize.toString())
			.set("from", "2021-03-24")
			// .set('sortProperty', 'numberOfPassangers')
			// .set('sortType', 'Asc')
			.set("filterType", filterType);

		return this.http.get<OtherServices[]>(apiUrl, { params, observe: "response" }).pipe(
			map((response) => {
				const customResponse: GenericResponse<OtherServices> = {
					body: response.body ?? [],
					headers: response.headers,
				};
				return customResponse;
			}),
		);
	}
	getOtherServiceData(id: number): Observable<OtherServices> {
		return this.http.get<OtherServices>(`${this.apiUrl}/api/planService/${id}`);
	}
	updateOtherService(id: any, data: any) {
		return this.http.put<OtherServices>(`${this.apiUrl}/api/planService/${id}`, data);
	}
	getPlanData(id: number): Observable<Sale> {
		return this.http.get<Sale>(`${this.apiUrl}/api/plans/${id}`);
	}
	getPlanServices(): Observable<OtherServices[]> {
		return this.http.get<OtherServices[]>(`${this.apiUrl}/api/planService`)
	}
	deleteOtherService(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}/api/Plans/planServices/${id}`, { responseType: 'text' });
	}
}
