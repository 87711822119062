
<div class="chart-container" *ngIf="simpleColumnStackedOrganizerProfit">
	<apx-chart
	[series]="simpleColumnStackedOrganizerProfit.series"
		[chart]="simpleColumnStackedOrganizerProfit.chart"
		[dataLabels]="simpleColumnStackedOrganizerProfit.dataLabels"
		[stroke]="simpleColumnStackedOrganizerProfit.stroke"
		[colors]="simpleColumnStackedOrganizerProfit.colors"
		[responsive]="simpleColumnStackedOrganizerProfit.responsive"
		[plotOptions]="simpleColumnStackedOrganizerProfit.plotOptions"
		[xaxis]="simpleColumnStackedOrganizerProfit.xaxis"
		[yaxis]="simpleColumnStackedOrganizerProfit.yaxis"
		[grid]="simpleColumnStackedOrganizerProfit.grid"
		[fill]="simpleColumnStackedOrganizerProfit.fill"
		class="rounded-lg bg-white dark:bg-black"
	>
	</apx-chart>
</div>