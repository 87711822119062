<div>
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 p-5 lg:justify-end print:hidden">
    <button type="button" class="btn btn-info gap-2" (click)="sendVoucher()">
      <icon-send />
      {{ "vaucher.edit_message_and_send" | translate }}
    </button>

    <button type="button" class="btn btn-primary gap-2" (click)="printVoucher()">
      <icon-printer />
      {{ "vaucher.print" | translate }}
    </button>

    <button type="button" class="btn btn-warning gap-2" (click)="navigateToEditVoucher()">
      <icon-edit />
      {{ "vaucher.edit" | translate }}
    </button>
  </div>

  <app-voucher-template [voucher]="announcement"></app-voucher-template>

  <modal #modal class="modal-top custom-modal">
    <ng-template #modalBody>
      <app-file-send
        [title]="'send_announcement_dialog_title' | translate"
        fileType="voucher"
        [passangers]="announcement.passengers"
        [sendDocumentFn]="announcementService.sendAnnouncement(announcement.id)"
        [messageRs]="'sending_announcement_RS_message' | translate"
        [messageEn]="'sending_announcement_EN_message' | translate"
        (notifyWhenSend)="finishSending()"
      >
        <div class="space-y-4 text-start text-sm text-dark">
          <div class="mb-4">
            <h2>{{ "agent_form.data" | translate }}</h2>
            <label for="" class="mb-1 block" *ngIf="organizer.name">{{ "agent_form.name" | translate }}: {{ organizer.name }}</label>
            <label for="" class="mb-1 block" *ngIf="organizer.firstName">{{ "agent_form.fullname" | translate }}: {{ organizer.firstName }} {{ organizer.lastName }}</label>
            <label for="" class="mb-1 block" *ngIf="organizer.phone">{{ "agent_form.phone" | translate }}: {{ organizer.phone }}</label>
            <label for="" class="mb-1 block" *ngIf="organizer.licence">{{ "agent_form.licence" | translate }}: {{ organizer.licence }}</label>
          </div>
        </div>
      </app-file-send>
    </ng-template>
  </modal>
</div>
