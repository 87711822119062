import { HttpClient, HttpParams } from "@angular/common/http";
import { setFrom, setFromTo } from "./../../store/datepicker/datepicker.actions";
// date.service.ts

import { Injectable } from "@angular/core";
import { BaseHttpService } from "src/app/service/base.http.service";
import { DateTimeFormatter } from "../utils/datetime.formatter";

@Injectable({
  providedIn: "root",
})
export class ExportService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getDataToExport<T>(url: string, filterType: string, from: Date, to: Date | undefined, sortColumn?: string | undefined, sortDirection?: string | undefined, search?: string) {
    const apiUrl = `${this.apiUrl}/${url}`;
    let params = new HttpParams()
      .set("filterType", filterType)
      .set("from", DateTimeFormatter.formatDateToString(from))
      .set("to", DateTimeFormatter.formatDateToString(to ?? null));
    if (sortDirection) {
      params = params.set("sortType", sortDirection);
    }

    if (sortColumn) {
      params = params.set("sortProperty", sortColumn);
    }

    if (search) {
      params = params.set("searchText", search);
    }
    return this.http.get<T[]>(apiUrl, { params, observe: "response" });
  }
}
